#account-menu {
	padding-bottom: 0.8em;
}

#account-menu button {
	background: none;
	border: none;
	padding: 0;
}

#account-menu button:hover {
	cursor: pointer;
}

#account-menu .link-button {
	vertical-align: middle;
}

#account-menu .current-user-label {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	text-align: left;
}
/*
#account-menu.mobile-account-menu {
	display: none;
}

#account-menu .mobile-upgrade-btn {
	display: none;
}
*/
.account-popover {
	text-align: left;
	white-space: normal;
	padding: 0.25em 0;
	position: absolute;
	min-width: 14em;
	box-sizing: border-box;
	bottom: 0.5em;
	left: 100%;
	transform: translate(0.5em, 0);
	border: 1px solid var(--editor-menu-border-color);
	border-radius: calc(var(--inspector-border-radius) * 2);
	background-color: var(--input-bg);
	box-shadow: var(--mq-box-shadow);
}

.account-popover-item a {
	color: var(--editor-text-color);
	padding: 0.2em 1em;
	height: 24px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	box-sizing: border-box;
	display: block;
	font-size: 13px;
}

.account-popover-item a:hover,
.account-popover-item a:focus {
	text-decoration: none;
	color: var(--editor-active-bg);
	background: var(--editor-active-color);

	/* Bring the background on top of the popover triangle */
	position: relative;
	z-index: 10;
}
.account-popover-item .separator {
	height: 1px;
	display: block;
	background: var(--editor-bg);
	margin: 0.25em 0;
}

.account-popover .current-user {
	display: flex;
	padding: 0.75em 1em;
	max-width: 12em;
}
.account-popover .current-user-details {
	padding-left: 0.8em;
}
.account-popover .current-user-name {
	font-size: 11px;
	font-weight: bold;
	color: var(--editor-text-color);
}
.account-popover .current-user-name:first-letter {
	text-transform: uppercase;
}
.account-popover .current-user-email {
	font-size: 11px;
	color: var(--editor-text-muted-color);
}
