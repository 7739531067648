.datasheet {
	background: var(--editor-menu-bg);
	border-collapse: collapse;
	table-layout: fixed;
	font-size: 12px;
	margin: 0 auto;
	margin-bottom: 30px;
	box-shadow: var(--mq-box-shadow-md);
}

.datasheet:focus {
	outline: none;
}

.datasheet td,
.datasheet th {
	position: relative;
	border: 1px solid var(--editor-border-color);
	min-width: 10em;
	max-width: 15em;
	color: var(--editor-text-color);
}

.datasheet td {
}

.datasheet tbody th {
	min-width: 12em;
}

.datasheet thead tr th {
	background: var(--editor-bg);
	border-bottom: 1px solid var(--input-border-color);
}

.datasheet th:first-child {
	background: var(--editor-bg);
	border-right: 1px solid var(--input-border-color);
}

.datasheet th:first-child .datasheet__cell {
	text-align: left;
	padding: 0 18px;
}

.datasheet thead tr .datasheet__cell {
}

.datasheet__cell {
	width: 100%;
	height: 100%;
	position: relative;
	box-sizing: border-box;
}

.datasheet__cell--value:hover,
.datasheet__cell--selected {
	outline: 1px solid var(--editor-active-color);
}

.datasheet__cellvalue {
	padding: 0.5em 1em;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.datasheet__cellvalue--empty:after {
	content: '.';
	color: transparent;
	display: block;
}

.datasheet__cellvalue--hidden {
	visibility: hidden;
}

.datasheet__celledit {
	position: absolute; /* position absolute and top-left-right-bottom 0 keeps td the same width when resizing */
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	outline: 1px solid var(--editor-active-color);
}

.datasheet__celledit input {
	padding: 0.5em 1em;
	height: 100%;
	width: 100%;
	border: none;
	box-sizing: border-box;
	font-size: 1em;
	font-family: inherit;
	background: transparent;
	min-width: 0;
	display: block;
	text-align: center;
	-moz-appearance: textfield;
	appearance: textfield;
}

.datasheet__celledit input::-webkit-inner-spin-button,
.datasheet__celledit input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.datasheet__celledit input:focus {
	border: none;
}

/* series input is full-width, so take into account the colorpicker */
.datasheet__cell--series .datasheet__celledit input {
	padding-left: 2em;
}

.datasheet__cell--value {
	text-align: center;
}

.datasheet__cell--series,
.datasheet__cell--label {
	font-weight: bold;
	text-align: center;
}

.datasheet__cell--extra {
	background: #f0f0f0;
	color: transparent;
}

.datasheet__cellmenu {
	position: absolute;
	top: 0;
	right: 0;
	width: 26px;
	height: 100%;
	z-index: 10;
	font-weight: normal;
	text-align: left;
}

.datasheet__cellmenu a {
	color: inherit;
	cursor: auto;
	text-decoration: none;
}

.datasheet__cellmenu .dropdownmenu__wrapper {
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	background: linear-gradient(90deg, transparent 0%, var(--editor-bg) 33%);
}

.datasheet__cellmenu-btn {
	display: flex;
	height: 20px;
	width: 20px;
	border-radius: 2px;
	align-items: center;
	justify-content: center;
	font-size: 8px;
	box-sizing: border-box;
	background: transparent;
	transition: background-color 0.1s var(--mq-transition);
	color: var(--editor-text-color);
	opacity: 0.4;
}

.datasheet__cellmenu-btn .mq-icon {
	fill: var(--editor-text-color);
}

.datasheet__cellmenu-btn:hover {
	background: var(--editor-menu-bg);
	opacity: 1;
}

.datasheet__cell .color__hint {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* to indicate colorpicker is actionable */
.datasheet__cell .must-swatch-button {
	cursor: pointer;
}

.datasheet__cell .must-swatch-button {
	width: 18px;
	height: 18px;
	margin: 0 4px;
}

.chart-editor-outlet .must-popup {
	z-index: 5000;
}
