.fitcontent__options__section {
	margin: 1.25em 0 0;
}

.fitcontent__options__section > div {
	margin-top: 0.4em;
	display: inline-flex;
	align-items: center;
	width: 100%;
}

.fitcontent__options__section label {
	display: inline-flex;
	color: var(--editor-text-muted-color);
	align-items: center;
	padding-right: 1.9em;
	gap: 7px;
}

.fitcontent__options__section input[type='radio']:checked + span,
.fitcontent__options__section input[type='checkbox']:checked + span {
	color: var(--editor-text-color);
}
