.global-search__wrapper {
	right: 0px;
	top: 50px;
	position: absolute;
	z-index: 60;
}

.global-search {
	display: block;
	background: var(--editor-bg);
	border: 1px solid var(--editor-border-color);
	border-top: 0;
	border-right: 0;
	padding: 0.75em 0.5em 0.75em 0.75em;
	width: 360px;
	box-sizing: border-box;
	border-bottom-left-radius: var(--inspector-border-radius);
}

.global-search__searchbox {
	flex-basis: 52%;
}

.global-search label {
	align-items: center;
	padding: 0 0.5em 0 0.5em;
	display: inline-flex;
	color: var(--editor-text-color);
}

.global-search input[type='radio'] {
	margin-right: 0.5em;
}

.global-search .global-search__done {
	vertical-align: middle;
	font-size: 13px;
	font-weight: bold;
	padding: 0 1em;
	margin-left: auto;
}
.global-search__main {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.global-search__nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.global-search__nav--button {
	width: 2em;
	display: flex;
	justify-content: center;
	align-items: center;
}

.global-search__settings {
	padding-top: 1em;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 1.5em;
	font-size: 12px;
}

.global-search__settings span {
	color: var(--editor-text-muted-color);
	margin-right: 5px;
}

.global-search__settings label span {
	color: var(--editor-text-color);
}

.global-search__nav--button .mq-icon {
	fill: var(--link-color);
}

.global-search__nav--button:hover .mq-icon {
	fill: var(--link-active-color);
}

.global-search .global-search__results {
	text-align: center;
	color: var(--editor-text-muted-color);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	padding: 0 10px;
	font-size: 12px;
	flex-grow: 1;
}
