#webnot-wrapper {
	position: fixed;
	bottom: 0;
	left: 50%;
	width: 400px;
	max-width: 80vw;
	transform: translate(-50%, 100%);
	opacity: 0;
	transition: all 0.3s;
	z-index: 100;
	text-align: center;
	border-radius: calc(var(--inspector-border-radius) * 1.5)
		calc(var(--inspector-border-radius) * 1.5) 0 0;
	border-bottom: none;
	overflow: hidden;
	background: var(--mq-blue-600);
	border: 0;
	color: var(--mq-white);
	font-size: 13px;
}

#webnot-wrapper.show {
	opacity: 1;
	transform: translateX(-50%);
	box-shadow: var(--mq-box-shadow);
}

#webnot-wrapper .webnot-heading {
	min-height: 28px;
	padding: 5px 28px 0;
	box-sizing: border-box;
	color: var(--mq-white);
	text-align: center;
	font-weight: 500;
}

#webnot-wrapper .webnot-heading:empty {
	display: none;
}

#webnot-wrapper .webnot-body {
	padding: 0.6em 28px;
	font-size: 13px;
}

#webnot-wrapper .webnot-body a {
	color: var(--mq-white);
	font-weight: 500;
	text-decoration: underline;
}

#webnot-wrapper .webnot-close {
	float: right;
	font-size: 12px;
	position: absolute;
	right: 0;
	top: 0;
	width: 28px;
	height: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
}

#webnot-wrapper .webnot-close:hover {
	cursor: pointer;
	opacity: 0.75;
}

#webnot-wrapper .dismiss .mq-icon {
	fill: var(--editor-text-color);
}
