.chart-editor {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background: var(--editor-bg);
	border-top: 1px solid var(--editor-border-color);
	z-index: 5000;
}

.chart-editor-outlet .must-popup {
	background: var(--toolbar-bg);
	border: 1px solid var(--toolbar-border-color);
	box-shadow: var(--mq-box-shadow-sm);
	border-radius: var(--inspector-border-radius);
}
.chart-editor-outlet .must-color-picker {
	width: 220px;
	border: 0;
}

.datasheet-wrapper {
	overflow: auto;
}

.datasheet-toolbar {
	background: var(--editor-menu-bg);
	position: relative;
	border: 1px solid transparent;
	border-bottom-color: var(--editor-menu-border-color);
	padding: 0.25em 0.5em;
}

.datasheet-toolbar__heading {
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 0.01em;
	color: var(--editor-text-color);
	margin: 0;
}

.datasheet-closebtn {
	position: absolute;
	right: 0.5em;
	top: -1px;
	font-size: 20px;
	height: 20px;
	width: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: var(--editor-icon-color);
}

.datasheet-resizebtn {
	position: absolute;
	top: -1px;
	left: 50%;
	height: 22px;
	width: 24px;
	font-size: 20px;
	transform: translate(-50%, 0);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	cursor: ns-resize;
	color: var(--editor-icon-color);
	font-weight: bold;
}

.datasheet-closebtn:hover,
.datasheet-closebtn:focus,
.datasheet-resizebtn:hover,
.datasheet-resizebtn:focus {
	color: var(--editor-active-color);
}

.datasheet-shortcut {
	text-align: center;
	padding: 10px 0;
	font-size: 11px;
	color: var(--editor-text-muted-color);
}
