.bordermanager__wrapper {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 51;
	pointer-events: none;
}

.bordermanager {
	position: absolute;
}

.bordermanager__plugin {
	pointer-events: all;
	display: inline-block;
}

.bordermanager__zone--wrapper {
	position: absolute;
}

.bordermanager__zone {
	position: absolute;
}

.bordermanager__zone--topleft {
	top: 0;
	left: 0;
	transform: translate(0, -100%);
}

.bordermanager__zone--topcenter {
	top: 0;
	left: 50%;
	transform: translate(-50%, -100%);
}

.bordermanager__zone--topright {
	top: 0;
	right: 0;
	transform: translate(0, -100%);
}

.bordermanager__zone--righttop {
	top: 0;
	right: 0;
	transform: translate(100%, 0);
}

.bordermanager__zone--rightmiddle {
	top: 50%;
	right: 0;
	transform: translate(100%, -50%);
}

.bordermanager__zone--rightbottom {
	bottom: 0;
	right: 0;
	transform: translate(100%, 0);
}

.bordermanager__zone--bottomright {
	bottom: 0;
	right: 0;
	transform: translate(0, 100%);
}

.bordermanager__zone--bottomcenter {
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 100%);
}

.bordermanager__zone--bottomleft {
	bottom: 0;
	left: 0;
	transform: translate(0%, 100%);
}

.bordermanager__zone--leftbottom {
	bottom: 0;
	left: 0;
	transform: translate(-100%, 0%);
}

.bordermanager__zone--leftmiddle {
	top: 50%;
	left: 0;
	transform: translate(-100%, -50%);
}

.bordermanager__zone--lefttop {
	top: 0;
	left: 0;
	transform: translate(-100%, 0%);
}

.bordermanager__zone--centermiddle {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.bordermanager__plugin--notes {
	pointer-events: none;
}

.symbol-border {
	color: var(--symbol-accent);
}

.symbol-border__label {
	display: flex;
	align-items: center;
	font-size: 11px;
}

.symbol-border__label > span {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 0;
	line-height: 1.3;
	height: 1.25em;
}

.symbol-border__label svg {
	padding-right: 0.3em;
	height: 1.25em;
	width: 1.25em;
	min-width: 1.25em;
	fill: currentColor;
}
