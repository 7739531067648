.must-gradient-slider {
	position: relative;
	/* Positioning */
	height: 1rem;
	margin-top: 0.625rem;
	/* Interactivity */
	touch-action: none;
}

.must-gradient-slider:focus-within {
	z-index: 100;
}

.must-gradient-slider .must-handle:not(.must-handle--selected) {
	--size: 0.5rem;
}

.must-gradient-preview {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	content: '';
	border-radius: 0.1875rem;
	background: linear-gradient(to right, var(--stops)), var(--must-bg-checkerboard);
	--stops: transparent 0, transparent 100%;
}

.must-gradient-slider__add {
	position: absolute;
	top: 100%;
	left: -9999px;
	display: flex;
	width: 100%;
	padding: 0;
	border: none;
	background: #fff;
	appearance: none;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
}

.must-gradient-slider__add .must-icon {
	display: block;
	margin-right: 0.25em;
}

.must-gradient-slider__add:focus {
	left: 0;
	outline: 1px dotted;
}

.must-gradient-slider__add:focus-visible {
	left: 0;
	outline: 1px dotted;
}

/*
	Hide tooltip immediately when beginning
	the 'remove stop' animation.
 */
.must-handle--removed ~ .must-handle-tooltip {
	display: none;
}

/*
	Increase tappable area on touchscreens.
 */
@media (pointer: coarse) {
	.must-gradient-slider .must-handle::before {
		position: absolute;
		top: -0.3125rem;
		right: -0.3125rem;
		bottom: -0.3125rem;
		left: -0.3125rem;
		content: '';
		border-radius: 50%;
	}
}
