.must-tab-bar {
	display: flex;
	user-select: none;
	border-bottom: 0.0625rem solid var(--mq-grey-200);
	border-radius: 0rem;
}

.must-tab-bar > * {
	flex: 1 1 0;
}

.must-tab-bar--pill {
	padding: 0.125rem;
	color: var(--mq-grey-800);
	border: none;
	border-radius: 0.375rem;
	background: var(--mq-grey-100);
}

.must-tab-bar--pill .must-tab-button {
	padding: 0.4375rem 0;
	border-bottom: none;
	border-radius: 0.25rem;
	transition: background-color 0.2s var(--mq-transition);
}

.must-tab-bar--pill .must-tab-button::after {
	display: none;
}

.must-tab-bar--pill .must-tab-button--active {
	background: var(--mq-white);
	box-shadow: 0rem 0.0625rem 0.0625rem rgba(0, 0, 0, 0.2);
}
