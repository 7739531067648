.dropdown {
	position: relative;
}

.dropdown a {
	text-decoration: none;
}

.dropdown input.dropdown-input {
	width: 100%;
	font-family: inherit;
	padding: 0.4em 2em 0.4em 0.4em;
	font-size: 1em;
	height: 2em;
	border: 1px solid var(--input-border-color);
	border-radius: var(--inspector-border-radius);
	background: var(--input-bg);
	color: var(--input-text-color);
	margin: 0; /* Safari https://github.com/Evercoder/new-engine/issues/376 */
}

.dropdown .dropdown-input:focus {
	font-family: inherit !important;
}

.dropdown input.dropdown-input:hover {
	border-color: var(--input-border-hover-color);
}

.dropdown-input:focus {
	outline: none;
}

.dropdown-list-separator {
	height: 5px;
	margin-top: 5px;
}

.dropdown-list-separator .separator {
	position: absolute;
	height: 1px;
	width: 100%;
	display: inline-block;
	background-color: var(--editor-menu-border-color);
}

.dropdown-menu {
	overflow-x: hidden;
	overflow-y: auto;
	box-shadow: var(--mq-box-shadow);
	background: var(--input-bg);
	border-radius: calc(var(--inspector-border-radius) * 2);
	border: 1px solid var(--editor-menu-border-color);
	z-index: 3;
	max-height: 400px;
	height: auto;
	padding: 0;
	list-style: none;
	margin: 0;
	top: 26px;
	position: absolute;
	border-top: none;
	left: 0;
	right: 0;
	will-change: scroll-position;
	min-width: 230px;
}

.dropdown-menu.menu-with-header {
	border-top-left-radius: unset;
	border-top-right-radius: unset;
	margin-top: 34px;
}

.dropdown-header {
	background: var(--input-bg);
	border-top-left-radius: calc(var(--inspector-border-radius) * 2);
	border-top-right-radius: calc(var(--inspector-border-radius) * 2);
	border: 1px solid var(--editor-menu-border-color);

	position: absolute;
	z-index: 4;
	min-width: 210px;
	height: 27px;
	padding: 5px 10px 0px;
}

.dropdown-item {
	display: block;
	padding: 1px 6px;
	overflow: hidden;
	color: var(--editor-text-color);
}

.dropdown-no-results {
	padding: 10px 6px 10px 12px;
	display: block;
	color: var(--editor-text-color);
}

.dropdown-item.soft-selected {
	background: var(--editor-active-color);
	color: var(--editor-active-bg);
}

.dropdown-btn {
	position: absolute;
	right: 1px;
	top: 1px;
	bottom: 1px;
	width: 1.5em;

	border-radius: 0 0.2em 0.2em 0;
	/*background: #fff;*/ /* Uncomment if necessary */
	color: var(--input-icon-color);
}

.dropdown-btn:before {
	content: '';
	position: absolute;
	top: 5px;
	right: 0;
	left: -1px;
	bottom: 5px;
	border-left: 1px solid var(--input-border-hover-color);
	opacity: 0.5;
}

.dropdown-btn:after {
	content: '\25BC';
	position: absolute;
	transform: scaleY(0.6);
	top: 1px;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.75em;
	line-height: 1.3;
}

.dropdown-btn:hover {
	color: var(--editor-text-color);
}

.dropdown--has-lefticon input.dropdown-input {
	padding-left: 1.5em;
}
.dropdown--lefticon {
	position: absolute;
	left: 0.25em;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
}
.dropdown--has-lefticon .dropdown--lefticon svg {
	padding: 0.2em;
}

.dropdown--has-unit input.dropdown-input {
	padding-right: 3em;
}
.dropdown--has-unit .mq-input-unit {
	right: 2.3em;
}

.dropdown-editable {
	padding: 0;
	list-style: none;
	margin: 0;
	top: 100%;
	position: absolute;
	z-index: 3;
	border-top: none;
	left: 0;
	right: 0;
	border-radius: 4px;
	background: #f5f5f5;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
}

.dropdown-editable.bottom {
	top: auto;
	bottom: 100%;
}

.dropdown-editable li.disabled {
	pointer-events: none;
	opacity: 0.5;
}

.mq-input :focus {
	outline: none;
}
