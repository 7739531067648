.stage-resize-handle {
	fill: transparent;
}

.stage-resize-handle--east {
	cursor: e-resize;
}

.stage-resize-handle--south {
	cursor: s-resize;
}

.stage-resize-handle--southeast {
	cursor: se-resize;
}
