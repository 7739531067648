/* Slider component */
.slider {
	height: 4px;
	border-radius: 0.25em;
	background: var(--editor-border-color);
	position: relative;
	margin: 0.8em 0;
	margin-right: 0.8em;
	flex-basis: 32.5%;
}

.slider-fill {
	top: 0;
	bottom: 0;
	left: 0;
	position: absolute;
	background: var(--editor-active-color);
	border-radius: 0.25em 0 0 0.25em;
}

[disabled] .slider-fill {
	background: var(--editor-text-muted-color);
}

.slider-knob {
	width: 14px;
	height: 14px;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	background: var(--mq-white);
	border: 1px solid var(--editor-text-muted-color);
	box-shadow: var(--mq-box-shadow-sm);
	border-radius: 14px;
}

.slider-knob:active {
	background: var(--mq-white);
}

/*
.slider-knob:active:before {
	background: rgb(247, 247, 247);
}

.slider-knob:before {
	display: block;
	content: '';
	width: 7px;
	height: 7px;
	background-color: red;
	transform: rotate(45deg);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	position: absolute;
	bottom: -4px;
	left: 2px;
	border: 1px solid #bbb;
	border-top: none;
	border-left: none;
}
*/
/* /Slider component */
