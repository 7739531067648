.mq-link-dropdown {
	/*text-align: center;*/
	color: var(--editor-text-color);
	position: relative;
}

.mq-link-dropdown-current {
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 5px;
}

.mq-link-dropdown-current:after {
	content: ' \25bc';
	transform: scaleY(0.6) translate(0, 1px);
	font-size: 0.75em;
	margin-left: 1px;
	/*color: #555;*/
}

.mq-link-dropdown a {
	/*text-decoration: underline;*/
	/*border-bottom: 1px solid #ccc;*/
}
