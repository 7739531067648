.floating__toolbar__wrapper {
	position: absolute;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	margin: 16px;
	z-index: 50;

	/* text color */
	--c-fg: var(--editor-text-color);
	--c-fg-disabled: var(--editor-border-color);

	/* background color */
	--c-bg: var(--editor-bg);
	--c-bg-hover: var(--editor-active-bg);

	--c-border: var(--editor-border-color);
}

.ft__button__group {
	height: 2.4em;
	display: flex;
	align-items: stretch;
	font-size: 14px;
	color: var(--c-fg);
	background-color: var(--c-bg);
	border: 1px solid var(--c-border);
	border-radius: 6px;
}

.ft__button__group > button {
	min-width: 2.4em;
	color: var(--c-fg);
	background-color: var(--c-bg);
	text-decoration: none;
	border: none;
	cursor: pointer;
	transition: all 0.14s cubic-bezier(0.23, 1, 0.32, 1);
}

.ft__button__group > button:first-of-type {
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}

.ft__button__group > button:last-of-type {
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}

.ft__button__group > button svg {
	fill: currentColor;
}

.ft__button__group > button:hover {
	color: var(--editor-active-color);
	background-color: var(--c-bg-hover);
}

.ft__button__group > button:focus {
	outline: none;
}

.ft__button__group > button svg {
	width: 16px;
	height: 16px;
}

.ft__button__group[disabled],
.ft__button__group > button[disabled] {
	/* 
		TODO: This undoes a global [disabled] style, 
		but ideally such a far-reaching style should not exist 
	*/
	opacity: 1 !important;
	color: var(--c-fg-disabled);
}

.ft__button__separator {
	height: 100%;
	border-right: 1px solid var(--c-border);
	opacity: 0.5;
}
