.must-color-footer {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	gap: 0.25rem;
}

input.must-input--opacity {
	width: 100%;
	max-width: 3rem;
	flex-shrink: 0;
}

button.must-button--edit-swatch {
	position: relative;
	width: 1.875rem;
	height: 1.875rem;
	border-radius: 0;
	flex-grow: 0;
	flex-shrink: 0;
}

button.must-button--edit-swatch:hover {
	cursor: pointer;
}

button.must-button--edit-swatch .must-swatch {
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: auto;
	height: auto;
	transition: transform 0.1s ease-in;
	border-radius: 0.25rem;
}

button.must-button--edit-swatch:hover .must-swatch {
	transform: scale(1.08);
}

button.must-button--edit-swatch .must-icon {
	position: absolute;
	z-index: 10;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.must-color-footer .must-swatch {
	border-radius: 0.25rem;
	--size: 1.875rem;
	flex-grow: 0;
	flex-shrink: 0;
}

.must-color-footer .must-swatch--indeterminate::after {
	content: ' ';
}

.must-button__eyedropper {
	font-size: 1rem;
	width: 1.5rem;
	margin: 0 -0.3125rem 0 0.1875rem;
	border-radius: 0.25rem;
	flex-shrink: 0;
}

.must-button__eyedropper:hover {
	cursor: pointer;
}

.must-button__eyedropper .must-icon {
	transition: all 0.1s var(--mq-transition);
	fill: var(--mq-grey-900);
}

.must-button__eyedropper:hover .must-icon {
	fill: var(--mq-blue);
}
