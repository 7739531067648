/*
	Toolbar
	-------
 */

.export-options {
	display: inline-flex;
	vertical-align: middle;
}

.export-options__menu {
	width: 172px;
	min-width: 172px;
}

.export__options__radio {
	padding-right: 0 !important;
}

.export-options__menu .contextmenu__item__title {
	justify-content: flex-start;
}

.export-options__menu svg {
	padding-right: 9px;
}

.export-options__menu .contextmenu__item:hover svg {
	filter: brightness(calc(-100 + ((1 - var(--font-preview-invert)) * 200)));
	opacity: 0.9;
}

@media (--phone) {
	.export-options {
		vertical-align: inherit;
	}
}
