.comment-style-preview {
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	border: 1px solid rgba(66, 66, 66, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--mq-comment-background-color);
	color: var(--mq-comment-color);
}
