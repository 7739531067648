.chart__legenditem {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
	min-width: 0;
}

.chart__legendcolor {
	width: 0.7em;
	height: 0.7em;
	/* in 2024 we have to make sure that circles are round... */
	width: round(nearest, 0.7em, 0.5px);
	height: round(nearest, 0.7em, 0.5px);
	margin-right: 0.5em;
	background: black;
	border-radius: 2em;
	display: flex;
	flex: none;
}

.chart__legendlabel {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	line-height: 1.5;
}

.chart__legend foreignObject {
	pointer-events: none;
}

.chart__legendcontent {
	width: 100%;
	height: 100%;
	gap: 1em;
}
