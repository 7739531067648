.mq-sidebar-nav {
	height: 100%;
	display: flex;
	align-items: center;
}

.mq-sidebar-nav .mq-toolbar-separator {
	width: 0.35em;
}

.mq-sidebar-nav button.selected {
	color: var(--editor-active-color);
}

.mq-sidebar-nav button.selected .mq-icon,
.mq-sidebar-nav button.selected .must-icon {
	color: var(--editor-active-color);
}

.mq-sidebar-nav button {
	color: inherit;
}

.mq-sidebar-nav .mq-icon {
	width: 1.4em;
	height: 1.4em;
	fill: currentColor;
}
