.switch__wrapper {
	display: inline-block;
}

.switch__wrapper--disabled {
	pointer-events: none;
}

.switch__wrapper--disabled .switch__background {
	opacity: 0.2;
	pointer-events: none;
	background-color: var(--editor-text-muted-color) !important;
}

.switch__wrapper > label > span {
	margin-right: 15px;
}

.switch {
	width: calc(3em + 2px);
	height: 1.5em;
	display: inline-block;
}

.switch__switch {
	width: 1.5em;
	height: 1.5em;
	border-radius: 50%;
	display: block;
	left: 0;
	transition: all 0.15s;
	position: relative;
	background-color: var(--mq-white);
}

.switch__checkbox {
	display: none;
}

.switch__background {
	height: 100%;
	width: 3em;
	display: block;
	overflow: hidden;
	padding: 1px;
	background-color: var(--editor-text-muted-color);
	border: 1px solid transparent;
	border-radius: 1.5em;
	transition: background-color 0.15s;
	cursor: pointer;
	user-select: none;
}
.switch__checkbox:checked + .switch__background {
	border-color: transparent;
	background-color: var(--button-primary-bg);
}
.switch__checkbox:checked + .switch__background .switch__switch {
	left: 1.5em; /* so transition works */
}
