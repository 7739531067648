.ruler {
	border-right: 1px solid var(--editor-menu-border-color);
	border-bottom: 1px solid var(--editor-menu-border-color);
	background: var(--editor-menu-bg);
	position: absolute;
	left: 0;
	top: 0;
	z-index: 10;
	overflow: hidden;
	user-select: none;
}

.vertical-ruler {
}

.horizontal-ruler {
}

.ruler path {
	stroke: var(--editor-menu-border-color) !important;
	/*stroke-linecap: butt;*/
	/*stroke-width: 1px;*/
}

.corner-ruler {
	/*border-right:#fff;*/
	/*border-bottom:#fff;*/
}

.ruler-numbers {
	position: absolute;
	color: var(--editor-text-muted-color);
	font-size: 9px;
	letter-spacing: 1px;
	white-space: nowrap;
}

.ruler-numbers span {
	box-sizing: border-box;
	padding: 0 3px;
	position: absolute;
	cursor: default;
}

.horizontal-ruler .ruler-numbers span {
	display: inline-block;
	/*cursor: row-resize;*/
}

.vertical-ruler .ruler-numbers span {
	display: block;
	/*cursor: col-resize;*/
	transform: rotate(-90deg);
	transform-origin: top left;
}
