.must-btn {
	border: 1px solid currentColor;
	background: #fff;
	padding: 0.25em 0.5em;
	cursor: default;
}

.must-btn[disabled] {
	pointer-events: none;
}

.must-btn:hover {
	color: royalblue;
}

.must-btn:active,
.must-btn:focus {
	color: tomato;
}

.must-btn--link {
	text-decoration: underline;
}
