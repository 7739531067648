#icons-sidebar-pane .controls-right {
	min-width: 1em;
}

#icons-sidebar-pane .icon-sidebar-grid {
	overflow-x: hidden !important;
}

.mq-sidebar-header-controls a.mq-sidebar-icon-dropdown {
	min-width: 8em;
	justify-content: flex-start;
	padding: 0.65em 0;
}

.mq-sidebar-icon-dropdown .mq-icon {
	width: 0.55em;
	height: 0.55em;
	margin-left: 0.3em;
}

.mq-icon-library-name {
	max-width: 11em;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: inline-block;
	overflow: hidden;
}

.mq-sidebar-header-controls span.mq-icon-library-name {
	font-size: 13px;
	margin-right: 0.1rem;
	color: var(--editor-header-color);
	display: inline-block;
}

.mq-sidebar-header-controls a:hover span.mq-icon-library-name {
	color: var(--editor-active-color);
}

.mq-sidebar-header-controls .mq-sidebar-icon-dropdown .mq-icon {
	color: var(--editor-header-color);
	fill: var(--editor-header-color);
}

.mq-sidebar-header-controls span.mq-icon-library-search-results {
	white-space: nowrap;
	text-overflow: ellipsis;
	display: inline-block;
	overflow: hidden;
	font-size: 12px;
	font-weight: normal;
	color: var(--link-color);
	text-decoration: underline;
	max-width: 180px;
}

.mq-icon-sidebar-library-menu.has-search-results {
	min-width: 20em;
}

.mq-icon-sidebar-library-menu.has-search-results .contextmenu__item__badge {
	max-width: 70px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 10px;
	display: inline-block;
	background: var(--new-accent);
	color: var(--new-text-color);
	margin-left: 0.3em;
	bottom: 0;
	right: 0;
	position: relative;
}

.mq-icon-library-variants-list {
	display: flex;
	gap: 3px;
	align-items: center;
	justify-content: space-evenly;
	padding: 0 0.375rem;
	height: var(--sidebar-header-height);
	border-bottom: 1px solid var(--editor-menu-border-color);
}

.mq-icon-library-variants-list a {
	border: 1px solid transparent;
	border-radius: 0.25rem;
	transition: border-color 0.1s ease-in-out;
	display: inline-block;
	width: 1.5rem;
	height: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mq-icon-library-variants-list a.selected {
	border-color: var(--editor-active-color);
}

.mq-icon-library-variants-list svg {
	width: 1.25rem;
	height: 1.25rem;
	color: var(--editor-icon-color);
	fill: var(--editor-icon-color);
}

.mq-icon-library-variants-list > .selected .mq-icon {
	fill: var(--editor-active-color);
}

.icon-sidebar-grid {
	background: var(--stencil-bg);
}

.mq-sidebar-icon-element {
	border-right: 1px solid var(--stencil-border-color);
	border-bottom: 1px solid var(--stencil-border-color);
	cursor: pointer;
}

.mq-sidebar-icon-element:hover {
	background-color: var(--stencil-hover-color);
}

.mq-sidebar-icon-element .mq-icon {
	width: 50%;
	height: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0.95;

	/*
		Set the color of inline icons to match
		the ones defined as `data:` URLs 
		(for which we can't alter the color) 
	 */
	fill: var(--stencil-color);

	/*
		Prevents things from inside the SVG
		from interfering with the sidebar drag & drop gesture.

		https://github.com/Evercoder/new-engine/issues/6956
	 */
	pointer-events: none !important;
}

#icons-sidebar-pane .section-header {
	font-weight: 500;
	font-size: 11px;
	color: var(--stencil-header-color);
	text-align: left;
	padding: 0 9px;
	box-sizing: border-box;
	background: var(--editor-menu-bg);
	border-bottom: 1px solid var(--editor-menu-border-color);
	border-top: 1px solid var(--editor-menu-border-color);
	margin-top: -1px;
	cursor: pointer;
	transition: all 0.1s ease-out;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

#icons-sidebar-pane .section-header.collapsed:not(:last-of-type) {
	border-bottom: 1px solid var(--editor-menu-bg);
}

#icons-sidebar-pane .section-header:hover {
	background-color: var(--editor-menu-hover-color);
}

#icons-sidebar-pane .section-header.collapsed:not(:last-of-type):hover {
	border-bottom: 1px solid var(--editor-menu-hover-color);
}

.section-header .section-title {
	width: 90%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
}
