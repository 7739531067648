.comment-sidebar-tabs {
	display: flex;
}

.comment-sidebar-tabs a {
	margin: 0;
	color: var(--editor-active-color);
	background-color: var(--editor-menu-bg);
	border: 1px solid var(--editor-active-color);
	padding: 0.2em 0.6em;
	font-size: 0.6em;
	text-transform: uppercase;
	box-sizing: border-box;
	text-decoration: none;
	min-width: 6em;
	text-align: center;
}

.comment-sidebar-tabs a.selected {
	color: var(--editor-menu-bg);
	background-color: var(--editor-active-color);
}

.comment-sidebar-tabs a:first-of-type {
	border-bottom-left-radius: 2px;
	border-top-left-radius: 2px;
}

.comment-sidebar-tabs a:last-of-type {
	border-bottom-right-radius: 2px;
	border-top-right-radius: 2px;
}

.comment-sidebar-tabs-big {
	width: 100%;
	height: 35px;
	border-bottom: 1px solid #eeeeee;
	box-sizing: border-box;
}

.comment-sidebar-tabs-big a {
	margin: 0 auto;
	color: #333333;
	background-color: #fff;
	/* border:1px solid #006be5; */
	display: inline-block;
	/*padding: 0.2em 0 0.5em 0;*/
	font-size: 13px;
	/* text-transform: uppercase; */
	box-sizing: border-box;
	text-decoration: none;
	/* margin-top: 1em; */
	min-width: 6em;
	text-align: center;
	width: 32%;
	height: 100%;
	line-height: 2.3;
}

.comment-sidebar-tabs-big a.selected {
	border-bottom: 3px solid #006be5;
}
