@keyframes bump {
	from {
		opacity: 0;
		transform: translate(var(--tx), var(--ty)) scale(0);
	}
	43% {
		transform: translate(var(--tx), var(--ty)) scale(1.04);
	}
	to {
		opacity: 1;
		transform: translate(var(--tx), var(--ty)) scale(1);
	}
}

.must-help-tooltip {
	--tx: 0;
	--ty: 0;
	--tooltip-transition: cubic-bezier(0, 0, 0.2, 1);
	z-index: 10000;
	position: absolute;
	width: 190px;
	background-color: var(--hint-xl-bg);
	border-radius: 12px;
	color: var(--mq-white);
	font-family: var(--must-font-main);
	padding: 20px 16px 20px 16px;
	text-align: center;
	will-change: transform;
	transform: translate(var(--tx), var(--ty));
	animation: bump 0.2s ease backwards;
	transition: transform 0.24s ease, left 0.24s ease, top 0.24s ease;
}

.must-help-tooltip--right {
	--tx: 0.625rem;
	--ty: -50%;
}
.must-help-tooltip--top {
	--tx: -50%;
	--ty: calc(-100% - 0.625rem);
}
.must-help-tooltip--left {
	--tx: calc(-100% - 0.625rem);
	--ty: -50%;
}
.must-help-tooltip--bottom {
	--tx: -50%;
	--ty: 0.625rem;
}
.must-help-tooltip--bottom-right {
	--tx: calc(-100% + 1.375rem);
	--ty: 0.625rem;
}

.must-help-tooltip:before {
	content: ' ';
	position: absolute;
	transform: translate(-50%, -50%) rotate(45deg);
	width: 14px;
	height: 14px;
	background-color: var(--hint-xl-bg);
	transition: left 0.1s ease;
}

.must-help-tooltip--bottom:before {
	top: 0;
	left: 50%;
}

.must-help-tooltip--bottom-right:before {
	top: 0;
	left: calc(100% - 22px);
}

.must-help-tooltip--top:before {
	top: 100%;
	left: 50%;
}

.must-help-tooltip--right:before {
	top: 50%;
	left: 0%;
}

.must-help-tooltip--left:before {
	top: 50%;
	left: 100%;
}

.must-help-tooltip--shortcut {
	opacity: 0.7;
	font-size: 15px;
	padding-left: 5px;
}

.must-help-tooltip--description {
	opacity: 0.7;
	padding-top: 10px;
	font-size: 13px;
	margin-block-start: 0;
	margin-block-end: 0;
}

.must-help-tooltip--title {
	font-weight: bold;
	font-size: 15px;
}
