.object-highlight-path {
	fill: none;
	stroke: var(--normal-stroke);
	stroke-width: 1.75px;
	pointer-events: none;
}

.hidden {
	visibility: hidden;
}

.object-highlight-path-temporary {
	fill: var(--normal-stroke);
	fill-opacity: 0;
	stroke-opacity: 0;
	stroke: var(--normal-stroke);
	stroke-width: 1.75px;
	pointer-events: none;
	animation: oh-highlight ease-in-out 0.6s;
	animation-iteration-count: 2;
}

.object-highlight-path--locked {
	stroke: var(--locked-stroke);
	stroke-width: 1px;
}

.object-highlight-path--symbol {
	stroke: var(--symbol-accent);
	stroke-width: 2px;
}

.object-highlight-path--blink {
	animation: oh-blinking 0.2s step-end 3;
}

.object-highlight-path--sticky {
	stroke-width: 2px;
	stroke: var(--sticky-accent);
}

@keyframes oh-blinking {
	0%,
	100% {
		stroke-opacity: 1;
	}
	50% {
		stroke-opacity: 0;
	}
}

@keyframes oh-highlight {
	0%,
	100% {
		stroke-opacity: 0;
		fill-opacity: 0;
	}
	50% {
		stroke-opacity: 1;
		fill-opacity: 0.4;
	}
}
