.consent-banner {
	position: fixed;
	bottom: 1rem;
	left: 50%;
	transform: translate(-50%, 0);
	padding: 1rem;
	background: white;
	color: black;
	border-radius: 0.5rem;
	/* Above all */
	z-index: 10000;
	border: 1px solid #aaa;
	padding-right: 3rem;
	max-width: 80vw;
	max-width: min(40rem, 80vw);
	width: 100%;
	box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
}

.consent-banner-message {
	margin: 0 0 1rem;
}

.consent-banner-close {
	position: absolute;
	top: 1rem;
	right: 1rem;
	background: transparent;
	padding: 0;
	cursor: pointer;
	appearance: none;
	border: none;
}

.consent-banner-close svg {
	display: block;
}

.consent-banner-actions {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
}
