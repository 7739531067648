.team__selected {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
	cursor: pointer;
}

.team__selected svg:last-of-type {
	margin-left: auto;
}

.team__selected span {
	margin-left: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 200px;
}

.team__select__name .mq-icon {
	font-size: 10px;
	margin: 0 5px;
}

.team__select__dropdown__menu {
	max-width: 480px;
}

.team__select__dropdown__menu .contextmenu__item__title {
	overflow: hidden;
	flex-grow: 1;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.team__select__dropdown__menu .dropdown__menu__option {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.team__select__badge {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-left: 1em;
}

.team__select--floating li {
	padding: 5px;
}
