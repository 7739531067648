.low_contrast,
.stickiness_off {
}

.note-contextual-toolbar-border {
	margin-top: 25px;
}

.note-style-toolbar__button div {
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	border-radius: 3px;
	border: 1px solid rgba(66, 66, 66, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
}

.note-style-toolbar__button svg,
.note-style-toolbar__button:hover svg {
	width: 16px;
	fill: var(--mq-editor-grey-800) !important;
}
/*
.contextual-toolbar-border__button:hover:not(.contextual-toolbar-border__button--active) svg {
	fill: var(--editor-menu-bg) !important;
}
*/
