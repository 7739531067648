.editor-text-link {
	position: absolute;
	z-index: 100;
	font-size: 0.8rem;

	/*
		Abbreviate the URL with ellipis...
	 */
	max-width: 30em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	transform: translate(-50%, 0.5em);

	/*
		Style it like other floating toolbars
	 */
	padding: 0.5em 0.8em;
	background: var(--toolbar-bg);
	box-shadow: var(--mq-box-shadow-sm);
	border-radius: 6px;
	border: 1px solid var(--toolbar-border-color);
}

.editor-text-link:focus {
	outline: none;
}

.editor-text-link a {
	color: inherit;
	text-decoration: underline;
	text-decoration-color: var(--editor-text-color);
}

.editor-text-link a:hover {
	text-decoration-color: currentColor;
}
