.collapsable__section {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid var(--editor-border-color);
}

.collapsable__section__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.6em 1em 0em 1em;
	margin: 0;
	background: var(--editor-bg);
	color: var(--editor-header-color);
	font-size: 12px;
	font-weight: 600;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	box-sizing: border-box;
}

.collapsable__section__header .collapsed-icon {
	font-weight: 300;
	letter-spacing: 0.5px;
	position: relative;
	bottom: 1px;
	color: var(--editor-text-muted-color);
}

.collapsable__section--collapsed,
.collapsable__section:last-of-type {
	border-bottom: 0;
}
.collapsable__section--collapsed .collapsable__section__header {
	padding: 0.6em 1em;
	border-bottom: 1px solid var(--editor-border-color);
}

.collapsable__section--collapsed:last-of-type .collapsable__section__header {
	padding: 0.6em 1em;
	border-bottom: 1px solid var(--editor-border-color);
}

.collapsable__section--collapsed .collapsable__section__header:hover {
}

.collapsable__section__content {
}
