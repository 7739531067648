.mq-loader {
	position: absolute;
	z-index: 1000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--stage-bg);
	display: flex;
	justify-content: center;
	align-items: center;
}

.m-loader-container {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 256px;
	height: 256px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.m-loader-text {
	width: 100%;
	font-size: 15px;
	font-weight: 600;
	color: var(--stage-text);
	letter-spacing: -0.2pt;
}

.m-loader {
	width: 96px;
	height: 96px;
}

.moqups-loader {
	width: 7rem;
	height: 7rem;
	position: fixed;
	left: calc(50% - 3.5rem);
	top: calc(50% - 3.5rem);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.moqups-loader div {
	width: 5rem;
	height: 4.5rem;
	background-color: var(--mq-brand-blue);
	mask-image: url('data:image/svg+xml,%3Csvg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 136 85"%3E%3Cpath d="M132.8 75.3c-.3 1.2-1 2.2-2 2.9A42.8 42.8 0 0 1 124 82a33.3 33.3 0 0 1-13 2.7c-4.2 0-8.3-1.3-11.5-4-3.2-2.5-4.8-6.8-4.8-12.8A43.9 43.9 0 0 1 96 57.2l3.6-16.5 2.4-11.6a36 36 0 0 0 1-7c0-1.8-.4-3-1.2-3.7a6.5 6.5 0 0 0-4-1c-2.8 0-5.6 1.1-8.7 3.3a24.6 24.6 0 0 0-9.5 14.7L70 80.9a2.5 2.5 0 0 1-2.4 2H51.2a1.2 1.2 0 0 1-1.2-1.5l11-51.5c.6-2.6 1-5.3 1-8 0-1.7-.3-2.9-1.1-3.5a6.2 6.2 0 0 0-4-1c-3 .1-6 1.2-8.4 3.2-5.1 3.7-8.7 9.2-10 15.3L29 81.2a2 2 0 0 1-2 1.6H9.6a1.3 1.3 0 0 1-1.3-1.6L19.8 28l.2-.7c.2-1.7.4-3.4.4-5.1a6.3 6.3 0 0 0-3.1-5.7c-.6-.3-1.3-.5-2-.5-1.7 0-3.5.4-5 1.2a29.4 29.4 0 0 0-9.3 6.8l-.9 1L3.8 7.7a3.8 3.8 0 0 1 1.6-2.4A28.1 28.1 0 0 1 22.1 0c6.4 0 11.3 1.4 14.7 4.4 2.2 1.8 3.8 4.4 4.6 7.2A36 36 0 0 1 66.8 0C70.5 0 74 1 77 3c2.7 1.9 4.3 5 4.7 9.2 3.7-4 8-7.1 13-9.4 4.1-1.8 8.5-2.7 13-2.7 4.2-.1 8.3 1.3 11.5 3.9 3.2 2.6 4.8 6.9 4.8 13a41.6 41.6 0 0 1-1.3 10.5l-.9 4-5 24.2a36 36 0 0 0-1 7c0 1.8.4 3 1.2 3.7 1.2.7 2.6 1 4 1 2.7 0 5.6-1.1 8.6-3.4a30.4 30.4 0 0 0 5.5-5.1.7.7 0 0 1 .8.8l-3.1 15.7Z" fill="%231181F1"/%3E%3C/svg%3E');
	-webkit-mask-image: url('data:image/svg+xml,%3Csvg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 136 85"%3E%3Cpath d="M132.8 75.3c-.3 1.2-1 2.2-2 2.9A42.8 42.8 0 0 1 124 82a33.3 33.3 0 0 1-13 2.7c-4.2 0-8.3-1.3-11.5-4-3.2-2.5-4.8-6.8-4.8-12.8A43.9 43.9 0 0 1 96 57.2l3.6-16.5 2.4-11.6a36 36 0 0 0 1-7c0-1.8-.4-3-1.2-3.7a6.5 6.5 0 0 0-4-1c-2.8 0-5.6 1.1-8.7 3.3a24.6 24.6 0 0 0-9.5 14.7L70 80.9a2.5 2.5 0 0 1-2.4 2H51.2a1.2 1.2 0 0 1-1.2-1.5l11-51.5c.6-2.6 1-5.3 1-8 0-1.7-.3-2.9-1.1-3.5a6.2 6.2 0 0 0-4-1c-3 .1-6 1.2-8.4 3.2-5.1 3.7-8.7 9.2-10 15.3L29 81.2a2 2 0 0 1-2 1.6H9.6a1.3 1.3 0 0 1-1.3-1.6L19.8 28l.2-.7c.2-1.7.4-3.4.4-5.1a6.3 6.3 0 0 0-3.1-5.7c-.6-.3-1.3-.5-2-.5-1.7 0-3.5.4-5 1.2a29.4 29.4 0 0 0-9.3 6.8l-.9 1L3.8 7.7a3.8 3.8 0 0 1 1.6-2.4A28.1 28.1 0 0 1 22.1 0c6.4 0 11.3 1.4 14.7 4.4 2.2 1.8 3.8 4.4 4.6 7.2A36 36 0 0 1 66.8 0C70.5 0 74 1 77 3c2.7 1.9 4.3 5 4.7 9.2 3.7-4 8-7.1 13-9.4 4.1-1.8 8.5-2.7 13-2.7 4.2-.1 8.3 1.3 11.5 3.9 3.2 2.6 4.8 6.9 4.8 13a41.6 41.6 0 0 1-1.3 10.5l-.9 4-5 24.2a36 36 0 0 0-1 7c0 1.8.4 3 1.2 3.7 1.2.7 2.6 1 4 1 2.7 0 5.6-1.1 8.6-3.4a30.4 30.4 0 0 0 5.5-5.1.7.7 0 0 1 .8.8l-3.1 15.7Z" fill="%231181F1"/%3E%3C/svg%3E');
	mask-repeat: no-repeat;
	-webkit-mask-repeat: no-repeat;
	mask-position: top center;
	-webkit-mask-position: top center;
	animation: pulse 1.7s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.moqups-loader p {
	line-height: 1;
	margin: 0;
	padding: 0;
	font-size: 0.813rem !important;
	color: var(--stage-text);
	font-weight: 400;
	font-family: 'Helvetica', 'Arial', sans-serif !important;
}

@keyframes pulse {
	from {
		transform: scale(1);
	}
	54% {
		transform: scale(1.1);
	}
	to {
		transform: scale(1);
	}
}
