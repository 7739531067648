/* =============================================================================
   Export popover
   ========================================================================== */

#export-view {
	display: inline-block;
	position: relative;
}

.export-popover {
	white-space: normal;
	position: absolute;
	min-width: 290px;
	top: 100%;
	left: 50%;
	transform: translate(-50%, 0);
	border: 1px solid var(--editor-menu-border-color);
	border-radius: calc(var(--inspector-border-radius) * 2);
	background-color: var(--input-bg);
	box-shadow: var(--mq-box-shadow);
	--button-bg: var(--button-alt-bg);
}
