.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
}

.animated-0-4s {
	animation-duration: 0.4s;
	animation-fill-mode: both;
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	to {
		opacity: 1;
		transform: none;
	}
}

.fadeInUp {
	animation-name: fadeInUp;
}

@keyframes fadeOutUp {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}
}

.fadeOutUp {
	-webkit-animation-name: fadeOutUp;
	animation-name: fadeOutUp;
}
