/* =============================================================================
   Avatars
   ========================================================================== */

.avatar {
	display: inline-block;
	vertical-align: middle;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	/*margin-right: 0.5em;*/
}

.avatar.small {
	width: 20px;
	height: 20px;
}

.avatar img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	vertical-align: baseline;
}

.avatar.big {
	width: 50px;
	height: 50px;
}

.avatar.bigger {
	width: 70px;
	height: 70px;
}

.avatar-full {
	width: 100%;
	height: auto;
	margin-bottom: 0.5em;
	cursor: pointer;
}

.avatar-list {
	min-width: 400px;
}

.avatar-list .avatar {
	margin-right: 0.5em;
	margin-bottom: 0.5em;
	float: left;
	cursor: pointer;
}

.avatar-list .avatar img {
	transition: all 0.1s ease-in-out;
}

.avatar-list .avatar:hover img {
	border: 2px solid #003863;
	box-sizing: border-box;
}

.avatar.presence img {
	box-sizing: border-box;
	border: 2px solid #e6e6e6;
}

.avatar.presence.online {
	position: relative;
}

.avatar.presence.online img {
	border: 2px solid #006ce5;
}

.avatar.presence.online:after {
	position: absolute;
	width: 6px;
	height: 6px;
	background: #4daa48;
	top: 15%;
	left: 85%;
	content: ' ';
	border-radius: 50%;
	border: 2px solid #fff;
	transform: translate(-50%, -50%);
}
