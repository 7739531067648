#collaborators-popover-view {
	display: inline-block;
	position: relative;
}

.sharing__modal {
	color: var(--editor-text-color);
}

.sharing__modal .mq-modal {
	padding-top: 2vh;
}

.contributor__list {
	max-height: 300px;
	overflow-y: auto;
}
