.settings-menu {
	user-select: initial !important;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

.settings-menu--disabled {
	opacity: 0.5;
	pointer-events: none;
}

.settings-menu span {
	cursor: pointer;
}

.settings-menu .mq-icon {
	width: 1.8em;
	height: 1.8em;
	transition: all 0.1s ease-in-out;
}

.settings-menu:not(.settings-menu--disabled) .mq-icon:hover {
	fill: var(--editor-active-color);
}
