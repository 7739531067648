.recent-project {
	color: inherit;
	padding: 0.5em 0.5em 0.5em 1.25em;
	transition: all 0.15s;
	border-radius: var(--welcome-border-radius);
	background: none;
}

.recent-project:hover,
.recent-project:active,
.recent-project:focus {
	text-decoration: none;
	color: var(--editor-active-color);
	background: var(--welcome-hover-color);
}

.recent-project__title {
	font-size: 14px;
	margin: 0;
	line-height: 1.25;
}

.recent-project__timestamp {
	margin-top: 1px;
	font-size: 11px;
	color: var(--editor-text-muted-color);
}

.recent-project .mq-icon {
	color: var(--editor-active-color);
	fill: var(--editor-active-color);
	font-size: 16px;
	margin-top: -6px;
	margin-right: 0.35em;
	vertical-align: middle;
}
