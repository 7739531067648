/* Simple dropdown style */
.dropdown__outer {
	display: inline-block;
	border: 1px solid var(--input-border-color);
	background: var(--input-bg);
	padding: 1px;
	border-radius: var(--inspector-border-radius);
	color: var(--input-text-color);
}

.dropdown__inner,
.dropdown__inner.dropdownmenu__wrapper {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.dropdown__value {
	padding: 0.2em 0.4em;
}

.dropdown__toggle {
	padding: 0 0.5em;
}

/*
	TODO: using !important to fix some overzealous icon sizing
	so that the downward-facing triangles used for dropdowns
	(e.g. in ChartStylesPane) show up correctly.

	The !important should eventually be made unnecessary.
 */
.dropdown__toggle .mq-icon {
	width: 0.6em !important;
	height: 0.6em !important;
}

/* New dropdown styles */

.dropdown__menu {
	max-height: 100%;
	overflow-y: auto;
}

.dropdown__menu__dropdown {
	font-size: 13px;
	padding: 0.4em;
	transition: all 0.2s ease;
	cursor: pointer;
	border: 1px solid transparent;
	border-radius: 4px;
	white-space: pre; /* Don't let dropdown trigger wrap text */
}

.dropdown__menu__dropdown:hover {
	border: 1px solid var(--input-border-color);
}

.dropdown__menu svg,
.dropdown__menu__dropdown svg {
	fill: var(--editor-active-color);
	width: 10px;
	height: 10px;
	padding: 4px;
}

.dropdown__menu__dropdown .dropdown__icon,
.dropdown__menu .dropdown__icon {
	padding-left: 0.7em;
}

.dropdown__menu__option .dropdown__icon {
	height: 12px;
	width: 12px;
}

.dropdown__menu.contextmenu {
	padding: 0;
}

.dropdown__menu .contextmenu__item:not(:last-of-type) {
	border-bottom: 1px solid var(--editor-menu-border-color);
}

.dropdown__menu__option {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.dropdown__menu__option__secondary {
	font-size: 0.9em;
	opacity: 0.7;
	padding-top: 0.6em;
}

.dropdown__menu__option > div {
	max-width: 75vw;
	overflow: hidden;
}

.dropdown__menu__option > div span {
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.dropdown__menu__option--important .dropdown__menu__option__secondary {
	color: var(--error-text-color);
}

.dropdown__menu .contextmenu__item__label {
	padding: 12px;
	cursor: pointer;
}
.dropdown__menu .contextmenu__item__label:hover {
	color: initial;
	background: initial;
}

.dropdown__option--important {
	color: var(--error-text-color);
}

.mq__modal__dropdown__menu {
	margin-left: 24px;
	max-width: 230px;
	overflow: visible;
}

.mq__modal__dropdown__menu .dropdown__menu__dropdown {
	width: 100%;
	box-sizing: border-box;
	background: var(--input-bg);
	padding: 0.7em;
	border: 1px solid var(--input-border-color);
	border-radius: calc(var(--inspector-border-radius) * 1.5);
}

.mq__modal__dropdown__menu .dropdown__menu__dropdown:hover {
	box-shadow: var(--mq-box-shadow-sm);
}
