.remotepresence__wrapper {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
	pointer-events: none;
}

.remotepresence {
	position: absolute;
	box-sizing: border-box;
	border-radius: 2px;
}

.remotepresence__user {
	position: absolute;
	top: 0;
	left: 0;
	color: var(--mq-editor-grey-900);
	font-size: 10px;
	font-weight: 500;
	padding: 1px 5px 1px;
	height: 13px;
	border-radius: var(--inspector-border-radius);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.remotepresence__pointer {
	font-size: 11px;
	font-weight: 500;
	padding: 3px 6px;
	height: 16px;
	border-radius: 16px;
}

.remoteusers__wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 50;
	pointer-events: none;
}

.remoteusers {
	padding: 16px 16px; /* also works as mouseenter/mouseleave events buffer */
}

.remoteusers:not(:empty) {
	pointer-events: auto;
}

.remoteusers--disabled {
	pointer-events: none;
}

.remoteusers--expanded .remoteuser {
	margin-left: 0;
}

.remoteuser {
	margin-left: -10px;
	transition: all 0.2s var(--mq-transition);
}

.remoteuser:hover {
	z-index: 2;
}

.remoteuser:hover .remoteuser__avatar {
	transform: scale(1.4);
}

.remoteuser__avatar {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: var(--presence-bg);
	transition: all 0.3s ease;
}

.remoteuser__avatar img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	vertical-align: baseline;
	opacity: 0.5;
	border-width: 2px;
}

.remoteuser--active .remoteuser__avatar img {
	opacity: 1;
}

.remoteusers__toggle {
	width: 26px;
	height: 26px;
	border-radius: 50%;
	font-weight: 500;
	border: 1px solid var(--editor-border-color);
	background-color: var(--button-bg);
	color: var(--editor-text-muted-color);
	font-size: 0.85em;
	cursor: pointer;
	z-index: 2;
}

.remoteusers__toggle:hover {
	color: var(--editor-text-color);
}

@keyframes typingElipsis {
	0% {
		content: '';
	}
	25% {
		content: '.';
	}
	50% {
		content: '..';
	}
	75% {
		content: '...';
	}
}

.remotepresence__typing-elipsis:after {
	animation: typingElipsis;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-timing-function: step-end;
	content: '...';
	width: 1em;
	display: inline-block;
}
