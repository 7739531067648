.must-icon {
	display: inline-block;
	overflow: visible;
	vertical-align: middle;
	fill: currentColor;
	width: 1em;
	height: 1em;
}

/*
	Note: we need this so that events that happen on the <use> element inside the SVG
	can be bubbled up to a parent outside of the containing SVG.
	See https://github.com/Evercoder/new-engine/issues/308
 */
.must-icon use {
	pointer-events: none;
}
