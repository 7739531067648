/*
	A variation on the label style: this one
	is outline with a white border.
 */
.chart__label--outlined {
	stroke: white;
	stroke-width: 2px;
	paint-order: stroke;
	stroke-linejoin: round;
}

/* pixel perfect axis text centering */
.chart__label,
.chart__axislabels text {
	transform: translateY(1px);
}
