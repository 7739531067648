.onboarding-video__modal {
}

.onboarding-video__modal .mq-modal {
	align-items: center;
	padding-top: 2vh;
}

.onboarding-video__modal .mq__modal__section {
	padding-top: 20px;
}

.onboarding-video__modal .mq-modal-content {
	padding: 0 0 20px 0;
}

.onboarding-video__modal .mq-modal-wrapper {
	width: 960px;
	overflow: hidden;
	border-radius: 15px;
	/* fix for border radius not applying to video element in Safari */
	-webkit-mask-image: -webkit-radial-gradient(white, black);
}

.onboarding-video__modal .mq__modal__video__section {
	padding: 0;
	width: 100%;
	max-height: 66vh;
}

.onboarding-video__modal .mq__modal__video__section iframe {
	display: block;
	width: 100%;
	height: 538px;
	max-height: 66vh;
}

.onboarding-video__modal h1 {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: bold;
	color: var(--editor-header-color);
}

.onboarding-video__modal p {
	margin-top: 0.25em;
	margin-bottom: 5px;
	color: var(--editor-text-color);
	font-size: 14px;
}

.onboarding-video__meta {
	display: flex;
	flex-direction: row;
	row-gap: 15px;
	column-gap: 30px;
	flex-wrap: wrap;
	justify-content: space-between;
}

.onboarding-video__actions {
	align-items: flex-end;
	margin: auto 0;
}

@media (--tablet) {
	.onboarding-video__modal .mq-modal-wrapper {
		width: 100%;
	}

	.onboarding-video__modal p {
		max-width: 400px;
	}

	.onboarding-video__modal .mq__modal__video__section iframe {
		max-height: 60vh;
	}
}
