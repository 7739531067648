.interactivity-transition {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}
.interactivity-icon {
	margin: 0;
	width: 11px;
	height: 11px;
	fill: var(--interaction-accent);
}

.interactivity-icon--large {
	fill: var(--interaction-accent);
	width: 32px;
	height: 32px;
	margin-right: 1em;
	flex: none;
}
.interactivity-icon-blue {
	fill: var(--mq-blue);
}
.interactivity-icon-error {
	fill: var(--error-text-color);
}
.interactivity-target-tooltip {
	display: inline-block;
	vertical-align: top;
	flex-grow: 12;
	margin-left: 0;
	margin-top: 5px;
	margin-bottom: 5px;
	font-size: 0.9em;
	padding: 0 6px;
}
.missing-target-text {
	color: var(--error-text-color);
}
