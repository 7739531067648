.support-menu {
	user-select: initial !important;
	border: none;
	text-align: left;
}

.support-menu span {
	cursor: pointer;
}

.support-menu .mq-icon {
	width: 1.5em;
	height: 1.5em;
	transition: all 0.1s ease-in-out;
}

.support-menu .mq-icon:hover {
	color: var(--editor-active-color);
	fill: var(--editor-active-color);
}

.support-menu .uix-select__button {
	display: none;
}

.support-menu .uix-select__value {
	text-align: center;
}

.support-menu .uix-select__value > span {
	vertical-align: middle;
}

.support-menu--bubble {
	position: absolute;
	display: block;
	width: 7px;
	height: 7px;
	left: 19px;
	top: -3px;
	border-radius: 50%;
	background-color: var(--mq-orange-500);
}

.support-menu .uix-popup {
	left: 100%;
	bottom: 0;
	transform: translate(0.5em, 0);
	border: none;
}

.support-menu .context-menu {
	top: auto;
	bottom: 0;
	min-width: 16em;
}

.support-menu .unread:after {
	content: '';
	width: 8px;
	height: 8px;
	border-radius: 50%;
	box-sizing: border-box;
	display: inline-block;
	vertical-align: super;
	margin-inline: 0.3em;
	background-color: var(--mq-orange-500);
}
