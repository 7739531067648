#editor-toolbar-container {
	pointer-events: none;
	position: absolute;
	height: 0px;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 9999;
}

.toolbar-color-picker {
	position: absolute;
	pointer-events: all;
	z-index: 999;
}

.editor-toolbar {
	position: absolute;
	z-index: 100;
	margin-top: -6px;
	transform: translate(0, -100%);
	position: absolute;
	pointer-events: all;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
	white-space: nowrap;
	color: var(--toolbar-text-color);
	background: var(--toolbar-bg);
	box-shadow: var(--mq-box-shadow-lg);
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	border: 1px solid var(--toolbar-border-color);
	height: var(--toolbar-size);
	padding: 0 calc(var(--toolbar-gap) * 2);
	gap: calc(var(--toolbar-gap) * 1);
	--editor-icon-color-tone: var(--editor-text-muted-color);
}

.editor-toolbar--visible-caret:after,
.editor-toolbar--visible-caret:before {
	top: 100%;
	left: 15px;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.editor-toolbar--visible-caret:after {
	border-color: rgba(255, 255, 255, 0);
	border-top-color: #f5f5f5;
	border-width: 10px;
	left: 16px;
}
.editor-toolbar--visible-caret:before {
	border-color: rgba(230, 230, 230, 0);
	border-top-color: #c0c0c0;
	border-width: 11px;
}

.editor-toolbar--bottom {
	transform: translate(0, 0);
}

.editor-toolbar--visible-caret.editor-toolbar--bottom:after,
.editor-toolbar--visible-caret.editor-toolbar--bottom:before {
	top: -60%;
	left: 15px;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.editor-toolbar--visible-caret.editor-toolbar--bottom:after {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #f5f5f5;
	border-width: 11px;
	left: 14px;
}
.editor-toolbar--visible-caret.editor-toolbar--bottom:before {
	border-color: rgba(230, 230, 230, 0);
	border-bottom-color: #c0c0c0;
	border-width: 10px;
}

.editor-toolbar-button {
	height: calc(var(--toolbar-size) - calc(var(--toolbar-gap) * 2.5));
	min-width: calc(var(--toolbar-size) - calc(var(--toolbar-gap) * 2.5));
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	cursor: pointer;
	background: transparent;
	border: none;
	border-radius: 4px;
}

.editor-toolbar-button.disabled {
	pointer-events: none;
	opacity: 0.5;
}

.editor-toolbar-button.invisible {
	display: none;
}

.editor-toolbar-button .mq-icon {
	color: var(--toolbar-text-color);
	fill: var(--toolbar-text-color);
	width: var(--toolbar-icon-size);
	height: var(--toolbar-icon-size);
}

.editor-toolbar-button.active .mq-icon {
	fill: var(--editor-active-color);
}

.editor-toolbar-button.active:not(.ql-link) {
	background: var(--toolbar-selection-bg);
	color: var(--editor-active-color);
}

.editor-toolbar-button:hover .mq-icon {
	fill: var(--editor-active-color);
}

.editor-toolbar-separator {
	height: 22px;
	border-left: 1px solid var(--toolbar-border-color);
	margin: 0 calc(var(--toolbar-gap) * 1.5);
}
