.gradient_slider {
	height: 50px;
	width: calc(100% - 2px);
	position: absolute;
	background: transparent;
}
.gradient_slider .uix-surface {
	pointer-events: none;
}

.gradient-colorpicker .uix-pad {
	background: transparent;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

.gradient-colorpicker .uix-slider {
}

.gradient-colorpicker .uix-colorpad .uix-colorband {
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

.gradient-colorband .uix-pad__handle {
	pointer-events: all !important;
}
.gradient-colorpicker {
	width: auto !important;
	border: none;
	padding: 0;
}

.cp-main-nav {
	list-style: none;
	margin: 0.5em 0 0;
	color: var(--editor-text-color);
	font-size: 12px;
	background: var(--switch-bg);
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	display: flex;
	box-shadow: var(--mq-box-shadow-inset-sm);
}

.gradient-btn {
	margin: 2px;
	line-height: 1em;
	height: 26px;
	width: 100%;
	padding: 4px;
	border-radius: var(--inspector-border-radius);
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--editor-text-color);
	opacity: 0.75;
	transition: background-color 0.2s var(--mq-transition), color 0.2s var(--mq-transition),
		opacity 0.2s var(--mq-transition);
}
.gradient-btn.active {
	background: var(--switch-active-bg);
	color: var(--editor-active-color);
	box-shadow: var(--mq-box-shadow-sm);
	opacity: 1;
}
.gradient-btn:not(.active):hover {
	opacity: 1;
}

.gradient-styles {
	display: flex;
	padding: 0.75em 0 0.25em;
}
.gradient-styles .inspector__checkbox {
	font-size: 12px;
}

.gradient-btn span {
	border-radius: 2px;
	overflow: hidden;
	height: 16px;
	width: 16px;
	display: block;
}

.solid-fill-icon {
	background: currentColor;
}

.gradient-linear-icon {
	background: currentColor;
	background: linear-gradient(to bottom, currentColor, rgba(99, 99, 99, 0.4));
}

.gradient-radial-icon {
	background: currentColor;
	background: radial-gradient(closest-side, rgba(99, 99, 99, 0.4), currentColor);
}

.gradient-colorband {
	position: relative;
	margin-top: 0.5em;
	margin-bottom: 1.25em;
}

.gradient-colorband canvas {
	width: calc(100% - 2px);
	height: 24.5px; /* Safari eats 0.5 pixel of canvas for no reason */
	cursor: copy;
	position: absolute;
	border-radius: var(--inspector-border-radius);
	border: 1px solid var(--editor-border-color);
}

.colorband-background {
	border-radius: var(--inspector-border-radius);
	border: 1px solid var(--editor-border-color);
	width: calc(100% - 2px);
	height: 24px;
	margin-bottom: 5px;
	background-image: var(--mq-checkerboard);
	background-size: 12px 12px;
}
.uix-polarpad {
	width: 50px;
	height: 50px;
}

#circular-slider {
	width: 22px !important;
	height: 22px !important;
}

.linear-gradient-section {
	margin-right: 0.5em;
}

.gradient-style-input {
	max-width: 48px;
}

.gradient-style-input input {
	border-radius: var(--inspector-border-radius);
	border: 1px solid var(--input-border-color);
}

.gradient-style-settings {
	display: flex;
	gap: 0.7em;
	font-size: 0.9em;
}

.gradient-center {
	width: 26px;
	height: 26px;
	border-radius: var(--inspector-border-radius);
	border: 1px solid var(--input-border-color);
	transition: 0.2s all;
	box-sizing: border-box;
	background: var(--input-bg);
}

.gradient-center:hover {
	transform: scale(2.5, 2.5);
	z-index: 10;
}

.gradient-center .uix-pad__handle {
	width: 7px;
	height: 7px;
	background: var(--editor-active-color);
	border-color: var(--editor-active-color);
}

.gradient-center .uix-pad__grid path {
	stroke: var(--input-border-color);
}

.gradient-center.uix-pad:focus .uix-pad__handle {
	border-color: var(--editor-active-color);
}

.swap-icon-wrapper {
	z-index: 9;
	position: absolute;
	top: 0;
	right: 10px;
}
.swap-icon-btn {
	display: inline;
}

.swap-icon-btn .mq-icon {
	height: 1.2em;
	width: 1.2em;
	fill: rgba(255, 255, 255, 0.8) !important;
	stroke: #bbbbbb;
	stroke-width: 70;
}

.radial-gradient-settings .inspector__checkbox-label {
	margin-left: 0.3em;
}
.radial-gradient-settings .inspector__checkbox {
	flex-grow: 2;
}
.colorbar-handle {
	width: 10px;
	height: 10px;
	position: absolute;
	top: 0 !important;
	transform: translate(-50%, -50%);
	background: var(--input-bg);
	border: 1px solid var(--input-border-color);
	border-radius: 3px;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	box-shadow: var(--mq-box-shadow-md);
}

.colorbar-handle.active {
	background: var(--editor-active-color) !important;
	border-color: var(--editor-active-color) !important;
}

.uix-pad:focus .colorbar-handle {
	border-color: var(--input-border-color) !important;
}
.uix-pad:focus .colorbar-handle.active {
	border-color: var(--editor-active-color) !important;
}
.colorbar-handle.active:before {
	background: var(--editor-active-color) !important;
	border-color: var(--editor-active-color) !important;
}

.colorbar-handle:before {
	display: block;
	content: '';
	width: 7px;
	height: 7px;
	background-color: var(--input-bg);
	border: 1px solid var(--input-border-color);
	transform: rotate(45deg);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	position: absolute;
	top: -4px;
	left: 1px;
	border-bottom: none;
	border-right: none;
	box-shadow: -1px -1px 1px #0000001a;
}

.gradient-styles .mq-input-unit {
	right: 0.4em;
}
