.chat__wrapper {
	position: absolute;
	bottom: 60px;
	right: 17px;
	height: calc(100vh - 170px);
	width: 260px;
	background-color: var(--editor-bg);
	border-radius: calc(var(--inspector-border-radius) * 2);
	min-height: 100px;
	min-width: 200px;
	visibility: hidden;
	pointer-events: auto;
	box-shadow: var(--mq-box-shadow-lg);
	font-size: 0.875rem;
}

.chat__wrapper--expanded {
	visibility: visible;
}

.chat__wrapper--minimized {
	width: 260px;
	height: 150px;
}

.chat--toggle--container {
	position: relative;
	margin: 17px 17px 17px 0;
	pointer-events: auto;
}

.chat--toggle {
	display: flex;
	background: var(--editor-bg);
	width: 34px;
	height: 34px;
	border: 1px solid var(--editor-border-color);
	border-radius: 24px;
	border-bottom-right-radius: 3px;
	padding: 0;
	color: var(--button-text-color);
	cursor: pointer;
	font-size: 1em;
	line-height: 15px;
	transition: all 0.1s ease-in-out;
}

.chat--toggle:hover {
	background: var(--editor-active-bg);
	color: var(--editor-active-color);
}

.chat--toggle_active,
.chat--toggle_active:hover {
	background: var(--editor-active-color);
	border: 1px solid var(--editor-active-bg);
	color: var(--editor-active-bg);
}

.chat--toggle svg {
	fill: currentColor;
}

.chat__windowhead {
	height: 2.5em;
	min-height: 2.5em;
	background-color: var(--editor-menu-bg);
	width: 100%;
	border: 1px solid var(--editor-bg);
	padding: 0 5px;
	box-sizing: border-box;
	color: var(--editor-text-color);
	border-top-right-radius: calc(var(--inspector-border-radius) * 2 - 1px);
	border-top-left-radius: calc(var(--inspector-border-radius) * 2 - 1px);
	line-height: 15px;
	font-size: 12px;
	fill: var(--editor-text-color);
}

.chat__windowhead svg {
	fill: currentColor;
}

.mq-people-icon {
	height: 2em;
	width: 2em;
	margin-right: 0.5em;
}

.chat__windowhead-close {
	margin-left: 0.6em;
	cursor: pointer;
}

.chat__input__wrapper {
	width: 100%;
	flex-shrink: 0;
	flex-grow: 0;
}

.chat__input {
	height: 36px;
	padding: 10px;
	display: flex;
	align-items: center;
	gap: 10px;
}

.chat__input textarea {
	min-height: 36px;
	max-height: 36px;
	resize: none;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.9em 1em;
	font-size: 12px;
	flex-grow: 1;
	background-color: var(--input-bg);
	border-radius: 18px;
}

.chatmessages__wraper {
	overflow: auto;
	height: 100%;
	width: 100%;
}

.chatmessages {
	flex: 1 1 auto;
	overflow: auto;
	height: 100%;
}

.chatmessage {
	-moz-user-select: text;
	-webkit-user-select: text;
	-ms-user-select: text;
	user-select: text;
	padding: 0 10px;
	margin: 0 0 0.25em;
}

.chatmessage-body {
	margin-left: 0.6em;
}

.chatmessage-text {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.chatmessage-text p {
	font-size: 12px;
	background: var(--input-bg);
	border-radius: calc(var(--inspector-border-radius) * 4);
	border-top-left-radius: var(--inspector-border-radius);
	border-bottom-left-radius: var(--inspector-border-radius);
	padding: 0.5em 1em 0.5em 0.5em;
	margin: 0;
	color: var(--editor-text-color);
}

.chatmessage-timestamp {
	font-size: 11px;
	font-weight: 300;
	margin-left: 1.2em;
	color: var(--editor-text-muted-color);
}

.chatuser__avatar {
	width: 24px;
	height: 24px;
	border-radius: 100%;
	background-color: var(--editor-text-muted-color);
	flex-shrink: 0;
	margin-top: 1em;
	overflow: hidden;
	position: relative;
	bottom: 4px;
}

.chatuser__avatar img {
	width: 100%;
	height: 100%;
	border-radius: 5px;
	vertical-align: baseline;
}

.chatuser__name {
	font-size: 11px;
	color: var(--editor-text-muted-color);
	margin-top: 0.8em;
	margin-bottom: 0.4em;
}

.chatmessage__condensed {
	flex-shrink: 0;
	width: 24px;
}

.chat__dummy-scroll {
	height: 25px;
	width: 100%;
}

.chatmessage-timestamp__condensed {
	font-size: 10px;
	font-weight: 300;
	visibility: hidden;
	color: var(--editor-text-muted-color);
	margin-top: 0;
	padding-top: 0.4em;
}

.chatmessage__condensed__wrapper {
	margin-top: 0.2em;
}

.chatmessage__condensed__wrapper:hover .chatmessage-timestamp__condensed {
	visibility: visible;
}

.chatmessage__operational {
	font-size: 0.85em;
	padding-top: 12px;
	border-radius: 5px;
	color: var(--editor-text-muted-color);
	margin: 0.1em;
	text-align: center;
}

.chatdate__wrapper {
	font-size: 12px;
	text-align: center;
	position: -webkit-sticky;
	position: sticky;
	padding: 5px;
	background: var(--editor-bg);
	top: 0;
	z-index: 2;
}

.chatdate__wrapper-hidden {
	visibility: hidden;
}

.chatdate__day {
	margin: auto;
	padding: 0.35em 1em;
	color: var(--editor-text-color);
	font-size: 10px;
	text-transform: uppercase;
	font-weight: bold;
}

.chatnotification__wrapper {
	position: absolute;
	top: -1px;
	right: -4px;
	width: 10px;
	height: 10px;
	border: 1px solid var(--mq-pink-500);
	background: var(--mq-pink-500);
	border-radius: 50%;
	visibility: hidden;
}

.chatnotification__wrapper-visible {
	visibility: visible;
}

.chatmessages__default-message {
	padding: 5px 10px;
	color: var(--editor-text-color);
}

.mq-chat-icon {
	height: 1.5em;
	width: 1.5em;
}

.chat-sound-notifications-toggle {
	cursor: pointer;
}

.chat-send-message-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2em;
	height: 2em;
	padding: 3px;
	cursor: pointer;
	border-radius: 9999px;
	color: var(--editor-active-color);
	background-color: transparent;
	transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
}

.chat-send-message-button:hover {
	background-color: var(--editor-active-color);
	color: var(--editor-active-bg);
}

.chat-send-message-button svg {
	fill: currentColor;
}

.mq-send-message-icon {
	height: 2em;
	width: 2em;
	margin-left: 0.2em;
}

.chat_people_typing {
	position: absolute;
	bottom: 50px;
	right: 20px;
	background-color: transparent;
	box-sizing: border-box;
	width: 240px;
	padding: 0 12px;
	font-size: 0.65em;
	color: var(--editor-text-muted-color);
	height: 1.5em;
	visibility: hidden;
	transition-property: all;
	transition-duration: 0.2s;
}

.chat_people_typing-active {
	visibility: visible;
	background-color: var(--editor-bg);
}

.chatmessages__load-more {
	display: block;
	width: 13em;
	font-size: 0.9em;
	text-align: center;
	margin: 1em auto;
	padding: 0.2em 1em;
	background-color: var(--editor-active-color);
	border-radius: 9999px;
	color: var(--editor-active-bg);
}

.chatmessages__conversation-start {
	display: block;
	text-align: center;
	margin: 0.75em 10px;
	padding: 0.5em 0.5em;
	border-radius: var(--inspector-border-radius);
	background: var(--editor-menu-bg);
	color: var(--editor-text-muted-color);
	font-size: 0.8em;
}

.mq-chat-sound-icon,
.mq-chat-close-icon {
	width: 2em;
	height: 2em;
}
