.must-avatar {
	border-radius: 50%;
	overflow: hidden;
	padding: 0;
	margin: 0;
}

.must-avatar__img {
	width: 100%;
	height: 100%;
}

.must-avatar--size-lg {
	width: 5em;
	height: 5em;
}

.must-avatar--size-md {
	width: 3em;
	height: 3em;
}

.must-avatar--size-sm {
	width: 1.5em;
	height: 1.5em;
}
