.sharing__card {
	padding: 8px 0;
}

.sharing__card > div:last-child {
	justify-self: end;
	flex-basis: 25%;
	display: flex;
	justify-content: flex-end;
	margin-left: auto;
}

.sharing__card__role {
	color: var(--editor-text-muted-color);
	justify-self: end;
	margin-left: auto;
}

.sharing__card .dropdown__menu__dropdown {
	margin: 1px;
	color: var(--editor-text-muted-color);
}

.sharing__card .dropdown__menu__dropdown:hover {
	border-color: var(--input-border-color);
	box-shadow: var(--mq-box-shadow);
}

.sharing__card--team .sharing__card__menu__dropdown {
	color: #1464f6;
}

.sharing__card__name {
	color: var(--editor-text-color);
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	max-width: 50%;
}

/* Email container (smaller and different color) */
.sharing__card__name > div:nth-of-type(2) {
	font-size: 0.85em;
	color: var(--editor-text-muted-color);
}
