.grid__controls {
	position: absolute;
	left: 0;
	top: 0;

	/*
		Grid controls size (width of vertical controls, height of horizontal controls)
	 */
	--grid-controls-size: 18px;
	--grid-controls-padding: 5px;
	--grid-controls-bg: rgba(196, 196, 196, 0.3);
	--grid-controls-stroke: rgba(0, 0, 0, 0.25);
}

.controls_wrapper_adapted {
	position: absolute;
	left: 0;
	top: 0;
}

.grid__control {
	position: absolute;
	border: 1px solid var(--grid-controls-stroke);
	background-color: var(--grid-controls-bg);
	border-radius: 4px;
}

.grid__control--horizontal {
	height: var(--grid-controls-size);
	top: calc(var(--grid-controls-size) * -1.5);
	left: calc(var(--grid-controls-padding) * -1);
	padding: 0 var(--grid-controls-padding);
}

.grid__control--vertical {
	width: var(--grid-controls-size);
	left: calc(var(--grid-controls-size) * -1.5);
	top: calc(var(--grid-controls-padding) * -1);
	padding: var(--grid-controls-padding) 0;
}

.grid__control__background {
	position: absolute;
	z-index: 0;
	background-color: var(--active-stroke);
}

.grid__resize {
	position: relative;
	width: 100%;
	height: 100%;
}

.grid__insert-handle {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 15px;
	width: 15px;
	border-radius: 50%;
	cursor: pointer;
	border: 1px solid transparent;
	background-color: transparent;
}

.grid__insert-handle:hover {
	border: 1px solid var(--toolbar-border-color);
	background-color: var(--toolbar-bg);
}

.grid__insert-handle .mq-icon {
	display: none;
}
.grid__insert-handle:hover .mq-icon {
	display: inline;
	fill: var(--toolbar-text-color);
	height: 15px;
	width: 15px;
}

.grid__insert-handle .inactive__handle {
	display: block;
	border: 1px solid var(--grid-controls-stroke);
	background: var(--grid-controls-bg);
	border-radius: 50%;
	width: 3px;
	height: 3px;
}
.grid__insert-handle:hover .inactive__handle {
	display: none;
}

.grid__control--vertical .grid__insert-handle {
	transform: translate(-125%, -50%);
}

.grid__control--horizontal .grid__insert-handle {
	transform: translate(-50%, -125%);
}

.grid__resize-handle {
	position: absolute;
	z-index: 5;
}

.grid__resize-handle:after {
	display: block;
	content: '';
	background-color: var(--grid-controls-stroke);
}

.grid__control--vertical .grid__resize-handle {
	width: 100%;
	cursor: row-resize;
	padding: var(--grid-controls-padding) 0;
	transform: translate(0, -50%);
}

.grid__control--vertical .grid__resize-handle:after {
	width: 100%;
	height: 1px;
}

.grid__control--horizontal .grid__resize-handle {
	height: 100%;
	cursor: col-resize;
	padding: 0 var(--grid-controls-padding);
	transform: translate(-50%, 0);
}

.grid__control--horizontal .grid__resize-handle:after {
	width: 1px;
	height: 100%;
}

.grid__selection__rectangle {
	position: absolute;
	top: 0;
	left: 0;
}
.grid__selection__rectangle > div {
	position: absolute;
	pointer-events: none;
}

.selection__controls {
	position: absolute;
	left: 0;
	top: -80px;
	display: flex;
}

.selection__trigger {
	position: absolute;
	/*background-color: var(--grid-controls-bg);*/
	cursor: pointer;
	z-index: 1;
}

.selection__trigger--active {
	background-color: var(--normal-stroke);
}

.selection__all {
	position: absolute;
	left: calc(var(--grid-controls-size) * -1.4);
	top: calc(var(--grid-controls-size) * -1.4);
	height: calc(var(--grid-controls-size) * 0.75);
	width: calc(var(--grid-controls-size) * 0.75);
	border-radius: 50%;
	border: 1px solid var(--grid-controls-stroke);
	cursor: pointer;
	background-color: var(--grid-controls-bg);
}

/*
	Position toolbar on the bottom of the bounds
	Used a wrapper so we can center the toolbar and
	to avoid adding a transform style directly on the toolbar
	(this would impact colorpicker positioning)
 */

.grid__control__selection {
	position: absolute;
	pointer-events: none;
}

.grid__control__selection > div {
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translate(-50%, 50%);
}

.grid__control--horizontal .selection__trigger {
	height: 100%;
}

.grid__control--vertical .selection__trigger {
	width: 100%;
}
