.must-handle {
	/* Positioning */
	position: absolute;
	z-index: 10;
	top: 50%;
	left: 50%;
	/* Sizing */
	width: var(--size);
	height: var(--size);
	user-select: none;
	transform: translate(-50%, -50%);
	transform-origin: 0 0;
	border-radius: 50%;
	/* Appearance */
	background: var(--mq-white);
	--size: 0.75rem;
}

.must-handle:focus {
	z-index: 30;
	border: 1px solid var(--mq-blue);
	outline: none;
	box-shadow: 0 0 0 1px var(--mq-white);
}

.must-handle:focus-visible {
	z-index: 30;
	border: 1px solid var(--mq-blue);
	outline: none;
	box-shadow: 0 0 0 1px var(--mq-white);
}

.must-handle::before {
	position: absolute;
	top: -1px;
	right: -1px;
	bottom: -1px;
	left: -1px;
	content: '';
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 50%;
}

.must-handle::after {
	position: absolute;
	z-index: 10;
	top: 50%;
	left: 50%;
	width: 0.5rem;
	height: 0.5rem;
	content: '';
	transform: translate(-50%, -50%);
	border-radius: 50%;
	background: var(--handle-color, transparent);
}

.must-handle--selected {
	z-index: 20;
	--handle-color: var(--mq-blue);
}

.must-handle--selected::after {
	width: 50%;
	height: 50%;
}

.must-handle--removed {
	animation-name: removed;
	animation-duration: 200ms;
	animation-timing-function: ease-out;
	animation-fill-mode: forwards;
}

@keyframes removed {
	0% {
		transform: scale(1) translate(-50%, -50%);
		opacity: 1;
	}

	100% {
		transform: scale(0) translate(-50%, -50%);
		opacity: 0;
	}
}

@media (prefers-reduced-motion: reduce) {
	.must-handle--removed {
		animation-duration: 0.01ms;
	}
}
