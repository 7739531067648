/* =============================================================================
   Window layout
   ========================================================================== */

#stage-wrapper {
	position: absolute;
	/*
	    WARNING:
	    Do not convert these into ems as they will cause stroke
	    subpixel issues for at least Gecko based browser
	    on non-retina displays
    */
	top: 50px;
	bottom: 0;
	left: 0;
	right: 0;
	/*z-index: 20;*/
}

#sidebar {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 50;
	border-right: 1px solid var(--editor-border-color);
	box-sizing: border-box;
	width: 250px;
}

#sidebar.viewer {
	width: 200px;
}

#right-sidebar {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 50;
	background: var(--editor-bg);
	border-left: 1px solid var(--editor-border-color);
	width: 250px;
	box-sizing: border-box;
}

#right-sidebar.collapsed {
	display: none;
}

#main-stage {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 250px;
	left: 250px;
	z-index: 20;
	background-color: var(--stage-bg);
}

.canvas-stage {
	overflow: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	outline: none;

	/* 
		Prevent browsers from navigating away 
		when scrolling at the edges of the page.
	*/
	overscroll-behavior: none;

	/*
		Disable all default browser gestures,
		since we handle one- and two-finger gestures ourselves.

		See the CanvasPinch plugin.
	 */
	touch-action: none;
}

.canvas-stage.view-mode {
	/*
		In view mode, handle two-finger gestures,
		but allow the browser's default one-finger panning.
	 */
	touch-action: pan-x pan-y;
}

/*
	Set the origin of the SVG canvas to its top-left corner,
	so that when we `transform` it (for pinch-zoom gestures)
	we can factor into the `matrix()` the preferred origin
	at the pointer coordinates.

	This happens in the CanvasPinch plugin.
 */
.canvas-svg {
	transform-origin: 0 0;
}

.canvas-svg.hidden {
	opacity: 0;
}

.connecting,
.connecting .stencil {
	cursor: crosshair !important;
}

.stencil-selection,
.stencil-selection .stencil {
	cursor: crosshair !important;
}

.pan {
	cursor: grab !important;
}

.panning {
	cursor: grabbing !important;
}

.pan .stencil {
	cursor: grab !important;
}

.panning .stencil {
	cursor: grabbing !important;
}

#stage-state {
	text-align: center;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 2em;
	color: var(--editor-text-color);
	font-weight: 200;
	z-index: 10;
}

#toolbar-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 50px;
	z-index: 52;
	border-bottom: 1px solid var(--editor-border-color);
	box-sizing: border-box;
	background: var(--editor-bg);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

#toolbar {
	/* On webkit browsers (Safari) when 50% is a float value,
	 the positioning is made with sub-pixel values,
	 and on devices devicePixelRatio is larger than one (mac)
	 this leads to blurry display of icons and text
	transform: translateX(calc(-50% + 0.5px));
	height: 100%;
	z-index: 5;
	white-space: nowrap;
	position: absolute;
	left: 50%;
	top: 0;*/
}

#main-menu {
}

#sidebar-nav {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 250px;
}
