.cp-reveal {
	/*right: 0 !important;*/
	right: 100%;
	/*transition: right 0.1s ease-in-out, opacity 0.1s ease-in;*/
	opacity: 1 !important;
}

#text-inspector-colorpicker {
	width: 17em;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	z-index: 3;
	top: 2em;
	right: 100%;
}

.cp {
	background: var(--editor-bg);
	border: 1px solid var(--editor-border-color);
	/*border-top: none;*/
	width: 250px;
	position: absolute;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	z-index: 3;
	top: 1.75em;
	right: 100%;
	opacity: 0;
	transition: right 0.05s ease-out, opacity 0.1s ease-out;
	border-top-left-radius: var(--inspector-border-radius);
	border-bottom-left-radius: var(--inspector-border-radius);
}

.cp-body {
	padding: 0 0.75em;
}

.cp-body .must-color-footer {
	padding-bottom: 0.75em;
	gap: 0.5em;
}

.cp a {
	text-decoration: none;
}

.cp-panel {
	display: none;
	/*width: 220px;*/
	min-height: 200px;
	/*border: 1px solid transparent;*/
}

.cp-panel.active {
	display: block;
}

.cp-main {
	width: inherit;
	padding: 0;
	border: none;
}

.cp-spectrum {
	/*width: 220px;*/
	height: 170px;
	position: relative;
	/*float:left;*/
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: 2px 2px 0 0;
}

.cp-hue {
	height: 15px;
	/*float:left;*/
	/*width: 220px;*/
	position: relative;
	background-image: linear-gradient(
		to right,
		hsl(0, 100%, 50%),
		hsl(60, 100%, 50%),
		hsl(120, 100%, 50%),
		hsl(180, 100%, 50%),
		hsl(240, 100%, 50%),
		hsl(300, 100%, 50%),
		hsl(360, 100%, 50%)
	);
	/*border-radius: 0 2px 2px 0;*/
}

.cp-opacity {
	/*width: 220px;*/
	height: 15px;
	position: relative;
	clear: left;
	/*border-radius: 0 0px 2px 2px;*/
}
.cp-opacity.cp-transparent-bg {
	background-size: 10px 10px;
}

.cp-opacity-inner {
	/*border-radius: 0 0 2px 2px;*/
}

.cp .uix-input {
	height: 26px;
}

.cp .uix-input input {
	font-size: 13px;
}

.cp-marker {
	position: absolute;
	border: 2px solid var(--mq-white);
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5) inset, 0px 1px 0px rgba(0, 0, 0, 0.25);
	border-radius: 50%;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	width: 12px;
	height: 12px;
	top: 0;
	left: 0;
	z-index: 10;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	border-radius: 50%;

	/*	transition: left 0.1s, top 0.1s;
	-webkit-transition: left 0.1s, top 0.1s;*/
}

.cp-horizontal-marker {
	/*	height: 110%;
	border-radius: 1px;
	top: -5%;
	transform: translate(-50%, 0%);
	-webkit-transform: translate(-50%, 0%);*/
	top: 50%;
	width: 10px;
	height: 10px;
	background: var(--mq-white);
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.75);
}

.cp-vertical-marker {
	/*	width: 110%;
	border-radius: 1px;
	left: -5%;
	transform: translate(0%, -50%);
	-webkit-transform: translate(0%, -50%);*/
	left: 50%;
	background: var(--mq-white);
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
}

.cp-swatch {
	position: relative;
	display: table-cell;
}

.cp-swatch:after {
	content: '.';
	visibility: hidden;
}

.cp-swatch:hover:not(.cp-empty) {
	/*transition: transform 0.1s;*/
	/*transform: scale(1.3, 1.3);*/
	/*z-index: 10;*/
	/*box-shadow: inset 0 0 10px #000000;*/
}

.cp-swatch:active:not(.cp-empty) {
	/*    transition: transform 0.1s;
    transform: scale(1, 1);
	z-index: 10;*/
	/*box-shadow: none;*/
}

.cp-swatch-selected .cp-swatch-inner:after {
	position: absolute;
	content: ' ';
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: var(--mq-white);
	color: var(--mq-white);
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.cp-panel .mq-link-dropdown-current a {
	text-decoration: none;
	font-weight: 500;
	color: var(--editor-active-color);
}

.cp-panel .mq-link-dropdown-current:after {
	color: var(--editor-active-color);
}

.cp-empty {
	background: var(--mq-checkerboard) no-repeat center center;
	background-size: 50px 50px;
	border: 1px solid var(--editor-border-color);
	border-right: none;
}

.cp-empty:last-child {
	border: 1px solid var(--editor-border-color);
}

.cp-swatches-container {
	max-height: 200px;
	overflow: auto;
	overflow-y: auto;
	overflow-x: hidden;
}

.cp-swatches-container-mini {
	max-height: 80px;
	overflow: auto;
	overflow-y: auto;
	overflow-x: hidden;
}

.cp-swatches {
	display: table;
	width: 100%;
	table-layout: fixed;
	border-radius: var(--inspector-border-radius);
	overflow: hidden;
	background: transparent;
}

.cp-swatches-row {
	display: table-row;
}

.cp-swatches-few .cp-swatch {
	width: 16.66%;
	padding-bottom: 16.66%;
}

.cp-nav {
	list-style: none;
	margin: 0.75em 0 1em;
	padding: 0;
	color: var(--editor-text-color);
	font-size: 13px;
	background: var(--switch-bg);
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	overflow: hidden;
	display: flex;
	box-shadow: var(--mq-box-shadow-inset-sm);
}

.cp-nav li {
	display: block;
	letter-spacing: 0;
	text-align: center;
	width: calc(50% - 4px);
	border: 2px solid transparent;
	background: transparent;
}

.cp-nav a {
	display: block;
	color: inherit;
	opacity: 0.7;
	padding: 0.25em 0.5em 0.3em;
	white-space: nowrap;
	overflow: hidden;
	text-transform: capitalize;
	text-overflow: ellipsis;
	background: transparent;
	min-width: 6.5em;
	border-radius: var(--inspector-border-radius);
	transition: background-color 0.2s var(--mq-transition), color 0.2s var(--mq-transition),
		opacity 0.2s var(--mq-transition);
}

.cp-nav a:hover {
	text-decoration: none;
	opacity: 1;
}

.cp-nav a.active {
	color: var(--editor-active-color);
	background: var(--switch-active-bg);
	box-shadow: var(--mq-box-shadow-sm);
	opacity: 1;
}

.cp-current-color {
	border-radius: var(--inspector-border-radius);
	overflow: hidden;
	box-sizing: border-box;
	display: inline-block;
	vertical-align: top;
}

.cp-current-color-swatch {
	height: 26px;
	width: 26px;
	position: relative;
	/*border-radius: 2px;*/
	/*cursor: pointer;*/
}

.cp-current-color-inputs-swatches {
	font-size: 0.85em;
	display: flex;
	align-items: center;
}

.cp-current-color-inputs {
	font-size: 13px;
	padding-top: 1em;
	display: flex;
	align-items: center;
}

.cp-current-color-inputs .mq-input {
}

.cp-clearfix:after {
	content: '.';
	clear: both;
	height: 0;
	display: block;
	visibility: hidden;
}

.cp-actions {
	margin-top: 0.75em;
	/*text-align: right;*/
}

.cp-actions > * {
	display: inline-block;
	vertical-align: middle;
}

.cp-close {
	position: absolute;
	top: 2px;
	right: 2px;
	font-size: 20px;
	line-height: 1em;
	width: 1em;
	height: 1em;
	text-align: center;
	color: var(--editor-active-color);
}

/*Clone of inspector-btn. Kept independent for the sake of re-usability tbd(?) */
.cp-btn {
	vertical-align: middle;
	text-decoration: none;
	padding: 0.25em 0.75em;
	color: #333;
	border-radius: 0.25em;
	border: 1px solid #e0e0e0;
	text-align: center;
	background-color: transparent;
	transition: 0.3s background-color;
	margin-right: 0.25em;
}

.cp-btn:hover {
	background-color: #fff;
	transition: 0.3s background-color;
}

.cp-btn-primary {
	border-color: #006ce5 !important;
	background: #006ce5;
	color: #fff;
	transition: 0.3s background-color;
}

.cp-btn-primary:hover {
	background-color: #357ae8;
	transition: 0.3s background-color;
}

.cp-btn-transparent {
	border: none;
	background: none;
	box-shadow: none;
	text-align: center;
}

.cp-variations {
	/*display: inline-block;*/
	/*width: 100%;*/
	display: block;
	/*vertical-align: bottom;*/
	border-radius: 4px;
	overflow: hidden;
	box-sizing: border-box;
	margin: 0.75em 0 0;
}

.cp-variation:not(:last-of-type) {
	/*margin-bottom: 0.25em;*/
}

.cp-variation-swatch {
	width: 14.28%;
	height: 15px;
	float: left;
	background: #c00;
	/*box-shadow: 0px 1px 1px rgba(0,0,0,0.1);*/
	transition: transform 0.1s;
	-webkit-transition: -webkit-transform 0.1s;
}

.cp-variation-swatch:hover {
	-ms-transform: scale(1.2, 1.2);
	transform: scale(1.2, 1.2);
	-webkit-transform: scale(1.2, 1.2);
	z-index: 10;
}

.cp-variation-swatch:first-child {
	/*border-radius:2px 0 0 2px;*/
}

.cp-variation-swatch:last-child {
	/*border-radius: 0 2px 2px 0;*/
}

.cp-transparent-bg {
	background-image: var(--mq-checkerboard);
	background-size: 50% 50%;
	position: relative;
}

.cp-inner {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.cp-current-color-swatch-inner {
}

.cp-swatches .cp-notice {
	font-size: 1.4em;
	font-weight: 200;
	padding: 2em;
}

.cp-heading {
	color: var(--editor-text-color);
	font-size: 12px;
	font-weight: 400;
	margin: 0.75em 0 0.35em;
}

.cp-notice {
	color: #556;
	font-size: 12px;
	line-height: 1.5;
	text-align: center;
	font-weight: 500;
}

.cp-remove-swatch {
	position: absolute;
	top: 0%;
	right: 0%;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	background: #fff;
	color: #000;
	text-align: center;
	line-height: 0.8em;
	font-weight: 500;
	-ms-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
	-webkit-transform: translate(50%, -50%);
	z-index: 10;
	opacity: 0;
	pointer-events: none;
	cursor: pointer;
	transition: opacity 0.2s;
	-webkit-transition: opacity 0.2s;
}

.cp-swatch:hover .cp-remove-swatch {
	pointer-events: all;
	opacity: 1;
}

.cp .mq-link-dropdown {
	margin: 0 0 0.35em;
}

.cp .mq-link-dropdown-current {
	/*font-weight: bold;*/
	/*text-transform: uppercase;*/
	font-size: 12px;
	line-height: 1.3;
}

.cp .mq-icon {
	fill: var(--editor-icon-color);
}

.cp-toggle-variations {
	font-variant: small-caps;
	text-transform: lowercase;
	margin: 0.5em 0 0;
}

.cp-project-swatches {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
}

.cp-project-swatches .cp-swatch {
	height: calc(226px / 12);
}

.cp-header {
	/*background: var(--editor-bg);*/
	padding: 0.4em;
	padding-left: 0.75em;
	height: 1.4em;
	font-size: 13px;
}
.cp-header-text {
	color: var(--editor-text-color);
}
.cp-eydropper {
	flex-shrink: 0;
	width: var(--inspector-icon-size);
	height: var(--inspector-icon-size);
	margin-left: 0.5em;
	cursor: pointer;
}
.cp-eydropper:hover {
	fill: var(--editor-active-color);
}

.must-button__eyedropper {
	color: var(--editor-icon-color);
}

.must-button__eyedropper:hover {
	color: var(--editor-active-color);
}
