/* =============================================================================
   Inspector
   ========================================================================== */

.inspector-info {
	background: var(--input-bg);
	padding: 0.75em 1em;
	border-radius: calc(var(--inspector-border-radius) * 2);
	display: flex;
	justify-content: stretch;
	align-items: center;
}

.inspector-info p {
	color: var(--editor-text-muted-color);
	font-size: 12px;
	margin: 0;
	line-height: 1.3;
}

.inspector-info p strong {
	color: var(--editor-text-color);
	display: block;
	padding-bottom: 0.25em;
}

.unselectable {
	user-select: none;
}

.selectable {
	user-select: text;
}

.inspector {
	/*font-size: 0.9em;*/
	font-size: 13px;
}

.inspector .mq-icon,
.inspector .mq-input .mq-icon,
.mq-input .mq-icon {
	width: var(--inspector-icon-size);
	height: var(--inspector-icon-size);
	fill: var(--input-icon-color);
	flex: none; /* Safari being dumb */
}

.inspector .connector-input-icon-label {
	width: 3.25em !important;
	height: 1.2em !important;
}

.inspector .connector-input-icon-item-label {
	width: 3.5em !important;
	height: 1.5em !important;
}

.mq-input-menu li:hover .mq-icon {
	fill: var(--editor-active-bg);
}

.highlighted {
	color: var(--editor-active-bg);
	background: var(--editor-active-color);
}

#inspector-align-panel {
	padding: 1px 5px;
	height: 2em;
	font-size: 13px;
	border-bottom: 1px solid var(--editor-border-color);
	opacity: 1;
	position: relative;
	z-index: 1;
}

#inspector-align-panel .mq-icon {
	width: var(--inspector-icon-size);
	height: var(--inspector-icon-size);
}

.inspector .mq-input {
	/*margin: 0 0.5em;*/
	display: flex;
	height: 2em;
}

.mq-input input,
.mq-input-value {
	width: 100%;
	font-size: 13px;
	color: var(--input-text-color);
}

.mq-input:hover .mq-input-value {
	border-color: var(--input-border-hover-color);
}

.mq-input-field .mq-input-value:focus {
	border-color: var(--editor-active-color);
}

.mq-input .mq-input-field .mq-input-value--lg {
	font-size: 15px;
	padding: 1.2em 0.5em;
	border-radius: calc(var(--inspector-border-radius) * 1.5);
}

.mq-input .mq-input-field .mq-input-icon--lg {
	left: 0.35em;
	font-size: 16px;
}

.inspector-panel {
	display: none;
}

.inspector-panel-mini .inspector-btn {
	padding: 0.39em;
}

.inspector-panel-active {
	display: block;
	border-bottom: 1px solid var(--editor-border-color);
}
.inspector-panel-active:last-of-type {
	border-bottom: 1px solid transparent;
}

.inspector-panel-content {
	display: block;
	padding: 2px 0 calc(0.5em - 2px) 0;
}

.inspector-panel-collapsed {
	border-bottom: none;
}

.inspector-panel-collapsed .inspector-panel-content {
	display: none;
}

.inspector-panel-collapsed {
	background: var(--editor-menu-hover-color);
	color: var(--editor-text-muted-color);
	position: relative;
}

.inspector-panel-heading-bar .expand-icon,
.inspector-panel-heading .expand-icon {
	display: none;
}

.inspector-panel-collapsed .expand-icon {
	display: flex;
	position: absolute;
	top: 8px;
	right: 11px;
}

.inspector-panel-collapsed .inspector-panel-heading:hover {
	background: var(--editor-menu-bg);
	color: var(--editor-text-color);
}

.inspector-panel-collapsed .inspector__heading__copy {
	display: none;
}

.inspector-panel-content-section {
	margin: 0.5em 0;
}

.inspector-panel-content-section--outline {
	margin: 0.4em 0;
}

.inspector-panel-content-section--outline div:last-child {
	margin-right: 0.25em;
}

.inspector-panel-content-section--centered {
	align-items: center !important;
}

.inspector-panel-content-section--separated {
	padding-top: 0.4em;
}

.inspector-panel-content-section--separated .inspector__checkbox-input {
	margin-right: 0.35em;
}

.inspector-panel-heading-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.inspector-panel-heading {
	padding: 8px 3em 0em 1em;
	margin: 0;
	background: var(--editor-bg);
	color: var(--editor-text-color);
	flex-grow: 1;
	/*text-transform: uppercase;
	font-size: 10px;*/
	font-size: 12px;
	font-weight: 600;
	cursor: pointer;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	box-sizing: border-box;
}

.inspector-panel-collapsed .inspector-panel-heading {
	padding: 8px 3em 8px 1em;
	border-bottom: 1px solid var(--editor-border-color);
}

.inspector-subpanel-heading {
	padding: 0.5em 2em;
	margin: 0;
	border-bottom: 1px solid #aaa;
	background: #ddd;
	color: #4a4a4a;
	text-transform: uppercase;
	font-size: 0.75em;
	cursor: pointer;
}
.inspector-subpanel-expanded {
	border-bottom: 1px solid var(--editor-border-color);
	display: block;
}
.inspector-subpanel-collapsed {
	display: none;
}
.inspector-subpanel-top {
	border-top: 1px solid var(--editor-border-color);
}
.inspector-btn {
	/*width: 100%;*/
	vertical-align: middle;
	text-decoration: none;
	padding: 0.285em 0.45em;
	height: 2em;
	/*margin: 0 0.5em;*/
	color: var(--editor-icon-color);
	background: var(--editor-bg);
	border-radius: var(--inspector-border-radius);
	border: 1px solid var(--editor-border-color);
	text-align: center;
	white-space: nowrap;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color 0.1s var(--mq-transition);
}
.interactivity-small-btn {
	vertical-align: middle;
	text-decoration: none;
	padding: 2px 3px;
	padding-top: 3px;
	text-align: center;
	white-space: nowrap;
	color: var(--editor-text-color);
}

.interactivity-small-btn svg {
	display: inline-block;
}

.inspector-interactivity-options .interactivity-small-btn:hover svg {
	fill: var(--interaction-accent);
}

.inspector__heading__copy {
	display: flex;
	flex-grow: 0;
	flex-shrink: 0;
	box-sizing: border-box;
	padding-top: 5px;
	padding-right: 9px;
	gap: 4px;
}

.inspector__heading__copy .inspector-btn {
	width: 20px;
	padding: 0;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--editor-text-color);
	margin-top: 1px;
	margin-bottom: -1px;
}

.inspector__heading__copy .inspector-btn .mq-icon {
	width: 14px;
	height: 14px;
	color: inherit;
	fill: currentColor;
	opacity: 0.6;
}

.inspector__heading__copy .inspector-btn:hover .mq-icon {
	opacity: 1;
}

.inspector-interactivity-options .interactivity-small-btn-toggled {
	background: var(--interaction-accent);
	fill: var(--mq-white);
	border-radius: 2px;
}
.inspector-interactivity-options .interactivity-small-btn-toggled svg,
.inspector-interactivity-options .interactivity-small-btn-toggled:hover svg {
	fill: var(--mq-white);
}

/* make page selection dropdown wider */
.interactivity-page-selection .mq-input-menu {
	width: 202px;
}

.inspector-btn-group {
	display: flex;
	justify-content: stretch;
	align-items: center;
}

.inspector-btn-group:after {
	clear: both;
	display: block;
	content: '';
	height: 0;
	visibility: hidden;
}

.inspector-btn-group .inspector-btn {
	flex-grow: 1;
	border-radius: 0;
	position: relative;
	margin-left: -1px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
}

.inspector-btn-group.btn-2 .inspector-btn {
	width: 50%;
}

.inspector-btn-group.btn-3 .inspector-btn {
	width: 33%;
}

.inspector-btn-group.btn-4 .inspector-btn {
	width: 25%;
}

.inspector-btn-group.btn-5 .inspector-btn {
	width: 20%;
}

.inspector-btn-group.btn-6 .inspector-btn {
	width: 16.66%;
}

.inspector-btn-group.btn-7 .inspector-btn {
	width: 14.5%;
}

.inspector-btn-group .inspector-btn.active {
	z-index: 2; /* bring it forward so that we can have different color border */
}

.inspector-btn-group .inspector-btn:first-child {
	border-radius: var(--inspector-border-radius) 0 0 var(--inspector-border-radius);
	margin-left: 0;
}

.inspector-btn-group .inspector-btn:last-child {
	border-radius: 0 var(--inspector-border-radius) var(--inspector-border-radius) 0;
}

.inspector-btn-lock-ratio {
	padding: 0.2em 0;
	left: 6px;
	flex-basis: 24px !important;
}

.inspector-btn.active,
.inspector-btn.active:hover {
	border-color: var(--input-border-hover-color) !important;
	background: var(--editor-active-bg);
	color: var(--editor-active-color);
}

.inspector-btn.active .mq-icon {
	color: var(--editor-active-color);
	fill: var(--editor-active-color);
}

.inspector-btn:hover {
	text-decoration: none;
	background: var(--editor-hover-color);
}

.inspector-btn .mq-icon {
	color: var(--editor-icon-color);
	fill: var(--editor-icon-color);
}

.inspector-btn-transparent {
	padding-top: 0;
	padding-bottom: 0;
	border: 0;
	background: none;
	box-shadow: none;
	/*width: 4em;*/
	text-align: center;
}

.inspector-btn-transparent.active {
	background: var(--editor-semiselection-bg);
}

.inspector-btn-transparent .mq-icon {
	color: var(--editor-icon-color);
	fill: var(--editor-icon-color);
}

.inspector-btn-transparent.active .mq-icon {
	color: var(--editor-active-color);
	fill: var(--editor-active-color);
}

.inspector-btn-inverse {
	vertical-align: middle;
	text-decoration: none;
	padding: 0.5em;
	border-radius: 0.3em;
	border: 1px solid #888;
	text-align: center;
	white-space: nowrap;
	background: #99999e;
	color: #fff;
	font-size: 0.75em;
	text-transform: uppercase;
}

.inspector-btn-inverse:hover {
	text-decoration: none;
	background: #aaa;
}

.inspector-btn-blue {
	vertical-align: middle;
	text-decoration: none;
	padding: 0.3em;
	display: inline-block;
	width: 100%;
	border-radius: 0.3em;
	border: 1px solid #0d47a1;
	text-align: center;
	white-space: nowrap;
	background: #006ce5;
	color: #fff;
}

.inspector-btn-target {
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	padding-top: 1px;
	text-align: center;
	border: 1px solid var(--interaction-accent);
	background: var(--interaction-accent);
	color: var(--mq-white);
	border-radius: var(--inspector-border-radius);
	height: 100%;
	width: 100%;
}

.inspector-btn-target:hover,
.inspector-btn-target:focus {
	border-color: var(--interaction-accent--dark);
}

.inspector-btn-target.active {
	background: var(--mq-orange-500);
	border: 1px solid var(--mq-orange-500);
	color: var(--mq-white);
}

.inspector .inspector-btn-target .mq-icon {
	fill: currentColor;
}

.inspector-target-input {
	cursor: pointer;
}

.inspector-panel-inner-frame {
	text-overflow: ellipsis;
	font-size: 0.9em;
	padding: 0.2em;
}

.inspector-panel-inner-frame > span {
	color: #9e9e9e;
	margin-bottom: 0.5em;
}

.inspector-panel-inner-frame > div {
	margin-bottom: 0.5em;
}
.inspector-btn-transparent-blue {
	vertical-align: middle;
	text-decoration: none;
	padding: 0.3em;
	border: 0;
	text-align: center;
	white-space: nowrap;
	color: #006ce5;
	font-size: 1em;
}

.inspector-btn-transparent-blue:hover {
	text-decoration: none;
	color: #006ce5;
}

.inspector-btn-blue:hover {
	text-decoration: none;
	background: #0d47a1;
}

.inspector-btn-outline {
	padding: 0 0.45em;
	margin-left: 0.25em;
	width: 1em;
}

.inspector-btn-lock-radius {
	padding: 0 0.5em;
	width: 1em;
	z-index: 2;
	position: relative;
	margin: -1.25em auto -1.25em auto;
}

.inspector-btn.hotspots-btn {
	color: #006ce5;
	padding: 0.3em 0.1em;
}
.inspector-el {
	box-sizing: border-box;
	display: inline-block;
}

.el-1-1 {
	width: 100%;
}

.inspector .droplist {
	margin: 0 0.5em;
	vertical-align: middle;
}

.inspector .droplist-input:focus {
	font-family: inherit !important;
}

.inspector .droplist-btn {
	/*color: #333;*/
	/*border-radius: 0 0.25em 0.25em 0;*/
	/*border: 1px solid #c0c0c0;*/
	/*background: linear-gradient(top, #ffffff 0%, #f3f3f3 1px, #e0e0e0 100%);*/

	/*display: inline-block;
	border: 1px solid #b8b8b8;
	box-sizing: border-box;
	*/ /*border-left: none;*/ /*
	border-radius: 0 0.15em 0.15em 0;
	padding: 0 1em;
	text-decoration: none;
	color: inherit;
	background: #fff;
	z-index: 2;*/
}

.inspector .droplist-menu {
	/*border-radius: 0.25em;*/
	/*border-color: #c0c0c0;*/
}

.inspector .droplist-menu.bottom {
	bottom: 100%;
	top: auto;
}

.inspector-color {
	display: inline-block;
	width: 2em;
	height: 2em;
	border-radius: 0.2em;
	vertical-align: middle;
}

.inspector-color-full {
	/*width: 8em;*/
}

.inspector-nowrap {
	white-space: nowrap;
	display: inline-block;
}

.inspector-label {
	flex-basis: 10%;
	text-transform: uppercase;
	font-size: 9px;
	font-weight: 500;
	color: var(--editor-text-muted-color);
	padding-left: 0.4em;
}

.inspector-label.inspector-label--nogrow {
	flex-grow: 0;
	margin-right: 1em;
	min-width: 6em;
	flex-basis: 6em;
}

.flex {
	display: flex;
}

.flex-space-between {
	justify-content: space-between;
	align-items: stretch;
}

.flex-space-around {
	justify-content: space-around;
	align-items: stretch;
}

.flex-col-1 {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}

.flex-col-1 > * {
	flex-grow: 1;
	flex-basis: 0;
	margin-left: 0.5em;
}

.flex-col-1 > :first-child {
	margin-left: 1em;
}

.flex-col-1 > :last-child {
	/*margin-left: 0;*/
	margin-right: 1em;
}

.flex-col-1 :nth-child(1) {
	/*flex-grow: 1;*/
}

.flex-col-1-inner {
	display: flex;
}

.flex-col-1-inner > * {
	flex-grow: 1;
	flex-basis: 0;
}

.flex-col-1-inner > :first-child {
	/*margin-left: 0.5em;*/
	margin-right: 0.5em;
}

.flex-col-1-inner > :last-child {
	margin-right: 0em;
}

.flex-no-grow {
	flex-grow: 0;
	flex-basis: 1.5em;
	margin-left: 0;
	/*margin-right: -6px;*/
	padding-left: 0;
	padding-right: 0;
}

.flex-col-2-1 {
	display: flex;
}

.flex-col-2-1 > * {
	flex-grow: 1;
	flex-basis: 0;
}

.flex-col-2-1 :nth-child(1) {
	flex-grow: 2;
}

.flex-col-2 {
	flex-grow: 2;
}

.flex-col-3 {
	flex-grow: 2;
	gap: 1em;
}

.flex-col-3 > * {
	flex-grow: 1;
	flex-basis: 0;
}

.flex-exp {
	display: flex;
}

.flex-exp > * {
	flex-grow: 1;
	flex-basis: 0;
}

.inspector-inline-link-btn {
	font-size: 0.9em;
	line-height: 2;
}

.inspector-alert-warning {
	color: #8a6d3b;
	background-color: #fcf8e3;
	border-color: #faebcc;
	border-radius: 0.3em;
	border-width: 1px;
	border-style: solid;
	font-size: 0.9em;
	padding: 0.6em;
}

.inspector-alert-success {
	color: #3c763d;
	background-color: #dff0d8;
	border-color: #d6e9c6;
	border-radius: 0.3em;
	border-style: solid;
	border-width: 1px;
	font-size: 0.9em;
	padding: 0.6em;
}

#right-sidebar .inspector {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	overflow: auto;
	transform: translateZ(0);
}

.inspector-styles {
	top: 29px !important;
	/* overflow-x -> Useful for any translation-related mishaps (long buttons that trigger overflow) */
	overflow-x: hidden;
}

.inspector-group--centered .inspector-label-wrapper {
	text-align: center;
	padding: 0.3em 0;
}
.inspector-group--centered .inspector-label {
	flex-basis: 100%;
	padding: 0;
}
.inspector-advanced-text-separator {
	position: relative;
	border-top: 1px solid var(--editor-border-color);
	margin: 1em 0;
}
.inspector-advanced-text-separator:after,
.inspector-advanced-text-separator:before {
	content: '';
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border-style: solid;
}
.inspector-advanced-text-separator:after {
	bottom: -1px;
	right: 34px;
	border-color: transparent transparent var(--editor-bg) transparent;
	border-width: 7px;
}

.inspector-advanced-text-separator:before {
	bottom: 0px;
	right: 33px;
	border-color: transparent transparent var(--editor-border-color) transparent;
	border-width: 8px;
}

.inspector-advanced-text-case a {
	padding-left: 4px;
	padding-right: 4px;
}

.inspector-advanced-letter-spacing {
	flex: none;
	width: 56px;
}

.inspector-btn-group.btn-2.inspector-rich-text-buttons {
	margin-right: 0;
}

.inspector-rich-text-buttons .mq-icon {
	width: 1.05em;
	height: 1.05em;
	padding: 0.1em 0;
}

.inspector-rich-text-buttons .inspector-btn {
	height: 1.7em;
}

input[type='text'].inspector-instance-name {
	width: 100%;
	background: transparent;
	border: none !important;
	border-radius: 0 !important;
	color: var(--editor-active-color);
	padding-left: 0 !important;
	font-weight: 500;
	/*emulates a prettier dotted border via https://stackoverflow.com/questions/6250394/how-to-increase-space-between-dotted-border-dots */
	background-image: linear-gradient(
		to right,
		var(--editor-text-muted-color) 40%,
		rgba(255, 255, 255, 0) 20%
	);
	background-position: bottom;
	background-size: 3px 1px;
	background-repeat: repeat-x;
}

.inspector-instance-name--symbol {
	color: var(--symbol-text-color) !important;
}

.inspector-instance-name[disabled] {
	background: transparent !important;
	color: var(--editor-text-muted-color) !important;
}

input[type='text'].inspector-instance-name:focus {
	background-image: linear-gradient(
		to right,
		var(--editor-text-color) 40%,
		rgba(255, 255, 255, 0) 20%
	);
}
input[type='text'].inspector-instance-name:hover {
}

/*---------------- */

.mq-input {
	/*display: flex;*/
	position: relative;
	vertical-align: middle;
}

.mq-input .mq-icon {
	width: 1em;
	height: 1em;
}

.mq-input .mq-input-field,
.mq-input .mq-input-btn {
	/*TODO Check for regressions (Emil) */
	/*display: table-cell;*/
	/*display: flex;*/
	width: 100%;
}

.mq-input .mq-input-field input,
.mq-input .mq-input-field .mq-input-value {
	/*height: 2.4em; */ /*Safari sux balls*/
	height: 2em;
	margin: 0;
	display: flex;
	align-items: center;
}

.mq-input-field {
	position: relative;
	color: var(--input-text-color);
	display: inline-block;
	/*z-index: 2;*/
}

.mq-input-field input,
.mq-input-field .mq-input-value {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	/*width: 7em;*/
	box-sizing: border-box;
	border: 1px solid var(--input-border-color);
	line-height: inherit;
	font-size: 13px;
	padding: 0.2em 0.4em;
	/* box-shadow: 0px 1px 0px 0px #ebebeb inset; */
	border-radius: var(--inspector-border-radius);
	color: inherit;
	background: var(--input-bg);
	display: inline-block;
	transition: border-color 0.2s var(--mq-transition);
}

.mq-input-has-customlabel {
	padding-left: 60px !important;
	width: 100%;
}
.mq-input-has-icon .mq-input-field input,
.mq-input-has-icon .mq-input-field .mq-input-value {
	padding-left: 1.7em;
}

.mq-input-has-unit .mq-input-field input,
.mq-input-has-unit .mq-input-field .mq-input-value {
	padding-right: 1.75em;
}

.mq-input-has-unit.mq-input-has-menu-items .mq-input-unit {
	right: 2.3em;
}

.mq-input-has-unit.mq-input-has-menu-items .mq-input-field input,
.mq-input-has-unit.mq-input-has-menu-items .mq-input-field .mq-input-value {
	padding-right: 3.5em;
}

.mq-input-has-menu-items .mq-input-field .mq-input-value {
	padding-right: 1.5em;
}

/* Lighter color for placeholder values */
.mq-input-has-placeholder .mq-input-value {
	color: #999;
}

/*
	Add VA middle for ellipsis because ellipsis adds overflow hidden
	and .mq-input-value is an inline block
	CSS 2.1 Specs:
		The baseline of an 'inline-block' is the baseline of 
		its last line box in the normal flow, unless it has 
		either no in-flow line boxes or if its 'overflow' property 
		has a computed value other than 'visible', in which case 
		the baseline is the bottom margin edge.
*/
.mq-input-value.ellipsis {
	vertical-align: middle;
}

.mq-input-icon {
	position: absolute;
	left: 0.25em;
	top: 50%;
	transform: translate(0, -50%);
	z-index: 2;
	display: flex;
}

.input-label-svg {
	height: 100.1%;
	width: 68px;
	box-sizing: border-box;
	position: absolute;
	text-align: left;
	top: 0;
	left: 0;
}
.interactivity-input-label {
	color: var(--editor-text-muted-color);
	box-sizing: border-box;
	font-size: 9px;
	width: 50px;
	padding: 0.75em;
	text-transform: uppercase;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}
.interactivity-event-header {
	display: block;
	color: var(--editor-text-color);
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.7em;
	padding: 0.5em 0.5em;
}

.interactivity-target-input {
	max-width: 75%;
}

.interactivity-target-input .mq-input-value {
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 2em;
}

.interactivity-target-input .mq-input-menu li div {
	overflow-wrap: break-word;
	word-break: break-word;
}

.inspector-panel-inner-frame {
}

.interactivity-checkbox {
	accent-color: var(--interaction-accent) !important;
}

.inspector-interactivity {
	display: flex;
	color: var(--editor-text-color);
	flex-direction: column;
	padding: 0.5em 0.8em 0.6em;
	margin: 0.75em 1em;
	background: var(--input-bg);
	box-shadow: var(--mq-box-shadow-sm);
	border-radius: calc(var(--inspector-border-radius) * 1.5);
}

.inspector-interactivity:last-child {
	margin-bottom: 1.25em;
}

.inspector-interactivity.interactivity-update-highlight {
	background-color: var(--interaction-bg);
	transition: background-color 0.5s;
}

.inspector-interactivity-event {
	color: var(--editor-text-muted-color);
	display: flex;
	gap: 3px;
	flex-grow: 1;
	font-size: 0.9em;
	align-items: center;
}

.inspector-interactivity .inspector-panel-content-section {
	display: flex;
	margin: 0;
	justify-content: stretch;
	gap: 0.5em;
}

.inspector-interactivity .inspector-panel-content-section--separated {
	padding-bottom: 0.4em;
}

.inspector-interactivity .inspector-panel-content-section .inspector-info {
	padding: 0.25em 0.5em;
}

.inspector-interactivity-title {
	color: var(--editor-text-color);
	align-items: center;
}

.inspector-interactivity-options {
	flex: none;
}

.inspector-interactivity-options svg {
	fill: var(--editor-text-color);
}

.inspector-interactivity .mq-input {
	flex-grow: 1;
	margin: 0.25em 0 0.35em;
	min-width: 0;
}

.inspector-interactivity .interactivity-btn {
	width: 100%;
	background: var(--interaction-accent);
	border-color: var(--interaction-accent);
	color: var(--mq-white);
	margin: 0.3em 0 0.25em;
	padding: 0.4em 1em;
	border-radius: var(--inspector-border-radius);
	height: 30px;
	box-sizing: border-box;
}

.inspector-interactivity .interactivity-btn:hover,
.inspector-interactivity .interactivity-btn:focus {
	background: var(--interaction-accent--dark);
	border-color: var(--interaction-accent--dark);
}

.inspector-interactivity .interactivity-btn[disabled] {
	background: var(--input-border-color);
	border-color: var(--input-border-color);
	color: var(--editor-text-muted-color);
}

.inspector-interactivity .inspector-btn-target {
	flex: none;
	width: 4em;
	margin: 0.25em 0 0.35em;
	height: auto;
	padding-top: 0;
}

.inspector-interactivity .mq-message {
	font-size: 12px;
	padding: 1em;
	margin: 0.5em 0;
}

.inspector-btn--hotspots {
	width: 100%;
	background: var(--input-bg);
	border-color: var(--input-border-color);
	color: var(--interaction-accent);
	margin: 0em 0 0.5em;
	padding: 0.8em 1em;
	font-weight: 600;
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.5em;
	height: 32px;
	box-sizing: border-box;
}

.inspector-btn--hotspots:hover,
.inspector-btn--hotspots:focus {
	cursor: pointer;
	background: var(--input-bg);
	border-color: var(--interaction-accent);
}

.inspector-btn--hotspots .mq-icon {
	width: 1em;
	fill: var(--interaction-accent);
}

.mq-input-unit {
	position: absolute;
	top: 50%;
	right: 0.6em;
	left: auto;
	transform: translate(0, -50%);
	z-index: 2;
	font-variant: small-caps;
	font-size: 11px;
	color: var(--input-icon-color);
	opacity: 0.5;
	pointer-events: none;
	user-select: none;
}

.mq-input-icon:last-child {
	right: 0.5em;
	left: auto;
}

.mq-input-btn {
	display: inline-block;
	border: 1px solid var(--inspector-border-radius);
	border-left: none;
	border-radius: 0 0.15em 0.15em 0;
	padding: 0 1em;
	text-decoration: none;
	color: inherit;
	z-index: 2;
	position: relative;
	background: var(--input-bg);
}

.mq-input-menu {
	font-size: 13px;
	padding: 0;
	overflow: auto;
	list-style: none;
	margin: 0;
	top: 100%;
	position: absolute;
	z-index: 3;
	border-top: none;
	left: 0;
	right: 0;
	color: var(--editor-text-color);
	border-radius: calc(var(--inspector-border-radius) * 2);
	background: var(--input-bg);
	box-shadow: var(--mq-box-shadow);
}

.mq-input-menu.bottom {
	top: auto;
	bottom: 100%;
}

.mq-input-menu li {
	display: block;
	padding: 0.5em 1em;
}

.mq-input-menu li:hover {
	background: var(--editor-active-color);
	color: var(--editor-active-bg);
}

.mq-input-menu li.disabled {
	pointer-events: none;
	opacity: 0.5;
}

.mq-input :focus {
	outline: none;
	/*box-shadow: 0px 0px 0px 1px #006ce5 inset;*/
}

.mq-color-input {
	display: block;
	width: 100%;
	height: 2em;
	border: 1px solid var(--input-border-color);
	background: var(--input-bg);
	padding: 3px 3px 3px 1.8em;
	border-radius: var(--inspector-border-radius);
	position: relative;
	box-sizing: border-box;
}

.mq-color-input .mq-color-swatch {
	background-size: 9px 9px;
}

.mq-color-input .mq-icon {
	height: var(--inspector-icon-size);
	width: var(--inspector-icon-size);
}

.mq-color-input--disabled {
	pointer-events: none;
	opacity: 0.5;
}

.mq-color-swatches {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
	margin: 0;
	padding: 0;
	list-style: none;
	gap: 2px;
}

.mq-color-swatches.project-palette {
	justify-content: flex-start;
	width: auto;
	gap: 5px;
}

.project__palette__selection {
	padding-top: 0 !important;
}

.project__palette__options .inspector__checkbox-label {
	max-width: 62px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.mq-color-swatch {
	flex-grow: 1;
	flex-basis: 0;
	margin-left: 1px;
	width: 1em;
	background-image: var(--mq-checkerboard);
	background-size: 9px 9px;
	border-radius: 2px;
	overflow: hidden;
	position: relative;
}

.mq-color-swatch:after {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	content: '';
	pointer-events: none;
	border-radius: 2px;
	border: 1px solid #333;
	opacity: 0.2;
	mix-blend-mode: multiply;
}

.mq-color-swatch.project-palette {
	margin: 0 0 5px 0;
	max-width: calc(2em - 8px);
	flex-basis: auto;
}

.mq-color-swatch.project-palette a {
	height: calc(2em - 8px);
}

.mq-color-swatch:first-child {
	margin-left: 0;
}

.mq-color-swatch a {
	display: block;
	height: calc(2em - 8px);
	border-radius: 0px;
}

.mq-color-input--disabled .mq-color-swatch a,
[disabled] .mq-color-swatch a {
	background: var(--input-bg) !important;
}

.input-blue-text {
	color: #006ce5 !important;
}

.btn-spacer {
	/*width: 4em;*/
	/*padding: 0.25em 0.5em;*/
	flex-basis: 24px;
}

.btn-spacer--lg {
	flex-basis: 48px;
}

.mq-modal-span {
	display: flex;
	padding: 0;
	margin: 0 !important;
}

.mq-modal-span.flex-col-1 > :first-child,
.mq-modal-span.flex-col-1 > :last-child {
	margin: 0;
}

.mq-modal-span.mq-message {
	padding: 0.5em;
	border-radius: 4px;
	margin-bottom: 0.5em !important;
}

label .mq-modal-span {
	font-size: 0.7em;
	text-transform: uppercase;
	color: var(--editor-text-muted-color);
}

.mq-modal-label {
	font-weight: bold;
	font-size: 12px;
	color: var(--editor-text-color);
	margin-bottom: 0.3em !important;
	display: inline-block;
}

.mq-modal-span .mq-input,
.mq-modal-span .inspector-btn {
	font-size: 0.9em;
	margin-bottom: 16px !important;
}

.mq-modal-span .inspector__checkbox {
	margin-bottom: 10px;
}

.mq-modal-span .inspector-btn--lg {
	height: 2.5em;
	font-size: 15px;
}

.mq-modal-content .flex-col-1 {
	gap: 10px;
}

.inspector__panel-section.inspector__replace-color {
	padding-top: 0;
}

.inspector__replace-color {
	color: #777;
}

/*
	React Rewrite
	---------------------------------------------------------
*/

.inspector__panel-content {
}

.inspector__panel-section {
	border-bottom: 1px solid var(--editor-border-color);
	padding: 0.5em 1em;
}

.inspector__panel-section:last-child {
	border-bottom: none;
}

.inspector__panel-section-heading {
	font-weight: normal;
	margin: 0 0 0.5em;
	font-size: 12px;
	color: var(--editor-text-muted-color);
}

.inspector__panel-section-content {
	margin: 0.5em 0;
}

.inspector__panel-section input.inspector__checkbox-input {
	display: inline-block;
	margin-right: 0.3em;
}

.inspector__controls {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	gap: 0.5em;
}

.inspector__controls--vertical {
	flex-direction: column;
	overflow: visible;
	gap: 0;
}

.inspector__controls input {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	box-sizing: border-box;
	border: 1px solid var(--input-border-color);
	line-height: inherit;
	font: inherit;
	padding: 0.2em 0.4em;
	border-radius: 0.2em;
	background: var(--input-bg);
	color: var(--input-text-color);
}

.inspector__controls--vertical > * {
	margin-left: 0;
	margin-top: 0.5em;
}

.inspector__controls--vertical > *:first-child {
	margin-top: 0;
}

.inspector__btn {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	padding: 0.1em 0.45em;
	height: 2em;
	vertical-align: middle;
	text-decoration: none;
	color: var(--editor-text-color);
	border-radius: var(--inspector-border-radius);
	border: 1px solid var(--editor-border-color);
	text-align: center;
	white-space: nowrap;
	cursor: pointer;
	box-sizing: border-box;
	background: inherit;
	transition: background-color 0.1s var(--mq-transition);
}

.inspector__btn span {
	display: flex;
	justify-content: center;
	align-items: center;
}

.inspector__btn--borderless {
	border: none;
}

.inspector__btn--nogrow {
	flex-grow: 0;
}

.inspector__btn:hover {
	text-decoration: none;
	background: var(--editor-hover-color);
}

.inspector__btn--active,
.inspector__btn.active,
.inspector__btn.active:hover,
.inspector__btn--active:hover {
	border-color: var(--input-border-hover-color);
	background: var(--editor-active-bg);
	color: var(--editor-active-color);
}

.inspector__btn--borderless.active {
	background: var(--editor-semiselection-bg);
}

.inspector__btn--active .mq-icon,
.inspector__btn.active .mq-icon {
	fill: var(--editor-active-color);
	color: var(--editor-active-color);
}

.inspector__btn:focus {
	outline: none;
}

.inspector__btn__group {
	flex: 1;
	display: flex;
	gap: 0;
}

.inspector__btn__group--auto {
	flex: auto;
	min-width: 0;
}

.inspector__btn__group > .inspector__btn:first-child {
	border-radius: var(--inspector-border-radius) 0 0 var(--inspector-border-radius);
	margin-left: 0;
}

.inspector__btn__group > .inspector__btn:last-child {
	border-radius: 0 var(--inspector-border-radius) var(--inspector-border-radius) 0;
}

.inspector__btn__group .inspector__btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 0;
	/*border: 1px solid var(--editor-menu-border-color);*/
	margin-left: -1px;
	min-width: 0;
	overflow: hidden;
	flex: 1;
	text-overflow: ellipsis;
}

.inspector__a {
	/*text-decoration: underline;
	text-decoration-style: dotted;*/
	appearance: none;
	color: var(--link-color);
	padding: 0.1em 0;
	margin: 0 0 0.3em;
	border: none;
	background: transparent;
	cursor: pointer;
}

.inspector__a:hover {
	color: var(--link-active-color);
	/*emulates a prettier dotted border via https://stackoverflow.com/questions/6250394/how-to-increase-space-between-dotted-border-dots */
	background-image: linear-gradient(to right, currentColor 40%, rgba(255, 255, 255, 0) 20%);
	background-position: bottom;
	background-size: 2px 1px;
	background-repeat: repeat-x;
	text-decoration: none;
}

.inspector__a--top {
	margin-top: 0.25em;
}

.chart__type {
	margin-top: 0.4em;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 4px;
}

.chart__type li.inspector__btn {
	border-radius: var(--inspector-border-radius);
	margin: 0;
	padding: 0;
}

.chart__type li.uix-list__item--selected {
}

.chart__panel {
	padding: 0;
	margin-top: -0.25em;
}

.chart__panel .inspector__panel-section {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	border-bottom: 0;
}

.chart__panel .inspector__panel-section-heading {
	margin-bottom: 0.5em;
}

.chart__panel .inspector__controls > div {
}
.chart__panel .inspector__controls .dropdown__value {
	width: 4em;
}

#inspector-uiuiui-colorpicker-target .must-popup:not(.gradient-colorpicker) {
	padding: 0;
	background: none;
	border: 0;
	box-shadow: none;
}

.inspector__label {
	flex-basis: 10%;
	text-transform: uppercase;
	font-size: 9px;
	font-weight: 500;
	font-weight: normal;
	color: var(--editor-text-muted-color);
}

.inspector__label--nogrow {
	flex-grow: 0;
	margin-right: 1em;
	min-width: 6em;
	flex-basis: 6em;
}

.inspector__colorpicker__chart-styles {
	background: var(--editor-bg);
	border: 1px solid var(--editor-border-color);
	padding: 0.5em;
	border-radius: var(--inspector-border-radius);
}

.inspector__colorpicker__chart-styles .must-color-picker {
	width: 230px;
}

.inspector__btn__axis__position--start svg {
	transform: rotate(-90deg);
}

.inspector__btn__axis__position--end svg {
	transform: rotate(90deg);
}

.inspector__btn__axis__position--bottom svg {
	transform: rotate(180deg);
}

.inspector__colorpicker {
	display: flex;
	border: 1px solid var(--input-border-color);
	background: var(--input-bg);
	padding: 1px;
	border-radius: var(--inspector-border-radius);
}

.inspector__colorpicker .mq-icon {
	width: var(--inspector-icon-size);
	height: var(--inspector-icon-size);
	padding: 0.2em 0.4em 0.1em 0.3em;
}

.inspector .dropdown__outer {
	padding: 0 0.1em;
}

.inspector .dropdown__outer--fullwidth {
	width: 100%;
}

.inspector .dropdown__outer--fullwidth .inspector__dropdown {
	width: 100%;
}

.inspector .dropdown__outer--fullwidth .dropdown__value {
	flex-grow: 1;
}

.inspector .dropdown__outer .contextmenu {
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
}

.inspector__dropdown {
	height: 100%;
	align-items: center;
}

.inspector__dropdown .dropdown__toggle {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.palette__selector {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	border: 1px solid var(--input-border-color);
	background: var(--input-bg);
	border-radius: var(--inspector-border-radius);
	cursor: pointer;
}

.palette__selector__selected {
	display: flex;
	padding: 3px;
	flex-grow: 1;
}

.palette__toggle {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 0.5em;
}

.palette__toggle .mq-icon {
	width: 0.6em;
	height: 0.6em;
}

.chart__palette__list {
	width: 209px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	background: var(--input-bg);
	border: 1px solid var(--editor-border-color);
	border-radius: var(--inspector-border-radius);
	z-index: 101;
	padding: 6px 6px 0;
	position: relative;
}
.chart__palette__list:focus {
	outline: none;
}

.chart__palette {
	padding: 0;
	border: none;
	background: transparent;
	width: 45px;
	height: 30px;
	margin-bottom: 6px;
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	cursor: pointer;
	border-radius: 3px;
	overflow: hidden; /* So that children don't overflow over the border radius */
}
.chart__palette--selected {
	box-shadow: 0 0 0 3px #1464f6;
}

/*
	Slider and slider UIX styles
*/

.inspector__slider {
	flex-grow: 2;
	margin-right: 0.8em;
	height: 4px;
	background: none;
}

.inspector__slider .uix-slider__progress {
	z-index: 1;
	background: var(--editor-active-color);
	border-radius: 0.25em 0 0 0.25em;
}
.inspector__slider .uix-slider__handle {
	width: 14px;
	height: 14px;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	background: var(--mq-white);
	border: 1px solid var(--editor-text-muted-color);
	box-shadow: var(--mq-box-shadow-sm);
	border-radius: 14px;
}

.inspector__slider .uix-pad {
	height: 4px;
	border-radius: 0.25em;
	background: var(--editor-border-color);
	position: relative;
}

.inspector__controls .uix-pad:focus .uix-pad__handle {
	background: var(--mq-white);
	border: 1px solid var(--editor-text-muted-color);
}

.inspector__slider__input {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.inspector__slider__input .uix-input {
	margin-left: 0.5em;
	flex-basis: 0;
	flex-grow: 1;
}

.inspector__slider__input--label .uix-input input {
	padding-left: 1.2em;
}

/*
	UIX styles
*/

.palette__selector__selected .must-swatch:first-of-type {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}
.palette__selector__selected .must-swatch:last-of-type {
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.palette__selector .must-swatch {
	height: 18px;
	width: auto;
	overflow: visible;
	border-radius: 0;
	flex: 1;
}

/** Disable the must--light border in this cases **/
.palette__selector .must-swatch::before,
.chart__palette .must-swatch::before {
	box-shadow: 0 0;
}

.chart__palette .must-swatch {
	height: 15px;
	width: 15px;
	border-radius: 0;
}

.uix-input input {
	border: 1px solid var(--input-border-color);
	border-radius: var(--inspector-border-radius);
	color: var(--input-text-color);
	background: var(--input-bg);
	min-height: 2em;
	padding-left: 3px;
	transition: border-color 0.2s var(--mq-transition);
}

.uix-input--numeric span {
	color: var(--input-icon-color);
}

.uix-input input:hover {
	border-color: var(--input-border-hover-color);
}

.uix-input input:focus {
	border-color: var(--editor-active-color);
}

input.must-input {
	border: 1px solid var(--input-border-color);
	border-radius: var(--inspector-border-radius);
	color: var(--input-text-color);
	background: var(--input-bg);
	min-height: 2em;
	padding-left: 4px;
	transition: border-color 0.2s var(--mq-transition);
}

input:hover.must-input {
	border-color: var(--input-border-hover-color);
}

input:focus.must-input {
	border-color: var(--editor-active-color);
}
.uix-input-icon {
	position: absolute;
	left: 0;
	bottom: 0;
	top: 0;
	display: flex;
	align-items: center;
	padding: 0.2em;
}
.uix-input-icon .mq-icon {
	width: 1em;
	height: 1em;
}

.inspector__input.uix-input input:focus {
	border: 1px solid var(--editor-active-color);
}
.inspector__input--has-unit.uix-input input {
	padding-right: 1.3em;
	min-width: 3em;
}

.inspector__input--has-icon.uix-input input {
	padding-left: 1.7em;
	min-width: 3em;
}
.inspector__input--has-icon .mq-icon {
	height: 100%;
	position: absolute;
	left: 1px;
	top: 0;
	bottom: 0;
	padding: 0 0.2em;
}

.inspector__controls .uix-list__item {
	font-weight: normal;
}
.uix-list__item--selected.inspector__btn {
	border-color: var(--input-border-hover-color) !important;
	background: var(--editor-active-bg);
	color: var(--editor-active-color);
}
.uix-list__item--selected.inspector__btn .mq-icon {
	fill: var(--editor-active-color);
}
.uix-popup {
	background: none;
}
.uix-popup:focus {
	outline: none;
}

.uix-colorinput .uix-popup {
	z-index: 500;
	transform: translate(-90%, 0);
}

.inspector__controls .uix-popup {
	z-index: 10;
	box-shadow: 2px 6px 15px rgba(0, 0, 0, 0.25);
}

/* New inspector styles, to be merged with the react rewrite */

/*
	Default Text Settings pane
	---------------------------------------------------------
*/

.default__text__settings__pane .inspector__panel-section-content:first-of-type {
	margin-top: 0;
}

.default__text__settings__pane .inspector__panel-section-content:last-of-type {
	margin-bottom: 0;
}

.font__defaults {
	display: flex;
	justify-content: space-between;
}

.font__defaults .font-family-dropdown {
	margin-left: 0;
}

.font__defaults .mq-input-value {
	white-space: nowrap;
	padding-right: 2.3em;
}

/*
	Page Settings pane
	---------------------------------------------------------
*/

.page__settings__pane .uix-input--numeric {
	max-width: 7em;
}

.page__settings__pane .mq-message {
	margin-bottom: 0;
	font-size: 0.9em;
	color: #4a4a4a;
}

.page__settings__pane .inspector__panel-section-content:first-of-type {
	margin-top: 0;
}

.page__settings__pane .inspector__panel-section-content:first-of-type .dropdown__value {
	padding: 0.6em 0.4em;
}

.page__settings__pane .inspector__panel-section-content:first-of-type .mq-icon {
	margin-left: 1px;
}

.page__settings__pane .inspector__panel-section-content:last-of-type {
	margin-bottom: 0;
}

.page__settings__pane .lock__overlay {
	position: relative;
	margin-left: -1em;
	width: 2em;
	height: 55%;
	border: 1px solid var(--input-border-color);
	border-radius: var(--inspector-border-radius);
	border-left: 0;
}

.page__size__lock {
	position: relative;
	width: 2em;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.page__size__lock button {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	padding: 0.2em;
	margin: 0.1em 0 0.1em 0.25em;
	background: var(--editor-bg);
	z-index: 2;
}

/*
	 / Page Settings pane
*/

/*
	Padding pane
	---------------------------------------------------------
*/

.padding__pane {
}

.padding__pane .inspector__panel-section {
	position: relative;
	padding: 0;
	margin: 0.2em 2.5em;
}

.padding__pane__overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: 0.75em 1.75em;
	border: 1px solid var(--input-border-color);
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	z-index: -1;
}

.padding__pane .uix-input--numeric {
	max-width: 4em;
}

/*
	 / Padding pane
	---------------------------------------------------------
*/

.inspector__vseparator {
	display: block;
	border-right: 1px solid var(--editor-border-color);
}

.inspector__checkbox {
	display: flex;
	align-items: center;
	color: var(--editor-text-muted-color);
	font-size: 13px;
}

/* when checkbox label is longer than one line */
.inspector__checkbox--long {
	align-items: flex-start;
}

.inspector__checkbox--long input[type='checkbox'].inspector__checkbox-input {
	margin-top: 3px;
}

.inspector__checkbox--checked,
input[type='checkbox'].inspector__checkbox-input:checked + .inspector__checkbox-label {
	color: var(--editor-text-color);
}

input[type='checkbox'].inspector__checkbox-input {
	display: inline-block;
	margin-right: 0.4em;
}

.project__palette__options {
	padding: 0.8em 0;
}

.palette__switch {
	list-style: none;
	margin: 0.5em 0 0;
	padding: 0;
	color: var(--editor-text-color);
	font-size: 13px;
	background: var(--switch-bg);
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	overflow: hidden;
	display: flex;
	box-shadow: var(--mq-box-shadow-inset-sm);
}

.palette__switch li {
	display: block;
	letter-spacing: 0;
	text-align: center;
	width: calc(50% - 4px);
	border: 2px solid transparent;
	background: transparent;
}

.palette__switch li a {
	display: block;
	color: inherit;
	opacity: 0.7;
	padding: 0.25em 0.5em 0.3em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	background: transparent;
	min-width: 6.5em;
	border-radius: var(--inspector-border-radius);
	transition: background-color 0.2s var(--mq-transition), color 0.2s var(--mq-transition),
		opacity 0.2s var(--mq-transition);
}

.palette__switch li a:hover {
	text-decoration: none;
	opacity: 1;
}

.palette__switch li a.active {
	color: var(--editor-active-color);
	background: var(--switch-active-bg);
	box-shadow: var(--mq-box-shadow-sm);
	opacity: 1;
}

.palette__info {
	color: var(--editor-text-muted-color);
	margin-bottom: 1.2em;
	background: var(--input-bg);
	padding: 0.75em 1em;
	margin: 1em 0;
	border-radius: calc(var(--inspector-border-radius) * 2);
	font-size: 12px;
}

.palette__info p {
	margin: 0;
}

.flip__buttons {
	gap: 0.2em;
}

.flip__buttons .inspector__btn {
	padding: 0.38em 0.45em;
}

.inspector .dropdown__outer--new {
	border-radius: var(--inspector-border-radius);
}

.inspector__dropdown--new ul {
	width: 230px;
}

.inspector__dropdown--new .dropdown__value {
	padding: 0.3em 0.4em;
}

.inspector__dropdown--new .dropdown__toggle {
	border: none;
	padding: 0 0.45em;
}

.inspector__dropdown--new .dropdown__toggle .mq-icon {
	width: 0.6em !important;
	height: 0.6em !important;
}

.inspector__input--new.uix-input input {
	padding-top: calc(0.3em - 1px);
	padding-bottom: calc(0.3em - 1px);
	border-radius: var(--inspector-border-radius);
}

.inspector__colorpicker--new {
	border-radius: var(--inspector-border-radius);
}

.inspector__colorpicker--new .mq-icon {
	padding: 0.1em 0;
}

.inspector__colorpicker--new .mq-color-swatch {
	width: auto;
	border-radius: 2px;
	overflow: hidden;
}

.inspector__colorpicker--new .mq-color-swatch a {
	height: calc(2em - 8px);
	border-radius: 0px;
}

/* ============================= */

.inspector__inspect {
	display: flex;
	flex-direction: column;
	max-height: 100%;
	height: 100%;
}

.inspector__inspect--noselection > div p {
	padding: 15px 10px 0 10px;
	color: var(--editor-text-color);
}

.inspector__empty {
	opacity: 0.5;
}

.inspect__separator {
	width: 100%;
	border-bottom: 1px solid var(--editor-border-color);
}

.inspect__title {
	padding: 10px 10px 10px 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 20px;
	color: var(--editor-text-color);
	user-select: text;
}

.inspector__inspect--noselection .inspect__title {
	color: var(--editor-text-color);
}

.inspector .inspect__title span {
	height: 1.75em;
	width: 1.75em;
	display: flex;
	justify-content: center;
	align-items: center;
}

.inspector .inspect__title svg {
	width: 0.9em;
	height: 0.9em;
	padding: 0.4em;
	cursor: pointer;
}

.inspector .inspect__title svg:hover {
	fill: var(--editor-active-color);
}

.inspect__properties {
	display: flex;
	flex-direction: column;
	background-color: var(--editor-bg);
	flex-grow: 1;
}

.inspect__properties__hint {
	display: flex;
	align-items: center;
	background: var(--editor-bg);
	color: var(--editor-text-muted-color);
	background: var(--input-bg);
	padding: 0.75em 1em;
	border-radius: calc(var(--inspector-border-radius) * 2);
	display: flex;
	justify-content: stretch;
	align-items: center;
	margin: 0.75em 1em;
	font-size: 13px;
}

.inspect__properties__hint span {
	font-size: 12px;
	line-height: 1.3;
}

.inspect__properties__hint strong {
	color: var(--editor-text-color);
}

.inspector .inspect__properties__hint svg {
	height: 32px;
	width: 32px;
	margin-right: 1em;
	flex: none;
}

.inspect__property__set {
	padding: 0 1em;
}

.inspect__properties__style {
	padding-top: 0.6em;
	padding-bottom: calc(0.6em + 1px);
}

.inspect__page__colors {
	display: flex;
	flex-wrap: wrap;
	justify-content: start;
	width: auto;
	gap: 5px;
}

.inspect__page__colors:not(:last-of-type) {
	margin-bottom: 5px;
}

.inspect__page__colors li {
	width: calc(1.6em - 4px);
	height: calc(1.6em - 4px);
}

.inspect__page__colors a {
	display: inline-block;
	width: calc(1.6em - 4px);
	height: calc(1.6em - 4px);
	box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
}

.inspect__code {
	padding: 0.5em 1em;
	user-select: text;
	font-family: Consolas, Monaco, Courier, 'Courier New', monospace;
	line-height: 1.5;
	color: var(--editor-text-muted-color);
}

/* Allow user to select code values easier */
.inspect__code span {
	user-select: all;
}

.inspect__code ::selection {
	background: var(--editor-semiselection-bg);
}

.inspect__code .hljs-selector-class {
	color: var(--editor-text-color);
}

.inspect__code .hljs-attribute {
	color: var(--editor-text-muted-color);
}

.inspect__code .hljs-built_in,
.inspect__code .hljs-string {
	color: var(--mq-purple-400);
}

.inspect__code .hljs-keyword,
.inspect__code .hljs-number {
	color: var(--mq-orange-500);
}

.inspect__code code {
	font-size: 11px;
	word-break: break-word;
}

.inspector__copy__code {
	font-weight: normal;
}

.inspect__code__options {
	display: flex;
	justify-content: stretch;
	align-items: center;
	gap: 0.5em;
	padding: 1em 1em 0.5em;
}

.inspect__code__options .mq__button {
	flex-grow: 1;
	border-color: var(--input-border-color);
	font-size: 1em;
	padding: 0.4em;
	min-height: 32px;
	height: 32px;
}

.inspect__code__options .codetype__dropdown {
	font-size: 1em;
	padding: 0 0.4em;
	min-height: 32px;
	height: 32px;
	box-sizing: border-box;
	display: flex;
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	background: var(--button-bg);
	border: 1px solid var(--input-border-color);
}

.inspect__code__options .codetype__dropdown:hover {
	background: var(--button-hover-color);
}

.inspector__property--label {
	width: 35%;
	min-width: 35%;
	color: var(--editor-text-muted-color);
	text-transform: capitalize;
	padding: 0.2em 0;
}

.inspector__property {
	font-size: 0.9em;
}

.inspector__property ul {
	width: 100%;
	min-width: 0; /* Prevents ul from exceeding parent width (being flex and all) */
}

.inspector__property ul li {
	display: flex;
	align-items: center;
	justify-content: stretch;
	padding: 0.2em 0.2em;
}

.inspect__iconmeta {
	padding: 0.3em 0 0.5em;
	font-size: 11px;
	color: var(--editor-text-muted-color);
}

.inspect__iconmeta a {
	text-decoration: underline;
	color: var(--editor-text-color);
}

.inspector__property--actionable {
	display: flex;
	flex-grow: 1;
	color: var(--editor-text-color);
	cursor: pointer;
	border-radius: 3px;
	margin-left: -0.2em;
}

.inspector__property--actionable:hover,
.inspector__property--actionable:focus {
	background: var(--editor-semiselection-bg);
}

.inspector__property--value {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.inspector__property--colorpreview {
	display: inline-block;
	height: 1em;
	width: 1em;
	min-height: 1em;
	min-width: 1em;
	box-sizing: border-box;
	border-radius: 2px;
	margin-right: 0.25em;
}

.inspector__colorpicker .must-swatch-button {
	height: 100%;
	padding: 1px;
	background: none;
}

.inspector__colorpicker .must-swatch {
	min-width: 3em;
	max-width: 6em;
	width: auto;
	height: 100%;
}

.codetype__dropdown__menu,
.codetype__dropdown {
	font-size: 0.9em;
}
.codetype__dropdown {
	color: var(--editor-text-color);
}

.codetype__dropdown__menu.dropdown__menu .contextmenu__item__label {
	padding: 0.5em 1em;
	font-size: 13px;
}
