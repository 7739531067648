/* =============================================================================
   Messages
   ========================================================================== */

.mq-badge {
	vertical-align: middle;
	display: inline-block;
	padding: 0.2em 0.75em;
	background: var(--button-bg);
	color: var(--button-text-color);
	border-radius: 0.25em;
	text-transform: uppercase;
	font-size: 0.8em;
	font-weight: bold;
	letter-spacing: 0.05em;
}

.mq-new {
	/*border: 1px solid currentColor;*/
	color: var(--editor-active-color);
	text-transform: uppercase;
	font-size: 0.7em;
	letter-spacing: 0.05em;
	padding: 0.2em 0.5em;
	display: inline-block;
	margin-right: 0.25em;
	border-radius: 0.25em;
	font-weight: bold;
}

.mq-message {
	margin: 0 0 1em 0;
	padding: 0.75em;
	border-radius: calc(var(--inspector-border-radius) * 1.5);
}

.mq-message--lg {
	padding: 1.25em;
	border-radius: calc(var(--inspector-border-radius) * 3);
}

.mq-message h3 {
	margin: 0 0 8px;
	font-size: 14px;
	font-weight: bold;
}

.mq-message a {
	text-decoration: underline;
}

.mq-message a:hover,
.mq-message a:focus {
	color: var(--link-active-color);
}

.mq-info {
	color: var(--info-text-color);
	background: var(--info-bg);
}

.mq-success {
	color: var(--success-text-color);
	background: var(--success-bg);
}

.mq-warning {
	color: var(--warning-text-color);
	background: var(--warning-bg);
}

.mq-warning .mq-icon {
	fill: var(--warning-text-color) !important;
	margin-right: 0.5em;
}

.mq-error {
	color: var(--error-text-color);
	background: var(--error-bg);
}

.mq-warning .mq-error {
	background: transparent;
	border: none;
}

.mq-stencil-alert {
	color: var(--error-text-color);
	background: var(--error-bg);
	text-align: center;
	margin-top: -0.5em;
}

.mq-message-secondary {
	font-size: 0.9em;
	margin: 1em 0;
}

.mq-message ul {
	list-style: disc;
	padding-left: 1em;
}
