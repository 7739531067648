/* TOOLBAR */

.mq-zone {
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--editor-text-color);
}

.mq-zone-left {
	z-index: 4;
	justify-content: flex-start;
}

.mq-zone-center {
	z-index: 3;
	position: relative;
	flex-grow: 1;
}

.mq-zone-right {
	justify-content: flex-end;
	padding-right: 0.2em;
}

.top-announcement #toolbar-wrapper {
	height: 100px;
}
.top-announcement #toolbar-wrapper .mq-zone {
	top: 50px;
	background-color: var(--editor-bg);
}
.top-announcement #stage-wrapper {
	top: 100px;
}
.top-announcement .toolbar-notification {
	left: 0;
}

.toolbar-notification {
	position: absolute;
	top: 0;
	left: 54px;
	padding: 0 50px;
	right: 0;
	box-sizing: border-box;
	min-height: 50px;
	overflow: hidden;
	z-index: 90;
	display: flex;
	align-items: center;
	line-height: 1.2;
	justify-content: center;
	text-align: center;
	text-overflow: ellipsis;
	color: var(--mq-white);
	background: var(--mq-blue-500);
	gap: 0.4em;
}

.toolbar-notification span {
}

.toolbar-close {
	position: absolute;
	right: 0;
	top: 0;
	height: 50px;
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.toolbar-notification--danger {
	background: var(--mq-orange-500);
}

.toolbar-notification a {
	color: inherit;
	font-weight: bold;
	text-decoration: underline;
	transition: opacity 0.2s var(--mq-transition);
}

.toolbar-notification a:hover {
	opacity: 0.8;
}

.toolbar-notification em {
	font-size: 1.5em;
	font-style: normal;
	display: inline-block;
	vertical-align: middle;
}

.toolbar-notification .mq-icon {
	fill: currentColor;
}

.mq-zone-right.view-mode {
	padding-right: 0.6em;
}

.mq-zone-right--expanded.view-mode {
}

.mq-zone-center {
	color: var(--editor-text-color);
}

.mq-zone-center.view-mode {
}

.mq-zone-center--contracted.view-mode {
}

.mq-zone-center.no-session .mq-toolbar-separator {
	width: 1.5em;
}

.mq-toolbar {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 2px;
	white-space: nowrap; /* Keeps items from wrapping on low res */
}

.mq-toolbar-section {
	display: inline-block;
}

.mq-toolbar-btn {
	cursor: pointer;
	color: inherit;
	text-decoration: none;
	pointer-events: all;
	display: flex;
	flex-direction: column;
	align-items: center;
	vertical-align: middle;
	line-height: 1;
	min-width: 4.4em;
	border: 1px solid transparent;
	border-radius: 6px;
	padding: 0.2em;
	transition: opacity 0.15s ease-in, color 0.1s var(--mq-transition);
	text-align: center;
	background: none;
}

.mq-toolbar-btn[disabled] {
	pointer-events: none;
}

.mq-toolbar-btn.compact {
	min-width: 3.5em;
}

.mq-toolbar-btn.active {
	/*border-color: var(--editor-active-color);*/
	background: var(--editor-active-bg);
	color: var(--editor-active-color);
	/*box-shadow: var(--mq-box-shadow-inset);*/
}

.mq-toolbar-btn.mq-inspect-btn.active {
	border-color: transparent;
	background: transparent;
	color: var(--editor-active-color);
	box-shadow: none;
}

.mq-toolbar-btn.active .mq-icon,
.mq-toolbar-btn.mq-inspect-btn.active .mq-icon,
.mq-toolbar-btn.active .must-icon,
.mq-toolbar-btn.mq-inspect-btn.active .must-icon {
	color: currentColor;
	fill: currentColor;
}

.mq-toolbar-btn:hover {
	text-decoration: none;
	color: var(--editor-active-color);
}

.mq-toolbar-btn:hover .mq-icon,
.mq-toolbar-btn:hover .must-icon {
	color: currentColor;
	fill: currentColor;
}

.mq-toolbar-btn .mq-icon {
	width: 24px;
	height: 24px;
	margin: 0 auto;
	display: block;
	color: var(--editor-icon-color);
	fill: currentColor;
	transition: color 0.1s var(--mq-transition);
}

.mq-toolbar-btn .must-icon {
	font-size: 24px; /* em @must icon sizing */
	color: var(--editor-icon-color);
}

.mq-toolbar-btn .mq-icon-menu {
	padding-right: 0.75em;
}

.mq-toolbar-dropdown {
	position: relative;
	display: inline-block;
	vertical-align: middle;
}

.mq-toolbar-dropdown-menu {
	position: absolute;
	top: 100%;
	padding: 1em;
	background: var(--editor-menu-bg);
}

.mq-sidebar-dropdown {
	position: relative;
	vertical-align: middle;
}
.mq-sidebar-dropdown span {
	font-size: 0.9em;
}

.mq-toolbar-btn span {
	font-size: 10px;
	margin-top: 4px;
	/*display: none;*/
}

.mq-toolbar-separator {
	display: inline-block;
	width: 2em;
	height: 2em;
	position: relative;
}

.mq-toolbar-separator:before {
	content: ' ';
	position: absolute;
	top: 4px;
	bottom: 4px;
	width: 1px;
	left: calc(50% - 1px);
	background: var(--editor-border-color);
}

.mq-separator-label {
	text-transform: uppercase;
	color: var(--editor-text-muted-color);
	font-size: 0.6em;
}

.mq-page-nav-button {
	min-width: 2.75em;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding: 4px;
	height: 30px;
}

.mq-page-counter {
	display: inline-block;
	vertical-align: middle;
	font-size: 13px;
	padding: 0 0.25em 0 0;
	white-space: nowrap;
}

.mq-page-counter input {
	text-align: center;
	display: inline-block;
	width: 3em;
	padding: 0.25em;
}

.mq-page-counter input.compact {
	width: 2em;
}

.toolbar__fl__message {
	position: fixed;
	top: 5em;
	left: 50%;
	width: 780px;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1em 3em 1em 1.5em;
	border-radius: 10px;
	box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.09);
	background: #fff;
	font-size: 16px;
	min-width: 500px;
}

.toolbar__fl__message p:first-of-type {
	margin-top: 0;
}
.toolbar__fl__message p:last-of-type {
	margin-bottom: 0;
}

.toolbar__fl__message .head {
	font-weight: bold;
}

.toolbar__fl__message .info {
	font-size: 0.7em;
	color: #929292;
}

.toolbar__fl__message .mq__button {
	height: 40px;
	font-size: 0.8em;
	font-weight: bold;
	display: flex;
	align-items: center;
	white-space: nowrap;
}

.toolbar__fl__message .mq__button:nth-of-type(2) {
	margin-left: 0.8em;
}

.toolbar__fl__message .mq__button__google {
	box-shadow: none;
	font-weight: normal;
	height: 40px;
	padding: 1px 8px;
}

.toolbar__fl__message .mq__button__google svg.mq-icon {
	margin-left: -7px;
}

.fl__message__toggle {
	display: flex;
	position: absolute;
	right: 0.75em;
	top: 0.75em;
}

.toolbar__fl__actions {
	display: flex;
	align-items: center;
}

.toolbar__auth__button {
	padding: 0 0.5em;
	font-weight: bold;
	white-space: nowrap;
}
