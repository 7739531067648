/*
	Profile card
	----------------------------------------------------------------------
*/

.mq-profile-card {
	position: relative;
	display: inline-block;
	color: var(--editor-text-color);
	user-select: text;
}

.mq-profile-card-compact {
	display: block;
}

.mq-profile-card-mini .mq-profile-card-name {
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.mq-profile-card-compact:hover .mq-profile-card-name {
	/*background: var(--editor-highlight-bg);*/
}

.mq-profile-card-compact .mq-profile-card-avatar {
	margin-right: 0.2em;
}

.mq-profile-card-expanded {
	position: absolute;
	padding: 1em;
	display: flex;
	align-items: center;
	/* same as context menu, unify? */
	background: var(--input-bg);
	box-shadow: var(--mq-box-shadow-md);
	border: 1px solid var(--input-border-color);
	z-index: 1000;
	border-radius: calc(var(--inspector-border-radius) * 2);
	opacity: 0;
	transition: opacity 0.15s 0.2s, visibility 0s 0.2s;
	left: 0;
	top: 1.75em;
	visibility: hidden;
}

.mq-profile-card:hover .mq-profile-card-expanded {
	opacity: 1;
	transition: all 0.15s 0.7s;
	visibility: visible;
}

.mq-profile-card-expanded.visible {
	opacity: 1;
	transition: opacity 0.15s 0.7s;
	visibility: visible;
}

.mq-profile-card-avatar {
	width: 1.25em;
	height: 1.25em;
	overflow: hidden;
}

.mq-profile-card-name {
	font-weight: bold;
	display: inline-block;
	padding: 0 4px;
	border-radius: 4px;
	max-width: 130px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.mq-profile-card-info .mq-icon {
	fill: var(--editor-icon-color);
	width: 0.8em;
	margin-right: 0.25em;
}

.mq-profile-card-email {
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.mq-profile-card-expanded .mq-profile-card-avatar {
	display: block;
	width: 4em;
	height: 4em;
	float: left;
}

.mq-profile-card-expanded .mq-profile-card-name {
	display: block;
	margin: 0;
	padding: 0;
	max-width: 180px;
}

.mq-profile-card-expanded {
	/*overflow: hidden;*/
	text-overflow: ellipsis;
	font-size: 12px;
}

.mq-profile-card-expanded .mq-profile-card-avatar + span {
	margin-left: 1em;
	display: block;
}

.mq-profile-card-mini {
	width: calc(100% - 3.1em);
}

.mq-profile-card-mini .mq-profile-card-expanded {
	max-width: 13em;
	text-align: center;
	padding: 0.5em;
	flex-direction: column;
}

.mq-profile-card-mini .mq-profile-card-expanded .mq-profile-card-avatar {
	float: none;
	display: block;
	margin: 0 auto 0.5em;
}

.mq-profile-card-mini .mq-profile-card-expanded .mq-profile-card-avatar + span {
	margin-left: 0;
}
