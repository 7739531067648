/* RTE */

#rich-toolbar-container {
	pointer-events: none;
	position: absolute;
	height: 0px;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 9999;
}

.rich-toolbar {
	position: absolute;
	z-index: 100;
	margin-top: -16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
	transform: translate(-50%, -100%);
	white-space: nowrap;
	color: var(--toolbar-text-color);
	background: var(--toolbar-bg);
	box-shadow: var(--mq-box-shadow-lg);
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	border: 1px solid var(--toolbar-border-color);
	height: var(--toolbar-size);
	padding: 0 calc(var(--toolbar-gap) * 2);
	gap: calc(var(--toolbar-gap) * 1);
}
/*
.rich-toolbar:after,
.rich-toolbar:before {
	top: 100%;
	left: 15px;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.rich-toolbar:after {
	border-color: rgba(255, 255, 255, 0);
	border-top-color: #f5f5f5;
	border-width: 10px;
	left: 16px;
}
.rich-toolbar:before {
	border-color: rgba(230, 230, 230, 0);
	border-top-color: #c0c0c0;
	border-width: 11px;
}
.rich-toolbar-below:before,
.rich-toolbar-below:after {
	content: none;
}
*/

.rich-toolbar-below {
	margin-top: 8px;
	transform: translate(0, 0);
}

.ql-clipboard {
	left: -100000px;
	height: 1px;
	overflow-y: hidden;
	position: absolute;
	top: 50%;
}

.patch-contenteditable,
.fo__content {
	color: var(--mq-black);
}

.ql-editor p,
.fo__content p {
	margin: 0;
}

.ql-rtl {
	direction: rtl;
	text-align: right;
}

.ql-ltr {
	direction: ltr;
	text-align: left;
}

.rich-text-button {
	position: relative;
	height: calc(var(--toolbar-size) - calc(var(--toolbar-gap) * 2.5));
	min-width: calc(var(--toolbar-size) - calc(var(--toolbar-gap) * 2.5));
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	cursor: pointer;
	background: transparent;
	border: none;
	border-radius: 4px;
	pointer-events: all;
	cursor: default;
}

.rich-text-count {
	height: calc(var(--toolbar-size) - calc(var(--toolbar-gap) * 2.5));
	min-width: calc(var(--toolbar-size) - calc(var(--toolbar-gap) * 2.5));
	padding: 0 9px;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: all;
	font-size: 12px;
}

.rte-counter-label {
	background: transparent;
	/*emulates a prettier dotted border via https://stackoverflow.com/questions/6250394/how-to-increase-space-between-dotted-border-dots */
	background-image: linear-gradient(
		to right,
		var(--editor-text-muted-color) 40%,
		rgba(255, 255, 255, 0) 20%
	);
	background-position: bottom;
	background-size: 3px 1px;
	background-repeat: repeat-x;
}

.rich-text-count:hover {
	cursor: pointer;
}

.rte-counter-value {
	margin-left: 6px;
}

.rte-toolbar-separator {
	height: 22px;
	border-left: 1px solid var(--toolbar-border-color);
	margin: 0 calc(var(--toolbar-gap) * 2);
}

.rich-text-button.active:not(.ql-link) {
	background: var(--toolbar-selection-bg);
	box-shadow: var(--mq-box-shadow-inset-sm);
}

.rich-text-button .mq-icon {
	overflow: visible;
	color: var(--toolbar-text-color);
	fill: var(--toolbar-text-color);
	width: var(--toolbar-icon-size);
	height: var(--toolbar-icon-size);
}

.rich-text-button.active .mq-icon {
	color: var(--editor-active-color);
	fill: var(--editor-active-color);
}

.rich-text-button:hover {
	cursor: pointer;
}

.rich-text-button:hover .mq-icon {
	color: var(--editor-active-color);
	fill: var(--editor-active-color);
}

.rte-color-picker {
	position: absolute;
	pointer-events: all;
	z-index: 999;
}
