/* Message dialog */

.mq-message-dialog .mq-btn {
	margin-right: 0.5em;
}

.mq-message-dialog .mq-btn.pull-right:not(.preserve-margin) {
	margin-right: 0;
}

/* /Message dialog */
