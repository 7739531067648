/**
 * Define color variables and shapes for each style
 */

.comment-style-circle {
	/* blue */
	--mq-comment-background-color: rgb(13, 110, 245);
	--mq-comment-color: #fff;
	--mq-comment-highlight-color: rgba(13, 110, 245, 0.25);
}

.comment-style-circle.shape,
.comment-marker.comment-style-circle > .comment-marker-animation-helper::before,
.comment-marker.comment-style-circle > .comment-marker-animation-helper::after {
	border-radius: 50%;
}

.comment-style-rhombus {
	/* green */
	--mq-comment-background-color: rgb(74, 156, 46);
	--mq-comment-color: #fff;
	--mq-comment-highlight-color: rgba(74, 156, 46, 0.25);
}

.comment-marker.comment-style-rhombus,
.comment-marker-highlight.comment-style-rhombus {
	width: 43px;
	height: 43px;
}

.comment-marker-index.comment-style-rhombus {
	width: 30px;
	height: 30px;
	line-height: 2.5em;
}

.comment-style-rhombus.shape,
.comment-marker.comment-style-rhombus > .comment-marker-animation-helper::before,
.comment-marker.comment-style-rhombus > .comment-marker-animation-helper::after {
	aspect-ratio: 1;
	clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
}

.comment-style-star {
	/* yellow/gold */
	--mq-comment-background-color: rgb(246, 202, 75);
	--mq-comment-color: #000;
	--mq-comment-highlight-color: rgba(246, 202, 75, 0.25);
}

.comment-style-star.shape,
.comment-marker.comment-style-star > .comment-marker-animation-helper::before,
.comment-marker.comment-style-star > .comment-marker-animation-helper::after {
	aspect-ratio: 1;
	clip-path: polygon(
		100% 50%,
		87.67% 60.09%,
		93.3% 75%,
		77.58% 77.58%,
		75% 93.3%,
		60.09% 87.67%,
		50% 100%,
		39.91% 87.67%,
		25% 93.3%,
		22.42% 77.58%,
		6.7% 75%,
		12.33% 60.09%,
		0% 50%,
		12.33% 39.91%,
		6.7% 25%,
		22.42% 22.42%,
		25% 6.7%,
		39.91% 12.33%,
		50% 0%,
		60.09% 12.33%,
		75% 6.7%,
		77.58% 22.42%,
		93.3% 25%,
		87.67% 39.91%
	);
}

.comment-marker.comment-style-star,
.comment-marker-highlight.comment-style-star {
	width: 37px;
	height: 37px;
}

.comment-marker-index.comment-style-star {
	width: 26px;
	height: 26px;
	line-height: 2.2em;
}

.comment-style-square {
	/* purple */
	--mq-comment-background-color: rgb(141, 106, 248);
	--mq-comment-color: #fff;
	--mq-comment-highlight-color: rgba(141, 106, 248, 0.25);
}

.comment-style-square.shape,
.comment-marker.comment-style-square > .comment-marker-animation-helper::before,
.comment-marker.comment-style-square > .comment-marker-animation-helper::after {
	border-radius: 0;
}

.comment-style-hexagon {
	/* red */
	--mq-comment-background-color: rgb(219, 94, 60);
	--mq-comment-color: #fff;
	--mq-comment-highlight-color: rgba(219, 94, 60, 0.25);
}

.comment-style-hexagon.shape,
.comment-marker.comment-style-hexagon > .comment-marker-animation-helper::before,
.comment-marker.comment-style-hexagon > .comment-marker-animation-helper::after {
	aspect-ratio: 0.8; /* cos(30deg) */
	clip-path: polygon(-50% 50%, 50% 100%, 150% 50%, 50% 0);
	height: initial;
}

/* Adjustments for tip of comment popover to prevent popover from moving while adjustin
 comment style */

.comment-thread:has(.comment-marker.comment-style-hexagon) .comment-popover {
	top: calc(-5em - 8.5px);
}

.comment-thread:has(.comment-marker.comment-style-rhombus) .comment-popover {
	top: calc(-5em - 9px);
}

.comment-thread:has(.comment-marker.comment-style-star) .comment-popover {
	top: calc(-5em - 3px);
}
