.features__modal {
	font-size: 16px;
}

.features__modal h3 {
	margin-top: 0;
	font-weight: bold;
}

.features__modal .mq-modal-content {
	position: relative;
}

.features__modal .mq__modal__close {
	position: absolute;
	right: 30px;
	top: 30px;
	z-index: 2;
}

.feature__nav {
	margin-top: 1em;
}

.feature__header {
	margin-top: 1em;
	width: 100%;
	font-weight: bold;
}

.feature__title {
	font-size: 24px;
	margin: 0;
	padding: 0 2.5em 0 0;
	color: var(--editor-text-color);
	font-weight: bold;
	font-family: var(--mq-font-display);
}

.feature__img {
	position: relative;
	margin-top: 12px;
}

.feature__img img {
	max-width: 100%;
	border-radius: 6px;
}

.feature__content {
	font-size: 15px;
	color: var(--editor-text-color);
	padding-right: 12px;
}

.feature__action {
	margin-right: 1.1em;
}

.feature__nav {
	display: flex;
	align-items: center;
}

.feature__nav__item {
	cursor: pointer;
	margin-right: 0.3em;
	width: 0.5em;
	height: 0.5em;
	border-radius: 50%;
	background-color: #d6d6d6;
}

.feature__stats {
	width: 100%;
	padding: 5px 0 20px 0;
	column-gap: 9px;
	font-size: 14px;
	color: #929292;
}

.feature__stats span {
	background: #ebebeb;
	border-radius: 8px;
	padding: 6px 10px;
}

.feature__nav__item:last-of-type {
	margin-right: 0;
}

.feature__nav__item--selected {
	background-color: #1464f6;
}

.feature__benefits {
	padding: 0 1.5em;
	color: #797979;
}

.new__features__badge {
	position: absolute;
	left: 0;
	bottom: 0;
	padding: 0.5em 0.8em;
	border-radius: 4px;
	font-weight: bold;
	font-size: 0.9em;
	color: #243e16;
	background-color: #fded13;
}
