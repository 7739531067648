#sidebar .vertical-icon-menu {
	float: left;
	position: relative;
	z-index: 10;
	margin-top: 0.25rem;
}

#sidebar .moqups-whats-new-link .mq-icon {
	fill: var(--mq-orange-500);
	width: 1.8em;
	height: 1.8em;
}

#sidebar .vertical-icon-menu.sidebar-bottom-actions {
	position: absolute;
	background: transparent;
	height: auto;
	bottom: 0;
}

#sidebar .vertical-icon-menu.sidebar-bottom-actions > li {
	padding-top: 1em;
}

#sidebar .vertical-icon-menu .mq-sidebar-add-template {
	display: flex;
	padding: 0.5em 0 0.45em;
	border: none;
}

#sidebar .vertical-icon-menu .mq-sidebar-add-template span {
	flex-grow: 1;
	display: flex;
}

#sidebar .vertical-icon-menu .mq-sidebar-add-template button {
	transition: all 0.2s ease-in-out;
	transition-delay: 0.05s;
	display: block;
	width: 100%;
	flex-grow: 1;
	padding: 0.5em 0;
	background: none;
	border: none;
}

#sidebar .vertical-icon-menu .mq-sidebar-add-template svg {
	color: var(--link-color);
	fill: var(--link-color);
	margin: auto;
	width: 24px;
	height: 24px;
	padding: 1px;
}

#sidebar .vertical-icon-menu .mq-sidebar-add-template button:hover {
	cursor: pointer;
}

#sidebar .vertical-icon-menu .mq-sidebar-add-template button:hover svg {
	color: var(--link-active-color);
	fill: var(--link-active-color);
}

#sidebar-content {
	height: 100%;
	position: relative;
	background: var(--editor-menu-bg);
	border-left: 1px solid var(--editor-border-color);
	box-sizing: border-box;
	/*padding: 0.5em;*/
	margin-left: 54px;
}
/*Need a better way to handle this...ideally without increasing to 50px margin left*/
.sidebar-content-viewer-mode {
	margin-left: 0 !important;
}

#sidebar.collapsed {
	width: auto !important;
	border-bottom: 0 !important;
}

#sidebar.collapsed #sidebar-content,
#sidebar.collapsed .mq-sidebar-resize,
#sidebar.collapsed .vertical-icon-menu > li.active:after {
	display: none;
}

.sidebar-pane {
	height: 100%;
	display: none;
	font-size: 0.875rem;
}

.sidebar-pane-active {
	display: block;
}

.force-visible {
	display: block !important;
}

.sidebar-pane-header {
	padding: 0 9px;
	min-height: var(--sidebar-header-height);
	border-bottom: 1px solid var(--editor-menu-border-color);
	background: var(--editor-menu-bg);
	box-sizing: border-box;
	display: flex;
	gap: 5px;
	justify-content: stretch;
	align-items: center;
}

.sidebar-pane-header .mq-searchbox {
	width: 100%;
	margin: 9px 0;
}
/*
.scrollable {
	overflow-y: scroll;
}
*/
.disable-pointer-events {
	pointer-events: none;
}

.sidebar-pane-header--absolute {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 2;
}

.sidebar-pane-header-label {
	color: var(--editor-text-color);
	text-transform: uppercase;
	font-size: 10px;
	min-height: 24px;
}

.mq-sidebar-open-pages + .sidebar-pane-header-label {
	border-top: 1px solid var(--editor-menu-border-color);
}

.sidebar-pane-info-box {
	border-radius: calc(var(--inspector-border-radius) * 2);
	background-color: var(--editor-menu-hover-color);
	/*background: linear-gradient(to bottom, rgb(248, 248, 248) 0%, rgba(255, 255, 255, 0) 100%);*/
	color: var(--editor-text-color);
	margin: 0.75em 9px 0;
	padding: 0.75em 1em;
	display: block;
}

.sidebar-pane-info-box h3 {
	font-size: 12px;
	font-weight: 500;
	margin: 0;
}

.sidebar-pane-info-box p {
	font-size: 12px;
	font-weight: normal;
	color: var(--editor-text-muted-color);
}

.sidebar-pane-info-box a {
	font-size: 12px;
	font-weight: 500;
	display: inline-block;
	border-radius: 3px;
	background-color: var(--button-primary-bg);
	padding: 4px 10px;
	margin-top: 0.5em;
	color: var(--button-primary-text-color);
}

.sidebar-pane-info-box a .mq-icon {
	fill: var(--button-primary-text-color);
}

.sidebar-pane-info-box a:hover {
	text-decoration: none;
	background-color: var(--button-primary-hover-color);
}

.mq-sidebar-info-popover {
	margin-top: 0.25em;
}

.mq-sidebar-info-popover .mq-icon {
	fill: #c0c0c0;
	width: 1.25em;
	height: 1.25em;
}

.mq-sidebar-info-popover:hover .mq-icon {
	fill: #006be5;
}

#icons-sidebar-pane .mq-link-dropdown {
	text-align: left;
	padding: 0.5em 0 0.5em 0;
}

.mq-sidebar-info {
	color: var(--editor-text-color);
	padding: 1em;
	text-align: center;
}

.sidebar-pane-header .mq-link-dropdown {
	font-size: 13px;
	max-width: 16em;
	/*flex-grow: 1;*/
}

.sidebar-pane-content {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	overflow: auto;
}

#sidebar-content.has-stencil-counter .sidebar-pane-content {
	bottom: 68px;
}

.sidebar-footer {
	z-index: 9;
	position: absolute;
	bottom: 0;
	width: 100%;
}

.sidebar-pane-header select,
.sidebar-pane-header input {
	width: 100%;
}

.mq-sidebar-list {
	text-align: center;
	overflow: hidden;
	-webkit-tap-highlight-color: transparent;
}

.mq-sidebar-sub-list {
	/*margin-left: 1.5em;*/
}

.sidebar-pane-content-outline:not(.searching)
	.mq-sidebar-list-page:not(.expanded)
	> .mq-sidebar-list-page__inner
	> .mq-sidebar-sub-list,
.sidebar-pane-content-outline.searching .mq-sidebar-list-page:not(.matches),
.sidebar-pane-content-outline.searching
	.mq-sidebar-list-page.matches:not(.expanded)
	> .mq-sidebar-list-page__inner
	> .mq-sidebar-sub-list {
	display: none;
}

.mq-sidebar-sub-list.hidden {
	display: none;
}

.sidebar-pane-content-outline .expand,
.sidebar-pane-content-outline .mq-sidebar-list-page-label {
	padding-left: 4px;
}

.sidebar-pane-content-outline .expand + .mq-sidebar-list-page-label {
	padding-left: 2px;
}

.mq-outline-icon {
	width: 12px;
	height: 12px;
	margin: 2px; /* to fill 16px box */
	align-items: center;
	display: flex;
	flex: none;
	--icon-c-main: currentColor;
}

.mq-outline-icon:not(.expand .mq-outline-icon) {
	opacity: 0.5;
}

#stencil-sidebar-pane .section-header,
#comments-sidebar-pane .section-header {
	position: relative;
	font-weight: 500;
	font-size: 11px;
	color: var(--link-color);
	white-space: nowrap;
	/*We'll see*/
	/*text-transform: uppercase;*/
	text-align: left;
	padding: 0.3em 9px;
	box-sizing: content-box;
	background: var(--editor-menu-bg);
	border-bottom: 1px solid var(--editor-menu-border-color);
	border-top: 1px solid var(--editor-menu-border-color);
	/*
		Small hack so the rows with uneven column numbers don't get gut
	+-----------------+
	|  Section header |
	+--------+--------+
	|        |        |
	|        |        |
	|        |        |
	+-----------------+
	|        |        |
	|        |        |
	|        |        |
	+--------+        <----+
	|  Section header |    |
	+-----------------+    |
						   +
	*/
	margin-top: -1px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

#stencil-sidebar-pane .section-header:hover,
#comments-sidebar-pane .section-header:hover {
	background-color: var(--editor-menu-hover-color);
}

#stencil-sidebar-pane .expand,
#comments-sidebar-pane .expand,
#icons-sidebar-pane .expand {
	width: 10px;
	height: 10px;
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.1s ease-in;
	transform: rotate(0deg);
}

#stencil-sidebar-pane .expand.collapsed,
#comments-sidebar-pane .expand.collapsed,
#icons-sidebar-pane .expand.collapsed {
	transform: rotate(90deg);
}

#stencil-sidebar-pane .expand .mq-icon,
#comments-sidebar-pane .expand .mq-icon,
#icons-sidebar-pane .expand .mq-icon {
	width: 10px;
	height: 10px;
	fill: var(--editor-text-color);
}

#stencil-sidebar-pane .mq-sidebar-list {
	background: var(--stencil-bg);
}

#stencil-sidebar-pane .mq-sidebar-list-item {
	border-color: var(--stencil-border-color);
}

#stencil-sidebar-pane .mq-sidebar-list-item:hover {
	background-color: var(--stencil-hover-color);
}

#stencil-sidebar-pane .section-header {
	color: var(--stencil-header-color);
}

.section-header-collapsed-extra-results {
	font-size: 10px;
	max-width: 60px;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: normal;
	text-transform: lowercase;
	border-radius: 14px;
	margin-left: 6px;
	margin-right: auto;
	padding: 0 4px;
	background: var(--new-accent);
	color: var(--new-text-color);
	height: 14px;
	white-space: nowrap;
	line-height: 1.4;
}

#stencil-sidebar-pane .mq-sidebar-list.collapsed {
	display: none;
}
.comment-unread-sidebar-number {
	background: var(--unread-comment-color);
	color: var(--unread-comment-text-color);
	border-radius: 0.4em;
	padding: 0 0.3em;
	font-size: 10px;
}

.mq-sidebar-list-item {
	float: left;
	width: 50%;
	height: 0;
	padding-bottom: 50%;
	box-sizing: border-box;
	border-bottom: 1px solid var(--editor-menu-border-color);
	position: relative;
	cursor: pointer;
}

.mq-sidebar-list-item:hover {
	background-color: var(--editor-menu-hover-color);
}

.mq-sidebar-list-item:nth-child(2n + 1) {
	border-right: 1px solid var(--editor-menu-border-color);
}

.extra-rows-1 .mq-sidebar-list-item {
	width: 33.33%;
	padding-bottom: 33.33%;
	border-right: 1px solid var(--editor-menu-border-color);
}

.extra-rows-1 .mq-sidebar-list-item:nth-child(3n) {
	border-right: none;
}

.extra-rows-2 .mq-sidebar-list-item {
	width: 25%;
	padding-bottom: 25%;
	border-right: 1px solid var(--editor-menu-border-color);
}

.extra-rows-2 .mq-sidebar-list-item:nth-child(4n) {
	border-right: none;
}

.mq-sidebar-list-item .mq-icon {
	width: 60px;
	height: 40px;
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: var(--mq-black);
}

.mq-sidebar-list-item.mq-sidebar-icon-item {
	width: 33.33%;
	padding-bottom: 33.33%;
	border-right: 1px solid var(--editor-menu-border-color);
}

.mq-sidebar-list-item.mq-sidebar-icon-item:nth-child(3n) {
	border-right: none;
}

.extra-rows-1 .mq-sidebar-list-item.mq-sidebar-icon-item {
	width: 25%;
	padding-bottom: 25%;
	border-right: 1px solid var(--editor-menu-border-color);
}

.extra-rows-1 .mq-sidebar-list-item.mq-sidebar-icon-item:nth-child(4n) {
	border-right: none;
}

.extra-rows-2 .mq-sidebar-list-item.mq-sidebar-icon-item {
	width: 20%;
	padding-bottom: 20%;
	border-right: 1px solid var(--editor-menu-border-color);
}

.extra-rows-2 .mq-sidebar-list-item.mq-sidebar-icon-item:nth-child(5n) {
	border-right: none;
}

.mq-sidebar-template-item {
	overflow: hidden;
}

.mq-sidebar-icon-item .mq-icon {
	width: 50%;
	height: 50%;
	top: 50%;
	left: 50%;
}

.mq-sidebar-image-item img {
	max-width: 80%;
	max-height: 80%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
}

.mq-sidebar-template-item img {
	max-width: 180%;
	max-height: 100%;
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0.5, 0.5);
	pointer-events: none;
}

.mq-sidebar-image-item strong,
.mq-sidebar-template-item strong {
	position: absolute;
	top: 2%;
	left: 2%;
	width: 1em;
	height: 1em;
	font-size: 115%;
	background: #fff;
	color: #000;
	text-align: center;
	line-height: 1em;
	font-weight: 500;
	z-index: 10;
	opacity: 0;
	pointer-events: none;
	cursor: pointer;
	transition: opacity 0.15s;
	border-radius: 5px;
}

.mq-sidebar-image-item:hover strong,
.mq-sidebar-template-item:hover strong {
	pointer-events: all;
	opacity: 1;
}

.sidebar-pane-content-pages {
	padding: 0.5em;
}

.mq-sidebar-list-no-content {
	font-size: 12px;
	color: var(--editor-text-muted-color);
	background-color: var(--editor-bg);
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	display: block;
	padding: 0.75em 1em;
	margin: 0.75em 9px;
	text-align: center;
}

.mq-sidebar-list-separator {
	font-size: 12px;
	font-style: italic;
	color: var(--editor-text-muted-color);
	display: block;
	text-align: center;
}

.mq-sidebar-list-no-content strong {
	color: var(--editor-text-color);
}

.mq-sidebar-list-no-content a {
	display: inline-block;
	padding: 5px;
}

.mq-sidebar-list-no-content--pinned {
	padding: 0.2em;
	color: var(--editor-text-muted-color);
	width: 100%;
}

/*
	PageList / Outline items
	------------------------

	In the comments below, we use `outline` to
	refer to both the Pages and the Outline panes.

	The top-level hierarchy is:

		.mq-sidebar-list-page
			.mq-sidebar-list-page__inner
				.mq-sidebar-list-page__title
 */

/*
	The wrapper element for each item in the outline.
	(Containing both the item and its children)
 */
.mq-sidebar-list-page {
	text-align: left;
	display: block;
	overflow: visible;
	position: relative;
	cursor: default;
}

/*
	Unintuitively, due to how the Outline panel
	is built with Mustache templates, the outermost wrapper
	(.mq-sidebar-list-page) will not update its classes
	based on changes to an item's state. Therefore, we delegate
	these classes to an inner wrapper.

	The following classes on .mq-sidebar-list-page are, however,
	updated from JavaScript (outline-delegate.js):

		* .selected
		* .expanded
		* .matches
 */
.mq-sidebar-list-page__inner {
}

/*
	Icons, in general, need to be filled with the same color
	as the text next to them.
 */
.mq-sidebar-list-page .mq-icon {
	fill: currentColor;
	width: 14px;
	height: 14px;
}

/*
	A "line" in outline,
	which does not contain the item's children.
	This is the default state.
 */
.mq-sidebar-list-page__title {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	user-select: none;
	color: var(--editor-text-color);
	position: relative;
	height: 2em;
}

/*
	A hidden item in the outline will be grayed out,
	and so will all the item's children.
 */
.mq-sidebar-list-page__inner--hidden .mq-sidebar-list-page__title {
	color: var(--editor-text-muted-color) !important;
	opacity: 0.75;
}

/* 
	Somehow I predicted text color var before color labeling 
	and it works with non-colored pages, but keeping an eye on this one.
*/
.mq-sidebar-list-page__inner--hidden .mq-sidebar-list-page__title.selected {
	color: var(--text-color) !important;
}

.mq-sidebar-list-page__inner--hidden .mq-sidebar-list-page__title .page .mq-icon {
	opacity: 0.75;
}

.mq-sidebar-list--targetselection
	.mq-sidebar-list-page__title:not(.mq-sidebar-list-page__title--folder):not(
		.mq-sidebar-list-page__title--master
	):not(.mq-sidebar-list-page__title--deleted):hover {
	cursor: crosshair;
	outline: 1px var(--interaction-accent) solid;
	outline-offset: -1px;
}

.mq-sidebar-list--targetselection .mq-page-context-button {
	visibility: hidden !important;
	pointer-events: none;
}

@keyframes blinkTarget {
	0% {
		background: var(--interaction-bg);
	}
	100% {
		background: transparent;
	}
}

.mq-sidebar-list--targetselection li {
	background: transparent;
	animation: blinkTarget 0.8s;
	animation-timing-function: ease-in-out;
}

/*
	Selected state of a normal item in the outline.

	1. The item's children should have a lighter background,
	if they themselves are not selected.

	2. The item itself (the row) should be colored
	in the blue accent color (--mq-blue).
 */

/*
	This applies to the item's children,
	only relevant to the Outline pane.
 */
.mq-sidebar-list-page__title {
}

/*
	...and this applies to the item itself.
 */

/*
	1. in the Outline panel, a bit of a drilldown
	to select the topmost __title element
*/
.mq-sidebar-list-page.selected >
	.mq-sidebar-list-page__inner >
	.mq-sidebar-list-page__title,

/* 2. in the Pages panel */
.mq-sidebar-list-page__title.selected {
	--bg-color: var(--editor-selection-bg);
	--text-color: var(--editor-selection-text-color);
	--icon-color: var(--editor-bg);
	background-color: var(--bg-color);
	color: var(--text-color);
}

/* in dark mode use darkColor variable */
html[data-theme='dark'] .mq-sidebar-list-page__title.selected {
	--text-color: var(--dark-color) !important;
	--icon-color: var(--editor-selection-text-color);
}

.mq-sidebar-assets-grid-item.selected {
}

.mq-sidebar-list-page.selected .mq-sidebar-list-page__title {
	background-color: var(--editor-semiselection-bg);
}

.mq-sidebar-page-label-menu .contextmenu__item__label {
	padding: 9px;
	min-width: 150px;
}

/* separate label colors from context menu item selection color */
.contextmenu__item:hover > .contextmenu__item__label .mq-page-label-color-row-item div {
	border: 1px solid rgba(255, 255, 255, 0.9);
}

.contextmenu__item:hover > .contextmenu__item__label .mq-page-label-menu-item div {
	outline: 1px solid rgba(255, 255, 255, 0.7);
	background: var(--input-bg);
}

.mq-sidebar-page-label-menu__dropdown {
	display: flex;
	margin-top: 6px;
}

.dropdown__menu__option .mq-page-label-color-row-item + span,
.mq-sidebar-page-label-menu__dropdown-title {
	max-width: 300px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.mq-sidebar-page-label-menu__dropdown .mq-page-label-color-row-item {
	margin-right: 4px;
}

.mq-sidebar-page-label-menu__dropdown .mq-page-label-color-row-item div {
	width: 20px;
	height: 20px;
}

.dropdown__menu__option .mq-page-label-color-row-item {
	margin-right: 8px;
}

.dropdown__menu__option .mq-page-label-color-row-item div {
	width: 20px;
	height: 20px;
}

.mq-page-label-color-row-item svg {
	width: 16px;
	height: 16px;
	fill: var(--mq-white) !important;
}

.mq-page-label-color-row-item .inverted svg {
	fill: var(--mq-black) !important;
}

/*
	Selected state of a symbol item in the outline.

	1. The item's children should have a lighter background,
	if they themselves are not selected.

	2. The item itself (the row) should be colored
	in the symbol accent color (--symbol-accent).

	It has precedence over the default (blue) style!
 */

/*
	Color the item's children...
 */

/* Either all children of a selected symbol */
.mq-sidebar-list-page.selected
	.mq-sidebar-list-page__inner--symbol
		.mq-sidebar-list-page__title,

/*
	...or the children of a selected item from inside a symbol
	(which might be of another color otherwise!)
*/
.mq-sidebar-list-page__inner--symbol
	.mq-sidebar-list-page.selected
		.mq-sidebar-list-page__title {
	background-color: var(--symbol-light);
	color: var(--symbol-text-color);
}

.mq-sidebar-list-page__inner--symbol .mq-sidebar-list-page__title {
	color: var(--symbol-text-color);
}

/*
	...while this applies to the item itself.
 */
.mq-sidebar-list-page.selected
	> .mq-sidebar-list-page__inner--symbol
	> .mq-sidebar-list-page__title,
.mq-sidebar-list-page__inner--symbol
	.mq-sidebar-list-page.selected
	> .mq-sidebar-list-page__inner
	> .mq-sidebar-list-page__title {
	background-color: var(--symbol-accent);
	color: var(--mq-white);
}

.mq-sidebar-list-page.selected .page .mq-icon,
.mq-sidebar-list-page__title.selected .page .mq-icon {
	fill: var(--icon-color);
}

.mq-sidebar-list-page.selected .has-master,
.mq-sidebar-list-page__title.selected .has-master {
	color: currentColor;
}

.mq-sidebar-list-page__title .expand,
.mq-sidebar-list-page.selected .folder .mq-icon {
	fill: var(--editor-text-color);
}

.mq-sidebar-list-page__title .expand,
.mq-sidebar-list-page__title .folder {
	margin-right: 4px;
	text-align: center;
}

.mq-sidebar-list-page__title .expand,
.mq-sidebar-list-page__title .folder .mq-icon {
	width: 16px;
	height: 16px;
	fill: var(--folder-color);
}

.mq-sidebar-list-page__title .expand {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0;
	width: 16px;
	height: 16px;
	flex: none;
	fill: var(--editor-text-color);
}

.mq-sidebar-list-page__title .page,
.mq-sidebar-list-page__title .folder {
	margin-right: 4px;
	max-height: 100%;
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mq-sidebar-list-page__title .page .mq-icon {
	width: 16px;
	height: 16px;
	vertical-align: middle;
	fill: var(--input-bg);
	stroke: var(--editor-icon-color-tone);
}

.mq-sidebar-list-page__title .expand .mq-icon {
	width: 0.6em;
	height: 0.6em;
}

.mq-sidebar-list-controls {
	margin: 0 0 0.5em 0;
}

.mq-sidebar-list-page.droptarget .mq-sidebar-list-page__title {
	background: none !important;
	color: var(--editor-active-color);
}
.targeting .mq-sidebar-list-page {
	cursor: crosshair !important;
}

.mq-page-context-button-group {
	position: absolute;
	right: 0.5em;
	top: 50%;
	transform: translate(0, -50%);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 2px;
}

.mq-page-context-button {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 0.1em;
	opacity: 0.5;
	/*color: #333;*/
	visibility: hidden;
	pointer-events: none;
}

.mq-page-context-button:hover {
	opacity: 0.8;
}

/*
	* Don't allow locking objects inside groups from the Outline panel.
	* Don't allow locking objects inside symbols.
*/
.mq-sidebar-sub-list .mq-lock-class,
.mq-sidebar-list-page__inner--symbol-contained .mq-lock-class {
	display: none;
}

/*
	When a list item is selected, icons should be white-based
	rather than black based. We have two different selectors
	due to a slight inconsistency between the Outline and the Pages tab.
 */
.mq-sidebar-list-page.selected
	> .mq-sidebar-list-page__inner
	> .mq-sidebar-list-page__title
	.mq-page-context-button,
.mq-sidebar-list-page__title.selected .mq-page-context-button {
	color: var(--selection-text-color);
}

.mq-page-context-button .mq-icon {
	fill: currentColor;
}

html:not(.unselectable) .mq-sidebar-list-page__title:hover .mq-page-context-button {
	visibility: visible;
	pointer-events: inherit;
}

.mq-page-context-button--active {
	visibility: visible;
	pointer-events: inherit;
	opacity: 1;
}

.mq-sidebar-list-page-label {
	display: flex;
	margin-right: 2.75em;
	align-items: center;
	gap: 2px;
	flex-grow: 1;
}

.mq-page-label-color-row {
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	max-width: 290px;
}

.page-label__select {
	margin-left: 0;
	max-width: none;
}

.label__selected {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
	cursor: pointer;
}

.label__selected svg:last-of-type {
	margin-left: auto;
}

.label__selected span {
	margin-left: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 200px;
}

.mq-page-label-color-row-item.mq-page-label-selected div {
	outline: 2px solid var(--editor-active-color);
	outline-offset: 2px;
}

.mq-page-label-color-row-item,
.mq-page-label-menu-item {
	display: flex;
	align-items: center;
	padding: 0;
	border: 0;
	border-radius: 3px;
	cursor: pointer;
}

.mq-page-label-color-row-item.mq-page-label-used div:after {
	content: '';
	background: var(--mq-white);
	width: 10px;
	height: 2px;
	/*transform: rotate(45deg);*/
	display: inline-block;
	border-radius: 8px;
	box-shadow: var(--mq-box-shadow-xs);
	color: var(--mq-white);
	text-align: center;
}

.mq-page-label-color-row-item div,
.mq-page-label-menu-item div {
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	border-radius: 3px;
	border: 1px solid transparent;
	display: flex;
	align-items: center;
	position: relative;
	justify-content: center;
}

.mq-page-label-color-row-item div:hover {
	border: 1px solid rgba(125, 125, 125, 0.25);
}

.mq-page-label-menu-item div {
	margin-right: 8px;
	width: 16px;
	height: 16px;
}

.mq-page-label-menu-item .mq-icon {
	width: 14px;
	height: 14px;
	fill: var(--mq-white) !important;
}

.mq-page-label-menu-item .inverted .mq-icon {
	fill: var(--mq-black) !important;
}

.mq-page-label-item-title {
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 200px;
}

.mq-sidebar-list-page__title-container {
	display: flex;
}

.mq-sidebar-list-page__title-container .mq-sidebar-list-page__title {
	flex-grow: 1;
	width: calc(100% - 3px); /* subtract the label border width */
}

.mq-page-label-border {
	display: flex;
}

.mq-page-label-border div {
	width: 3px;
	height: 24px;
	align-items: center;
	justify-content: center;
}

.mq-page-label-filter-dropdown {
	width: 16px;
	margin-right: -2px;
	margin-left: 1px;
	color: var(--editor-text-color);
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mq-page-label-filter-dropdown:hover {
	cursor: pointer;
	color: var(--editor-active-color);
}

.mq-page-label-filter-dropdown.mq-page-label-filter-dropdown-disabled a {
	color: var(--editor-text-muted-color);
	cursor: default;
	pointer-events: none;
}

.mq-page-label-filter-dropdown.mq-page-label-filter-dropdown-disabled {
	cursor: default;
}

.mq-page-label-filter-dropdown__with-nr {
	width: 32px;
	background: var(--editor-selection-bg);
	color: var(--editor-selection-text-color);
	font-size: 12px;
	border-radius: 5px;
	padding: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: -2px;
	margin-left: 1px;
	gap: 3px;
}

.mq-page-label-filter-dropdown__with-nr svg {
	font-size: 14px;
}

.mq-page-label-filter-dropdown__with-nr:hover {
	cursor: pointer;
}

/* mysterious empty div creating a gap */
.mq-page-label-filter-dropdown__with-nr > div {
	display: none;
}

/*
.mq-sidebar-list-page__title.parent:not(.mq-sidebar-list-page__title--folder) {
	font-weight: 600;
}

.mq-sidebar-list-page__title.parent:not(.mq-sidebar-list-page__inner--symbol):not(.selected) {
	color: var(--editor-header-color);
}
*/

.mq-sidebar-list-page-label.ellipsis span {
	text-overflow: ellipsis;
	overflow: hidden;
}

/* Assets don't need a lot of margin right (no controls like pin/hide) */
.mq-sidebar-list-page--asset .mq-sidebar-list-page__title .mq-sidebar-list-page-label {
	margin-right: 1em;
}

.mq-sidebar-list-page--page .mq-sidebar-list-page__title .mq-sidebar-list-page-label {
	margin-right: 0em;
}

.mq-sidebar-list-page--page
	.mq-sidebar-list-page__inner--hidden
	.mq-sidebar-list-page__title
	.mq-sidebar-list-page-label {
	margin-right: 1.25em;
}

/* Larger margin right on page item hover so buttons are visible (pin/visibility) */
.mq-sidebar-list-page--page .mq-sidebar-list-page__title:hover .mq-sidebar-list-page-label {
	margin-right: 2.75em;
}

.mq-sidebar-list-page-label .has-master {
	font-size: 0.7em;
	color: #006be5;
	float: left;
	width: 1.25em;
	height: 1.25em;
	line-height: 1.25em;
	margin-top: 0.3em;
	margin-right: 0.3em;
	text-align: center;
	border-radius: 2px;
	border: 1px solid currentColor;
	vertical-align: middle;
}

.mq-sidebar-list-page-label .has-master:after {
	content: 'M';
}

.mq-sidebar-list-page-label .divider {
	font-weight: 200;
	opacity: 0.5;
}

.mq-sidebar-list-item-label {
	display: inline-block;
	position: absolute;
	bottom: 0.5em;
	left: 0.5em;
	right: 0.5em;
	font-size: 11px;
	line-height: 14px;
	color: var(--editor-text-color);
	max-height: 2.5em;
	text-overflow: ellipsis;
	overflow: hidden;
}

.mq-sidebar-list-item-badge {
	position: absolute;
	top: 0.5em;
	right: 0.5em;
	pointer-events: none;
	background: var(--new-accent);
	font-weight: 600;
	color: var(--new-text-color);
	padding: 0.1em 0.3em;
	border-radius: 0.25em;
	font-size: 9px;
}

.no-content {
	padding: 1em;
	font-size: 90%;
	text-align: center;
}

.mq-sidebar-header-controls {
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 1;
}

.mq-sidebar-header-controls a {
	color: var(--editor-text-color);
	cursor: pointer;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
}

.mq-sidebar-header-controls a:hover {
	color: var(--editor-active-color);
	cursor: pointer;
	text-decoration: none;
}

.mq-sidebar-header-controls a span {
	vertical-align: middle;
	display: flex;
	align-items: center;
	font-size: 11px;
	line-height: 1.2;
	height: 16px;
	letter-spacing: 0;
}

.mq-sidebar-header-controls a span .mq-icon {
	margin-right: 3px;
}

.mq-sidebar-header-controls .mq-icon {
	vertical-align: middle;
	font-size: 16px;
	color: var(--editor-text-color);
	fill: var(--editor-text-color);
}

.mq-sidebar-header-controls a:hover .mq-icon {
	color: var(--editor-active-color);
	fill: var(--editor-active-color);
}

.mq-sidebar-header-controls .controls-right {
	flex-grow: 1;
	text-align: right;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 5px;
	margin-right: -3px;
}
.mq-sidebar-header-controls .controls-right a:last-child {
	margin-left: -3px;
}

.mq-sidebar-header-button {
}

.mq-sidebar-header-button:first-child {
}

.mq-sidebar-header-button:hover {
	cursor: pointer;
	text-decoration: none;
}

.mq-sidebar-header-button .mq-icon {
	width: 16px;
	height: 16px;
}

.mq-sidebar-header-button:hover .mq-icon {
}

.nowrap {
	white-space: nowrap;
}

.outline-item-highlight {
	position: absolute;
	z-index: 22;
	border: solid 2px #006ce5;
	pointer-events: none;
	box-sizing: border-box;
}

.mq-sidebar-resize {
	position: absolute;
	z-index: 2;
	top: 0;
	bottom: 0;
	width: 0.25em;
	cursor: ew-resize;
}

.mq-sidebar-resize:hover,
.mq-sidebar-resize.active {
	/*background-color: rgba(0,108,299,0.25);*/
}

.mq-sidebar-resize-right {
	right: 0;
}

.mq-sidebar-resize-left {
	left: 0;
}

.mq-sidebar-list.mq-sidebar-list--unfocused
	.mq-sidebar-list-page.selected
	.mq-sidebar-list-page__title,
.mq-sidebar-list.mq-sidebar-list--unfocused .mq-sidebar-list-page__title.selected {
	background-color: var(--editor-semiselection-bg);
	color: var(--editor-text-color) !important;
}

.drag-preview {
	position: fixed;
	z-index: 998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.icon-target-ghost {
	position: absolute;
	top: 0;
	left: 0;
	box-sizing: border-box;
	transform-origin: 0 0;
}

.icon-target-ghost:after {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background: #006ce5;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
	border: 1px solid #fff;
	display: block;
	box-sizing: border-box;
	content: ' ';
}

#sidebar-popup-wrapper {
	position: relative;
}
