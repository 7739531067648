.sidebar-footer-quota {
	bottom: 0px;
	border-top: 1px solid var(--editor-border-color);
	background: var(--editor-bg);
	color: var(--editor-text-color);
	font-size: 12px;
	padding: 8px 9px 11px;
	left: 0;
	right: 0;
	box-sizing: border-box;
}

.sidebar-footer-quota.no-upgrade-button {
	text-align: center;
}

.sidebar-footer-quota .mq-progress-bar {
	width: 45%;
	margin: 0.25em 1em 0.25em 0;
	display: inline-block;
	max-width: 110px;
}

.sidebar-footer-quota.no-upgrade-button .mq-progress-bar {
	margin: auto;
}

.sidebar-footer-quota .mq-btn {
	font-size: 1em;
	padding: 0.3em 0.75em;
	font-weight: bold;
	vertical-align: bottom;
	margin-top: 0.5em;
}
