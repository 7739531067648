/* 
	Attached style for square, circle, rhombus, hexagon and star comment styles
	===============================================================
 */
.comment-marker.comment-style-circle.attached::after,
.comment-marker.comment-style-square.attached::after,
.comment-marker.comment-style-hexagon.attached::after,
.comment-marker.comment-style-star.attached::after,
.comment-marker.comment-style-rhombus.attached::after {
	--rotation: 0deg;
	--scale: 1;
	content: '';
	display: block;
	position: absolute;
	bottom: 50%;
	left: calc(50% - 10px);
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 13px solid var(--mq-comment-background-color, var(--mq-blue));
	transform: scaleX(var(--scale)) rotate(var(--rotation)) translateY(-7px);
	transform-origin: bottom center;
	transition: transform 0.2s var(--mq-transition), border-color 0.2s ease-in-out;
}

/* Make pseudo element longer pointier for larger styles */
.comment-marker.comment-style-star.attached::after {
	border-bottom-width: 16px;
}

.comment-marker.comment-style-rhombus.attached::after {
	border-bottom-width: 14px;
}

.comment-marker.comment-style-circle.attached-top::after,
.comment-marker.comment-style-square.attached-top::after,
.comment-marker.comment-style-hexagon.attached-top::after,
.comment-marker.comment-style-star.attached-top::after,
.comment-marker.comment-style-rhombus.attached-top::after {
	--rotation: 180deg;
}

.comment-marker.comment-style-circle.attached-bottom::after,
.comment-marker.comment-style-square.attached-bottom::after,
.comment-marker.comment-style-hexagon.attached-bottom::after,
.comment-marker.comment-style-star.attached-bottom::after,
.comment-marker.comment-style-rhombus.attached-bottom::after {
	--rotation: 0deg;
}

.comment-marker.comment-style-circle.attached-left::after,
.comment-marker.comment-style-square.attached-left::after,
.comment-marker.comment-style-hexagon.attached-left::after,
.comment-marker.comment-style-star.attached-left::after,
.comment-marker.comment-style-rhombus.attached-left::after {
	--rotation: 90deg;
}

.comment-marker.comment-style-circle.attached-right::after,
.comment-marker.comment-style-square.attached-right::after,
.comment-marker.comment-style-hexagon.attached-right::after,
.comment-marker.comment-style-star.attached-right::after,
.comment-marker.comment-style-rhombus.attached-right::after {
	--rotation: 90deg;
	--scale: -1;
}
/*
	Hexagon attached styles
 */

.comment-marker.comment-style-hexagon.attached-left::after {
	left: calc(50% - 4px);
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
}

.comment-marker.comment-style-hexagon.attached-right::after {
	left: calc(50% - 12px);
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
}

/*
	Rhombus attached styles
	===============================================================
 */

.comment-marker.comment-style-rhombus.attached-top .comment-marker-index {
	clip-path: polygon(50% 0, 100% 50%, 65% 100%, 35% 100%, 0 50%);
}

.comment-marker.comment-style-rhombus.attached-bottom .comment-marker-index {
	clip-path: polygon(35% 0, 65% 0, 100% 50%, 50% 100%, 0 50%);
}

.comment-marker.comment-style-rhombus.attached-right .comment-marker-index {
	clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 65%, 0 35%);
}

.comment-marker.comment-style-rhombus.attached-left .comment-marker-index {
	clip-path: polygon(50% 0, 100% 35%, 100% 65%, 50% 100%, 0 50%);
}

/*
	Style for pseudoelement for resolved attached comments
	===============================================================
 */
.comment-marker.attached.resolved::after {
	border-bottom-color: var(--mq-editor-grey-300);
}
