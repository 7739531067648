/* =============================================================================
   Grids
   ========================================================================== */

.mq-grid,
[class*='mq-col-'] {
	box-sizing: border-box;
}
.mq-grid:after {
	content: '';
	display: table;
	clear: both;
}
.mq-grid > * {
	float: left;
}
.mq-grid-padded > * {
	padding: 1em;
}
.mq-grid-gutted > * {
	padding: 0 1.5em;
}
.mq-slim-gutter > * {
	padding: 0 0.75em;
}
.mq-fat-gutter > * {
	padding: 0 2em;
}

.mq-grid-gutted > *:first-child {
	padding-left: 0;
}
.mq-grid-gutted > *:last-child {
	padding-right: 0;
}

.mq-col-1-1 {
	width: 100%;
}
.mq-col-1-2 {
	width: 50%;
}

.mq-col-1-3 {
	width: 33.33%;
}
.mq-col-2-3 {
	width: 66.66%;
}

.mq-col-1-4 {
	width: 25%;
}
.mq-col-2-4 {
	width: 50%;
}
.mq-col-3-4 {
	width: 75%;
}

.mq-col-1-5 {
	width: 20%;
}
.mq-col-2-5 {
	width: 40%;
}
.mq-col-3-5 {
	width: 60%;
}
.mq-col-4-5 {
	width: 80%;
}

.mq-col-1-6 {
	width: 16.66%;
}
.mq-col-2-6 {
	width: 33.33%;
}
.mq-col-3-6 {
	width: 50%;
}
.mq-col-4-6 {
	width: 66.66%;
}
.mq-col-5-6 {
	width: 83.33%;
}

.mq-col-border-left {
	border-left: 1px solid #e0e0e0;
}

.mq-col-border-right {
	border-right: 1px solid #e0e0e0;
}

.grid__table .fo__empty {
	display: none;
}
