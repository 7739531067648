#account-actions {
	z-index: 10; /* so account-actions overlap toolbar buttons */
	/*background-color: var(--editor-bg);*/
	position: absolute;
	right: 0;
}

#account-actions .mq-btn {
	margin-left: 0.75em;
}

#account-actions .upgrade-btn {
	font-weight: 500;
	padding: 0.2em 0.4em 0.4em 0.4em;
	font-variant: small-caps;
	letter-spacing: 0.04em;
}

#account-actions .link-button {
	vertical-align: middle;
}

#account-actions .mobile-account-actions {
	display: none;
}

#account-actions .mobile-upgrade-btn {
	display: none;
}
