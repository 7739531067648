.resize-handle {
	stroke: var(--normal-stroke);
	fill: var(--mq-white);
	stroke-width: 1px;
}

.resize-handle--symbol {
	stroke: var(--symbol-accent);
}

.resize-handle--imagecrop {
	stroke: var(--mq-white);
	fill: var(--mq-black);
	stroke-width: 1px;
}
