.lock-border {
	position: relative;
	pointer-events: none;
}

.lock-border__button {
	color: var(--lock-icon-color);
	background-color: var(--lock-stroke);
	border: 1px solid transparent;

	width: 16px;
	height: 16px;
	border-radius: 50%;
	box-sizing: border-box;

	pointer-events: all;

	position: absolute;
	transform: translate(-50%, -50%);
}

.lock-border__button:hover {
	background-color: var(--lock-icon-color);
	color: var(--lock-stroke);
	border-color: currentColor;
}

/*
	Position the icon at the center of the button.
 */
.lock-border__button .mq-icon {
	fill: currentColor;
	height: 10px;
	width: 10px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
