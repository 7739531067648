.add__contributors {
	position: relative;
	display: flex;
	align-items: flex-start;
	padding: 0.32em 0.64em;
	border: 1px solid var(--input-border-color);
	background: var(--input-bg);
	border-radius: 4px;
	margin-right: 5px;
	margin-top: 0.5em;
	margin-bottom: 1em;
}

.add__contributors,
.share__button {
	box-sizing: border-box;
	height: 40px;
	margin-top: 0.5em;
}

.add__contributors--has-proposals {
	height: auto;
}

.add__contributors input {
	flex-grow: 1;
	border: none;
	padding: 0.5em 0;
	font-size: 13px;
	background: var(--input-bg);
	color: var(--input-text-color);
}

.add__contributors .permission__dropdown {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
}

/* Don't allow label to collapse text in more than 1 line */
.permission__dropdown > div {
	white-space: pre;
	font-size: 13px;
}

.add__contributors .dropdown__menu__dropdown {
	position: absolute;
	right: 0.32em;
	top: 0.32em;
	padding: 0 0 0 0.5em;
	color: var(--input-text-color);
	border: 0;
	background: var(--input-bg);
	z-index: 2;
}

.add__contributors .dropdown__menu__dropdown:hover {
	background: var(--button-hover-color);
}

.add__contributors__suggestions {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	max-height: 300px;
	overflow-y: auto;
	z-index: 1;
	background-color: var(--input-bg);
	border: 1px solid var(--input-bg);
	border-bottom-right-radius: calc(var(--inspector-border-radius) * 2);
	border-bottom-left-radius: calc(var(--inspector-border-radius) * 2);
}

.contributor__category {
	padding: 10px 15px;
	font-size: 0.8em;
	text-transform: uppercase;
	color: var(--editor-text-muted-color);
}

.contributor__suggestion {
	cursor: pointer;
	padding: 10px 15px;
	color: var(--input-text-color);
}

.contributor__suggestion span {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.contributor__suggestion--inactive {
	cursor: default;
}

.contributor__suggestion--multiple svg {
	margin-right: 0.5em;
}

.contributor__suggestion--selected {
	background-color: rgba(0, 0, 0, 0.05);
}

.contributor__suggestion:not(.contributor__suggestion--inactive):hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.contributor__suggestion .add__icon {
	fill: #1464f6;
}

.contributor__suggestion__role {
	color: var(--editor-text-muted-color);
}

.add__contributors__list {
	display: flex;
	flex-wrap: wrap;
}

.add__contributors__list > div {
	display: flex;
	align-items: center;
	color: var(--editor-text-color);
	background-color: var(--editor-menu-hover-color);
	border: 1px solid var(--input-border-color);
	border-radius: 4px;
	padding: 2px;
	margin-right: 0.4em;
	margin-bottom: 0.4em;
	font-size: 0.9em;
}

.add__contributors__list > div:first-of-type {
	margin-right: 100%;
}

.add__contributors__list input {
	width: auto;
	min-width: 40%; /* Smaller looks weird while inline with a contributor */
}

.add__contributors__list svg {
	fill: var(--editor-text-muted-color);
	padding: 0 0.5em;
}

.add__contributors__list svg:first-of-type {
	height: 0.8em;
	width: 0.8em;
}

.add__contributors__list svg:last-of-type {
	height: 0.7em;
	width: 0.7em;
	cursor: pointer;
}

@media (--phone) {
	.add__contributors {
		margin-bottom: 0;
		margin-right: 0;
	}

	.share__button {
		margin-bottom: 1em;
	}

	.add__contributors--wrapper {
		flex-direction: column;
		align-items: stretch !important;
	}
}
