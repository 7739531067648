.custom-handle-circle {
	stroke: var(--normal-stroke);
	fill: var(--mq-white);
	stroke-width: 1;
}
.custom-handle-inner-circle {
	fill: var(--normal-stroke);
	pointer-events: none;
}
.custom-handle-path {
	stroke: var(--normal-stroke);
	stroke-dasharray: 1, 2;
	fill: none;
	pointer-events: none;
}
