/* =============================================================================
   Modals
   ========================================================================== */

.mq-overlay {
	position: fixed;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;

	padding: 30px 30px 0;
	overflow: auto;
	/* should go over chart-editor (5000)*/
	z-index: 5001;
	background: rgba(33, 33, 35, 0.8);
}
.mq-overlay-transparent {
	background: transparent;
}
.mq-authorization-modal {
	padding: 0 1em 0.5em;
}

.mq-modal {
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	padding: 8vh 2rem 2vh;
	font-size: 0.875rem;
	overflow: auto;
	max-width: 9999px;
	background: none;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.mq-modal-active {
	display: block;
}

.mq-modal-heading {
	/*border-bottom: 1px solid var(--editor-menu-border-color);*/
	/*font-size: 1.1em;*/
	/*padding: 1em;*/
	padding: 30px 60px 0 30px;
	position: relative;
	color: var(--editor-header-color);
	text-align: left;
	font-weight: 700;
	font-size: 22px;
	font-family: var(--mq-font-display);
}

.mq-modal-img {
	max-height: 12em;
}

.mq-modal-check {
	margin-right: 0.5em;
}

.mq-modal-x {
	position: absolute;
	top: 24px;
	right: 28px;
}

.mq-modal-x:hover {
	cursor: pointer;
	color: var(--editor-active-color);
}

.mq-modal-close {
	position: absolute;
	top: 12px;
	right: 12px;
	color: var(--editor-text-muted-color);
	border-radius: var(--modal-border-radius);
	background: var(--modal-bg);
	width: 36px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
}

.mq-modal-close:hover {
	cursor: pointer;
	color: var(--editor-active-color);
}

.mq-modal-close .mq-icon {
	fill: currentColor;
}

.mq-modal-close:hover .mq-icon {
	fill: var(--editor-active-color);
	color: var(--editor-active-color);
}

.mq-modal-wrapper {
	position: relative;
	flex: none;
	/*max-width: 1200px;*/
	/*padding: 1em;*/
	background: var(--modal-bg);
	box-shadow: var(--mq-box-shadow-xl);
	border-radius: var(--modal-border-radius);
}

.mq-modal-content {
	padding: 20px 30px 30px;
	color: var(--editor-text-color);
}

.mq-modal-footer {
	padding-top: 20px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 10px;
}

.mq__modal__section.mq-modal-footer {
	padding-top: 30px;
}

/* TODO: probably better name here */
.mq-modal-content-compact {
	padding: 0;
}

.mq-small {
	font-size: 0.8em;
	/*font-weight: bold;*/
	margin: 1em 0;
}

/* todo shitty class */
.start-designing-btn {
	margin: 0 auto 0.75em !important;
	max-width: 14em;
	display: block !important;
}

.mq-modal-separator {
	border-bottom: 1px solid var(--editor-menu-border-color);
	width: 100%;
}

.mq-modal-section {
	margin: 20px;
}

.mq-modal-actions {
	padding: 0 20px 20px 20px;
	display: flex;
	flex-direction: row;
}
.mq-modal-actions button:not(:first-of-type) {
	margin-left: 0.5em;
}
.mq-modal-actions--align-right {
	justify-content: flex-end;
}

.mq-modal-actions--spread {
	justify-content: space-between;
}

.mq-modal-actions--no-vpadding {
	padding-bottom: 0;
	padding-top: 0;
}

.mq-modal-dashboard-link {
	padding-top: 10px;
	color: var(--editor-text-muted-color);
}

.mq-modal-dashboard-link a {
	opacity: 0.9;
}

.mq-modal-privacy-details span {
	display: block;
	font-size: 0.9em;
	color: var(--editor-text-muted-color);
}

.mq-modal-checkbox-label {
	margin-left: 0.5em;
}

.mq-modal-grey-text {
	color: var(--editor-text-muted-color);
	margin-top: 1.8em;
}

.mq-modal-title {
	font-family: var(--mq-font-display);
	font-size: 30px;
	line-height: 1.22;
	margin-right: 1em;
	font-weight: 700;
}

.mq-modal-nudger {
	padding: 0 0.5em 0 0;
}

.mq-modal-nudger--guests .mq-modal-title {
	margin-right: 1.5em;
}
.mq-modal-nudger--guests .mq-modal-img {
	margin-right: 2em;
}

/*
	Nudger: Soft Object Limit
	-------------------------
 */
.mq-modal-nudger--soft-limit {
}

.mq-modal-nudger--soft-limit .mq-modal-nudger__hero {
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-start;
}

.mq-modal-nudger--soft-limit .mq-modal-img {
	align-self: center;
	margin: 0 0 1em;
}

.mq-modal-mb-0 {
	margin-bottom: 0;
}

.mq-modal-upgrade-benefits {
	margin-top: 1em;
}

.mq-modal-margin-right {
	margin-right: 3em;
}

.mq-modal-margin-bottom {
	margin-bottom: 0.5em;
}

/* =============================================================================
   New type of modal (e.g. sharing and new project )
   ========================================================================== */

.mq__modal .mq-modal-content {
	padding: 30px 0;
}

.mq__modal__close {
	color: var(--editor-text-color);
	width: 16px;
	height: 16px;
	cursor: pointer;
}

.mq__modal__close:hover {
	color: var(--editor-active-color);
}

.mq__modal__title {
	margin: 0;
	padding: 0;
	font-family: var(--mq-font-display);
	font-weight: 700;
	font-size: 22px;
	color: var(--editor-header-color);
}

.mq__modal__subtitle {
	font-size: 0.85em;
	color: var(--editor-text-muted-color);
	padding-bottom: 1.25em;
	display: block;
}

.mq__modal__heading {
	color: var(--editor-text-color);
	font-weight: bold;
	font-size: 14px;
	margin: 0;
}

.mq__modal__footer {
	color: var(--editor-text-muted-color);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 6px 0px 4px 0px;
}

.mq__modal__footer__link {
	margin-top: 20px;
	text-align: center;
}

.mq__modal__footer__link span + a {
	margin-left: 5px;
	display: inline-block;
}

.mq__modal__footer a:focus {
	text-decoration: underline;
}

.mq__modal__subheading {
	color: var(--editor-text-muted-color) !important;
	font-size: 10px;
	text-transform: uppercase;
}

.mq__modal__subtext {
	padding: 0 0 0.5em 0;
	color: var(--editor-text-muted-color) !important;
	font-weight: bold;
	font-size: 11px;
}

/* New modals don't use headings for now */
.mq__modal .mq-modal-heading {
	display: none;
}

.mq__modal__separator {
	border-bottom: 1px solid var(--editor-menu-border-color);
	width: 100%;
	padding-top: 20px;
}

.mq__modal__section {
	padding: 10px 30px 0 30px;
}

.mq__modal__saml__error__section {
	padding: 0px 30px 10px 30px;
}

.mq__modal__saml__error__section .mq__modal__subtitle {
	font-size: 14px;
	color: #000;
	margin: 1em 0;
	line-height: 1.5;
}

.mq__modal__auth__section {
	padding: 30px 0px 5px 0px;
}

.mq__modal__no__account__section {
	padding: 5px 0px 30px 0px;
}

.mq__modal__section__first {
	padding-top: 0;
}

.mq__modal__section h2 {
	margin: 0;
}
.new__account__modal {
	position: relative;
}

.new__account__modal .mq__modal__section {
	color: #3b4753;
	padding: 36px 30px 0px 259px;
	width: 100%;
}

.new__account__modal .mq__modal__subtitle {
	font-size: 0.875rem;
	padding-bottom: 0;
}

/*.mq__modal .mq-modal-content .new__account__modal {*/
/*	max-width: 1090px;*/
/*	width: 1120px;*/
/*	min-width: 800px;*/
/*}*/
.new__account__sidebar {
	border-radius: 24px 0 0 24px;
	position: absolute;
	top: -30px;
	bottom: -30px;
	background: #1071f2;
	width: 230px;
}

.new__account__sidebar__logo {
	position: absolute;
	fill: #fff;
	z-index: 10;
	top: 42px;
	width: 100%;
	height: 24px;
}

.new__account__sidebar__bg {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	object-fit: contain;
	display: inline-block;
	width: 100%;
}
.new__account__sidebar span {
	font-size: 1.1em;
	font-weight: 400;
	margin: 0 0 1em 0;
	color: var(--editor-text-color);
}

.new__account__content .mq__modal__title {
	margin: -30px 0 10px 0;
	color: var(--editor-text-color);
}

.login__content .mq__modal__title {
	margin-top: 5px;
	color: var(--editor-text-color);
}

.mq__modal__actions {
}

.mq__modal__actions button {
	font-size: 15px;
}

/* =============================================================================
   Specific modals style
   ========================================================================== */

.mq__modal--creator .mq__input--lg input {
	padding: 1em 0.7em;
	font-size: 15px;
	border-radius: calc(var(--inspector-border-radius) * 1.5);
}

.mq__modal--creator .mq__modal__section__first {
	padding-bottom: 1em;
}

.mq__input.mq__input--lg {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.mq__input--lg label {
	display: none;
}

.mq__modal .mq-message {
	margin-top: 1em;
	font-size: 13px;
}

.invite__modal .mq__input label {
	margin-bottom: 0;
}

.mq__modal__nudger {
	font-size: 18px;
}

.mq__modal__nudger .mq-modal-x {
	margin: 0em;
}

.mq__modal__nudger .mq__modal__img {
	max-height: 10em;
}

.mq__modal__nudger .mq__modal__title {
	font-size: 1.5em;
	color: #3b4754;
}

/* Invite members to your team Modal */

.mq__modal__rounded {
	border-radius: 17px;
}

.mq__modal__inverted-bg {
	background-color: #107fee;
}

.mq__modal__no-padding {
	margin-top: -30px;
	margin-bottom: -30px;
}

.mq__modal__item-rounded-right {
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
}

.mq__modal__text-white {
	color: white;
}

.mq__modal__padding-vertical {
	padding: 1.5em 0;
}

.mq__modal__min-width {
	min-width: 375px;
}

.mq__modal__img-invite {
	max-width: 21.9em;
}

@media (--phone) {
	.mq-modal {
		padding: 1rem;
	}
}

/* Extension Colorpicker override */

html #mq-colorpick-container {
	z-index: 55;
}

html #mq-magnifying-glass {
	width: 120px;
	height: 120px;
	border: 1px solid var(--editor-border-color);
	border-radius: var(--inspector-border-radius);
}
html #mq-color-picker-rectangle {
	border-radius: var(--inspector-border-radius);
	border: 1px solid var(--editor-border-color);
	margin-bottom: 5px;
}

html #mq-colorpicker-modal {
	background: var(--editor-bg);
	border: 1px solid var(--editor-border-color);
	box-shadow: var(--mq-box-shadow-md);
	color: var(--editor-text-color);
	border-radius: var(--inspector-border-radius);
	width: 16em;
}

html #mq-colorpicker-modal-header {
	background: none;
	padding: 5px 10px 0;
	border-bottom: 0;
	font-size: 12px;
}

html #mq-colorpicker-modal-header-close {
	right: 7px;
	font-size: 20px;
	color: var(--link-color);
}

html #mq-colorpicker-modal-header-close:hover {
	cursor: pointer;
}

html #mq-colorpicker-modal-body {
	font-size: 12px;
}
