.mq-logo-btn {
	background: var(--mq-blue);
	border: 0;
	border-right: 1px solid var(--mq-blue);
	color: var(--mq-white);
	padding: 1px;
	display: inline-block;
	z-index: 1;
	position: relative;
}

.mq-logo-btn:hover {
	cursor: pointer;
}

#main-menu:hover .mq-logo-btn .mq-icon,
#main-menu .mq-logo-btn.active .mq-icon {
	transform: scale(1.2, 1.2);
}

.mq-logo-btn .mq-icon {
	width: 52px;
	height: 49px;
	transition: transform 0.2s;
	pointer-events: none;
}

.mq-logo-btn use {
	fill: currentColor;
}

.mq-megamenu {
	font-size: 0.875rem;
	padding: 1em;
	max-width: calc(100vw - 2em);
	overflow: auto;
	display: flex;
	align-items: stretch;
	background: var(--editor-bg);
	border: 1px solid var(--editor-border-color);
	border-left: 0;
	box-shadow: var(--mq-box-shadow-md);
	line-height: 2;
	position: absolute;
	white-space: nowrap;
	top: 100%;
	border-bottom-right-radius: calc(var(--inspector-border-radius) * 2);
}

.mq-megamenu-section {
	min-width: 15em;
	min-height: 16em;
	padding: 0 1em 0 1em;
	border-right: 1px solid var(--editor-border-color);
	display: inline-block;
	vertical-align: top;
}

.mq-megamenu-section:first-of-type {
	padding-left: 0;
}

.mq-megamenu-section:last-of-type {
	border-right: none;
	padding-right: 0;
}

.mq-megamenu-section h3 {
	text-transform: uppercase;
	margin: 0;
	font-weight: bold;
	font-size: 0.7em;
	color: var(--editor-text-muted-color);
	margin-bottom: 0.5em;
}

/*Usability: Makes the whole menu item clickable/tapable instead of just the link*/
.mq-megamenu-section li a {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: var(--editor-text-color);
}

.mq-megamenu-section li a.disabled {
	color: var(--editor-text-color);
	opacity: 0.5;
	cursor: default;
	pointer-events: none;
}

.mq-megamenu-section li a:hover.disabled {
	text-decoration: none;
}

.mq-megamenu-section li em {
	height: 100%;
	font-style: normal;
	font-size: 0.8em;
	color: var(--editor-text-muted-color);
}

.mq-megamenu-section li a:hover,
.mq-megamenu-section li a:focus {
	text-decoration: none;
	color: var(--link-color);
}

.moqups-about {
	padding-top: 1em;
	margin-top: 1.5em;
	border-top: 1px solid var(--editor-menu-border-color);
	line-height: 1.25;
	font-size: 0.9em;
}

.moqups-about h4 {
	font-size: 1.2em;
	letter-spacing: 0.2px;
	font-weight: bold;
	margin: 0.4em 0 0.2em;
}

.moqups-about small {
	font-size: 0.9em;
	color: var(--editor-text-color);
}

.moqups-about .qr-code {
	padding: 4px;
	background: var(--mq-white);
	border-radius: 4px;
	float: left;
	margin-right: 0.75em;
	box-shadow: var(--mq-box-shadow-xs);
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.moqups-about .mq-icon {
	width: 62px;
	height: 62px;
}

.moqups-about .weight {
	font-size: 1.2em;
	/*font-weight: bold;*/
	/*clear: left;*/
}

.moqups-about .guidance {
	padding-top: 1em;
	margin-bottom: 0em;
	font-size: 0.9em;
	font-weight: 600;
	color: var(--editor-text-muted-color);
	clear: left;
}

.moqups-about .weight span {
	font-weight: normal;
	font-size: 0.8em;
}
