.inline__nudger {
	position: relative;
	padding: 25px 30px;
	color: var(--premium-text-color);
	background-color: var(--premium-accent);
	border-radius: calc(var(--inspector-border-radius) * 2.5);
	margin-bottom: 0.25em;
}

.inline__nudger__close {
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;
}

/* =============================================================================
   Specific nudger style
   ========================================================================== */

.sharing__legacy__nudger {
	font-size: 13px;
}

.sharing__legacy__nudger p {
	font-size: 20px;
	margin-top: 0;
}

.sharing__legacy__nudger div {
	margin-top: 1.5em;
}

.sharing__legacy__nudger svg {
	fill: var(--mq-white);
	margin-right: 0.5em;
}

.newproject__nudger .mq__modal__heading {
	padding-bottom: 1em;
}
