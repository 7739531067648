.promo__modal {
	font-size: 16px;
}

.promo__modal h3 {
	margin-top: 0;
	font-weight: bold;
}

.promo__modal .mq-modal-content {
	position: relative;
}

.promo__modal .mq__modal__close {
	position: absolute;
	right: 30px;
	top: 30px;
	z-index: 2;
}

.promo__nav {
	margin-top: 1em;
}
.promo__title {
	font-size: 1.8em;
	margin: 0;
	padding: 0 1.5em 0 0;
	color: #232323;
	font-weight: bold;
}

.promo__img {
	position: relative;
	margin-top: 0;
}

.promo__img img {
	max-width: 100%;
	border-radius: 17px;
}

.promo__content {
	font-size: 17px;
	color: #444444;
	padding-bottom: 10px;
}

.promo__content h2 {
	font-size: 48px;
	font-weight: bold;
	text-align: center;
}

.promo__action {
	margin-right: 1.1em;
}

.promo__nav {
	display: flex;
	align-items: center;
}

.promo__nav__item {
	cursor: pointer;
	margin-right: 0.3em;
	width: 0.5em;
	height: 0.5em;
	border-radius: 50%;
	background-color: #d6d6d6;
}

.promo__stats {
	width: 100%;
	padding: 5px 0 20px 0;
	column-gap: 9px;
	font-size: 14px;
	color: #929292;
}

.promo__stats span {
	background: #ebebeb;
	border-radius: 8px;
	padding: 6px 10px;
}

.promo__nav__item:last-of-type {
	margin-right: 0;
}

.promo__nav__item--selected {
	background-color: #1464f6;
}

.promo__benefits {
	padding: 0 1.5em;
	color: #797979;
}

.promo__modal button {
	font-weight: bold;
	font-size: 18px;
	padding: 12px 55px;
	background-color: #f92e0b;
	text-transform: uppercase;
	margin: 0 auto 20px;
}

.promo__modal button:hover {
	background-color: #db290a;
}
