.inspect__wrapper {
	pointer-events: none;
}

.inspect__referencepath,
.inspect__currentpath {
	stroke: cyan;
	fill: none;
}

.inspect__text {
	fill: fuchsia;
	font-size: 10px;
	text-anchor: middle;
	/*alignment-baseline: central;*/
	dominant-baseline: central;
}

.inspect__solidline {
	stroke: fuchsia;
	fill: none;
}

.inspect__dottedline {
	stroke: fuchsia;
	fill: none;
	stroke-dasharray: 1 1;
}
