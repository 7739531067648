.page__thumbnail {
	width: 160px;
	pointer-events: none !important;
	padding: 0;
	margin: 0;
}

.page__thumbnail__title {
	font-size: 13px;
	color: var(--editor-text-color);
	padding-bottom: calc(1em - 1px);
}

.page__thumbnail__image {
	width: 160px;
	border-radius: 2px;
	overflow: hidden;
}

.page__thumbnail__image > iframe {
	border: 1px solid var(--editor-menu-border-color);
}

.page__thumbnail__arrow {
	right: 94%;
	top: 50%;
	position: absolute;
	pointer-events: none;
}

.page__thumbnail__arrow::before,
.page__thumbnail__arrow::after {
	content: ' ';
	position: absolute;
	top: 0;
	right: 0;
	height: 0;
	width: 0;
	border: solid transparent;
}

.page__thumbnail__arrow::before {
	border-color: rgba(230, 230, 230, 0);
	border-right-color: var(--editor-menu-border-color);
	border-width: 9px;
	margin-top: -9px;
}
.page__thumbnail__arrow::after {
	border-color: rgba(255, 255, 255, 0);
	border-right-color: var(--input-bg);
	border-width: 8px;
	margin-top: -8px;
}

.page__thumbnail .breadcrumbs {
	padding: 0;
	margin: 1em 0 0 0;
	display: inline-block;
}

.page__thumbnail .breadcrumbs-page-label {
	display: inline-block;
	float: none;
}
