/*
	RESPONSIVE DESIGN
	-----------------------------------------------------------
*/

@media only screen and (min-width: 1366px) {
	.breadcrumbs .current-page-title {
		max-width: 260px;
	}

	.mq-zone-center {
	}

	#sidebar-nav {
		width: calc(250px + 54px); /*sidebar + m-menu*/
	}

	#breadcrumbs {
		width: 250px;
	}

	.mq-sidebar-nav {
		gap: 0.1em;
	}

	.mq-toolbar-btn.compact {
		min-width: 3.5em;
	}

	.mq-sidebar-nav .mq-toolbar-btn.compact {
		min-width: 4.3em;
	}
}

@media (--tablet-large) {
	.mq-toolbar-btn,
	.mq-toolbar-btn.compact {
	}

	.mq-toolbar-separator {
		width: 1em;
	}

	.mq-toolbar-separator:before {
		display: none;
	}

	#toolbar {
	}

	#account-actions {
		position: static;
		margin-left: 1em;
	}
}

@media (--tablet) {
	.mq-toolbar-separator {
		width: 0.5em;
	}

	.mq-toolbar-btn,
	.mq-toolbar-btn.compact {
		min-width: 3em;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		border-radius: 0.5em;
	}

	/* Hide the labels of the toolbar buttons */
	.mq-toolbar-btn span,
	.breadcrumbs .breadcrumbs-page-label,
	.zoom__level {
		display: none;
	}

	.breadcrumbs .current-page-title {
		max-width: 160px;
	}

	.zoom__control--reset .mq-icon {
		padding: 0;
	}

	.zoom__control {
		height: 36px;
	}

	/* Smaller text for top announcement */
	.toolbar-notification {
		font-size: 0.8em;
	}

	.mq-zone-right.view-mode .mq__button span {
		max-width: 4.5em;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.mq-zone-right.view-mode .mq__button--primary span {
		display: none;
	}

	.mq-page-counter {
		display: none;
	}

	.mq-zone {
		height: 50px;
	}

	.mq-zone-left {
	}

	.mq-zone-center {
	}

	.mq-zone-right {
	}

	#sidebar-nav {
		width: auto;
	}

	#breadcrumbs {
		width: 150px;
	}

	#account-actions {
		display: none;
	}

	/* easier to tap context menu options */
	.context-menu-item-title,
	.contextmenu__item__label,
	.drawing-tools .context-menu-item-title,
	.annotation-tools .context-menu-item-title {
		padding-top: 0.4em;
		padding-bottom: 0.4em;
		min-height: 28px;
	}

	/*easier to pick simple color swatches */
	.simple-color-picker {
		--swatch-size: 24px;
		--swatch-spacing: 3px;
		--swatch-radius: 11px;
	}

	/* Chart data editor */
	.datasheet-shortcut {
		display: none;
	}
	.datasheet-wrapper {
		max-height: 30vh;
	}
	.datasheet {
		margin: 0 auto;
	}

	.paste-options__actions button {
		flex-basis: 49%;
	}
}

@media (--phone) {
	.mq-logo-btn {
		padding: 0;
	}

	.mq-logo-btn .mq-icon {
		height: 40px;
		width: 40px;
	}

	.toolbar-notification {
		left: 40px;
		min-height: 0;
		height: 40px;
		padding: 0 40px 0 10px;
	}

	.toolbar-close {
		height: 40px;
		width: 40px;
	}

	.top-announcement #toolbar-wrapper {
		height: 90px;
	}

	#toolbar-wrapper {
		height: 40px;
	}

	.mq-zone {
		height: 40px;
	}

	.mq-toolbar-btn .mq-icon,
	.zoom__control--reset .mq-icon {
		height: 20px;
		width: 20px;
	}

	.mq-toolbar-btn .must-icon {
		font-size: 20px;
	}

	.mq-toolbar-btn,
	.mq-toolbar-btn.compact,
	.mq-page-nav-button {
		min-width: 2.75em;
		padding-left: 8px;
		padding-right: 8px;
	}

	.mq-toolbar-separator {
		display: none;
	}

	.mq-zone-left {
	}

	.mq-zone-center {
		justify-content: flex-start;
		padding-left: 0.2em;
	}

	.mq-zone-center.view-mode {
	}

	#account-menu .current-context-label {
		display: none;
	}

	#breadcrumbs {
		display: none;
	}

	/* 
		Make the sidebar menu narrower and hide the labels.
	*/

	.vertical-icon-menu {
		width: 40px;
	}

	.vertical-icon-menu > li > button {
		width: 40px;
	}

	#sidebar .vertical-icon-menu .mq-sidebar-add-template a {
		width: 40px;
	}

	#sidebar .vertical-icon-menu .mq-sidebar-add-template svg,
	#account-menu .avatar {
		width: 24px;
		height: 24px;
	}

	.vertical-icon-menu > li .vertical-icon-menu__label {
		display: none;
	}

	.moqups-help-wrapper {
		display: none;
	}

	#sidebar-content {
		margin-left: 40px;
	}

	#stage-wrapper {
		top: 40px;
	}

	.zoom {
		margin-top: 0;
	}

	.zoom-level {
		display: none;
	}

	.mq-megamenu-section {
		min-width: 13em;
	}

	#account-menu > .upgrade-btn {
		display: none;
	}

	#account-menu > .link-button {
		display: none;
	}

	html,
	html body {
		height: 100.1%;
	}

	.view-mode .mq-toolbar-btn {
	}

	.mq-zone-right .mq__button--edit {
		width: 28px;
		min-width: 28px;
		padding: 5px;
		white-space: nowrap;
		overflow: hidden;
	}

	#account-menu.mobile-account-menu {
		display: block;
	}

	.toolbar__fl__message {
		width: 90%;
		flex-direction: column;
		align-items: start;
		max-width: 680px;
	}

	.toolbar__fl__actions {
		margin-top: 1em;
	}
	.toolbar__fl__message {
		width: 90%;
		max-width: 680px;
	}
	.mq-page-counter {
		display: none;
	}
	.new__account__modal .mq__modal__section {
		padding: 20px 20px 0 20px;
	}
	.new__account__sidebar {
		display: none;
	}

	.toolbar__fl__message {
		width: 75%;
		max-width: 680px;
		min-width: 75%;
	}
	.cp {
		max-width: calc(100vw - 250px);
		overflow-x: auto;
	}
	.cp-body {
		min-width: 17em;
	}

	.global-search__wrapper {
		top: 40px;
	}

	.global-search {
		width: auto;
	}

	.global-search__settings > span {
		display: none;
	}
	.global-search__main,
	.global-search__settings {
		flex-direction: column;
		align-items: flex-start;
		gap: 0.5em;
		height: auto;
	}

	.comment-thread-actions {
		left: 0;
		display: flex;
		justify-content: space-evenly;
	}

	.comment-popover-header > span {
		display: none;
	}

	.comment-popover {
		--max-comment-height: 300px;
	}

	.comment-input {
		padding: 0.75em 1.25em;
	}
}

/* Make topbar at least accessible on vertical phones */
@media (--phone-small) {
	.toolbar-notification {
		height: 80px;
		flex-direction: column;
	}

	#toolbar-wrapper {
		flex-flow: column-reverse;
		flex-wrap: wrap;
		height: 80px;
		justify-content: stretch;
		align-items: stretch;
	}

	body.fullscreen #toolbar-wrapper {
		top: -80px;
	}

	.mq-zone-left,
	.mq-logo-btn .mq-icon {
		height: 80px;
	}

	.mq-logo-btn {
		height: 82px;
	}

	.mq-zone-center,
	.mq-zone-right {
		flex: 1;
		padding-left: 0;
	}

	.mq-zone-right {
		padding-right: 0;
		width: calc(100% - 40px);
		justify-content: space-around;
	}

	.mq-zone-right.view-mode {
		justify-content: flex-end;
		padding: 0 0.75em 0 0.5em;
		width: calc(100% - 40px - 1.25em);
	}

	.mq-zone-right.view-mode .mq-toolbar-separator {
		display: block;
		margin-left: auto;
	}

	.mq-zone-center {
		border-top: 1px solid var(--editor-menu-border-color);
		width: calc(100% - 40px);
	}

	#toolbar,
	#sidebar-nav {
		flex-grow: 1;
	}

	.mq-toolbar,
	.mq-sidebar-nav {
		justify-content: space-evenly;
		flex-grow: 1;
	}

	.mq-sidebar-nav::after {
		display: none;
	}

	#stage-wrapper {
		top: 80px;
	}

	.mq-zone-right.view-mode .mq__button span {
		max-width: 7em;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	#arrange-objects .context-menu,
	#export-view .context-menu {
		left: auto;
		right: 0;
	}

	.export-popover {
		left: auto;
		right: -16px;
		transform: none;
	}

	.global-search__wrapper {
		top: 80px;
	}

	.paste-options__actions button {
		flex-basis: 100%;
	}

	.comment-popover {
		--max-comment-height: 260px;
	}
}

/* Dedicated media rules for tall screens */
@media screen and (min-height: 56rem) {
	.vertical-icon-menu > li > button {
		padding: 9px 0 7px;
	}

	.vertical-icon-menu > li > button > span {
		margin-top: 3px;
	}

	.cp-swatches-container {
		max-height: 220px;
	}

	.cp-swatches-container-mini {
		max-height: 100px;
	}
}

/* Dedicated media rules for small laptop enjoyers like Dan */
@media screen and (max-height: 40rem) {
	.vertical-icon-menu > li > button {
		padding: 0.25em 0;
	}
	#sidebar .vertical-icon-menu .mq-sidebar-add-template button {
		padding: 0.25em 0;
	}
	#sidebar .vertical-icon-menu.sidebar-bottom-actions > li {
		padding-top: 0.5em;
	}

	#sidebar .vertical-icon-menu .mq-sidebar-add-template svg,
	#account-menu .avatar {
		width: 24px;
		height: 24px;
	}

	.recent-projects {
		padding-top: 0;
	}

	.comment-popover {
		--max-comment-height: 300px;
	}
	.comment-input {
		padding: 0.75em 1.25em;
	}

	.mq-sidebar-open-pages {
		max-height: 30vh;
	}

	.mq-modal {
		padding-top: 4vh;
	}

	.new__account__modal .mq__modal__subtitle {
		display: none;
	}
}

@media screen and (max-height: 36rem) {
	.vertical-icon-menu > li > button {
		padding: 0.6em 0;
	}

	#sidebar .vertical-icon-menu .mq-sidebar-add-template {
		padding: 0.7em 0 calc(0.7em - 1px);
	}

	.vertical-icon-menu > li .vertical-icon-menu__label {
		display: none;
	}

	#sidebar .vertical-icon-menu.sidebar-bottom-actions > li#account-menu {
		padding-bottom: 0.6em;
	}

	.cp {
		max-height: calc(100vh - 50px - 1.75em - 1.75em); /* :) */
		overflow: auto;
		overflow-x: hidden;
	}
}

/* On narrow height apply scroll on left menu */
@media screen and (max-height: 540px) {
	#sidebar .vertical-icon-menu:first-child {
		max-height: calc(100% - 9em);
		overflow: auto;
		overflow-x: hidden;
		border-bottom: 1px solid var(--editor-border-color);
	}
	/* add hint that its scrollable */
	#sidebar .vertical-icon-menu:nth-child(2)::before {
		content: '';
		position: absolute;
		top: -40px;
		left: 0;
		right: 0;
		height: 40px;
		background: transparent;
		background: linear-gradient(0deg, var(--editor-bg) 0%, transparent 100%);
		pointer-events: none;
	}

	#sidebar .vertical-icon-menu.sidebar-bottom-actions {
		background: var(--editor-bg);
	}

	.vertical-icon-menu .mq-icon,
	.support-menu .mq-icon {
		width: 22px;
		height: 22px;
	}

	.support-menu--bubble {
		left: 17px;
		top: -2px;
		width: 6px;
		height: 6px;
	}

	#sidebar .vertical-icon-menu.sidebar-bottom-actions > li {
		padding: 0.5em 0;
	}
}
