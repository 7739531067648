/*
	Toolbar
	-------
 */

.drawing-tools {
	display: inline-flex;
	vertical-align: middle;
}

.drawing-tools .uix-popup {
	position: relative; /* popup child has absolute positioning */
	width: auto;
	z-index: 10;
	border: 0;
}

.drawing-tools button:hover {
	color: var(--editor-active-color);
}

.drawing-tools .context-menu {
	min-width: 12em;
	left: -6em;
}

.drawing-tools .uix-list__item {
	padding: 0;
}

.drawing-tools .context-menu-item-title {
	padding: 0.3em 1em;
	padding-left: 0.95em;
	min-height: 28px;
}

.drawing-tools .uix-select {
	border: none;
	width: 1.6em;
	display: flex;
	justify-content: center;
	color: inherit;
}

.drawing-tools .uix-select.disabled {
	opacity: 0.5 !important;
	pointer-events: none;
}

.drawing-btn {
	border: 1px solid transparent;
	padding: 0.2em 0;
	width: 4em;
	border-radius: 3px;
	display: inline-block;
	text-align: center;
	background: transparent;
}

.drawing-btn.disabled {
	opacity: 0.5 !important;
	pointer-events: none;
}

.drawing-btn.active {
	border-color: var(--editor-active-color);
	box-shadow: var(--mq-box-shadow-inset);
	color: var(--editor-active-color);
}

.drawing-btn svg {
	margin: 0 auto;
	display: block;
	stroke: none;
}

.drawing-btn span {
	font-size: 0.7em;
}

.drawing-btn:focus {
	outline: none;
}

.drawing-tools .uix-select__button {
	position: static;
	transform: none;
	display: flex;
	align-items: center;
	height: 100%;
	cursor: pointer;
}

.drawing-tools .uix-select__button button {
	appearance: none;
	border: none;
	background: transparent;
	font: inherit;
	padding: 0.25em;
	font-size: 0.75em;
	outline: none;
	color: inherit;
	cursor: pointer;
	position: relative;
	transition: top 0.2s ease;
	top: 0;
}
.drawing-tools .uix-select__button button:hover {
	color: var(--editor-active-color);
	top: 0.3em;
}

.drawing-tools .uix-select__current {
	height: 100%;
}

.drawing-tools .uix-select__value {
	display: none;
}

/* 
	Canvas helpers
	--------------
*/

.polygon-close-point {
	fill: transparent;
	visibility: hidden;
	pointer-events: none;
	stroke: rgb(0, 107, 229);
	stroke-width: 1px;
}
.polygon-close-point.active {
	visibility: visible;
}

/*
	Responsive design
	-----------------
 */

@media (--tablet) {
	.drawing-btn {
		width: 2.25em;
	}

	.drawing-btn span {
		display: none;
	}

	.drawing-tools .uix-select__button button {
		margin-bottom: 0;
	}
}

@media (--phone) {
	.drawing-tools {
		vertical-align: inherit;
	}
}
