.must-gradient-controls {
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
	gap: 0.3125rem;
}

.must-slider--gradient-angle {
	box-sizing: border-box;
	width: 1.875rem;
	height: 1.875rem;
	margin: 0 auto;
	padding-bottom: 0;
	--handle-size: 0.5rem;
}

input.must-input--gradient-angle {
	width: 4rem;
}

.must-gradient-controls .must-button-group {
	display: flex;
}

.must-gradient-controls .must-button-group > :not(:first-child) {
	width: 1.8125rem;
	border-left: 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.must-gradient-controls .must-button-group > :not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.must-gradient-controls .must-button {
	display: flex;
	width: 1.875rem;
	max-height: 1.875rem;
	padding: 0 0;
	text-align: center;
	border-color: var(--mq-grey-300);
	border-radius: 0.25rem;
	background: var(--mq-grey-100);
	align-items: center;
	justify-content: center;
}

.must-gradient-controls .must-button:hover {
	cursor: pointer;
	background: var(--mq-white);
}

.must-gradient-controls .must-button:active {
	box-shadow: inset 0.0625rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.1);
}

.must-gradient-controls .must-button .must-icon {
	display: block;
	fill: var(--mq-grey-900);
}

.must-gradient-controls .must-input {
	max-height: 1.875rem;
	padding: 0 0.5rem;
}

.must-pad--gradient-center {
	box-sizing: border-box;
	width: 1.875rem;
	height: 1.875rem;
	margin: 0 auto;
	padding-bottom: 0;
}

.must-pad--gradient-center .must-handle {
	--size: 0.5rem;
}
