/* Search box */
.mq-searchbox {
	position: relative;
}

.mq-searchbox input {
	box-sizing: border-box;
	width: 100%;
	line-height: inherit;
	font-family: inherit;
	font-size: 14px;
	background: var(--input-bg);
	border: 1px solid var(--input-border-color);
	display: block;
	border-radius: 0.25em;
	padding: 0.2em 1.75em;
	height: 2em;
}

.mq-searchbox input:placeholder-shown {
	overflow: hidden;
	text-overflow: ellipsis;
}

.mq-searchbox input:focus {
	/*box-shadow: 0px 0px 0px 1px var(--editor-active-color);*/
	/*border-color: transparent;*/
	outline: none;
}

.mq-searchbox .mq-icon {
	fill: var(--editor-text-color);
	color: var(--editor-text-color);
}

.mq-searchbox .mq-icon:first-child {
	transform: translate(0, -50%);
	position: absolute;
	top: 50%;
	left: 0.35em;
	width: 16px;
	height: 16px;
	pointer-events: none;
}

.mq-searchbox .clear {
	transform: translate(0, -50%) scale(0, 0);
	transition: transform 0.2s;
	position: absolute;
	top: 50%;
	right: 0.25em;
	width: 12px;
	height: 12px;
	padding: 4px;
}

.mq-searchbox .clear:hover {
	cursor: pointer;
	color: var(--editor-active-color);
	fill: var(--editor-active-color);
}

.mq-searchbox.has-content .clear {
	transform: translate(0, -50%) scale(1, 1);
}

.mq-search {
	font-size: 0.9em;
}

.mq-search input {
	font-weight: 300;
	border-radius: 1.25em;
	padding: 0.25em 1.75em !important;
}

.mq-search .mq-icon {
	width: 1em;
	height: 1em;
	stroke: #999;
	fill: none;
}

.mq-search input:focus ~ .mq-icon {
	stroke: #006ce5;
}
