/* =============================================================================
   Buttons
   ========================================================================== */

.mq-btns {
	margin: 1em 0;
}

.mq-btn {
	user-select: none;
	box-sizing: border-box;
	position: relative;
	display: inline-block;
	text-align: center;
	/*padding: 0.25em 1em;*/
	padding: 0.4em 1em 0.4em 1em;
	margin: 0;
	color: var(--button-primary-text-color);
	background-color: var(--button-primary-bg);
	text-decoration: none;
	border-radius: 0.25em;
	cursor: default;
	transition: background-color 0.1s;
	border: 1px solid var(--button-primary-bg);
	font-size: 1em;
}

.mq-btn-secondary {
	background-color: var(--button-secondary-bg);
	border-color: var(--button-secondary-bg);
	color: var(--button-secondary-text-color);
}

.mq-btn-secondary:hover,
.mq-btn-secondary:focus {
	background-color: var(--button-secondary-hover-color);
}

.mq-btn.link,
.mq-btn.link:hover {
	color: var(--link-color);
	border-color: transparent;
	background: none;
	cursor: pointer;
}

.mq-btn.link:active,
.mq-btn.link:focus {
	background-color: var(--editor-semiselection-bg);
	border-radius: 0;
}

.mq-btn-danger {
	background: var(--error-text-color);
	border: 1px solid var(--error-text-color);
}

.mq-btn-large {
	padding: 0.55em 1em;
}

.mq-btn-hover,
.mq-btn:hover,
.mq-btn:focus {
	background-color: var(--button-primary-hover-color);
	border: 1px solid var(--button-primary-hover-color);
	text-decoration: none;
	color: var(--button-primary-text-color);
}

.mq-btn:focus {
	outline: 0;
}

.mq-btn::-moz-focus-inner {
	padding: 0;
	border: 0;
}

.mq-btn-active,
.mq-btn:active {
	text-decoration: none;
	color: var(--editor-active-color);
	background-color: var(--editor-active-bg);
}

.mq-btn[disabled],
.mq-btn.disabled,
.mq-btn-disabled,
.mq-btn-disabled:hover,
.mq-btn-disabled:focus,
.mq-btn-disabled:active {
	opacity: 0.4;
	cursor: not-allowed;
	box-shadow: none;
	pointer-events: none;
}

.mq-btn.transparent,
.mq-btn.transparent:hover {
	color: var(--editor-text-color);
	border-color: transparent;
	background: none;
}

.mq-btn.transparent:active,
.mq-btn.transparent:focus {
	background-color: var(--editor-highlight-bg);
	border-radius: 0;
}

.mq-btn.leftside {
	border-radius: 4px 0 0 4px;
}

.mq-btn.rightside {
	border-radius: 0 4px 4px 0;
}

.mq-btn .mq-icon {
	pointer-events: none;
}

.mq-btn-group {
	display: inline-block;
	vertical-align: middle;
}

.mq-btn-group .mq-btn:not(.leftside):not(.rightside) {
	border-radius: 0;
}

.mq-btn-group .mq-btn:not(.leftside) {
	margin-left: -1px;
}

.mq-btn-group .mq-btn {
	float: left;
}

.mq-btn-faded {
	opacity: 0.7;
}

/* =============================================================================
   New button styles
   ========================================================================== */

.mq__button {
	user-select: none;
	box-sizing: border-box;
	padding: 8px 16px;
	min-width: 6em;
	height: 38px;
	margin: 0;
	color: var(--button-text-color);
	background: var(--button-bg);
	text-decoration: none;
	text-align: center;
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	cursor: pointer;
	transition: background-color 0.1s;
	border: 1px solid transparent;
	font-size: 14px; /* Default size */
	font-weight: 600;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
}

.mq__button:hover,
.mq__button:focus {
	background-color: var(--button-hover-color);
	text-decoration: none;
	outline: none;
}

.mq__button:disabled,
.mq__button--disabled {
	color: var(--editor-text-muted-color);
	background-color: var(--button-bg);
	border: 1px solid var(--button-bg);
	opacity: 0.7;
	pointer-events: none;
	cursor: default;
}

.mq__button--hollow {
	color: var(--button-primary-bg);
	background-color: transparent;
	border: 1px solid var(--button-primary-bg);
}

.mq__button--hollow:hover,
.mq__button--hollow:focus {
	background-color: var(--editor-semiselection-bg);
}

.mq__button--important {
	color: var(--new-text-color);
	background-color: var(--new-accent);
}

.mq__button--important:hover {
	color: var(--new-text-color);
	background-color: var(--new-accent);
	opacity: 0.9;
}

.mq__button--secondary {
	color: var(--button-text-color);
	background-color: transparent;
}

.mq__button--secondary:hover {
	color: var(--editor-active-color);
}

/*
	Button variation: compact
	-------------------------
 */
.mq__button--compact {
	padding: 4px 12px;
	font-size: 12px;
	white-space: nowrap;
	height: 32px;
	min-width: 0;
}

.mq__button--narrow {
	min-width: 3em;
}

/*
	Button icons
	------------
 */
.mq__button .mq-icon {
	fill: currentColor;
	width: 1.25em;
	height: 1.25em;
}

/* =============================================================================
   Custom button styles
   ========================================================================== */

.mq__button__apple svg.mq-icon {
	width: 20px;
	height: 20px;
	fill: var(--stencil-color);
}

.mq__button__google {
	border-radius: 0.4rem;
	height: 40px;
	padding: 1px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03);
	border: 1px solid var(--button-login-border-color);
	transition: border-color 0.1s ease-out, color 0.1s ease-out;
	background: var(--button-login-bg);
	color: var(--button-login-text-color);
	font-weight: 600;
}

.mq__button__google:hover {
	color: var(--editor-active-color);
	border-color: var(--editor-active-color);
	background-color: var(--editor-semiselection-bg);
}

.mq__button__google:focus {
	background-color: var(--editor-semiselection-bg);
	border-color: var(--editor-active-color);
}

.mq__button__google svg.mq-icon {
	margin-left: -9px;
	width: 36px;
	height: 36px;
	display: inline-block;
}

.mq__button--template-secondary {
	margin-right: 10px;
	white-space: nowrap;
}

.orange__button {
	height: 40px;
	background-color: var(--mq-yellow-500);
	box-shadow: 0px 3px 6px rgba(254, 219, 44, 0.2);
	color: var(--mq-grey-900);
	border-radius: 0.4rem;
	margin-top: 1rem !important;
}

.orange__button:hover,
.orange__button:focus {
	background-color: var(--mq-yellow-700);
}

.blue__button {
	height: 40px;
	background-color: var(--button-primary-bg);
	color: var(--button-primary-text-color);
	border-radius: 0.4rem;
	margin-top: 1rem !important;
}

.blue__button:hover,
.blue__button:focus {
	background-color: var(--button-primary-hover-color);
}

.mq__signup .mq__input label {
	margin-bottom: 0.4rem;
}

.mq__button--primary {
	background-color: var(--button-primary-bg);
	color: var(--button-primary-text-color);
}

.mq__button--primary:hover,
.mq__button--primary:focus {
	color: var(--button-primary-text-color);
	background-color: var(--button-primary-hover-color);
}

.mq__button--secondary {
	background-color: var(--button-secondary-bg);
	color: var(--button-secondary-text-color);
}

.mq__button--secondary:hover,
.mq__button--secondary:focus {
	color: var(--button-secondary-text-color);
	background-color: var(--button-secondary-hover-color);
}

.mq__button--dark {
	background-color: var(--editor-text-color);
	color: var(--editor-bg);
}

.mq__button--dark:hover,
.mq__button--dark:focus {
	background-color: var(--editor-text-color);
	color: var(--editor-bg);
	opacity: 0.9;
}
