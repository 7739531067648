/* Comments */

.comments-layer {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 101;
	pointer-events: none;
}

.comments-layer.dragging-comment {
	pointer-events: none;
}

.comments-layer.dragging-comment .comment-marker {
	pointer-events: none;
}

.canvas-stage.view-mode .comments-layer {
	z-index: 1000;
}

.comment-thread {
	position: absolute;
	top: 0;
	left: 0;
}

.comments-layer > div:has(.comment-thread.view-mode) {
	z-index: 1000;
}

.comments-layer > div:has(.comment-thread.view-mode.expanded) {
	z-index: 1001;
}

.comment-marker {
	font-size: 0.9em;
	position: relative;
	width: 34px;
	height: 34px;
	pointer-events: all;
	text-align: center;
	font-weight: 400;
	cursor: pointer;
	transform: translate(-50%, -50%);
	transition-property: color, background-color, transform, opacity;
	transition-duration: 0.2s;
	transition-timing-function: ease-in-out;
}

/*
 	The hexagon shape doesn't have a 1:1 aspect ratio, so adjust margin
 */

.comment-marker.comment-style-hexagon {
	height: initial;
}

.comment-marker-index.comment-style-hexagon.shape {
	line-height: 2.5em;
}

.comment-marker-highlight {
	width: 34px;
	height: 34px;
	color: var(--mq-white);
	background-color: rgba(41, 50, 61, 0.2);
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto;
}

.comment-marker.unread .comment-marker-highlight {
	background-color: rgba(76, 175, 80, 0.25);
}

.comment-marker.unread .comment-marker-highlight:hover {
	background-color: rgba(67, 160, 71, 0.35);
}
.comment-marker.unsaved .comment-marker-highlight {
	background-color: rgba(255, 102, 36, 0.25);
}

.comment-marker.unsaved .comment-marker-highlight:hover {
	background-color: rgba(255, 102, 36, 0.35);
}

.comment-resolved-icon {
	display: none;
}

.comment-resolved-icon.resolved {
	display: block;
	position: absolute;
	left: 3px;
	top: -23px;
	z-index: 1;
	fill: var(--mq-comment-background-color, var(--mq-blue));
	width: 20px;
	height: 20px;
	animation-name: bounceInNoTranslate;
	animation-duration: 0.7s;
	animation-delay: 0s;
}

.comments-layer.show-unresolved > .comment-thread:not(.expanded) .resolved:not(.hide) {
	display: none;
}

.comments-layer.show-resolved > .comment-thread:not(.expanded) .unresolved:not(.hide) {
	display: none;
}

.comments-layer.show-unread > .comment-thread:not(.expanded) .read {
	display: none;
}

.comments-layer.show-mentions .comment-thread:not(.expanded) .nomentions:not(.hide) {
	display: none;
}

.comments-layer.show-style.show-style-circle
	.comment-thread:not(.expanded)
	.comment-marker:not(.comment-style-circle) {
	display: none;
}

.comments-layer.show-style.show-style-rhombus
	.comment-thread:not(.expanded)
	.comment-marker:not(.comment-style-rhombus) {
	display: none;
}

.comments-layer.show-style.show-style-star
	.comment-thread:not(.expanded)
	.comment-marker:not(.comment-style-star) {
	display: none;
}

.comments-layer.show-style.show-style-square
	.comment-thread:not(.expanded)
	.comment-marker:not(.comment-style-square) {
	display: none;
}

.comments-layer.show-style.show-style-hexagon
	.comment-thread:not(.expanded)
	.comment-marker:not(.comment-style-hexagon) {
	display: none;
}

.comment-marker.hide {
	animation-name: bounceOut;
	animation-duration: 0.6s;
	animation-delay: 0.3s;
	animation-fill-mode: forwards;
}

.comment-marker > .comment-marker-highlight:hover {
	background-color: var(--mq-comment-highlight-color, rgba(0, 113, 243, 0.25));
}

.comment-marker.resolved > .comment-marker-highlight {
	background-color: rgba(41, 50, 61, 0.1);
}

.comment-marker.resolved > .comment-marker-highlight:hover {
	background-color: rgba(207, 216, 220, 0.55);
}

.comment-marker:active {
	opacity: 0.5;
}

.comment-marker-index {
	width: 24px;
	height: 24px;
	line-height: 2em;
	background: var(--mq-comment-background-color, var(--mq-blue));
	font-size: 0.75rem;
	font-weight: 800;
	/*border: 1px solid #fff;*/
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	transition-property: color, background-color, transform;
	transition-duration: 0.2s;
	transition-timing-function: ease-in-out;
	color: var(--mq-comment-color, var(--mq-white));
}

.comment-marker-index span {
	position: relative;
	z-index: 3;
	color: var(--mq-comment-color, var(--mq-white));
}

.comment-marker.resolved span {
	color: var(--mq-editor-grey-700);
}

.comment-marker.resolved .comment-marker-index {
	background: var(--mq-editor-grey-300);
	color: var(--mq-editor-grey-700);
}

.comment-popover {
	pointer-events: all;
	z-index: 2;
	position: relative;
	top: -5em;
	left: 2em;
	width: 340px;
	max-height: var(--max-comment-height);
	display: flex;
	flex-direction: column;
	/*overflow: hidden;*/
	min-height: 4em;
	background-color: var(--modal-bg);
	box-shadow: var(--mq-box-shadow-xl);
	border-radius: calc(var(--inspector-border-radius) * 3);
	animation-name: genie;
	animation-duration: 0.2s;
	animation-timing-function: var(--mq-transition);
}

.comment-popover.right {
	right: 370px;
	left: initial;
}

.comment-popover .comment-row {
	background: transparent;
	margin: 0 !important;
	padding: 0.8em 1.25em 0.35em;
	border: 0;
	border-radius: 0;
	border-bottom: 1px solid var(--editor-menu-border-color);
}

@keyframes comment-highlight {
	0% {
		background: transparent;
	}
	50% {
		background: var(--editor-semiselection-bg);
	}
	100% {
		background: transparent;
	}
}

.comment-popover .comment-row.selected {
	animation: comment-highlight 1s var(--mq-transition);
}

.comment-popover .comment-row:hover {
	cursor: default;
	border-bottom: 1px solid var(--editor-menu-border-color);
}

.comment-popover .comment-row:last-child,
.comment-popover .comment-row:last-child:hover {
	border-bottom: none;
}

.comment-popover .comment-read-status {
	top: 17px;
	left: 5px;
	width: 6px;
	height: 6px;
}

.comment-popover .comment-time {
	top: 13px;
	right: 20px;
}

.comment-popover .mq-profile-card-avatar {
	width: 1.5em;
	height: 1.5em;
}

.comment-popover .mq-profile-card-expanded .mq-profile-card-avatar {
	width: 3em;
	height: 3em;
}

.comment-popover .comment-row:last-of-type:not(:only-child) .mq-profile-card-expanded {
	top: auto;
	bottom: 1.75em;
}

.comment-popover .comment-content {
	font-size: 13px;
}

@media (max-width: 900px) {
	.comment-popover {
		width: 300px;
	}
	.comment-popover.right {
		right: 328px;
	}

	.comment-input-footer button {
		padding-left: 6px;
		padding-right: 6px;
	}
}

@media (max-width: 500px) {
	.comment-popover {
		width: 250px;
	}
	.comment-popover.right {
		right: 278px;
	}
	.comment-input-footer button {
		padding-left: 4px;
		padding-right: 4px;
	}
}

.comment-popover:after,
.comment-popover:before {
	right: 100%;
	top: 22px;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.comment-popover.bottom {
	left: -50%;
	top: -22px;
}

.comment-popover.bottom:after,
.comment-popover.bottom:before {
	display: none;
}

.comment-popover.right:after,
.comment-popover.right:before {
	left: 100%;
	right: initial;
}

.comment-popover:after {
	border-color: rgba(255, 255, 255, 0);
	border-right-color: var(--modal-bg);
	border-width: 8px;
	margin-top: 15px;
}
.comment-popover.right:after {
	border-color: rgba(255, 255, 255, 0);
	border-left-color: var(--modal-bg);
	border-width: 8px;
	margin-top: 15px;
}

@keyframes genie {
	0% {
		opacity: 0;
		transform-origin: -5% 15%;
		transform: translate(0%, 0%) scale(0.3, 0.3);
	}

	100% {
		opacity: 1;
		transform-origin: 0 0;
		transform: translate(0, 0) scale(1, 1);
	}
}

.comment-popover-header {
	padding: 0.5em 1.25em;
	font-size: 1em;
	min-height: 24px;
	font-size: 13px;
	display: flex;
	align-items: center;
	position: relative;
	font-weight: normal;
	color: var(--editor-text-color);
	border: 2px solid;
	border-image-slice: 1;
	border-width: 1px;
	border-image-source: linear-gradient(
		to right,
		var(--modal-bg) 0%,
		var(--editor-menu-border-color) 5%
	);
	border-left: 0;
	border-right: 0;
	border-top: 0;
}

.comment-thread-replies {
	overflow: auto;
	overscroll-behavior-y: contain;
	overflow-x: hidden;
	margin-bottom: 0;
}
/* hide margin set in place of hidden comment-input when editing comments above */
.comment-popover:not(:has(> .comment-input)) .comment-thread-replies {
	margin-bottom: 1em;
}

.comment-style-button {
	width: 12px;
	height: 12px;
	background: var(--mq-comment-background-color, var(--mq-blue));
}

.comment-style-button__menu {
	z-index: 100;
	position: absolute;
	left: 10%;
	top: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
	transform: translate(-50%, 0);
	white-space: nowrap;
	color: var(--toolbar-text-color);
	background: var(--modal-bg);
	box-shadow: var(--mq-box-shadow-lg);
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	border: 1px solid var(--toolbar-border-color);
	height: var(--toolbar-size);
	padding: 0 calc(var(--toolbar-gap) * 2);
	gap: calc(var(--toolbar-gap) * 1);
}

.comment-style-button__menu-button {
	height: calc(var(--toolbar-size) - calc(var(--toolbar-gap) * 2.5));
	min-width: calc(var(--toolbar-size) - calc(var(--toolbar-gap) * 2.5));
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	cursor: pointer;
	background: transparent;
	border: none;
	border-radius: 4px;
}

.comment-row,
.comment-group {
	position: relative;
	display: flex;
	color: var(--editor-text-color);
	flex-direction: column;
	padding: 10px 7px 10px 10px;
	margin: 9px;
	background: var(--editor-bg);
	border-radius: calc(var(--inspector-border-radius) * 2);
	border: 1px solid var(--editor-bg);
}

.comment-group {
	padding: 0;
}

.comment-group .comment-row {
	background: transparent;
	border-radius: 0;
	border: none;
	margin: 0;
	transition: background-color 0.05s linear;
}

.comment-group > .comment-row:first-child {
	border-top-left-radius: calc(var(--inspector-border-radius) * 2);
	border-top-right-radius: calc(var(--inspector-border-radius) * 2);
}

.comment-group > .comment-row:only-child,
.comment-replies .comment-row:last-child {
	margin-bottom: 0;
	border-bottom-left-radius: calc(var(--inspector-border-radius) * 2);
	border-bottom-right-radius: calc(var(--inspector-border-radius) * 2);
}

.comment-group:has(.comment-replies) .comment-row:first-child {
	padding-bottom: 8px;
}

.comment-group:has(.comment-replies .comment-row) {
	background: var(--editor-menu-bg);
	border-color: var(--editor-border-color);
	margin-bottom: 14px;
}

.comment-group--search .comment-replies::before {
	content: '···';
	font-size: 16px;
	margin: 0 auto;
	color: var(--editor-text-muted-color);
	display: flex;
	align-items: center;
	justify-content: center;
	height: 10px;
	width: 10px;
}

.comment-group--search > .comment-row {
	opacity: 0.75;
}

.comment-replies .comment-row + .comment-row {
	margin-bottom: 0;
}

.comment-replies button {
	position: sticky;
	top: -1px;
	z-index: 9;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	left: 0;
	width: 100%;
	text-align: left;
	padding-left: 10px;
	background: var(--editor-bg);
	color: var(--link-color);
	border: 0;
	font-size: 12px;
	border-top: 1px solid transparent;
}

.comment-group:has(.comment-replies .comment-row) .comment-replies button {
	background: var(--editor-menu-bg);
	border-color: var(--editor-menu-border-color);
}

.comment-replies button:last-child {
	border-bottom: 0;
	border-bottom-left-radius: calc(var(--inspector-border-radius) * 2);
	border-bottom-right-radius: calc(var(--inspector-border-radius) * 2);
}

.comment-replies button:hover,
.comment-group:has(.comment-replies .comment-row) .comment-replies button:hover {
	cursor: pointer;
	color: var(--link-active-color);
}

.comment-group .comment-row:hover {
	cursor: pointer;
	background: var(--editor-semiselection-bg);
}

.comment-row + .comment-row {
	margin-bottom: 0.5em;
}

.comment-row + .section-header {
	margin-top: 12px !important;
}

.comment-row:last-child {
	margin-bottom: 9px;
}

.comment-row .mq-profile-card {
	display: flex;
	width: calc(100% - 30px);
	font-size: 11px;
	margin-bottom: 0.5em;
}

.comment-row .mq-profile-card-compact {
	display: flex;
	align-items: center;
	width: 100%;
}

.comment-row .mq-profile-card-avatar {
	flex: none;
	overflow: hidden;
}

.comment-row .mq-profile-card-name {
	color: var(--editor-text-color);
}

.mq-comments {
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: stretch;
}

.mq-comments-header {
}

.mq-comments-actions {
	display: flex;
	gap: 0.5em;
	padding: 0.3em 9px 0.3em;
	justify-content: space-between;
	box-sizing: border-box;
	border-bottom: 1px solid var(--editor-menu-border-color);
	height: var(--sidebar-header-height);
	align-items: center;
}

.mq-comments-actions-right {
	display: flex;
	gap: 0.3em;
	align-items: center;
}

.mq-comments-actions-right > a,
.mq-comments-actions-right > div,
.mq-comments-actions-right > div > a {
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mq-comments-actions-right > a:hover .mq-icon,
.mq-comments-actions-right > div > a:hover .mq-icon {
	color: var(--editor-text-color);
	fill: var(--editor-text-color);
}

.mq-comments-actions-right .mq-icon {
	font-size: 16px;
	width: 1em;
	height: 1em;
	color: var(--editor-active-color);
	fill: var(--editor-active-color);
}

.mq-comments-actions-right .mq-icon.disabled {
	color: var(--editor-text-muted-color);
	fill: var(--editor-text-muted-color);
}

.mq-sidebar-header-button {
	border: 0;
	color: var(--button-text-color);
	padding: 0.3em 0.1em;
	font-weight: 500;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-radius: var(--inspector-border-radius);
	gap: 0.4em;
	white-space: nowrap;
	font-size: 13px;
	overflow: hidden;
}

.mq-sidebar-header-button:hover {
	color: var(--editor-active-color);
}

.mq-comments-tabs {
	list-style: none;
	margin: 9px 9px 0;
	padding: 0;
	color: var(--editor-text-color);
	font-size: 13px;
	background: var(--editor-bg);
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	overflow: hidden;
	display: flex;
	box-shadow: var(--mq-box-shadow-inset-sm);
}

.mq-comments-tabs li {
	display: block;
	letter-spacing: 0;
	text-align: center;
	width: calc(50% - 4px);
	border: 3px solid transparent;
	background: transparent;
}

.mq-comments-tabs li a {
	display: block;
	color: inherit;
	opacity: 0.7;
	padding: 0.25em 0.5em 0.3em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	background: transparent;
	min-width: 5em;
	border-radius: var(--inspector-border-radius);
	transition: background-color 0.2s var(--mq-transition), color 0.2s var(--mq-transition),
		opacity 0.2s var(--mq-transition);
}

.mq-comments-tabs li a:hover {
	text-decoration: none;
	opacity: 1;
}

.mq-comments-tabs li a.selected {
	color: var(--editor-active-color);
	background: var(--switch-active-bg);
	box-shadow: var(--mq-box-shadow-sm);
	opacity: 1;
}

.mq-comments-contents {
	overflow: auto;
	will-change: scroll-position;
	flex-grow: 1;
}

.mq-comments-filter {
	padding: 0 9px;
	height: var(--sidebar-header-height);
	font-size: 0.95em;
	border-bottom: 1px solid var(--editor-menu-border-color);
	display: flex;
	box-sizing: border-box;
	justify-content: space-between;
	align-items: center;
	vertical-align: middle;
}

.mq-comments-filter .mq-link-dropdown-current a {
	margin-left: 0px;
	text-align: left;
	color: var(--editor-active-color);
	text-decoration: none;
}

.mq-comments-filter .mq-link-dropdown-current a:hover {
	text-decoration: none;
}

.mq-comments-filter--page {
	color: var(--editor-text-color);
	background-image: linear-gradient(
		to right,
		var(--editor-text-muted-color) 40%,
		rgba(255, 255, 255, 0) 20%
	);
	background-position: bottom;
	background-size: 3px 1px;
	background-repeat: repeat-x;
}

.mq-comments-filter--page:hover {
	text-decoration: none;
}

.mq-comments-filter .mq-link-dropdown-current::after {
	color: var(--editor-active-color);
}

#comments-sidebar-pane .section-header {
	color: var(--stencil-header-color) !important;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

#comments-sidebar-pane .section-header .expand {
	margin-left: 0.4em;
}

#comments-sidebar-pane .section-header .page-title {
	flex-grow: 1;
}

#comments-sidebar-pane .section-header .comment-read-status {
	flex: none;
}

.comment-row:hover .comment-time {
	display: none;
}

.comment-time.editing {
	display: none;
}

.mq-comments-sidebar .comment-row .comment-time {
	right: 0.2em;
}

.mq-comments-contents .comment-row:hover .comment-time {
	display: block;
}

.comment-row:hover .comment-actions {
	display: block;
}

.comment-row .no-body {
	color: var(--editor-text-muted-color);
}

.comment-row .no-session {
	font-size: 0.9em;
}

.comment-read-status {
	width: 9px;
	height: 9px;
	z-index: 9;
	border-radius: 50%;
	position: absolute;
	top: -4px;
	left: -4px;
	transition: background-color 0.2s var(--mq-transition);
}

.comment-read-status.unread {
	background-color: var(--unread-comment-color);
}

.comment-read-status.read {
	background-color: transparent;
}

.comment-info {
	overflow-wrap: break-word;
	word-break: break-word;
}

.comment-content {
	display: block;
	color: var(--editor-text-color);
	font-size: 12px;
}

.comment-content .mq-profile-card {
	display: inline-flex;
	font-size: 14px;
	width: auto;
	margin: 0;
}

.comment-content .mq-profile-card-name {
	padding: 0;
	font-weight: 500;
	color: var(--editor-active-color);
}

.comment-content ul,
.comment-content ol {
	/*list-style-position: inside;*/
	/*margin-bottom: 0.5em;*/
	padding-left: 2em;
}

.comment-content ul {
	list-style-type: disc;
}
.comment-content ol {
	list-style-type: decimal;
}

.comment-content ul ul,
.comment-content ol ul {
	list-style-type: circle;
}

.comment-content ul ol,
.comment-content ol ol {
	list-style-type: lower-roman;
}

.comment-thread code {
	font-family: Consolas, Monaco, Courier, 'Courier New', monospace;
	font-size: 0.9em;
	color: var(--editor-text-muted-color);
	padding: 0 3px;
}

.comment-thread pre code {
	padding: 0;
}

.comment-thread code span {
	color: var(--editor-text-color);
}

.comment-thread code .hljs-built_in,
.comment-thread code .hljs-number {
}

.comment-thread code .hljs-string,
.comment-thread code .hljs-keyword {
	color: var(--mq-purple-400);
}

/*automatically resize linked images in comments*/
.comment-content img {
	max-width: 100%;
}

.comment-content table {
	width: 100%;
}

.comment-content tr {
}

.comment-content thead {
	font-weight: bold;
}

.comment-content td,
.comment-content th {
	border: 1px solid var(--editor-border-color);
	padding: 3px 6px;
	font-size: 1em;
}

.comment-content th {
	color: var(--editor-text-color);
	border-bottom-width: 1px;
	background: rgba(0, 0, 0, 0.1);
}

.comment-content tr:nth-child(2n) {
	background: rgba(0, 0, 0, 0.05);
}

.comment-content p .emoji-native {
	font-size: 1.4em;
	line-height: 14px;
	vertical-align: middle;
	position: relative;
	bottom: 1px;
}

.comment-content a {
	overflow-wrap: break-word;
	word-break: break-word;
}

.no-comments {
	text-align: center;
	font-size: 0.9em;
}

.comment-content .name {
	font-weight: 500;
	padding-bottom: 0.3em;
}

.comment-content .comment-input {
	padding: 0;
}

.comment-content blockquote {
	padding-left: 5px;
	border-left: 2px solid #e0e0e0;
}

.comment-time {
	/* text-transform: uppercase; */
	color: var(--editor-text-muted-color);
	font-size: 0.7em;
	opacity: 0.75;
	position: absolute;
	top: 8px;
	right: 9px;
}

.comment-actions {
	display: none;
	font-size: 13px;
	position: absolute;
	top: 10px;
	right: 20px;
}

.comment-actions.editing {
	display: block;
}

.comment-actions a {
	display: inline-block;
	margin-left: 0.8em;
	font-size: 0.9em;
}

.comment-input {
	padding: 1em 1.25em;
	background-color: var(--modal-bg);
	border-radius: calc(var(--inspector-border-radius) * 3);
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.comment-info .comment-input {
	padding: 0.5em 0;
}

.comment-input .keyboard-shortcut {
	font-size: 86%;
	margin-left: 0.25em;
	color: var(--editor-text-muted-color);
}

.comment-input .cancel-edit-link {
	margin-right: 1em;
}

.no-comment-permission {
	white-space: normal;
	line-height: 1.2;
	color: var(--editor-text-color);
	font-size: 13px;
	text-align: left;
	justify-content: flex-start;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	margin: 0 calc(var(--toolbar-gap) * 2);
	padding-bottom: 1.5em;
}

.comment-input textarea {
	height: 5.5em;
	min-height: 5.5em;
	padding-bottom: 2em;
	background: var(--input-bg);
	font-size: 14px;
	max-height: calc(var(--max-comment-height) / 2);
}

.comment-input-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.comment-input-footer--left {
	display: flex;
	justify-content: flex-start;
	gap: 0.4em;
}

.comment-input-footer--right {
	flex-grow: 1;
	display: flex;
	justify-content: flex-end;
	gap: 0.4em;
}

.comment-input-footer button {
	max-width: 122px;
}

.comment-input-footer button span {
	min-width: 0;
	text-overflow: ellipsis;
	overflow: hidden;
}

.comment-unread-sidebar-badge {
	background: var(--unread-comment-color);
	color: var(--unread-comment-text-color);
	border-radius: 0.5em;
	padding: 1px 3px 1px 3px;
	border: 1px solid var(--editor-menu-bg);
	position: absolute;
	top: 2px;
	right: 3px;
	font-size: 10px;
	min-height: 0 !important;
	cursor: pointer;
}

.comment-thread-actions {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
}

.comment-thread-actions > button {
	height: 100%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0 1em;
	float: left;
	color: inherit;
	cursor: pointer;
	border: none;
	background: transparent;
}

.comment-thread-actions .navigation-icon .mq-icon {
	width: 12px;
	height: 12px;
}

.comment-thread-actions > button .mq-icon {
	fill: currentColor;
	height: 14px;
	width: 14px;
}

.comment-thread-actions > button.navigation-icon .mq-icon {
}

.comment-thread-actions > button.trash-icon .mq-icon {
}

.comment-thread-actions > button:hover .mq-icon {
	fill: var(--editor-active-color);
}

.comment-thread-actions > button.trash-icon:hover .mq-icon {
	fill: var(--error-text-color);
}

.comment-composer {
	position: relative;
	margin-bottom: 0.75em;
}

.comment-preview {
	min-height: 5.5em;
}

.comment-preview a {
	overflow-wrap: break-word;
	word-break: break-word;
}

.comment-preview .label {
	position: absolute;
	top: -1em;
	right: 0;
	font-size: 0.75em;
	margin: 0 0 4px 0;
	color: var(--editor-text-muted-color);
}

.comment-preview p,
.comment-preview .label + * {
	margin-top: 0;
}

.mq-comment-color-swatch {
	display: inline-block;
	width: 0.9em;
	height: 0.9em;
	vertical-align: middle;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 3px;
	margin-top: -2px;
}

.mq-comment-color-definition {
	font-family: Consolas, Monaco, monospace;
	color: var(--editor-text-color);
	font-size: 0.9em;
}

.mq-comment-preview-btn {
	white-space: nowrap;
	max-width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
}

@keyframes bounceIn {
	0% {
		transform: translate(-50%, -50%) scale3d(1, 1, 1);
	}

	20% {
		transform: translate(-50%, -50%) scale3d(1.2, 1.2, 1.2);
	}

	40% {
		transform: translate(-50%, -50%) scale3d(0.9, 0.9, 0.9);
	}

	60% {
		transform: translate(-50%, -50%) scale3d(1.1, 1.1, 1.1);
	}

	80% {
		transform: translate(-50%, -50%) scale3d(0.97, 0.97, 0.97);
	}

	100% {
		transform: translate(-50%, -50%) scale3d(1, 1, 1);
	}
}

@keyframes bounceInNoTranslate {
	0% {
		transform: scale3d(0, 0, 0);
	}

	20% {
		transform: scale3d(1.2, 1.2, 1.2);
	}

	40% {
		transform: scale3d(0.9, 0.9, 0.9);
	}

	60% {
		transform: scale3d(1.1, 1.1, 1.1);
	}

	80% {
		transform: scale3d(0.97, 0.97, 0.97);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
}

.bounceIn {
	animation-name: bounceIn;
	animation-duration: 0.6s;
	animation-delay: 0.2s;
}

@keyframes bounceOut {
	0% {
		transform: translate(-50%, -50%) scale3d(1, 1, 1);
	}

	20% {
		transform: translate(-50%, -50%) scale3d(1.4, 1.4, 1.4);
	}

	40% {
		transform: translate(-50%, -50%) scale3d(1.4, 1.4, 1.4);
	}

	50% {
		transform: translate(-50%, -50%) scale3d(0.9, 0.9, 0.9);
	}

	60% {
		transform: translate(-50%, -50%) scale3d(0.7, 0.7, 0.7);
		opacity: 1;
	}

	80% {
		transform: translate(-50%, -50%) scale3d(0.2, 0.2, 0.2);
	}

	99% {
		transform: translate(-50%, -50%) scale3d(0, 0, 0);
	}

	100% {
		pointer-events: none;
		visibility: hidden;
		opacity: 0;
	}
}

.bounceOut {
	animation-name: bounceOut;
	animation-duration: 0.6s;
	animation-delay: 0.3s;
}

.greenRipple {
	animation-name: greenRipple;
	animation-duration: 4s;
	animation-timing-function: ease-out;
	animation-iteration-count: 4;
}

@keyframes greenRippleTransform {
	0% {
		transform: scale(0.7);
		opacity: 1;
	}

	100% {
		transform: scale(2);
		opacity: 0;
	}
}

/*
Step 1: First circle shows up
*/

@keyframes greenRipple {
	/*0 0 0 0px rgba(76,175,80,0.3)*/

	0% {
		box-shadow: 0 0 0 1px rgba(76, 175, 80, 0.3), 0 0 0 0px rgba(76, 175, 80, 0),
			0 0 0 0px rgba(76, 175, 80, 0), 0 0 0 0px rgba(76, 175, 80, 0);
	}

	10% {
		box-shadow: 0 0 0 1px rgba(76, 175, 80, 0.3), 0 0 0 2px rgba(76, 175, 80, 0.3),
			0 0 0 5px rgba(76, 175, 80, 0.3), 0 0 0 7px rgba(76, 175, 80, 0.3);
	}

	40% {
		box-shadow: 0 0 0 1px rgba(76, 175, 80, 0.3), 0 0 0 5px rgba(76, 175, 80, 0),
			0 0 0 10px rgba(76, 175, 80, 0), 0 0 0 15px rgba(76, 175, 80, 0);
	}
	100% {
		box-shadow: 0 0 0 1px rgba(76, 175, 80, 0.3), 0 0 0 7px rgba(76, 175, 80, 0),
			0 0 0 25px rgba(76, 175, 80, 0), 0 0 0 25px rgba(76, 175, 80, 0);
	}
}

.comment-marker.unread .comment-marker-animation-helper::before,
.comment-marker.unread .comment-marker-animation-helper::after {
	animation-name: greenRippleTransform;
}

.comment-marker-animation-helper::before,
.comment-marker-animation-helper::after {
	position: absolute;
	content: '';
	width: inherit;
	height: inherit;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	background-color: rgba(76, 175, 80, 0.4);
	animation-duration: 4s;
	animation-timing-function: ease-out;
	opacity: 0;
}

.comment-marker-animation-helper::before {
	animation-delay: 1s;
}

/* Comments sidebar */

.mq-comments-sidebar {
	position: absolute;
	top: 0em;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: auto;
	color: var(--editor-text-color);
}

.comment-sidebar-actions {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.7em;
	padding-right: 0;
}

.comment-sidebar-actions a {
	margin-left: 0.65em;
	display: flex;
}

/*TODO Get rid of this once @DB BPTLs the styles*/
.comment-sidebar-actions a:hover .mq-icon {
	fill: var(--editor-active-color);
}

.comment-sidebar-actions a.active .mq-icon {
	fill: var(--editor-active-color);
}

.comment-sidebar-actions a.disabled .mq-icon {
	fill: var(--editor-icon-color);
	opacity: 0.5;
}

.comment-sidebar-actions a.disabled:hover .mq-icon {
	fill: var(--editor-active-color);
	opacity: 1;
}

.state--zooming .comments-layer {
	opacity: 0;
}

.notification__options {
	--spacing: 15px;

	position: absolute;
	min-width: 230px;
	max-width: 270px;
	padding: var(--spacing) 0;
	background: var(--modal-bg);
	border-radius: 8px;
	border: 1px solid var(--editor-border-color);
	box-shadow: var(--mq-box-shadow);
	z-index: 100;
	font-size: 13px;
	font-variant: initial;
	color: var(--editor-text-color);
	text-transform: none;
}

.notification__options__content {
	padding: 0 var(--spacing);
}

.notification__options__footer {
	padding: calc(var(--spacing) / 1.5) var(--spacing) 0 var(--spacing);
}

.notification__options__footer--bordered {
	border-top: 1px solid var(--editor-menu-border-color);
}

/* Override any thread anchor styles */
.notification__options__footer a {
	height: initial;
	display: inline;
	padding: 0;
	float: initial;
	color: var(--link-color);
}

.notification__options__content p {
	margin-top: 0;
	color: var(--editor-text-muted-color);
}

.notification__options__content h5 {
	font-weight: bold;
	margin-top: 0;
}

.notification__options__content button {
	margin: 0.5em 0;
}

.notification__options__toggle {
	margin: 0 0 0.5em;
}

.notification__options label {
	display: flex;
	align-items: center;
	margin: 0.25em 0;
}

.notification__options label:last-of-type {
	margin-bottom: calc(var(--spacing) / 1.5);
}

.notification__options label input {
	margin-right: 0.6em;
	accent-color: var(--editor-text-color);
}

.notification__options svg {
	width: 16px;
	height: 16px;
	margin-right: 0.5em;
}

.follow__disabled {
	color: var(--editor-text-muted-color);
	opacity: 0.5;
}

.notification__toggle {
	font-size: 8px;
	margin-right: 14px;
}

/*End of comments*/
