.must-color-picker {
	font-family: var(--mq-font-main);
}

.must-color-picker .must-hsv .must-pad {
	margin: 0 0 0.625rem;
}

.must-color-picker .must-pad--radial {
	margin: 0;
}

.must-color-picker .must-gradient-controls {
	margin: 0.625rem 0;
	justify-content: space-between;
}

.must-color-picker .must-tab-bar {
	margin: 0 -0.75rem 0.625rem;
	padding: 0 0.75rem;
}

.must-color-picker .must-tab-button .must-icon {
	color: var(--mq-grey-700);
	--size: 1.3334;
}

.must-color-picker .must-tab-button--active .must-icon {
	color: var(--mq-blue);
}
