#context-menu {
	pointer-events: none;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 100;
}

.context-menu-wrapper {
	position: absolute;
	z-index: 100;
}

.context-menu {
	pointer-events: all;
	cursor: default;
}

.context-menu,
.context-submenu {
	position: absolute;
	z-index: 100;
	padding: 0.4em 0;
	font-size: 13px;
	background: var(--input-bg);
	box-shadow: var(--mq-box-shadow);
	border: 1px solid var(--editor-menu-border-color);
	border-radius: calc(var(--inspector-border-radius) * 2);
	min-width: 18em;
	top: 100%;
	left: 0;
}

.context-menu.wrapped,
.context-submenu.wrapped {
	position: static;
}

.context-menu.right-side,
.context-menu-wrapper.right-side {
	left: auto;
	right: 0;
}

.context-menu.flexible,
.context-submenu.flexible {
	width: auto;
	min-width: 10em;
}

.context-menu.link-dropdown {
	width: auto;
	min-width: 0;
	left: 0;
	top: 100%;
	text-align: left;
	white-space: nowrap;
}

.context-menu a {
	color: var(--editor-text-color);
	text-decoration: none;
}

.context-menu-item {
	display: block;
	position: relative;
}

.context-menu-item.delete-permanently,
.mq-icon.delete-permanently {
	fill: #e34429;
	color: #e34429;
}

.context-menu-item-undoable .context-menu-item-title {
	color: red !important;
}

.context-menu-item .icon {
	display: table-cell;
	width: 1.5em;
}

.context-menu-item .mq-icon {
	/*margin-right: 0.25em;*/
}

.context-menu-item-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0.3em 1em;
	height: 24px;
	box-sizing: border-box;
	font-size: 1em;
}

.context-menu-item-title.checked:before {
	content: '✓';
}

.context-menu-item:hover > .context-menu-item-title {
	color: var(--editor-active-bg);
	background: var(--editor-active-color);
	--symbol-text-color: var(--editor-active-bg);
	--editor-icon-color-tone: var(--editor-active-bg);
}

.context-menu-item:hover > .context-menu-item-title:not(.disabled) .mq-icon,
.context-menu-item:hover > .context-menu-item-title:not([disabled]) .mq-icon {
	/*filter: invert(calc(1 - var(--font-preview-invert)));*/
}

.context-menu-item-title.disabled,
.context-menu-item-title[disabled],
.context-menu-item:hover > .context-menu-item-title.disabled,
.context-menu-item:hover > .context-menu-item-title[disabled] {
	pointer-events: none;
	background: transparent;
	color: var(--editor-text-muted-color);
	--symbol-text-color: var(--editor-text-muted-color);
	--editor-icon-color-tone: var(--editor-text-muted-color);
}

.context-menu-item:hover > .context-menu-item-title:not(.disabled) .shortcut,
.context-menu-item:hover > .context-menu-item-title:not([disabled]) .shortcut {
	color: var(--editor-active-bg);
}

.context-menu-item:hover > .context-menu-item-title.disabled .shortcut,
.context-menu-item:hover > .context-menu-item-title[disabled] .shortcut {
	pointer-events: none;
	color: var(--editor-text-muted-color);
}

.context-menu-item-title .label {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 7px;
	white-space: nowrap;
}

.context-menu-item-title .shortcut {
	display: table-cell;
	text-align: right;
	color: var(--editor-text-muted-color);
	font-size: 0.9em;
	padding-left: 1em;
}

.context-menu-item-title .submenu-marker {
	display: table-cell;
	text-align: right;
	font-size: 0.9em;
}

.context-menu .separator {
	display: block;
	height: 1px;
	background: var(--editor-menu-border-color);
	margin: 0.2em 0;
}

.context-menu .context-menu-wrapper {
	top: 0;
	left: 100%;
	visibility: hidden;
	transition: visibility 0.3s ease;
}

.context-menu-item:hover .context-menu-wrapper {
	visibility: visible;
}

.context-menu-wrapper.right,
.context-menu.right {
	left: auto !important;
	right: 0;
}

.context-menu-wrapper.bottom,
.context-menu.bottom,
.context-submenu.bottom {
	top: auto !important;
	bottom: 0 !important;
}

.context-menu.right .context-submenu,
.context-menu.submenu-right .context-menu-wrapper {
	left: -100%;
}

.context-menu.bottom .context-submenu {
	bottom: 0;
	top: auto !important;
}

.context-menu.scroll-vertical .context-submenu {
	position: fixed;
	top: auto !important;
}

.context-menu-badge {
	font-size: 0.8em;
	display: inline-block;
	background-color: #e0efff;
	border-radius: 30px;
	padding: 0 0.5em;
	color: #333333;
	/* float: right; */
	position: absolute;
	right: 10px;
	bottom: 5px;
}

.context-menu--components .mq-icon {
	width: 1.25em;
	height: 1.25em;
}

/* =============================================================================
   New contextmenu styles
   ========================================================================== */

.contextmenu__item__title {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.contextmenu__item .contextmenu__item__title .stage__contextmenu__label .mq-icon {
	margin-right: 0.25em;
}

.contextmenu__item:hover .contextmenu__item__title .stage__contextmenu__label .mq-icon {
	filter: invert(calc(1 - var(--font-preview-invert)));
}

.contextmenu__item__title .shortcut {
	color: var(--editor-text-muted-color);
	font-size: 0.9em;
	padding-left: 1em;
}

.contextmenu__item:hover .contextmenu__item__title .shortcut {
	color: var(--editor-active-bg);
}

/* Overwrite the FloatingMenu.css */

.contextmenu,
.contextmenu__submenu {
	color: var(--editor-text-color);
	background: var(--input-bg);
	box-shadow: var(--mq-box-shadow);
	border: 1px solid var(--editor-menu-border-color);
	border-radius: calc(var(--inspector-border-radius) * 2);
	font-size: 13px;
	padding: 0.4em 0;
}

.contextmenu:not(.contextmenu--scrollable) {
	overflow: hidden;
}

.contextmenu__separator {
	background: var(--editor-menu-border-color);
}

.contextmenu__item__label {
	align-items: center;
	min-height: 24px;
	font-size: 13px;
}

.contextmenu__item {
	color: var(--editor-text-color);
}

.contextmenu__item__description {
	color: var(--editor-text-muted-color);
}
.contextmenu__item:hover > .contextmenu__item__label,
.contextmenu__item--submenuactive > .contextmenu__item__label,
.contextmenu__item:hover > .contextmenu__item__description {
	color: var(--editor-active-bg);
	background: var(--editor-active-color);
	--unread-comment-color: var(--editor-active-bg);
	--unread-comment-text-color: var(--editor-active-color);
	--new-accent: var(--editor-active-bg);
	--new-text-color: var(--editor-active-color);
}

.contextmenu__item--disabled .contextmenu__item__label {
	color: var(--editor-text-muted-color);
	opacity: 0.75;
}

.contextmenu__item:hover svg {
	fill: var(--editor-active-bg);
}

.contextmenu__item__badge {
	background: var(--info-bg);
	color: currentColor;
	position: static;
}

.contextmenu__item-no-hover.contextmenu__item:hover > .contextmenu__item__label,
.contextmenu__item-no-hover .contextmenu__item--submenuactive > .contextmenu__item__label {
	color: var(--editor-text-color);
	background: var(--input-bg);
}
.contextmenu__item-no-hover.context-menu-item:hover > .context-menu-item-title {
	color: var(--editor-text-color);
	background: var(--input-bg);
}
