.keyboard-shortcuts__modal {
}

.keyboard-shortcuts__modal .mq-modal-wrapper {
	border-radius: var(--modal-border-radius);
	max-height: 650px;
	width: 1020px;
	overflow: scroll;
	overflow-x: hidden;
}

.keyboard-shortcuts__group_column {
	display: flex;
	flex-direction: column;
	row-gap: 30px;
	flex-wrap: wrap;
}

.keyboard-shortcuts__group_grid {
	display: flex;
	flex-direction: row;
	row-gap: 30px;
	column-gap: 48px;
	flex-wrap: wrap;
	padding-top: 20px;
}
