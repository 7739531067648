.mq-throbber {
	text-align: center;
	padding: 2em;
}

.mq-throbber svg {
	fill: #006ce5;
}

.mq-throbber span {
	font-size: 1.4em;
	color: #999;
	display: block;
	margin: 0.5em 0;
	font-weight: 300;
}
