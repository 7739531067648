.connector-handle {
	stroke: var(--normal-stroke);
	fill: var(--mq-white);
	stroke-width: 1;
	display: none;
}

.connector-handle--visible {
	display: block;
}
