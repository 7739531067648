/* Legacy CSS? */

.keeppermissions__heading {
	color: #606060;
	font-weight: bold;
	font-size: 14px;
	margin: 0.5em 0;
}

.keeppermissions__secondary {
	font-size: 0.85em;
	color: #929292;
}
