.must-hsv {
	user-select: none;
}

.must-hsv .must-slider {
	height: 1rem;
}

.must-pad--spectrum {
	/* Add a different aspect ratio. */
	padding-bottom: 75%;
	/* Render HSV spectrum for `--hue` value */
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)),
		linear-gradient(to right, hsl(var(--hue, 0), 100%, 100%), hsl(var(--hue, 0), 100%, 50%));
	/* The hue to display */
	--hue: 0;
}

.must-slider--hue {
	/*
		Render rainbow gradient
	 */
	background: linear-gradient(
		to right,
		hsl(0, 100%, 50%),
		hsl(60, 100%, 50%),
		hsl(120, 100%, 50%),
		hsl(180, 100%, 50%),
		hsl(240, 100%, 50%),
		hsl(300, 100%, 50%),
		hsl(360, 100%, 50%)
	);
}

.must-slider--opacity {
	background: linear-gradient(to right, var(--color-start), var(--color-end)),
		var(--must-bg-checkerboard);
	background-size: auto, 0.6875rem;
	--color-start: rgb(0, 0, 0, 0);
	--color-end: rgb(0, 0, 0, 1);
}
