.link__sharing {
	margin-top: 5px;
	color: var(--editor-text-color);
	cursor: pointer;
}

.link__sharing > div:first-of-type {
	background-color: var(--editor-bg);
	border: 1px solid var(--input-border-color);
	border-top-left-radius: var(--inspector-border-radius);
	border-top-right-radius: var(--inspector-border-radius);
	position: relative;
}

.link__sharing > div:first-of-type > svg {
	position: absolute;
	top: 50%;
	right: 12px;
	transform: translate(0, -50%);
	width: 12px;
	height: 12px;
	pointer-events: none;
}

.link__sharing > div:last-of-type {
	border-left: 1px solid var(--input-border-color);
	border-right: 1px solid var(--input-border-color);
	border-bottom: 1px solid var(--input-border-color);
	border-bottom-left-radius: var(--inspector-border-radius);
	border-bottom-right-radius: var(--inspector-border-radius);
	background: var(--input-bg);
}

.link__sharing > div:last-of-type > *:first-child {
	border-bottom-left-radius: var(--inspector-border-radius);
	border-bottom-right-radius: var(--inspector-border-radius);
}

.link__sharing input {
	padding: 0.8em;
	flex-grow: 1;
	z-index: 20;
	border: none;
	color: var(--input-text-muted-color);
	background: var(--input-bg);
}

.link__sharing .copy__link {
	display: flex;
	margin: 4px;
	height: 22px;
	border-radius: var(--inspector-border-radius);
	align-items: center;
	justify-content: center;
	padding: 0.4em 1.2em;
	font-weight: bold;
	color: var(--button-primary-text-color);
	background-color: var(--button-primary-bg);
	min-width: 76px;
	font-size: 14px;
}

.link__sharing .copy__link:hover {
	background-color: var(--button-primary-hover-color);
}

.link__sharing .copy__link svg {
	margin-right: 0.4em;
}

.link__sharing .copy__link .mq-icon {
	fill: var(--button-primary-text-color);
	color: var(--button-primary-text-color);
}

.link__sharing__options {
	padding-top: 0.5em;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-size: 13px;
	color: var(--editor-text-color);
}

.link__sharing__options label {
	padding-top: 0.5em;
	display: flex;
	align-items: center;
}

.link__sharing__options span {
	margin-left: 0.5em;
}

.link__sharing__privacy {
	min-width: 320px;
	max-width: 100vw;
	flex-grow: 1;
}

.sharing__privacy__label {
	display: flex;
	padding-right: 0.9em;
	overflow: hidden;
	align-items: center;
	flex-grow: 1;
}

.dropdown__menu .sharing__privacy__label svg {
	padding: 0;
	padding-right: 0.45em;
	width: 16px;
	height: 16px;
}

.sharing__privacy__label span {
	max-width: 90%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 13px;
}

.sharing__privacy__label--selected svg {
	padding: 0 0.45em;
}

.dropdown__menu__option--selected .sharing__privacy__label {
	font-weight: bold;
}

.link__sharing__privacy__options {
	display: flex;
	flex-grow: 1;
}

.link__sharing__privacy__options--disabled,
.link__sharing__privacy__options.dropdown__menu__dropdown {
	padding: 0.8em 2.4em 0.8em 0.6em;
	overflow: hidden;
	display: flex;
}

.link__sharing__privacy__options.dropdown__menu__dropdown > div {
	max-width: 100%;
}

.link__sharing__privacy__options.dropdown__menu__dropdown:hover {
	border: 1px solid transparent;
}

.link__sharing--disabled .link__sharing__privacy__options {
	opacity: 0.5;
	cursor: default;
}

.link__sharing__privacy__options svg:first-child {
	color: var(--link-color) !important;
	width: var(--inspector-icon-size);
	height: var(--inspector-icon-size);
	margin-right: 0.6em;
	padding: 0;
}

.link__sharing__privacy__options svg:last-child {
	margin-left: 0.25em;
}

@media (--phone) {
	.link__sharing > div:last-of-type {
		flex-direction: column;
	}
}
