.import__templates_list {
	padding-top: 0.25em;
}
/*
	Responsiveness
	--------------
 */
@media (--tablet) {
	.import__templates_list {
		grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
	}
}

@media (--phone) {
	.import__templates_list {
		grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
	}
}
