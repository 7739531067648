.letter__avatar {
	text-align: center;
	line-height: 2em;
	min-width: 2em;
	max-width: 2em;
	height: 2em;
	display: inline-block;
	border-radius: 50%;
	margin-right: 0.75em;
	color: white;
	background-color: #007ee5;
}
