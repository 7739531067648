.zoom__bar {
	margin-right: 0.5em;
}

.zoom__bar__level {
	width: 3.5em;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.8em;
	font-weight: bold;
	padding: 0;
}

.zoom__bar__level--fit {
	color: #006ce5;
}

@media (--tablet) {
	.zoom__bar button:last-child,
	.zoom__bar .ft__button__separator {
		display: none;
	}
	.zoom__bar button:nth-child(3) {
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
		display: block;
	}
}

@media (--phone) {
	.zoom__bar__level {
		display: none;
	}
}
