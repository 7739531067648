.modal__warning {
	padding: 1em;
	margin: 0.5em 0;
	background-color: var(--warning-bg);
	color: var(--warning-text-color);
	border-radius: var(--inspector-border-radius);
}

.modal__warning svg {
	height: 1.5em;
	width: 1.5em;
	padding-right: 1em;
	fill: var(--warning-text-color);
}
