.soft-selected .font-family-item.fontitem-preview img {
	filter: invert(calc(1 - var(--font-preview-invert)));
}

.font-family-item.fontitem-preview img {
	transform: translateY(-10%);
}

.font-family-dropdown {
	margin: 0 0.5em;
	vertical-align: middle;
}

.fontitem-preview img {
	padding-left: 11px;
	filter: invert(var(--font-preview-invert));
}

.fontitem-preview {
	display: inline-block;
	width: 200px;
	height: 24px;
	line-height: 24px;
	background-size: auto 30px;
	background-position: 11px 0;
	background-repeat: no-repeat;
	font-size: 1.2em;
}

.fontitem-preview.text-view {
	padding-left: 15px;
}

.fontitem-preview-system,
.fontitem-preview-custom {
	padding-left: 16px;
}

.font-categories-select {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0 6px;
	height: 26px;
	margin: -2px -7px 0;
	border-radius: var(--inspector-border-radius);
	color: var(--editor-text-color);
	transition: background-color 0.1s ease;
}

.font-categories-select:hover {
	background-color: var(--editor-menu-hover-color);
	cursor: pointer;
}

.font-categories-select .mq-icon:first-child {
	width: 12px;
	height: 16px;
}

.font-categories-select .mq-icon:last-child {
	width: 8px;
}

.font-categories-select:hover .mq-icon:last-child {
}

.font-categories-select span {
	flex-grow: 4;
	margin-left: 9px;
	font-size: 12px;
	height: 16px;
}

.font-categories-select em {
	color: var(--editor-text-muted-color);
	font-style: normal;
}

.font-categories-menu {
	min-width: 10rem;
}
