.vertical-icon-menu {
	text-align: center;
	color: var(--editor-text-color);
	height: 100%;
	/*text-transform: uppercase;*/
	position: relative;
	width: 54px;
	/*font-weight: 500;*/
	background: var(--editor-bg);
}

.vertical-icon-menu__label {
	font-size: 9px;
	letter-spacing: -0.02em;
}

.vertical-icon-menu > li {
	display: block;
	padding: 0;
	position: relative;
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
	/*border-bottom: 1px solid #bbb;*/
	/*transition: all 0.2s;*/
}

.vertical-icon-menu > .active {
	background: var(--editor-menu-bg);
	color: var(--editor-active-color);
	border-color: var(--editor-border-color);
	/*box-shadow: 0px 1px 2px rgba(0,0,0,0.3);*/
}

/* Anti-OCD for @emiltamas */
.vertical-icon-menu > li:first-child {
	border-top: none;
}

.vertical-icon-menu > li > button {
	display: block;
	color: inherit;
	padding: 7px 0 5px;
	text-decoration: none;
	line-height: 1;
	background: none;
	border: none;
	width: 54px;
}

.vertical-icon-menu > li > button:hover {
	cursor: pointer;
}

.vertical-icon-menu > li > button > span {
	display: inline-block;
	width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 10px;
	min-height: 11px; /* safari cutting out fonts fix */
	margin-top: 2px;
}

.vertical-icon-menu > li > button > .comment-unread-sidebar-badge {
	width: auto;
	min-width: 1em;
	max-width: 2em;
	height: 1em;
}

.vertical-icon-menu .mq-icon {
	display: block;
	margin: 0 auto 2px;
	padding: 0;
	width: 24px;
	height: 24px;
	stroke: none;
	color: var(--editor-icon-color);
	fill: var(--editor-icon-color);
	transition: color 0.2s var(--mq-transition), fill 0.2s var(--mq-transition);
}

.vertical-icon-menu > .active .mq-icon {
	color: var(--editor-active-color);
	fill: var(--editor-active-color);
}

.vertical-icon-menu > li:not(.active) > button:hover,
.vertical-icon-menu > li:not(.active) > button:hover .mq-icon {
	/*color: var(--editor-active-color);
	fill: var(--editor-active-color);*/
}

.vertical-icon-menu > .active:after {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 100%;
	width: 1px;
	background: var(--editor-menu-bg);
	z-index: 2;
	content: '';
}
