.welcome__container .mq-modal {
	color: var(--editor-text-color);
	padding-top: 2vh;
	align-items: center;
}

.welcome__modal {
	height: 90vh;
	min-height: 365px;
	max-height: 800px;
	position: relative;
	max-width: 800px;
	width: 800px; /* Force width so scrollbar doesn't alter it */
	min-width: 600px;
}

.welcome__modal--has-sidebar {
	max-width: 1080px;
	width: 1080px; /* Force width so scrollbar doesn't alter it */
	min-width: 600px;
}

.welcome__modal > div:first-of-type {
	border-top-left-radius: var(--modal-border-radius);
	border-bottom-left-radius: var(--modal-border-radius);
}

.welcome__modal > div:last-of-type {
	border-top-right-radius: var(--modal-border-radius);
	border-bottom-right-radius: var(--modal-border-radius);
}

/*
	Welcome Screen: Sidebar
	-----------------------
 */

.welcome__sidebar {
	padding: calc(var(--welcome-padding-y) * 1.5) 0 var(--welcome-padding-y);
	background-color: var(--modal-bg);
	display: flex;
	flex-direction: column;
	flex: 18em 0 0;
}

.welcome__sidebar:focus {
	outline: none;
}

.welcome__sidebar h2 {
	font-family: var(--mq-font-display);
	color: var(--editor-header-color);
	font-size: 16px;
	font-weight: 700;
	margin: 0 0 1em var(--welcome-padding-x);
}

.welcome__sidebar h2 .mq-icon {
	width: 28px;
	height: 28px;
	margin: -4px 12px -4px -8px;
}

.recent-projects {
	color: var(--editor-text-color);
	overflow-y: auto;
	padding: 0.7em calc(var(--welcome-padding-x) / 2);
}

.recent-projects-title {
	overflow: visible;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.welcome__intro {
	padding: 0 var(--welcome-padding-x);
	color: var(--editor-text-muted-color);
}

.welcome__intro h1 {
	font-weight: 700;
	font-size: 1.75em;
	margin: 1.5em 0 1.2em;
	line-height: 1.2;
	color: var(--editor-header-color);
}

.welcome__logo {
	font-size: 3.5em;
}

.welcome__logo .mq-icon {
	display: block;
}

.welcome__dashboard-link {
	padding-top: 1.5em;
	margin: 0 calc(var(--welcome-padding-x) / 2) 0;
}

.welcome__dashboard-link a {
	padding: 1.25em 0.5em;
	width: 100%;
	white-space: nowrap;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5em;
}

.welcome__links {
	padding: var(--welcome-padding-y) var(--welcome-padding-x) 0;
	color: var(--editor-text-muted-color);
	font-size: 0.9em;
}

.welcome__links a {
	color: inherit;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0.4em 0;
	transition: color 0.1s var(--mq-transition);
}

.welcome__links a:hover,
.welcome__links a:focus {
	text-decoration: none;
	color: var(--editor-text-color);
}

.welcome__links .mq-icon {
	fill: currentColor;
	margin-right: 0.4em;
	position: relative;
	right: 2px;
	overflow: visible;
	font-size: 16px;
	vertical-align: text-bottom;
}

/*
	Welcome Screen: Main
	--------------------
 */

.welcome__main {
	background-color: var(--welcome-bg);
	border-top-right-radius: var(--modal-border-radius);
	border-bottom-right-radius: var(--modal-border-radius);
}

.welcome__main h2 {
	font-family: var(--mq-font-display);
	color: var(--editor-header-color);
	font-size: 19px;
	font-weight: 700;
	margin: 0;
	padding: calc(var(--welcome-padding-y) * 1.5 - 3px) var(--welcome-padding-x)
		var(--welcome-padding-y);
}

.welcome__first__project {
	padding: calc(var(--welcome-padding-y) * 1.5) var(--welcome-padding-x) var(--welcome-padding-y);
}

.welcome__first__project h2 {
	padding: 0;
}

.welcome__first__project span {
	color: var(--editor-text-muted-color);
}

.welcome__project__list__wrapper {
	overflow-y: auto;
	overflow-x: hidden;
}

.welcome__project__list {
	overflow: visible;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	gap: 20px;
	margin: 4px var(--welcome-padding-x);
	padding-bottom: var(--welcome-padding-y);
}

.welcome__loading {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

/*
	Close button
	------------

	TODO: this should be a generic component!
 */

.welcome__close-btn {
	appearance: none;
	font-family: inherit;
	font-size: 14px;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	color: inherit;

	box-sizing: border-box;
	border: 2px solid transparent;
	height: 40px;
	width: 40px;
	border-radius: 40px;

	/* positioning */
	position: absolute;
	z-index: 10;
	top: calc((var(--welcome-padding-y) * 1.5) - 8px);
	right: calc((var(--welcome-padding-y) * 1.5) - 8px);
	transition: background-color 0.2s var(--mq-transition);
}

.welcome__close-btn:hover {
	color: var(--editor-active-color);
	background: var(--editor-highlight-bg);
	cursor: pointer;
}

.welcome__close-btn:focus {
	border-color: var(--editor-semiselection-bg);
	outline: none;
}

.welcome__close-btn::-moz-focus-inner {
	border: none;
}

.welcome__close-btn .mq-icon {
	width: 14px;
	height: 14px;
}

.welcome__g--news {
	color: var(--mq-orange-500);
}
.welcome__g--news svg {
	color: var(--mq-orange-500);
}

/*
	Shared styles
*/
.welcome__g--underline {
	white-space: nowrap;
}

.welcome__g--underline:focus,
.welcome__g--underline:hover,
.welcome__g--underline:active {
	text-decoration: underline;
}

/*
	Search Box
*/

.welcome__modal .mq-searchbox {
	position: absolute;
	right: 6em;
	top: calc((var(--welcome-padding-y) * 1.5) - 8px);
}

.welcome__modal .mq-searchbox.searchbox__shifted {
	right: var(--welcome-padding-x);
}

.welcome__modal .mq-searchbox .mq-icon:first-child {
	transform: translate(0, -50%) scaleX(-1);
	width: 1.25em;
	height: 1.25em;
	left: 0.75em;
}

.welcome__modal .mq-searchbox .clear {
	right: 0.5em;
}

.welcome__modal .mq-searchbox input {
	background: var(--editor-menu-bg);
	box-sizing: border-box;
	width: 14em;
	line-height: inherit;
	font-family: inherit;
	font-size: 14px;
	border: 1px solid transparent;
	display: block;
	border-radius: 4em;
	padding: 0.25em 1.75em 0.25em 2.45em;
	height: 40px;
	color: var(--editor-text-color);
	transition: 0.1s width ease-in-out;
}

.welcome__modal .mq-searchbox input:focus {
	width: 20em;
	border-color: transparent;
	background: var(--editor-bg);
	box-shadow: 0px 0px 0px 1px var(--editor-active-color);
}

.welcome__modal .no-results {
	color: var(--editor-text-color);
	font-weight: bold;
	font-size: 18px;
	text-align: center;
	margin: 4em;
	grid-column: 1/-1;
}

/*
	Responsiveness
	--------------
 */
@media only screen and (min-width: 1366px) {
}

@media (--tablet-large) {
	.mq-modal-wrapper {
		max-width: 100%;
	}
	.welcome__modal--has-sidebar {
		max-width: 960px;
		width: auto;
	}
	.welcome__modal {
		width: auto;
		min-width: 0;
	}
	.welcome__project__list {
		grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	}
}

@media (--tablet) {
	.welcome__sidebar {
		flex: 15em 0 0;
	}

	.recent-projects-title .mq-icon {
		display: none;
	}

	.recent-projects {
		padding-top: 0;
	}

	.welcome__sidebar h2 {
		font-size: 17px;
	}

	.welcome__logo .mq-icon {
		width: 0.75em;
		height: 0.75em;
	}

	.welcome__intro {
		padding-left: 2.5em;
	}

	.welcome__intro h1 {
		font-size: 20px;
		margin: 1.25em 0;
	}

	.welcome__main h2 {
		font-size: 17px;
		padding: calc(var(--welcome-padding-y) * 1.5) var(--welcome-padding-x)
			var(--welcome-padding-y);
	}
}

@media (--phone) {
	.welcome__container .mq-modal {
		padding-top: 0;
		padding-bottom: 0;
		--welcome-padding-x: 30px;
		--welcome-padding-y: 15px;
	}

	.welcome__modal--has-sidebar {
		max-width: none;
		width: auto;
	}

	.welcome__links {
		display: none;
	}

	.welcome__intro {
		padding-left: 2em;
		padding-top: 0.5em;
	}

	.welcome__project__list {
		grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
	}

	.welcome__modal .mq-searchbox input:not(:focus) {
		width: 40px;
		padding: 0;
	}

	.welcome__modal .mq-searchbox input::placeholder {
		color: transparent;
	}

	.welcome__modal .mq-searchbox input:focus {
	}

	.welcome__dashboard-link a {
		border-radius: 20px;
	}
}

@media (--phone-small) {
	.welcome__modal--has-sidebar {
		flex-direction: column-reverse;
	}

	.welcome__main {
		border-top-right-radius: var(--modal-border-radius);
		border-top-left-radius: var(--modal-border-radius);
		border-bottom-right-radius: 0;
	}

	.welcome__modal .mq-searchbox {
		right: 4.5em;
	}

	.welcome__sidebar {
		border-bottom-right-radius: var(--modal-border-radius);
		border-top-left-radius: 0;
		max-height: 15em;
		position: relative;
	}

	.welcome__sidebar--intro {
		flex-basis: 3em;
		padding: 1.5em 0;
		justify-content: center;
	}

	.welcome__sidebar--intro .welcome__dashboard-link {
		padding-top: 0;
	}

	.welcome__intro {
		display: none;
	}

	.welcome__sidebar:after {
		content: '';
		pointer-events: none;
		position: absolute;
		top: -29px;
		left: 0;
		right: 0;
		z-index: 2;
		height: 30px;
		background: transparent;
		background: linear-gradient(0deg, var(--welcome-bg) 0%, transparent 100%);
	}
}

@media screen and (max-height: 540px) {
	.welcome__links {
		display: none;
	}
}
