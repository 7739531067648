.project__template {
	position: relative;
	display: flex;
	flex-direction: column;
	border-radius: calc(var(--welcome-template-border-radius) + 1px);
	box-sizing: border-box;
	box-shadow: 0 2px 3px 1px rgba(41, 50, 61, 0.08);
	text-decoration: none !important;
	min-width: 100px;
	max-width: 360px;
	/*background: var(--mq-white);*/
}

.project__template img {
	max-width: 100%;
	min-width: 100%;
	object-fit: cover;
	height: calc(100% - 38px);
	object-position: top;
}

.project__template:after {
	content: '';
	display: block;
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
	border-radius: calc(var(--welcome-template-border-radius) + 1px);
	border: 2px solid transparent;
	pointer-events: none;
	transition: border-color 0.1s ease-out;
	z-index: 23;
}

.project__template__overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	background: var(--editor-active-color);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity 0.1s ease-out;
	z-index: 23;
	border-radius: var(--welcome-template-border-radius);
}

.project__template__overlay button {
	border-radius: 6px;
	font-size: 13px;
	padding-left: 16px;
	padding-right: 16px;
}

/* Normal border on hover would change element size */
.project__template:hover .project__template__overlay,
.project__template:focus-within .project__template__overlay,
.project__template__overlay--importing {
	opacity: 0.3;
}

.project__template:hover:after,
.project__template:focus-within:after {
	border-color: var(--editor-active-color);
}

.project__template__preview {
	height: 220px;
	border-radius: var(--welcome-template-border-radius);
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	background-color: var(--editor-menu-bg);
}

.project__template__preview > div {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--editor-menu-bg);
	border-radius: var(--welcome-template-border-radius);
}

.project__template__preview svg {
	font-size: 3em;
}

.project__template__tags {
	position: absolute;
	top: 6px;
	right: 6px;
	z-index: 100;
	display: flex;
	gap: 0.25rem;
}

.project__template__tags .mq-icon {
	margin-right: 0.25rem;
	margin-bottom: -1px;
}

.project__template__tag {
	user-select: none;
	display: inline-block;
	background: var(--new-accent);
	border-radius: 0.25rem;
	font-size: 0.625rem;
	font-weight: 600;
	line-height: 1;
	text-transform: uppercase;
	padding: 0.25rem 0.313rem;
	color: var(--new-text-color);
}

.project__template__tag:before,
.project__template__tag:after {
	text-transform: none;
	text-align: center;
}

.project__template__tag.is-premium {
	background: var(--premium-accent);
	color: var(--premium-text-color);
}

.project__template__title {
	padding: 0.8em 1em;
	text-align: left;
	font-size: 13px;
	font-weight: bold;
	color: var(--editor-text-color);
	background-color: var(--editor-menu-bg);
	border-bottom-right-radius: calc(var(--welcome-template-border-radius) - 1px);
	border-bottom-left-radius: calc(var(--welcome-template-border-radius) - 1px);
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 22;
}

.project__template__title:after {
	position: absolute;
	bottom: calc(100% - 1px);
	left: 0;
	right: 0;
	height: 30px;
	content: '';
	background: linear-gradient(
		0deg,
		/*smooth gradient based on https://css-tricks.com/easing-linear-gradients/ */
			hsl(0, 100%, 100%) 0%,
		hsla(0, 100%, 100%, 0.738) 19%,
		hsla(0, 100%, 100%, 0.541) 34%,
		hsla(0, 100%, 100%, 0.382) 47%,
		hsla(0, 100%, 100%, 0.278) 56.5%,
		hsla(0, 100%, 100%, 0.194) 65%,
		hsla(0, 100%, 100%, 0.126) 73%,
		hsla(0, 100%, 100%, 0.075) 80.2%,
		hsla(0, 100%, 100%, 0.042) 86.1%,
		hsla(0, 100%, 100%, 0.021) 91%,
		hsla(0, 100%, 100%, 0) 95.2%,
		hsla(0, 100%, 100%, 0.002) 98.2%,
		hsla(0, 100%, 100%, 0) 100%
	);
}

html[data-theme='dark'] .project__template__title:after {
	background: none;
}

.project__template:hover .project__template__title {
	color: var(--editor-active-color);
}

.project__template .mq-throbber {
	padding: 0;
}

.project__template .mq-throbber svg {
	fill: #fff;
}

.project__template__action {
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	appearance: none;
	border: none;
	background: transparent;
	cursor: pointer;
}

.project__template__action:focus {
	outline: none;
}

/*
	Responsiveness
 */
@media (--tablet) {
	.project__template {
		/*width: calc(50% - 20px);*/
	}
}

@media (--phone) {
	.project__template__preview {
		height: 160px;
	}
}
