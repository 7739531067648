.transform-border {
	stroke: var(--normal-stroke);
	stroke-width: 1px;
	fill: none;
	pointer-events: none;
}

.transform-border--locked {
	stroke: var(--lock-stroke) !important;
}

.transform-border--hidden {
	display: none;
}

/* Image Crop Styles */

.transform-border--imagecrop {
	stroke: var(--mq-black);
	stroke-dasharray: 0, 2, 0;
}

/* Symbol styles */
.transform-border--symbol {
	stroke: var(--symbol-accent);
}
