.disable-pointer-events {
	pointer-events: none;
}

.disable-pointer-events .stencil--easier-to-select {
	pointer-events: none;
}

.disable-pointer-events .stencil__selection-helper {
	pointer-events: none;
}

.stencil.disable-pointer-events {
	/*opacity: 0.25;*/
}

.is-transforming {
	image-rendering: pixelated;
}

/*
	This class makes stencils easier to select by catching pointer events
	on all the painted area of the SVG, even the invisible parts.
	Useful for icons (which may have hollow, unselectable parts).
	Note: Not applied if the stencil is a descendant of a .drag-preview element,
	which has pointer-events:none to enable dropping stencils on stage.
*/
.stencil--easier-to-select {
	pointer-events: painted;
}

.drag-preview .stencil--easier-to-select,
.master-page .stencil--easier-to-select {
	/* Re: https://github.com/Evercoder/new-engine/issues/4552 */
	pointer-events: inherit;
}

.isolated-group-border {
	stroke: var(--normal-stroke);
	stroke-width: 4;
	shape-rendering: crispEdges;
	fill: none;
	pointer-events: none;
	stroke-opacity: 0.25;
}

.isolated-group-border--symbol {
	stroke: var(--symbol-accent);
}

.isolated-group-border--locked {
	stroke: var(--lock-stroke);
}

/* Patches for content editable on Firefox and Safari.
*/

.patch-contenteditable {
	user-select: text !important;
	position: absolute;
	z-index: 1000;
	overflow: hidden;
	transform-origin: 0 0;
}

.patch-contenteditable > div {
	overflow: hidden;
	outline: none;
	box-shadow: var(--normal-stroke) 0px 0px 1px inset;
}

.patch-contenteditable--clean > div {
	box-shadow: none;
}

/*
	When editing the text content of an object,
	don't make the links look like they're clickable
	because they're not.
 */
.state--typing .stencil a,
.state--typing .patch-contenteditable a {
	cursor: inherit;
}

/* Master page layer */
.master-page {
	pointer-events: none;
}

/* enable master page links in viewer */
#canvas-stage.view-mode .master-page a {
	pointer-events: auto;
}

/* =============================================================================
   Various improvements and defaults
   ========================================================================== */

/*
 * Global styles for hyperlinks.
 */

a {
	color: var(--link-color);
	text-decoration: none;
}

a:focus {
	outline: 0;
}

a:hover,
a:active {
	outline: 0;
	text-decoration: underline;
	cursor: pointer;
}

/*
 * Setting global font family, size and color.
 */

button,
input,
select,
textarea {
	font-family: inherit;
	font-size: 13px;
}

/*
 * Bold and italic.
 */

b,
strong {
	font-weight: bold;
}

i,
em {
	font-style: italic;
}

/*
 * Improves readability of pre-formatted text in all browsers
 */

pre {
	white-space: pre;
	white-space: pre-wrap;
	overflow-wrap: break-word;
}

/* =============================================================================
   Clearfix
   ========================================================================== */

.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: ' ';
	clear: both;
	height: 0;
}
/* IE6 IE7 relics
* html .clearfix {
	zoom: 1;
} 
*:first-child + html .clearfix {
	zoom: 1;
} 
*/

/* =============================================================================
   Generic styles
   ========================================================================== */

html,
body {
	height: 100%;
	width: 100%;
	font-size: 16px;
}

body {
	overflow: hidden;

	/* 
		Prevent browsers from navigating away 
		when scrolling at the edges of the page.
	*/
	overscroll-behavior: none;

	/*
		Disable double-tap-to-zoom behavior.
		It makes clicks respond faster on mobile devices.
	 */
	touch-action: manipulation;
	-webkit-tap-highlight-color: transparent; /* test on mobile safari */

	/*
		For Chinese/Japanese/Korean languages,
		don't break in the middle of the word.
		For non-CJK languages it behaves as `normal`.
	 */
	word-break: keep-all;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
	margin: 1em 0 0.5em 0;
}

h1 {
	font-size: 1.5em;
}

h2 {
	font-size: 1.4em;
}

h3 {
	font-size: 1.2em;
}

h4,
h5,
h6 {
	font-size: 1em;
}

p {
	margin: 0.5em 0;
}

hr {
	border: 0;
	color: var(--editor-border-color);
	background: var(--editor-border-color);
	height: 1px;
	padding: 0;
	margin: 1em 0;
}

[hidden] {
	display: none !important;
}

.ellipsis {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 10px 0 0;
}

.resp-img {
	max-width: 100%;
	height: auto;
	display: block;
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.scroll-vertical {
	overflow-y: auto;
}

.full-width {
	width: 100%;
}

.drop-shadow {
	box-shadow: var(--mq-box-shadow-md);
}

/* Text selection highlight color */
::selection {
	background: var(--text-selection);
}

/* Addresses textarea overflow and vertical align in all browsers. */

textarea {
	overflow: auto;
	vertical-align: top;
	resize: vertical;
}

label input[type='checkbox'],
label input[type='radio'] {
	margin: 0;
	padding: 0;
	vertical-align: baseline;
	accent-color: var(--input-accent-color);
}

input[type='text'],
input[type='password'],
input[type='number'],
input[type='email'],
select,
textarea {
	font-size: 1em;
	box-sizing: border-box;
	border: 1px solid var(--input-border-color);
	background: var(--input-bg);
	/*border:none;*/
	/*background: #ecf0f1;*/
	/*color: #7f8c8d;*/
	/*font-weight: bold;*/
	outline: none;
	padding: 0.5em;
	/*box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25) inset, 0 0 0 1px rgba(0, 0, 0, 0.15) inset;*/
	/*box-shadow: 0px 1px 1px rgba(0,0,0,0.1);*/
	color: var(--input-text-color);
	transition: border-color 0.1s;
	border-radius: 0.25em;
}

textarea {
	height: auto;
}

.textarea-compact {
	height: 40px;
	transition: all 0.1s ease-in-out;
}

.textarea-medium {
	height: 80px;
	transition: all 0.1s ease-in-out;
}

.textarea-big {
	height: 160px;
	transition: all 0.1s ease-in-out;
}

input[type='text']:hover,
input[type='number']:hover,
input[type='email']:hover,
input[type='password']:hover,
textarea:hover {
	border-color: var(--input-border-hover-color);
}

input[type='text']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='password']:focus,
textarea:focus {
	/*box-shadow: 0 0 0 1px #006ce5 inset, 0 0 3px 0 #006ce5;*/
	border-color: var(--editor-active-color);
	/*border-color: #94A9C0;*/
}

input[disabled],
select[disabled],
textarea[disabled] {
	cursor: not-allowed;
	color: #7f8c8d;
	background: #ecf0f1;
	border-color: transparent;
}

input.has-error,
select.has-error,
textarea.has-error {
	border-color: #a94442;
}

select {
	padding: 0.25em;
	border: 1px solid #aaa;
	margin: 0;
	vertical-align: top;
}

input::placeholder,
textarea::placeholder {
	color: var(--editor-text-muted-color);
	opacity: 1; /* Firefox */
}
/*
::-webkit-input-placeholder {
	color: #999 !important;
}
:-moz-placeholder {
	color: #999 !important;
}
*/
html[data-os='windows'] .scrollable,
html[data-os='windows'] .contextmenu--scrollable,
.mq-scroll {
	scrollbar-color: var(--input-border-color);
	scrollbar-width: thin;
	/*
	perspective: 1px;
	-webkit-perspective: 1px; 
	*/
}

html[data-os='windows'] .scrollable::-webkit-scrollbar,
html[data-os='windows'] .contextmenu--scrollable::-webkit-scrollbar,
.mq-scroll::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

html[data-os='windows'] .scrollable::-webkit-scrollbar-track,
html[data-os='windows'] .contextmenu--scrollable::-webkit-scrollbar-track,
.mq-scroll::-webkit-scrollbar-track,
html[data-os='windows'] .scrollable::-webkit-scrollbar-corner,
.mq-scroll::-webkit-scrollbar-corner {
	background: transparent;
}

html[data-os='windows'] .scrollable::-webkit-scrollbar-thumb,
html[data-os='windows'] .contextmenu--scrollable::-webkit-scrollbar-thumb,
.mq-scroll::-webkit-scrollbar-thumb {
	background-color: var(--input-border-color);
	border-radius: 3px;
	border: 1px solid transparent;
	box-sizing: content-box;
}

/* --------------------------------- */

/* doamne-ajută! FIXME if necessary */
[disabled] {
	opacity: 0.4 !important;
	pointer-events: none;
}
/*Hides the focus rect for content editable*/
[contenteditable='true'] {
	outline: none;
}
.editing foreignObject [contenteditable='true'] {
	box-shadow: #006ce5 0px 0px 1px inset;
}

.svg-inject {
	display: none;
}

/* SVG Styles */

.blur {
	pointer-events: none;
}
.connector-selected {
	stroke: #cce2fa;
}
.connector-selected.locked {
	stroke: #ffd2cc;
}

.connector-snap {
	fill: rgba(20, 100, 246, 0.25);
	cursor: crosshair;
}

.connector-snap:hover,
.connector-snap.active {
	stroke: var(--normal-stroke);
}

/*
	Drawing state
	-----------------------------------
 */

.drawing,
.drawing .stencil {
	cursor: crosshair;
}
