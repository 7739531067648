/*
	Welcome Screen Project Categories
	---------------------------------
 */

.project__categories {
	padding: 3px var(--welcome-padding-x) calc(var(--welcome-padding-y) / 2);
	gap: 1em;
	overflow: visible;
	transition: width 0.5s var(--mq-transition);
}

.project__categories > * {
	flex-basis: 0;
}

.project__category {
	--category-color: var(--editor-border-color);
	margin: 0;
	color: var(--editor-text-color);
	border: 0;
	box-sizing: border-box;
	font-size: 13px;
	padding: 16px 12px;
	font-weight: 600;
	text-align: center;
	overflow: visible;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	line-height: 1.25;
	word-break: break-word;
	hyphens: manual;
	background: none;
	position: relative;
	transition: color 0.1s var(--mq-transition);
}

.project__category span {
	z-index: 100;
}

.project__category:before {
	content: '';
	display: block;
	height: 4px;
	border-radius: 4px;
	position: absolute;
	overflow: hidden;
	bottom: 0;
	left: 1px;
	right: 1px;
	border: 0;
	background-color: var(--category-color);
	opacity: 0.8;
	transition: height 0.15s ease-out;
}

.project__category:focus {
	outline: none;
}

.project__category:focus:before {
	opacity: 1;
	height: 6px;
}

.project__category--selected:before,
.project__category:hover:before {
	opacity: 1;
}

.project__category--selected {
	color: var(--mq-editor-grey-900) !important;
}

.project__category--selected:focus:before,
.project__category--selected:before {
	height: 100%;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

@keyframes customCat {
	0% {
		overflow: hidden;
		padding: 0;
		flex-grow: 0.0001;
		color: transparent;
		white-space: nowrap;
		font-size: 0;
	}
	33% {
		white-space: normal;
	}
	66% {
		flex-grow: 1;
		padding: 16px 12px;
		font-size: 13px;
		color: transparent;
	}
	100% {
		overflow: visible;
		color: inherit;
	}
}

.project__category--custom {
	animation: customCat 0.5s var(--mq-transition);
	animation-fill-mode: forwards;
}

@media (--tablet) {
	.project__categories {
		overflow-y: hidden;
		overflow-x: scroll;
		gap: 0.5em;
	}

	.project__categories > * {
		flex-basis: auto;
	}

	/* on mobile animate width instead of flex-grow */
	@keyframes customCat {
		0% {
			width: 0;
			white-space: nowrap;
			overflow: hidden;
			font-size: 0;
			color: transparent;
		}
		33% {
			white-space: normal;
		}
		66% {
			width: 100px;
			font-size: 13px;
			color: transparent;
		}
		100% {
			overflow: visible;
			color: inherit;
		}
	}

	.project__category {
		width: 100px;
		flex: none;
		font-size: 13px;
	}
}

@media (--phone) {
	.project__category {
		padding-top: 10px;
		padding-bottom: 12px;
	}
}
