/* =============================================================================
   Progress bars
   ========================================================================== */

.mq-progress-bar {
	color: var(--editor-active-color);
	background: var(--editor-bg);
	height: 1em;
	border: 1px solid var(--input-border-color);
	overflow: hidden;
	border-radius: 2px;
}

.mq-progress-bar span {
	height: 100%;
	background: currentColor;
	display: block;
	transition: width 0.35s;
}

.mq-progress-bar-small {
	height: 0.5em;
}
