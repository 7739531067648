.mq-comments__filter-style-item {
	display: inline-block;
	padding: 0 calc(var(--toolbar-gap) * 1.5);
	align-items: center;
	justify-content: center;
	outline: none;
	cursor: pointer;
	background: transparent;
	border: none;
}

.mq-comments__filter .contextmenu__submenu {
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: var(--mq-box-shadow-lg);
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	border: 1px solid var(--toolbar-border-color);
	height: var(--toolbar-size);
	padding: 0 var(--toolbar-gap);
	gap: var(--toolbar-gap);
}

.mq-comments__filter-menu li {
	cursor: pointer;
}

/* move submenu marker closer to the edge of menu*/
.mq-comments__filter-menu .contextmenu__item__label:has(.contextmenu__submenu__marker) {
	padding: 0.2em 0 0.2em 1em;
}

.mq-comments__filter-style-item .contextmenu__item__label {
	min-width: 0;
	min-height: 0;
	font-size: 0;
}

.mq-comments__filter-style-item:hover > .contextmenu__item__label,
.mq-comments__filter-style-item:hover > .contextmenu__item__description {
	background: var(--editor-active-bg);
}

.mq-comments__filter-selected {
	display: flex;
	flex-direction: row;
	column-gap: 3px;
	align-items: center;
	justify-content: center;
	text-align: center;
	cursor: pointer;
	color: var(--editor-text-color);
}

.mq-comments__filter-selected:after {
	content: ' \25bc';
	transform: scaleY(0.6) translate(0, 1px);
	font-size: 0.75em;
	margin-left: 1px;
	/* color: #555; */
}

.mq-comments__filter-selected .mq-comments__filter-style-preview {
	width: 15px;
	height: 15px;
}

.mq-comments__filter-style-preview.comment-style-hexagon.shape {
	height: auto;
}

.mq-comments__filter-style-preview {
	width: 20px;
	height: 20px;
	font-size: 13px;
}

/* makes sure that the svg icon color is not affected by the
context menu hover styles */
.mq-comments__filter-style-item:hover svg {
	fill: currentcolor;
}

/* don't change background color of style preview on hover */
.mq-comments__filter-style-item:hover .contextmenu__item__label {
	background: initial;
}
