.must-swatch {
	--size: 18px;
	--radius: 2px;
	--paint: transparent;
	position: relative;
	background: var(--must-bg-checkerboard);
	background-position: center center;
	background-size: auto 50%;
	width: var(--size);
	height: var(--size);
	border-radius: var(--radius);
	overflow: hidden;
	font-family: var(--must-font-main);
}

.must-swatch::before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	content: '';
	background: var(--paint);
	border-radius: inherit;
	z-index: 1;
}

.must-swatch--opaque {
	/*
		For fully opaque solid colors, 
		don't render the checkerboard background
		underneath (should boost performance).
	 */
	background: none;
}

.must-swatch--light::before {
	/*
		Show a border around light swatches
	 */
	box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
}

/*
	Swatch: <none> color
	--------------------
 */
.must-swatch--none {
	background: var(--mq-white);
}

.must-swatch--none::before {
	background: transparent;
}

.must-swatch--none::after {
	content: ' ';
	background: var(--mq-pink-400);
	border-radius: 2px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	width: 100%;
	height: 2px;
	display: block;
	font-size: 0.813rem;
	top: calc(50% - 1px);
	left: 0;
	transform-origin: center;
	transform: rotate(45deg);
}

/*
	Swatch: indeterminate color
	---------------------------
 */
.must-swatch--indeterminate {
	background: var(--mq-grey-200);
}

.must-swatch--indeterminate::before {
	background: transparent;
}

.must-swatch--indeterminate::after {
	color: var(--mq-grey-500);
	content: '?';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	font-size: 0.813rem;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate(-50%, -50%);
	font-weight: bold;
	line-height: 1;
}

.icon-plus-swatch .must-swatch--indeterminate::after {
	content: ' ';
}
