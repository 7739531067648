.tooltip {
	opacity: 1;
}
.tooltip .rc-tooltip-inner {
	border-radius: 0;
	min-height: 20px;
	transition: 0.3s ease;
}
.tooltip--rounded .rc-tooltip-inner {
	border-radius: var(--inspector-border-radius);
}

.tooltip--white.rc-tooltip-placement-bottom .rc-tooltip-arrow {
	border-bottom-color: #ffffff;
	color: #333;
}
.tooltip--white.rc-tooltip-placement-right .rc-tooltip-arrow {
	border-right-color: #fff;
}
.tooltip--white .rc-tooltip-inner {
	background-color: #ffffff !important;
	color: #333;
}
.tooltip--red .rc-tooltip-arrow {
	border-bottom-color: #e34429;
}
.tooltip--red .rc-tooltip-inner {
	background-color: #e34429 !important;
}
.tooltip--green .rc-tooltip-arrow {
	border-bottom-color: #008200;
}
.tooltip--green .rc-tooltip-inner {
	background-color: #008200 !important;
}

.tooltip--blue .rc-tooltip-inner {
	background: #f0f8ff !important;
	color: #333;
}
.tooltip--blue .rc-tooltip-arrow {
	border-bottom-color: #f0f8ff !important;
	color: #333;
}

.tooltip--gray.rc-tooltip-placement-right .rc-tooltip-arrow {
	border-right-color: #f6f6f6;
}
.tooltip--gray .-inner {
	background-color: #f6f6f6 !important;
	border: 1px solid #d1d1d1;
	color: #aaaaaa;
}

.tooltip--noarrow .rc-tooltip-arrow {
	display: none;
}

.tooltip__children {
	display: inline-block;
	width: 100%;
}
