.sharing__avatar {
	display: flex;
	flex-shrink: 0;
	margin-right: 0.5em;
}

.sharing__avatar img {
	border-radius: 50%;
	width: 28px;
	height: 28px;
}

.sharing__avatar {
	width: 28px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-items: center;
	border-radius: 50%;
}

.sharing__avatar--team {
	border-radius: 4px;
}

.sharing__avatar--team img {
	border-radius: 25%;
}

.sharing__avatar svg {
	fill: #fff;
	width: 100%;
	height: 100%;
	margin: auto;
}

.sharing__avatar--team svg,
.sharing__avatar--suggestion svg {
	width: 50%;
	height: 50%;
}
