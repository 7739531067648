.clipboard-data-container {
	opacity: 0;
	font-size: 1px;
	white-space: pre;
	position: fixed;
	top: 0px;
	left: 200px;
	width: 50px;
	height: 50px;
	overflow: scroll;
	pointer-events: none;
	/*-webkit-pointer-events: none;*/
	-webkit-font-smoothing: none;
}
