:root {
	--must-border-radius: 2px;
	--must-box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.15);
	--must-scrollbar-width: 9px;

	--must-mq-white: #fff;
	--must-mq-black: #000;

	--must-mq-blue: #006ce5;

	--must-mq-grey-10: #f7f6f6;
	--must-mq-grey-20: #ebebeb;
	--must-mq-grey-30: #d6d6d6;
	--must-mq-grey-40: #c0c0c0;
	--must-mq-grey-50: #aaa;
	--must-mq-grey-60: #929292;
	--must-mq-grey-70: #7a7a7a;
	--must-mq-grey-90: #444;
	--must-mq-grey-100: #232323;

	--must-font-main: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif;

	--must-font-mono: monospace;
	--must-checkerboard: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAAH6ji2bAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAFKADAAQAAAABAAAAFAAAAACRFdLHAAAAmUlEQVQ4EeVTWw7AIAjDXcn730DP5KxJCR/NQjKXfcgPr1IEtbTWhk2ptdoFg1LGFDi9dzPAIA5ZaaQKGYAFC8SL4SwCBIkkCkknhEORQeckH9AayUbkg45NGZfVTEbtvWMwnoPxNGMa6FtjC+hXw6Rb7wf+uMcPhlHPLN4SbXVbzEWdPmIserIPJJQvTO1I/V6FO3CH20e+AXluTDqe7hKAAAAAAElFTkSuQmCC');
	--must-bg-checkerboard: var(--must-checkerboard) top left / 10px;
	--must-paint-none: linear-gradient(
		-45deg,
		white 0,
		white calc(50% - 1px),
		red calc(50% - 1px),
		red calc(50% + 1px),
		white calc(50% + 1px),
		white 100%
	);

	--must-transition: cubic-bezier(0.23, 1, 0.32, 1);
}

input.must-input,
.must-button {
	margin: 0;
	width: auto;
	appearance: none;
	font: inherit;
	font-family: var(--must-font-main);
	letter-spacing: inherit;
	padding: 0.5em 1em;
	box-sizing: border-box;
	border-radius: var(--must-border-radius);
	border: 1px solid;
	transition: all 0.4s var(--must-transition);
}

.must-button--bare {
	padding: 0;
	border: none;
	border-radius: 0;
	line-height: 1;
	background: transparent;
}

input.must-input {
	margin: 0;
	font-family: var(--must-font-main);
	font-size: 13px;
	padding: 3px 3px 3px 5px;
	min-height: 22px; /*28px*/
	border: 1px solid var(--must-mq-grey-30);
	border-radius: 4px;
	color: var(--must-mq-grey-100);
}

input.must-input:hover {
	border-color: var(--must-mq-grey-50);
}

input.must-input:focus {
	border-color: var(--must-mq-blue);
	outline: none;
}

/*
	Create an invisible input that stretches
	across the parent element. The parent needs 
	to be positioned (e.g. position: relative).

	Use case: overlay an invisible <input type='file'/>
	on top of a custom upload button.
 */
input.must-input--overlaid {
	position: absolute;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	font-size: 1px;
	opacity: 0;
	cursor: pointer;
}

.must-input-unit {
	position: absolute;
	top: 50%;
	right: 0.8em;
	left: auto;
	transform: translate(0, -50%);
	z-index: 10;
	font-variant: small-caps;
	font-size: 0.9em;
	pointer-events: none;
	user-select: none;
}

.must-icon {
}

/*
	Utility: screen-reader-only text
	See: https://piccalil.li/quick-tip/visually-hidden/
 */
.must-sr-only {
	border: 0;
	clip: rect(0 0 0 0);
	height: auto;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	white-space: nowrap;
}

/*
	Unobtrusive scrollbar for a container.
 */

.must-lite-sb {
	scrollbar-width: thin;
	scrollbar-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.05);
}

.must-lite-sb::-webkit-scrollbar {
	width: var(--must-scrollbar-width);
	height: var(--must-scrollbar-width);
}

.must-lite-sb::-webkit-scrollbar-track {
	background-color: transparent;
	margin: -2px 0;
}

.must-lite-sb::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.25);
	border: 2px solid rgba(0, 0, 0, 0);
	border-radius: 8px;
	background-clip: padding-box;
}
