#editArea {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	width: 200px;
	height: 100px;
	box-sizing: border-box;
	padding: 0;
	font-size: 0.875rem;
}
