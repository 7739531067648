.teamaccess__heading {
	margin: 0.5em 0;
	color: var(--editor-text-color);
	font-weight: bold;
	font-size: 14px;
}

.teamaccess__heading span {
	margin-right: 0.5em;
}

.teamaccess__switch {
	margin-top: 0.3em;
	font-size: 13px;
}

.teamaccess__secondary {
	font-size: 0.85em;
	color: var(--editor-text-muted-color);
}

.teamaccess__secondary:last-of-type {
	margin-top: 10px;
	padding-right: 32px;
}

.teamaccess__info {
	margin: 10px 0 0;
	font-size: 0.85em;
	color: var(--editor-text-muted-color);
}
.teamaccess__info svg {
	height: 1.2em;
	width: 1.2em;
	margin-right: 0.5em;
	fill: var(--link-color);
}

.teamaccess__warning {
	font-size: 0.85em;
	color: #db3b26;
}

.teamaccess__warning svg {
	fill: #db3b26;
}
