.dropdownmenu__wrapper {
	display: inline-block;
	position: relative;
}

.dropdownmenu__wrapper--full {
	width: 100%;
}

.contextmenu,
.contextmenu__submenu {
	font-size: 0.813rem;
	pointer-events: all;
	cursor: default;
	position: absolute;
	padding: 0.25rem 0;
	background: var(--toolbar-background);
	box-shadow: var(--toolbar-menu-box-shadow);
	border: 1px solid var(--mq-grey-200);
	border-radius: var(--toolbar-action-border-radius);
	min-width: 8rem;
	z-index: 6000;
	top: 100%;
	left: 0;
}

.contextmenu--scrollable {
	overflow-y: auto;
}

.contextmenu--auto-width,
.contextmenu--auto-width .contextmenu__submenu {
	min-width: auto;
}

.contextmenu--borderless,
.contextmenu--borderless .contextmenu__submenu {
	border: none;
	box-shadow: var(--toolbar-menu-box-shadow);
}

.contextmenu__submenu__marker {
	font-size: 0.8em;
}

.contextmenu--bottom {
	top: auto !important;
	bottom: 0;
}

.contextmenu--right {
	left: auto !important;
	right: 0;
}

.contextmenu__submenu {
	position: absolute;
	top: 0;
	left: 100%;
}

.contextmenu__submenu--scrollable {
	overflow-y: auto;
}

.contextmenu__item {
	position: relative; /* so submenu positions properly */
}

.contextmenu__item:hover > .contextmenu__item__label {
	color: var(--mq-white);
	background: var(--toolbar-action-active-color);
}

.contextmenu__item--submenuactive > .contextmenu__item__label {
	color: var(--mq-white);
	background: var(--toolbar-action-active-color);
}

.contextmenu__item__label {
	width: 100%;
	padding: 0.2em 1em;
	display: flex;
	box-sizing: border-box;
	font-size: 0.95em; /*Slightly smaller, but better visuals*/
}

.contextmenu__item__title {
	flex-grow: 1;
	white-space: nowrap;
}

.contextmenu__item__description {
	flex-grow: 0;
	white-space: nowrap;
	color: #999;
	font-size: 0.9em;
}

.contextmenu__item:hover > .contextmenu__item__description {
	color: #fff;
	background: var(--mq-blue);
}

.contextmenu__item__badge {
	font-size: 10px;
	display: inline-block;
	background: var(--new-accent);
	color: var(--new-text-color);
	border-radius: 30px;
	padding: 0 0.5em;
	/* float: right; */
	position: absolute;
	right: 10px;
	bottom: 5px;
}

.contextmenu__separator {
	display: block;
	height: 1px;
	background: var(--mq-grey-100);
	margin: 0.25rem 0;
}

.contextmenu__item--disabled {
	pointer-events: none;
	color: var(--toolbar-action-disabled-color);
}

.contextmenu__item--disabled .contextmenu__item__label {
	background: transparent;
	color: var(--toolbar-action-disabled-color);
	pointer-events: none;
}

.contextmenu__item.delete-permanently {
	fill: var(--toolbar-action-danger-color);
	color: var(--toolbar-action-danger-color);
}

.contextmenu__item__label .contextmenu__item__checked {
	margin-right: 2px;
}
