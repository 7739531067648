.badge {
	display: inline-block;
	vertical-align: middle;
	border-radius: 50px;
	padding: 0 8px;
	font-weight: 600;
	font-size: 0.7em;
	line-height: 1.8em;
}

.badge--premium {
	background: var(--premium-accent);
	color: var(--premium-text-color);
}

.badge--new {
	background: var(--new-accent);
	color: var(--new-text-color);
}

.badge--warning {
	background: #fce559;
	color: #262e39;
}

.badge--info {
	background: #1464f6;
	color: #ffffff;
}

.badge--free {
	background: #c0c0c0;
	color: #fff;
}

.badge--left-spaced {
	margin-left: 1em;
}

.badge--right-spaced {
	margin-right: 0.5em;
}

.badge--square {
	border-radius: 0.2em;
}

.badge--semiround {
	border-radius: 0.5em;
}

.badge--narrow {
	line-height: 1.6em;
	padding: 0 0.3em;
}

.badge--heavy {
	font-weight: bold;
}

.badge--uppercase {
	text-transform: uppercase;
}
