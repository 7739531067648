.must-color-well {
	position: relative;
	--padding: 0.125rem;
}

.must-color-well > .must-swatch {
	position: absolute;
	top: var(--padding);
	right: var(--padding);
	bottom: var(--padding);
	left: var(--padding);
	--size: auto;
}

.must-color-well > .must-svg-swatch {
	position: absolute;
	top: var(--padding);
	left: var(--padding);
	width: calc(100% - var(--padding) * 2);
	height: calc(100% - var(--padding) * 2);
}
