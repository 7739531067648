.droplist {
	position: relative;
}

.droplist a {
	text-decoration: none;
}

input.droplist-input {
	width: 100%;
	font-family: inherit;
	padding: 0.4em 2em 0.4em 0.4em;
	font-size: 1em;
	height: 2em;
	border: 1px solid #bbb;
	border-radius: 0.2em;
	background: #fff;
	color: #333;
	margin: 0; /* Safari https://github.com/Evercoder/new-engine/issues/376 */
}

.droplist-input:focus {
	outline: none;
}

.droplist-menu {
	overflow-x: hidden;
	overflow-y: auto;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-radius: 4px;
	z-index: 3;
	max-height: 400px;
	height: auto;
	padding: 0;
	list-style: none;
	margin: 0;
	top: 26px;
	position: absolute;
	z-index: 3;
	border-top: none;
	left: 0;
	right: 0;

	min-width: 230px;
}

.droplist-menu-item {
	display: block;
	padding: 1px 6px;
	overflow: hidden;
}

.droplist-menu-item:hover {
	background: #006ce5;
	color: #fff;
}

.droplist-btn {
	position: absolute;
	right: 1px;
	top: 1px;
	bottom: 1px;
	width: 1.5em;
	border-radius: 0 0.2em 0.2em 0;
	/*background: #fff;*/ /* Uncomment if necessary */
	color: var(--input-icon-color);
}

.droplist-btn:after {
	content: '';
	position: absolute;
	top: 5px;
	right: 0;
	left: -1px;
	bottom: 5px;
	border-left: 1px solid var(--input-border-hover-color);
	opacity: 0.5;
}

.droplist-btn:before {
	content: '\25BC';
	position: absolute;
	transform: scaleY(0.6);
	top: 1px;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.75em;
	line-height: 1.3;
}

.droplist-btn:hover {
	color: var(--editor-text-color);
}
