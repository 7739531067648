.mq-sidebar-list-react {
	font-size: 0.75rem;
	line-height: 2em;
	padding: 0;

	/* zebra background */
	background-image: linear-gradient(var(--editor-menu-hover-color) 50%, transparent 0);
	background-size: auto 4em;
	background-origin: content-box;
}

.mq-sidebar-list-react
	.mq-sidebar-list-page.expanded
	> .mq-sidebar-list-page__inner
	> .mq-sidebar-list-page__title
	.expand
	.mq-outline-icon,
.mq-sidebar-list-react
	.mq-sidebar-list-page.expanded
	> .mq-sidebar-list-page__title
	.expand
	.mq-icon {
	transform: rotate(90deg);
}

.mq-sidebar-list-react .mq-sidebar-list-page-input-wrapper {
	width: 100%;
}

.mq-sidebar-list-page-input-wrapper input.title-rename-input {
	padding: 2px 5px;
}

.mq-sidebar-open-pages {
	max-height: 200px;
}

.mq-sidebar-contextmenu-container {
	height: 100%;
}

.mq-sidebar-open-pages .sidebar-pane-header-label {
	border-top: none;
}
.mq-sidebar-open-pages .sidebar-pane-header-label .mq-icon {
	width: 1.25em;
	height: 1.25em;
	fill: var(--editor-active-color);
}

.mq-sidebar-header-controls .delete-permanently .mq-icon {
	fill: var(--error-text-color);
	color: var(--error-text-color);
}

.mq-sidebar-list-pages {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	overflow-x: hidden;
}

.has-stencil-counter .mq-sidebar-list-pages {
	bottom: 45px;
}
