#breadcrumbs {
	width: calc(250px - 54px);
	margin-left: 5px;
}

.breadcrumbs {
	font-size: 0.8em;
	padding: 2px 1px 1px 3em;
	position: relative;
	border-radius: var(--inspector-border-radius);
}

.breadcrumbs.no-privacy-ui {
	padding: 2px 1px 1px 1px;
}

.breadcrumbs.offline {
	background: linear-gradient(to left, var(--editor-bg) 0%, var(--error-bg) 100%);
}

.breadcrumbs .project-privacy {
	position: absolute;
	top: 0;
	left: 0.25em;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 2.5em;
	height: 3em;
	padding-right: 0.5em;
	border: none;
	background: none;
	color: var(--link-color);
}

.breadcrumbs:not(.view-mode) .project-privacy:hover {
	cursor: pointer;
	color: var(--link-active-color);
}

.breadcrumbs .project-privacy .mq-icon {
	width: 1.5em;
	height: 1.5em;
}

.breadcrumbs .project-privacy-red .mq-icon {
	color: var(--error-text-color);
	fill: var(--error-text-color);
}

.breadcrumbs .title-wrapper {
	display: block;
	transition: all 0.35s;
	border-radius: var(--inspector-border-radius);
}

.breadcrumbs .title-wrapper.saving {
	color: var(--warning-text-color);
	background: var(--warning-bg);
}

.breadcrumbs .title-wrapper.saved {
	color: var(--success-text-color);
	background: var(--success-bg);
}

.breadcrumbs .project-title {
	font-weight: 500;
	font-size: 13px;
	line-height: 1.2;
	cursor: default;
	box-sizing: border-box;
	padding: 0 5px;
	margin: 0;
	border: 1px solid transparent;
}

.breadcrumbs .current-page-title {
	display: block;
	line-height: 1.2;
	font-size: 12px;
	box-sizing: border-box;
	cursor: default;
	padding: 0 5px;
	margin: 2px 0 0;
	border: 1px solid transparent;
	color: var(--editor-text-muted-color);
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.breadcrumbs .title-wrapper:not(.view-mode):hover {
	background-color: var(--editor-semiselection-bg);
	cursor: text;
}

.breadcrumbs .current-page-title .mq-icon {
	width: 0.5em;
	height: 0.5em;
}

input.title-rename-input {
	padding: 0 5px;
	border-radius: var(--inspector-border-radius);
	font-size: 13px;
	line-height: 1.2;
	box-sizing: border-box;
	width: 100%;
	background: var(--input-bg);
	color: var(--input-text-color);
	border: 1px solid var(--input-border-color);
	margin: 0;
	box-shadow: var(--mq-box-shadow);
}

.breadcrumbs input.title-rename-input:last-child {
	margin: 2px 0 0;
	font-size: 12px;
}

.breadcrumbs .breadcrumbs-page-title {
	display: flex;
}
.breadcrumbs .breadcrumbs-page-label {
	color: var(--mq-white);
	border-radius: 3px;
	max-width: 84px;
	padding-right: 4px;
	padding-left: 4px;
	float: left;
	margin-right: 5px;
	font-size: 11px;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 600;
}
