.stencil .link-ref {
	text-align: center;
	/*display:table-cell;*/
	display: none;
	white-space: nowrap;
	vertical-align: middle;
	color: var(--hotspot-border-color);
	font-size: 10px;
	font-weight: bold;
	text-transform: uppercase;
}

.stencil div,
.stencil-grouped div {
	box-sizing: border-box;
}

.stencil table,
.stencil-grouped table {
	border-collapse: collapse;
	border-spacing: 0;
}

.stencil table p {
	display: inline;
}

/* <p> mean new lines in the grid cell content  */
.grid__table td p {
	display: block;
}

.crispLines {
	text-rendering: optimizeSpeed;
}

line {
	shape-rendering: crispEdges;
}

.stencil {
	user-select: none;
	cursor: default;
}

.stencil,
.patch-contenteditable {
	/*
		Disable ligatures by default.
		See: https://github.com/Evercoder/new-engine/issues/6937
	 */
	font-variant-ligatures: none;

	/*
		For Chinese/Japanese/Korean languages,
		don't break in the middle of the word.
		For non-CJK languages it behaves as `normal`.
	 */
	word-break: keep-all;
}

.stencil.editing {
	user-select: auto;
}

.textselect-mode,
.textselect-mode .stencil {
	user-select: text;
	cursor: auto;
}

.textselect-mode .mq-link-ghost {
	pointer-events: none;
}

.stencil p,
.patch-contenteditable p {
	margin: 0;
}

.stencil [contenteditable='true'],
.patch-contenteditable [contenteditable='true'] {
	cursor: text;
}

.stencil ul:not([data-checked]),
.patch-contenteditable ul:not([data-checked]) {
	list-style-type: disc;
	margin: 1em 0;
	padding-inline-start: 2.5em;
}

.stencil ol,
.patch-contenteditable ol {
	margin: 1em 0;
	padding-inline-start: 2.5em;
	list-style: decimal;
}

.stencil ul:first-child:not([data-checked]),
.patch-contenteditable ul:first-child:not([data-checked]),
.stencil ol:first-child,
.patch-contenteditable ol:first-child {
	margin-top: 0;
}

.stencil ul:last-child:not([data-checked]),
.patch-contenteditable ul:last-child:not([data-checked]),
.stencil ol:last-child,
.patch-contenteditable ol:last-child {
	margin-bottom: 0;
}

.stencil ul[data-checked],
.patch-contenteditable ul[data-checked] {
	--check-size: 15px;
	--check-margin: 6px;

	list-style: none;

	/*
		Regardless of the user's choice of text alignment
		for checkbox lists, align them to the start of the 
		inline axis (ie. left for LTR and right for RTL)
	 */
	text-align: start !important;

	/*
		Create space at the start of the rows 
		to fit the checkmarks.
	 */
	padding-inline-start: calc(var(--check-size) + var(--check-margin));
}

.stencil ul[data-checked] > li::before,
.patch-contenteditable ul[data-checked] > li::before {
	/*
		Use a negative margin to pull the checkmark
		out of the <li>s bounding rectangle. 

		This allows proper alignment of multi-row text
		and enables us to only toggle the checkmark on and off
		when clicking on the ::before pseudo-element.

		See: https://github.com/Evercoder/quill/commit/36f23aa60233e
	 */
	margin-inline-start: calc(-1 * (var(--check-margin) + var(--check-size)));
	margin-inline-end: var(--check-margin);

	/*
		Create a bit of vertical space around the checkmarks,
		so that they don't get squished together 
		when they're the tallest things in the list item
		(eg. when the font size is very small)
	 */
	padding: calc(0.5 * var(--check-margin)) 0;

	/*
		Set up a vertically-centered square box 
		for the checkmark to sit in as a background...
	 */
	content: '';
	display: inline-block;
	vertical-align: middle;
	width: var(--check-size);
	height: var(--check-size);

	/*
		...making sure it's positioned & scaled correctly.
	 */
	background-size: 100%;
	background-position: center center;
	background-repeat: no-repeat;
}

.stencil ul[data-checked='true'] > li::before,
.patch-contenteditable ul[data-checked='true'] > li::before {
	background-image: url("data:image/svg+xml;utf8,<svg version='1.1' width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15'><path fill='%23006ce5' d='M7.5,0C3.4,0,0,3.4,0,7.5S3.4,15,7.5,15S15,11.6,15,7.5S11.6,0,7.5,0z M11.2,6.2L7,10.4 c-0.3,0.3-0.8,0.3-1.1,0L3.8,8.3C3.5,8,3.5,7.5,3.8,7.2c0.3-0.3,0.8-0.3,1.1,0l1.6,1.6l3.7-3.7c0.3-0.3,0.8-0.3,1.1,0 C11.5,5.4,11.5,5.9,11.2,6.2z'/></svg>");
}

.stencil ul[data-checked='false'] > li::before,
.patch-contenteditable ul[data-checked='false'] > li::before {
	background-image: url("data:image/svg+xml;utf8,<svg version='1.1' width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 15' ><path fill='%23565656' d='M7.5,1C11.1,1,14,3.9,14,7.5S11.1,14,7.5,14S1,11.1,1,7.5S3.9,1,7.5,1 M7.5,0C3.4,0,0,3.4,0,7.5S3.4,15,7.5,15 S15,11.6,15,7.5S11.6,0,7.5,0L7.5,0z'/></svg>");
}

.stencil h1,
.stencil h2,
.stencil h3,
.stencil h4,
.stencil h5,
.stencil h6,
.stencil b,
.stencil strong,
.patch-contenteditable h1,
.patch-contenteditable h2,
.patch-contenteditable h3,
.patch-contenteditable h4,
.patch-contenteditable h5,
.patch-contenteditable h6,
.patch-contenteditable b,
.patch-contenteditable strong {
	font-weight: bold;
}

.stencil h1,
.patch-contenteditable h1 {
	font-size: 2em;
	margin: 0 0 0 0;
}
.stencil h2,
.patch-contenteditable h2 {
	font-size: 1.5em;
	margin: 0.75em 0;
}
.stencil h3,
.patch-contenteditable h3 {
	font-size: 1.17em;
	margin: 0.83em 0;
}

.hide-hotspots .tmplsvg-hotspot {
	visibility: hidden;
	pointer-events: none;
}

.line {
	overflow: visible !important;
}

/* Fix for #729 / Gecko */

svg {
	overflow: visible;
}
/*
This fixes the abysmal drag performance on Chrome when zoomed in (at least on Retina)
reference: https://developer.mozilla.org/en/docs/Web/CSS/image-rendering
 */
svg image {
	/*image-rendering:-webkit-optimize-contrast;*/
	/*image-rendering:auto;*/
}

foreignObject {
	overflow: hidden;
}

.overflowHidden {
	overflow: hidden;
}

.hit-helper {
	fill: none;
	stroke: none;
	pointer-events: fill;
}

.drag-preview .hit-helper {
	pointer-events: none;
}

.stencil-hidden {
	visibility: hidden;
	pointer-events: none;
}

.stencil-hidden .stencil--easier-to-select {
	pointer-events: none;
}

.canvas--force-redraw {
	display: none !important;
}

/*
	State-dependent cursors
	-----------------------

	When we change between states on the canvas,
	the .canvas-svg element gets its classes toggled.

	DrawingState, in particular, adds a data attribute
	with the currently selected drawing tool.
 */

/*
	In states that allow quick-editing of objects' text,
	make this more obvious by changing the cursor to a caret.
 */
.state--drawing[data-drawingtool='text'] .editable-fo,
.state--typing .editable-fo {
	cursor: text;
}

/*
	Flexbox Layout for HTML inside SVG (via foreignObject)
	------------------------------------------------------
*/

/* Basic elements */

.svglayout {
	display: flex;
}

.svglayout__item {
}

/* Layout modifiers */

.svglayout--hbox {
	overflow-x: hidden;
}
.svglayout--vbox {
}
.svglayout--reversed {
}

.svglayout--spaced {
	justify-content: space-around;
}

.stencil li,
.patch-contenteditable li {
	margin-inline-start: calc(var(--indent-level, 0) * 2em);
}
