.must-popup {
	position: fixed;
	z-index: 100;
	box-sizing: border-box;
	padding: var(--spacing);
	border: 0.0625rem solid var(--mq-grey-200);
	border-radius: 0.375rem;
	outline: none;
	background: var(--mq-white);
	/*overflow: hidden;*/
	box-shadow: var(--mq-box-shadow);
	--spacing: 0.75rem;
}

.must-popup__header {
	font-family: var(--mq-font-main);
	font-size: 0.8125rem;
	display: flex;
	margin: calc(var(--spacing) * -1) calc(var(--spacing) * -1) 0;
	padding: 0.625rem var(--spacing);
	flex-wrap: nowrap;
	align-items: center;
}

.must-popup__header[data-must-draggable] {
	cursor: move;
	user-select: none;
}

.must-popup__header .must-icon {
	fill: var(--mq-grey-900);
}

.must-popup__header .must-button:hover {
	cursor: pointer;
}

.must-popup__header .must-button:hover .must-icon {
	fill: var(--mq-blue);
}

.must-popup__title {
	font-size: 1em;
	font-weight: bold;
	line-height: 1rem;
	margin: 0;
	color: var(--mq-grey-900);
	flex-grow: 1;
}
