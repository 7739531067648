.chartborder {
	width: 100%;
}

.chartborder__ui {
	position: absolute;
	left: -50%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
	transform: translate(-50%, 0);
	white-space: nowrap;
	color: var(--toolbar-text-color);
	background: var(--toolbar-bg);
	box-shadow: var(--mq-box-shadow-lg);
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	border: 1px solid var(--toolbar-border-color);
	height: var(--toolbar-size);
	padding: 0 calc(var(--toolbar-gap) * 2);
	gap: calc(var(--toolbar-gap) * 1);
}

.chartborder__ui svg {
	color: var(--toolbar-text-color);
	fill: var(--toolbar-text-color);
	width: var(--toolbar-icon-size);
	height: var(--toolbar-icon-size);
}

.chartborder__button {
	height: calc(var(--toolbar-size) - calc(var(--toolbar-gap) * 2.5));
	min-width: calc(var(--toolbar-size) - calc(var(--toolbar-gap) * 2.5));
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	cursor: pointer;
	background: transparent;
	border: none;
	border-radius: 4px;
}

.chartborder__button:hover svg {
	fill: var(--editor-active-color);
}

.chartborder__button:focus {
	outline: none;
}

.chartborder__button--active {
	background: var(--toolbar-selection-bg);
	box-shadow: var(--mq-box-shadow-inset-sm);
}

.chartborder__button--active svg {
	fill: var(--editor-active-color);
}

.chartborder__button--done {
	font-size: 13px;
	padding: 5px 12px;
	font-weight: bold;
	color: var(--toolbar-text-color);
}

.chartborder__button--done:hover {
	color: var(--editor-active-color);
}
