.mq-logo {
	background: var(--mq-blue-500);
	color: var(--mq-white);
	padding: 1px;
	display: inline-block;
	pointer-events: none;
}

.mq-logo:hover {
	cursor: pointer;
}

.mq-logo .mq-icon {
	width: 3rem;
	height: 3rem;
}

.mq-logo use {
	fill: currentColor;
}
