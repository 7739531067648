/* Viewer */
.mq-link-ghost {
	display: block;
	z-index: 999;
	position: absolute;
	/*transform-origin: 0 0;*/
}

.mq-link-ghost.ellipse {
	border-radius: 50%;
	overflow: hidden;
}

.mq-link-ghost span {
	display: block;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 0.3s;
	background: rgba(102, 255, 51, 0.4);
	box-shadow: 0 0 0 1px rgba(57, 230, 0, 0.75);
}

.mq-link-ghost.hover span {
	opacity: 1;
}
