:root {
	--bg-default: #f0f0f0;

	/* Moqups Blue */
	--mq-blue: var(--mq-blue-500);

	/* Moqups logo blue */
	--mq-brand-blue: #1181f1;

	/*
		Moqups Blue (light)
		Obtained at 0.33 interpolation between
		Moqups Blue and White (in RGB space)
	*/
	--mq-blue-light: #a8cdf6;

	/*
		The stroke color around normal (unlocked) objects.
		Applies to object highlighting, individual border, etc.
	 */
	--normal-stroke: var(--mq-blue);
	--active-stroke: var(--mq-orange-600);
	--locked-stroke: var(--mq-editor-grey-400);
	--semiselection-stroke: var(--mq-blue-600);

	/*
		Base editor visual assistance colors
	*/
	--guide-accent: cyan;
	--snap-accent: fuchsia;
	--text-selection: #40a2ff77;

	/*
		The stroke color for locked objects.
		Applies to object highlighting, individual border, etc.
	 */
	--lock-stroke: var(--mq-pink-500);
	--lock-icon-color: var(--mq-white);

	/*
		Accent color for Symbols.
	 */
	--symbol-accent: #8946e0;
	--symbol-text-color: #8946e0;
	--symbol-light: var(--mq-purple-100);

	/*
		Accent color for sticky objects.
	 */
	--sticky-accent: var(--mq-yellow-700);

	/*
		Interactions distinct color
	 */
	--interaction-accent: #08aa5f;
	--interaction-accent--dark: #079251;
	--interaction-bg: #08aa5f20;

	--hotspot-border-color: #00ff88;
	--hotspot-bg: #00ff88aa;

	/*
		Premium features accent
	*/
	--premium-accent: var(--mq-purple-500);
	--premium-text-color: var(--mq-white);

	/*
		New features accent
	 */
	--new-accent: var(--mq-yellow-700);
	--new-text-color: var(--mq-editor-grey-950);

	/*
		Border radius of elements in the welcome modal
		(including modal itself).
	 */
	--welcome-border-radius: 6px;
	--welcome-template-border-radius: 12px;
	--welcome-padding-x: 40px;
	--welcome-padding-y: 25px;

	/*
		Border radius of inputs in the inspector
	 */
	--inspector-border-radius: 4px;
	--modal-border-radius: 24px;

	/*
		Base size for icons in inspector
	 */
	--inspector-icon-size: 16px;

	/*
		Unify left sidebar toolbars size
	 */
	--sidebar-header-height: 36px;

	/*
		Comments sizing
	 */
	--max-comment-height: 420px;

	/* Moqups 2023 Color Palette */

	/* 
 	--mq-font-main: "Inter", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif;
   	*/

	--mq-font-display: 'Figtree', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial,
		sans-serif;
	--mq-font-mono: Consolas, Monaco, Courier, 'Courier New', monospace;

	--mq-editor-grey-25: #f7f8f9;
	--mq-editor-grey-50: #eaeaf0;
	--mq-editor-grey-100: #dfe1e7;
	--mq-editor-grey-200: #d6d9dd;
	--mq-editor-grey-300: #ced2d7;
	--mq-editor-grey-400: #a8afb7;
	--mq-editor-grey-500: #848d93;
	--mq-editor-grey-600: #6c757f;
	--mq-editor-grey-700: #525960;
	--mq-editor-grey-800: #40444a;
	--mq-editor-grey-850: #33373c;
	--mq-editor-grey-900: #2e3237;
	--mq-editor-grey-950: #26292d;
	--mq-editor-grey-975: #1e1e1f;

	--mq-grey-25: #f7f8f9;
	--mq-grey-50: #eef1f5;
	--mq-grey-100: #dee4eb;
	--mq-grey-200: #c9d3dd;
	--mq-grey-300: #9fafc1;
	--mq-grey-400: #798da3;
	--mq-grey-500: #677d92;
	--mq-grey-600: #5a6e81;
	--mq-grey-700: #465666;
	--mq-grey-800: #3b4754;
	--mq-grey-900: #29323d;
	--mq-grey-950: #22272f;

	--mq-blue-50: #f1f7fd;
	--mq-blue-100: #e2f1ff;
	--mq-blue-200: #badcff;
	--mq-blue-300: #73baff;
	--mq-blue-400: #40a2ff;
	--mq-blue-500: #1071f2;
	--mq-blue-600: #1068dd;
	--mq-blue-700: #185dcb;
	--mq-blue-800: #183eac;
	--mq-blue-900: #40487c;

	--mq-yellow-50: #fffde7;
	--mq-yellow-200: #fef69b;
	--mq-yellow-500: #ffe533;
	--mq-yellow-700: #fcc223;
	--mq-yellow-800: #dda624;
	--mq-yellow-900: #a17013;

	--mq-orange-50: #fbeae8;
	--mq-orange-100: #ffcfbd;
	--mq-orange-400: #ff7b45;
	--mq-orange-500: #ff6624;
	--mq-orange-600: #f45f20;

	--mq-pink-50: #ffe9ee;
	--mq-pink-100: #ffc8d0;
	--mq-pink-300: #f85f6a;
	--mq-pink-400: #f54c52;
	--mq-pink-500: #f0373e;

	--mq-green-100: #def0c3;
	--mq-green-500: #8acd25;
	--mq-green-700: #63a90f;
	--mq-green-900: #1e7300;

	--mq-purple-50: #efebff;
	--mq-purple-100: #ded6ff;
	--mq-purple-200: #c6b9fe;
	--mq-purple-300: #a48ffe;
	--mq-purple-400: #8468fb;
	--mq-purple-500: #7656f3;
	--mq-purple-600: #5e39ec;
	/* ... */
	--mq-purple-900: #3d3365;

	--mq-white: #fff;
	--mq-black: #000;
	--mq-transparent: #0000;

	--mq-transition: cubic-bezier(0.23, 1, 0.32, 1);

	--mq-box-shadow: 0 5px 9px -1px #0000001a, 0 3px 6px -1px #0000000f;
	--mq-box-shadow-xs: 0 1px 1px 0 #0000001a, 0 1px 1px 0 #0000001a;
	--mq-box-shadow-sm: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
	--mq-box-shadow-md: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
	--mq-box-shadow-lg: 0 8px 12px -2px #0000001a, 0 4px 8px -3px #0000001a;
	--mq-box-shadow-xl: 0 8px 40px -2px #00000050, 0 4px 32px -3px #0000001a;

	--mq-box-shadow-inset: 0 3px 2px -2px #00000033 inset, 3px 0 2px -2px #00000033 inset;
	--mq-box-shadow-inset-sm: 2px 2px 3px -1px #00000015 inset;

	--mq-checkerboard: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAQMAAAC3R49OAAAABlBMVEX////MzMw46qqDAAAAE0lEQVQI12P4f4CBKMxg/4EYDAAFkR1NiYvv7QAAAABJRU5ErkJggg==');

	/* States */

	--success-text-color: var(--mq-green-900);
	--warning-text-color: var(--mq-yellow-900);
	--error-text-color: var(--mq-pink-400);
	--info-text-color: var(--mq-editor-grey-800);

	--success-bg: #8acd2537;
	--warning-bg: #ffe53345;
	--error-bg: #f54c5245;
	--info-bg: #73baff37;

	/* filter inverting colors for png font previews in dark modes - adds to invert value */
	--font-preview-invert: 0;

	--toolbar-size: 36px;
	--toolbar-gap: 3px;
	--toolbar-icon-size: 16px;

	/* Universal stencil colors */
	--stencil-chart1: var(--mq-blue-500);
	--stencil-chart2: var(--mq-blue-400);
	--stencil-chart3: var(--mq-blue-300);

	/*
		Comments notification colors
	 */
	--unread-comment-color: var(--mq-orange-400);
	--unread-comment-text-color: var(--mq-white);
}

:root,
html[data-theme='light'] {
	--editor-bg: var(--mq-editor-grey-25);
	--editor-border-color: var(--mq-editor-grey-100);
	--editor-hover-color: var(--mq-editor-grey-50);

	--editor-icon-color: var(--mq-grey-700);
	--editor-icon-color-tone: var(--mq-editor-grey-400);
	--editor-text-color: var(--mq-grey-800);
	--editor-text-muted-color: var(--mq-editor-grey-500);
	--editor-header-color: var(--mq-editor-grey-900);

	--editor-active-color: var(--mq-blue-500);
	--editor-active-bg: var(--mq-white);

	--input-bg: var(--mq-white);
	--input-border-color: var(--mq-editor-grey-300);
	--input-border-hover-color: var(--mq-editor-grey-200);
	--input-text-color: var(--mq-editor-grey-800);
	--input-icon-color: var(--mq-editor-grey-500);
	--input-accent-color: var(--mq-blue-500);

	--switch-bg: var(--mq-editor-grey-50);
	--switch-active-bg: var(--mq-white);

	--button-bg: var(--mq-editor-grey-50);
	--button-alt-bg: var(--button-bg);
	--button-text-color: var(--mq-editor-grey-700);
	--button-hover-color: var(--mq-editor-grey-100);

	--button-primary-bg: var(--mq-blue-500);
	--button-primary-text-color: var(--mq-white);
	--button-primary-hover-color: var(--mq-blue-700);

	--button-secondary-bg: #1071f21b;
	--button-secondary-text-color: var(--mq-blue-500);
	--button-secondary-hover-color: #1071f22e;

	--button-login-bg: var(--mq-white);
	--button-login-text-color: var(--mq-editor-grey-700);
	--button-login-border-color: var(--mq-editor-grey-200);

	--modal-bg: var(--mq-white);
	--welcome-bg: var(--mq-editor-grey-25);
	--welcome-hover-color: var(--mq-editor-grey-25);

	/* stencil thumbnail colors */
	--stencil-color: var(--mq-editor-grey-800);
	--stencil-color2: var(--mq-editor-grey-500);
	--stencil-color-light: var(--mq-white);
	--stencil-tone1: var(--mq-white);
	--stencil-tone2: var(--mq-editor-grey-50);
	--stencil-tone3: var(--mq-editor-grey-200);
	--stencil-tone4: var(--mq-editor-grey-400);

	/* stencil container colors */
	--stencil-bg: var(--mq-white);
	--stencil-hover-color: rgba(159, 175, 193, 0.1);
	--stencil-border-color: var(--mq-editor-grey-50);
	--stencil-header-color: var(--mq-blue-500);

	--link-color: var(--mq-blue-500);
	--link-active-color: var(--mq-blue-600);
	--folder-color: var(--mq-blue-300);

	--hint-bg: var(--mq-editor-grey-700);
	--hint-text-color: var(--mq-white);
	--hint-xl-bg: var(--mq-blue-500);

	--editor-menu-bg: var(--mq-white);
	--editor-menu-hover-color: var(--mq-editor-grey-25);
	--editor-menu-border-color: var(--mq-editor-grey-50);

	--editor-highlight-bg: var(--mq-editor-grey-50);
	--editor-selection-bg: #1071f233;
	--editor-semiselection-bg: #1071f21b;
	--editor-selection-text-color: var(--editor--text-color);

	--toolbar-bg: var(--mq-white);
	--toolbar-text-color: var(--mq-editor-grey-800);
	--toolbar-selection-bg: #1071f21b;
	--toolbar-border-color: var(--mq-editor-grey-50);

	--presence-bg: var(--mq-white);

	--stage-bg: var(--mq-grey-50);
	--stage-text: var(--mq-editor-grey-400);
}

/* Classic Theme */
html[data-theme='classic'] {
	--mq-blue: #006ce5;

	--editor-bg: #f5f5f5;
	--editor-border-color: #d2d2d2;
	--editor-hover-color: #fcfcfc;

	--editor-icon-color: #505050;
	--editor-icon-color-tone: #aaa;
	--editor-text-color: #444;
	--editor-text-muted-color: #999;
	--editor-header-color: #222;

	--editor-active-color: #006ce5;
	--editor-active-bg: #fff;

	--input-bg: #fff;
	--input-border-color: #c8c8c8;
	--input-border-hover-color: #aaa;
	--input-text-color: #222;
	--input-icon-color: #888;
	--input-accent-color: #006ce5;

	--switch-bg: #e9e9e9;
	--switch-active-bg: #fff;

	--button-bg: #eee;
	--button-alt-bg: var(--button-bg);
	--button-text-color: #333;
	--button-hover-color: #dedede;

	--button-primary-bg: #006ce5;
	--button-primary-text-color: #fff;
	--button-primary-hover-color: #065ec3;

	--button-secondary-bg: #006be51b;
	--button-secondary-text-color: #006ce5;
	--button-secondary-hover-color: #006be52e;

	--button-login-bg: #fff;
	--button-login-text-color: #333;
	--button-login-border-color: #d1d1d1;

	--modal-bg: #fff;
	--welcome-bg: #eee;
	--welcome-hover-color: #f8f8f8;

	/* stencil thumbnail colors */
	--stencil-color: #444;
	--stencil-color2: #888;
	--stencil-color-light: #fff;
	--stencil-tone1: #fff;
	--stencil-tone2: #eee;
	--stencil-tone3: #ccc;
	--stencil-tone4: #aaa;
	--stencil-tone4: #aaa;

	/* stencil container colors */
	--stencil-bg: #fff;
	--stencil-hover-color: rgba(187, 187, 187, 0.1);
	--stencil-border-color: #eee;
	--stencil-header-color: #006ce5;

	--link-color: #006ce5;
	--link-active-color: #105aae;
	--folder-color: #86d6fa;

	--hint-bg: #333;
	--hint-text-color: #eee;
	--hint-xl-bg: #006ce5;

	--editor-menu-bg: #fff;
	--editor-menu-hover-color: #f8f8f8;
	--editor-menu-border-color: #eee;

	--editor-highlight-bg: #eee;
	--editor-selection-bg: #006be533;
	--editor-semiselection-bg: #006be51b;
	--editor-selection-text-color: var(--editor-text-color);

	--toolbar-bg: #f8f8f8;
	--toolbar-text-color: #333;
	--toolbar-selection-bg: #006be51b;
	--toolbar-border-color: #d9d9d9;

	--presence-bg: #fff;

	--stage-bg: #eee;
	--stage-text: #aaa;
}

/* Dark Theme */
html[data-theme='dark'] {
	color-scheme: dark;

	--mq-checkerboard: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAQMAAAC3R49OAAAABlBMVEVmZmZEREThwlUuAAAAE0lEQVQI12P4f4CBKMxg/4EYDAAFkR1NiYvv7QAAAABJRU5ErkJggg==');

	--bg-default: var(--mq-editor-grey-900);

	--interaction-accent: #09b967;

	--symbol-text-color: var(--mq-purple-300);
	--symbol-light: var(--mq-purple-900);

	--editor-bg: var(--mq-editor-grey-900);
	--editor-border-color: var(--mq-editor-grey-800);
	--editor-hover-color: var(--mq-editor-grey-800);

	--editor-icon-color: var(--mq-editor-grey-400);
	--editor-icon-color-tone: var(--mq-editor-grey-600);
	--editor-text-color: var(--mq-editor-grey-300);
	--editor-text-muted-color: var(--mq-editor-grey-500);
	--editor-header-color: var(--mq-editor-grey-100);

	--editor-active-color: var(--mq-white);
	--editor-active-bg: var(--mq-editor-grey-700);

	--input-bg: var(--mq-editor-grey-800);
	--input-border-color: var(--mq-editor-grey-700);
	--input-border-hover-color: var(--mq-editor-grey-600);
	--input-text-color: var(--mq-editor-grey-200);
	--input-icon-color: var(--mq-editor-grey-400);
	--input-accent-color: var(--mq-blue-300);

	--switch-bg: var(--mq-editor-grey-950);
	--switch-active-bg: var(--mq-editor-grey-700);

	--button-bg: var(--mq-editor-grey-800);
	--button-alt-bg: var(--mq-editor-grey-850);
	--button-text-color: var(--mq-editor-grey-100);
	--button-hover-color: var(--mq-editor-grey-700);

	--button-primary-bg: var(--mq-blue-500);
	--button-primary-text-color: var(--mq-editor-grey-25);
	--button-primary-hover-color: var(--mq-blue-600);

	--button-secondary-bg: var(--mq-editor-grey-100);
	--button-secondary-text-color: var(--mq-editor-grey-800);
	--button-secondary-hover-color: var(--mq-editor-grey-300);

	--button-login-bg: var(--modal-bg);
	--button-login-text-color: var(--mq-editor-grey-200);
	--button-login-border-color: var(--mq-editor-grey-200);

	--modal-bg: var(--mq-editor-grey-900);
	--welcome-bg: var(--mq-editor-grey-850);
	--welcome-hover-color: var(--mq-editor-grey-800);

	/* stencil thumbnail colors */
	--stencil-color: var(--mq-editor-grey-300);
	--stencil-color2: var(--mq-editor-grey-400);
	--stencil-color-light: var(--mq-editor-grey-100);
	--stencil-color-dark: var(--mq-editor-grey-900);
	--stencil-tone1: var(--mq-editor-grey-800);
	--stencil-tone2: var(--mq-editor-grey-700);
	--stencil-tone3: var(--mq-editor-grey-600);
	--stencil-tone4: var(--mq-editor-grey-500);

	/* stencil container colors */
	--stencil-bg: var(--mq-editor-grey-950);
	--stencil-hover-color: rgba(90, 90, 96, 0.1);
	--stencil-border-color: var(--mq-editor-grey-850);
	--stencil-header-color: var(--mq-editor-grey-300);

	--success-text-color: var(--mq-green-500);
	--info-text-color: var(--mq-editor-grey-300);
	--warning-text-color: var(--mq-yellow-700);

	--link-color: var(--mq-blue-400);
	--link-active-color: var(--mq-blue-300);
	--folder-color: var(--mq-blue-200);

	--hint-bg: var(--mq-editor-grey-800);
	--hint-text-color: var(--mq-editor-grey-100);
	--hint-xl-bg: var(--mq-blue-500);

	--editor-menu-bg: var(--mq-editor-grey-950);
	--editor-menu-hover-color: var(--mq-editor-grey-900);
	--editor-menu-border-color: var(--mq-editor-grey-850);

	--editor-highlight-bg: var(--mq-editor-grey-700);
	--editor-selection-bg: #525960aa;
	--editor-semiselection-bg: var(--mq-editor-grey-800);
	--editor-selection-text-color: var(--mq-white);

	--toolbar-bg: var(--mq-editor-grey-800);
	--toolbar-text-color: var(--mq-editor-grey-200);
	--toolbar-selection-bg: var(--mq-editor-grey-700);
	--toolbar-border-color: var(--mq-editor-grey-700);

	--presence-bg: var(--mq-editor-grey-600);

	--stage-bg: var(--mq-editor-grey-975);
	--stage-text: var(--mq-editor-grey-600);

	--font-preview-invert: 1;
}
