/* Mention dropdown */

.mq-mentions-dropdown {
	width: 200px;
	border-radius: 4px;
	padding: 6px 0 8px;
	position: absolute;
	background: var(--input-bg);
	z-index: 1;
	right: 0;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

.mention-list {
	font-size: 0.9em;
}

.mq-mentions-dropdown input {
	width: calc(100% - 8px);
	background: var(--input-bg);
	border: 1px solid transparent;
	font-size: 13px;
	padding: 5px;
	margin: 0 4px 6px;
}

.mq-mentions-dropdown .mention {
	/*margin: 2px 0;*/
	padding: 0.25em 0.5em;
	cursor: pointer;
	font-size: 13px;
}

.mq-mentions-dropdown .mention .avatar.presence img {
	border: 0;
}

.mq-mentions-dropdown .mention .mq-profile-card,
.mq-mentions-dropdown .mention .mq-profile-card-compact {
	color: var(--editor-text-color);
	font-size: 13px;
	margin: 0;
	width: auto;
}

.mq-mentions-dropdown .mention .mq-profile-card-name,
.mq-mentions-dropdown .mention .mq-profile-card-compact:hover .mq-profile-card-name {
	background: none;
	color: inherit;
}

.mq-mentions-dropdown .mention .mq-profile-card-expanded {
	display: none;
}

.mq-mentions-dropdown .mention:first-child {
	border-top: 1px solid var(--input-border-color);
}

.mq-mentions-dropdown .mention.focused {
	color: var(--editor-active-bg);
	background: var(--editor-active-color);
}

.mq-mentions-dropdown .mention.focused .mq-profile-card-compact {
	color: var(--editor-active-bg);
}

.mention .mq-profile-card-compact .mq-profile-card-name {
	overflow: hidden;
	font-weight: normal;
	text-overflow: ellipsis;
	max-width: 12em;
	vertical-align: middle;
	white-space: nowrap;
}
