/* Smart Textarea */

.smart-textarea-tools {
	position: absolute;
	bottom: 0.4em;
	right: 0.55em;
}

.smart-textarea-button {
	cursor: pointer;
	color: var(--editor-text-color);
}

.smart-textarea-button .mq-icon {
	vertical-align: middle;
}

.smart-textarea-button:hover {
	color: var(--link-color);
}

.smart-textarea-button > .mq-button {
	width: 1.2em;
	height: 1.2em;
}

.markdown-info .mq-icon {
	stroke: currentColor;
	width: 1.25em;
	height: 1em;
	padding: 0.5em 0.5em 0 0;
}
