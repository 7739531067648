.image-patch-ui {
	stroke: var(--normal-stroke);
	stroke-dasharray: 2, 2;
}

.instant-alpha-ui {
	fill: none;
	stroke: var(--normal-stroke);
}
.image-editor-border {
	margin-top: 12px;
	white-space: nowrap;
	display: flex !important;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: var(--toolbar-text-color);
	background: var(--toolbar-bg);
	box-shadow: var(--mq-box-shadow-lg);
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	border: 1px solid var(--toolbar-border-color);
	height: var(--toolbar-size);
	padding: 0 calc(var(--toolbar-gap) * 2);
	gap: calc(var(--toolbar-gap) * 1);
}

.image-editor-border > div:empty {
	display: none;
}

.image-editor-border p {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	min-width: calc(var(--toolbar-size) - calc(var(--toolbar-gap) * 2.5));
}

.image-editor-border p.image-editor-annotation {
	white-space: normal;
	line-height: 1.2;
	color: var(--editor-text-color);
	font-size: 11px;
	text-align: left;
	justify-content: flex-start;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	width: 245px;
	margin: 0 calc(var(--toolbar-gap) * 2);
}

.image-editor-border.mode_instant_alpha,
.image-editor-border.mode_image_patch,
.image-editor-border.mode_image_crop {
}

.image-edit-icon {
	overflow: visible;
	color: var(--toolbar-text-color);
	fill: var(--toolbar-text-color);
	width: var(--toolbar-icon-size);
	height: var(--toolbar-icon-size);
}

.image-toolbar-border__separator {
	height: 22px;
	border-left: 1px solid var(--toolbar-border-color);
	margin: 0 calc(var(--toolbar-gap) * 1.5);
}

.instant-alpha-tooltip {
	width: 36px;
	height: 18px;
	text-align: center;
	background: var(--mq-blue);
	color: var(--mq-white);
	font-size: 12px;
	vertical-align: middle;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	border-radius: var(--inspector-border-radius);
}

/*
	Don't display the element when it has no content, 
	otherwise we get a weird floating square.
 */
.instant-alpha-tooltip:empty {
	display: none;
}

.instant-alpha-fo {
	overflow: visible;
}
.image-editor-canvas {
	position: absolute;
	top: 0;
	left: 0;
}
.instant-alpha-svg {
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none;
}
.image-editor-button {
	height: calc(var(--toolbar-size) - calc(var(--toolbar-gap) * 2.5));
	min-width: calc(var(--toolbar-size) - calc(var(--toolbar-gap) * 2.5));
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	cursor: pointer;
	background: transparent;
	border: none;
	border-radius: 4px;
}

.image-editor-border .mq__button--compact {
	height: calc(var(--toolbar-size) - calc(var(--toolbar-gap) * 3));
	border-radius: 4px;
}

.image-editor-border .mq__button + .mq__button {
	margin-left: var(--toolbar-gap);
}

.image-editor-button.loading {
	pointer-events: none;
}

.image-editor-button .mq-throbber svg {
	fill: var(--toolbar-text-color);
	display: inline-block;
	overflow: visible;
	vertical-align: middle;
	width: var(--toolbar-icon-size);
	height: var(--toolbar-icon-size);
}

.image-editor-button .mq-throbber {
	padding: 0;
	pointer-events: none;
}

.image-editor-button:hover svg {
	color: var(--editor-active-color);
	fill: var(--editor-active-color);
}

.image-editor-button:focus {
	outline: none;
}

.image-editor-slider {
	display: inline-block;
	width: 96px;
	margin-left: 0.5em;
	margin-right: 1em;
	height: 4px;
	background: none;
}

.image-editor-slider .uix-pad__handle {
	border-color: var(--editor-text-muted-color);
	background: var(--mq-white);
	box-shadow: var(--mq-box-shadow-sm);
	width: 1em;
	height: 1em;
}

.image-editor-slider .uix-pad {
	background: var(--toolbar-bg);
}

.image-editor-slider .uix-surface {
	background: rgba(0, 0, 0, 0.2);
	border-radius: 6px;
}

.image-editor-slider .uix-slider__progress {
	background: var(--editor-active-color);
	border-radius: 6px;
}

.image-editor-slider .uix-pad:focus .uix-pad__handle {
	border-color: var(--editor-text-muted-color);
}
