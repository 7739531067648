/* =============================================================================
   Icons
   ========================================================================== */

.mq-icon {
	display: inline-block;
	overflow: visible;
	vertical-align: middle;
	fill: currentColor;
	width: 1em;
	height: 1em;
}

/*
	Note: we need this so that events that happen on the <use> element inside the SVG
	can be bubbled up to a parent outside of the containing SVG.
	See https://github.com/Evercoder/new-engine/issues/308
 */
.mq-icon use {
	pointer-events: none;
}

.mq-icon-logo {
	width: 2em;
	height: 2em;
	fill: var(--mq-white);
}

.mq-icon-menu-btn {
	transform: translate(1.75em, 0.5em) scale(0.3, 0.3);
}

.mq-icon-chevron-btn {
	height: 1.25em;
	width: 1.25em;
}
