/*
	Apply this to the canvas for better zoom performance
 */

.performance--pinch {
	/* 
		Instructs the browser to rasterize 
		the canvas before the transform, for better performance
	*/
	will-change: transform;

	/*
		Disabling interactivity makes things faster
	 */
	pointer-events: none;

	/*
		Disabling antialiasing makes things faster
	 */
	shape-rendering: optimizeSpeed;
	text-rendering: optimizeSpeed;
}
