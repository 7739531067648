.mq-sidebar-assets-list {
	text-align: center;
	overflow-y: scroll;
	overflow-x: hidden;
	font-size: 0.75rem;
	line-height: 1.75;
	padding: 1px 0;
	-webkit-tap-highlight-color: transparent;
}

.mq-sidebar-assets-list div:nth-child(even) {
	background-image: linear-gradient(var(--editor-menu-hover-color) 50%, transparent 0);
	background-size: auto 3.5em;
	background-origin: content-box;
}

.mq-sidebar-assets-list.droptarget div:nth-child(even) {
	box-shadow: inset 2px 0 0 #006ce5, inset -2px 0 0 #006ce5;
}

.mq-sidebar-assets-contextmenu-container {
	width: 100%;
}

#assets-list.droptarget .mq-sidebar-list,
#template-assets-list.droptarget .mq-sidebar-list {
	box-shadow: 2px 0px 0px 0px var(--link-color) inset, -2px 0px 0px 0px var(--link-color) inset,
		0px 2px 0px 0px var(--link-color) inset;
}

.assets-header {
	height: 35px;
	display: flex;
}

.assets-header .assets-view {
	margin-left: auto;
}

.assets-header-upload-button-container {
	display: inline;
}

.sidebar-header-assets-label .context-menu-item-title {
	justify-content: flex-start;
}

.mq-sidebar-header-controls {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	justify-content: space-evenly;
	gap: 6px;
}

.mq-sidebar-header-controls .sidebar-header-assets-label .mq-link-dropdown-current > a {
	color: var(--editor-header-color);
	text-decoration: none;
}

.mq-sidebar-header-controls .mq-link-dropdown-current:after {
	color: var(--editor-header-color);
}

.mq-sidebar-header-controls .sidebar-header-assets-label .mq-link-dropdown-current:hover > a,
.mq-sidebar-header-controls .mq-link-dropdown-current:hover:after {
	color: var(--editor-active-color);
}

.folder-preview {
	margin: auto;
	display: inline-block;
	overflow: visible;
	vertical-align: middle;
	width: 128px;
	height: 128px;
	fill: var(--folder-color);
}

.tooltip.tooltip--assets .rc-tooltip-inner {
	border-radius: calc(var(--inspector-border-radius) * 2);
	border: 1px solid var(--editor-menu-border-color);
	background-color: var(--input-bg);
	color: var(--editor-text-muted-color);
	box-shadow: var(--mq-box-shadow-lg);
	padding: 1em;
}

.tooltip.tooltip--assets .assets-arrow {
	border-color: var(--input-bg) !important;
}

.tooltip.tooltip--assets hr {
	background-color: var(--editor-text-muted-color);
	opacity: 0.25;
	margin: 0.5em 0;
}

.tooltip.tooltip--assets .highlight {
	color: var(--editor-text-color);
}

.asset-thumbnail-container {
	display: flex;
	flex-direction: column;
	max-width: 240px;
	min-width: 180px;
}

.page__thumbnail__arrow.assets-arrow {
	right: 95.5%;
}

.asset-thumbnail-container .no-thumb {
	min-width: 240px;
}

.asset-thumbnail-container .thumb {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;
	max-width: 240px;
}

.asset-thumbnail-placeholder {
	min-height: 180px;
}

.mq-sidebar-assets-grid {
	margin: 0.5em;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(77px, 1fr));
	gap: 0.5em;
}

.mq-sidebar-assets-grid-item {
}

.mq-sidebar-assets-grid.shrink .mq-sidebar-assets-grid-item {
	color: var(--link-color);
}

.mq-sidebar-assets-grid.shrink .mq-sidebar-assets-grid-item .folder .mq-icon {
}

.mq-sidebar-assets-grid.shrink .mq-sidebar-assets-grid-item .thumb-container {
}

.mq-sidebar-asset-item--inner {
	display: flex;
	flex-direction: column;
	margin: 0em;
}

.mq-sidebar-assets-grid-item:hover {
	cursor: pointer;
}

.mq-sidebar-assets-grid-item:hover .thumb-container,
.mq-sidebar-assets-grid-item:hover .folder {
	border-color: var(--editor-semiselection-bg);
}

.mq-sidebar-assets-grid-item.droptarget {
	box-shadow: none;
}

.mq-sidebar-assets-grid-item.droptarget .folder {
	border-color: var(--link-color);
}

.mq-sidebar-assets-grid-item .title {
	text-align: center;
	color: var(--editor-text-color);
	padding: 4px;
	font-size: 11px;
}

.mq-sidebar-assets-grid-item .thumb-container,
.mq-sidebar-assets-grid-item .folder {
	border-radius: var(--inspector-border-radius);
	background-color: var(--editor-bg);
	border: 2px solid var(--editor-bg);
	position: relative;
}

.mq-sidebar-assets-grid-item .thumb-container:before,
.mq-sidebar-assets-grid-item .folder:before {
	content: '';
	display: block;
	padding-top: 100%;
}

.mq-sidebar-assets-grid-item .folder .mq-icon {
	fill: var(--folder-color);
	position: absolute;
	width: 48px;
	height: 48px;
	top: calc(50% - 24px);
	left: calc(50% - 24px);
}

.mq-sidebar-assets-grid-item img {
	position: absolute;
	top: 5%;
	left: 5%;
	width: 90%;
	height: 90%;
	object-fit: scale-down;
}

.mq-sidebar-header-controls .primary-action {
	white-space: nowrap;
}

.mq-sidebar-assets-grid-item .title-rename-input {
	margin-top: 6px;
}

.mq-sidebar-assets-grid-item.selected .title span {
	color: var(--editor-active-color);
}

.mq-sidebar-assets-grid-item.selected .thumb-container,
.mq-sidebar-assets-grid-item.selected .folder {
	border-color: var(--editor-active-color);
}

.mq-assets-thumb {
	width: 16px;
	height: 16px;
	object-fit: contain;
}

.mq-assets-container {
	display: flex;
	z-index: 0;
}

.mq-assets-no-items-dragsource {
	margin: 3px;
}

.has-stencil-counter .mq-assets-sidebar {
	bottom: 45px;
}
