.mq-link-dropdown {
	color: var(--editor-text-color);
	position: relative;
}

.mq-link-dropdown-current {
	cursor: pointer;
}

.mq-link-dropdown-current:after {
	content: ' \25be';
	/*color: #555;*/
}

.mq-link-dropdown .mq-link-dropdown-current a {
	text-decoration: underline;
}

.mq-link-dropdown .mq-link-dropdown-current.isDisabled {
	cursor: default;
}

.mq-link-dropdown .mq-link-dropdown-current.isDisabled a {
	cursor: default;
	text-decoration: none;
	color: var(--editor-text-color);
}

.mq-link-dropdown .mq-link-dropdown-current.isDisabled a:hover {
	text-decoration: none;
}

.mq-link-dropdown .mq-link-dropdown-current a:hover {
	text-decoration: underline;
}
