.gridborder {
	width: 100%;
}

.gridborder__ui {
	position: absolute;
	left: -50%;
	transform: translate(-50%, 0);
	margin-top: -1px;
	white-space: nowrap;
	display: flex !important;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: var(--toolbar-bg);
	box-shadow: var(--mq-box-shadow-lg);
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	border: 1px solid var(--toolbar-border-color);
	height: var(--toolbar-size);
	padding: 0 calc(var(--toolbar-gap) * 2);
	gap: calc(var(--toolbar-gap) * 1);
}

.gridborder__ui svg {
	overflow: visible;
	color: var(--toolbar-text-color);
	fill: var(--toolbar-text-color);
	width: var(--toolbar-icon-size);
	height: var(--toolbar-icon-size);
}

.gridborder__button {
	height: calc(var(--toolbar-size) - calc(var(--toolbar-gap)));
	min-width: calc(var(--toolbar-size) - calc(var(--toolbar-gap)));
	padding: 0 calc(var(--toolbar-gap) * 2.5);
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	cursor: pointer;
	background: transparent;
	border: none;
	border-radius: 4px;
	color: var(--toolbar-text-color);
	font-size: 13px;
	line-height: var(--toolbar-icon-size);
	font-weight: bold;
}

.gridborder__button--secondary {
	opacity: 0.85;
}

.gridborder__button svg {
	margin-right: 0.5em;
}

.gridborder__button:hover {
	color: var(--editor-active-color);
}

.gridborder__button:hover svg {
	color: var(--editor-active-color);
	fill: var(--editor-active-color);
}

.gridborder__button:focus {
	outline: none;
}

.gridborder__toolbar__separator {
	height: 22px;
	border-left: 1px solid var(--toolbar-border-color);
	margin: 0 calc(var(--toolbar-gap) * 2);
}
