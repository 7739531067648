.must-swatches-panel {
	font-family: var(--mq-font-main);
	overflow-y: auto;
	box-sizing: border-box;
	height: 17.8125rem;
	margin: 0 -0.75rem 0.625rem;
	padding: 0 0 0 0.75rem;
}

.must-swatch-row {
	display: flex;
	max-width: calc(var(--popup-width) - var(--must-scrollbar-width) - 0.75rem);
	margin-right: 0rem;
	margin-bottom: 0rem;
	flex-wrap: wrap;
}

.must-swatch-row:last-child {
	margin-bottom: 0;
}

.must-swatch-row > * {
	margin: 0 0.25rem 0.25rem 0;
}

/* Normal Swatches */
.must-swatch-row .must-swatch-button {
	border-radius: 0.3125rem;
}

.must-swatch-row .must-swatch {
	--size: 1.125rem;
	--radius: 0.125rem;
}

/* Small Swatches */
.must-swatches-panel--sm .must-swatch-row {
	margin-bottom: 0rem;
}

.must-swatches-panel--sm .must-color-palette__content {
	margin-bottom: 0.3125rem;
}

.must-swatches-panel--sm .must-swatch-row > * {
	margin: 0 0.125rem 0.125rem 0;
}

.must-swatches-panel--sm .must-swatch-row .must-swatch-button {
	border-radius: 0.125rem;
}

.must-swatches-panel--sm .must-swatch-row .must-swatch {
	--size: 0.9375rem;
	--radius: 0.125rem;
}

/* Large Swatches */
.must-swatches-panel--lg .must-swatch-row {
	margin-bottom: 0rem;
}

.must-swatches-panel--lg .must-swatch-row > * {
	margin: 0 0.375rem 0.375rem 0;
}

.must-swatches-panel--lg .must-color-palette__content {
	margin-bottom: 0.125rem;
}

.must-swatches-panel--lg .must-swatch-row .must-swatch-button {
	border-radius: 0.25rem;
}

.must-swatches-panel--lg .must-swatch-row .must-swatch {
	--size: 1.5rem;
	--radius: 0.25rem;
}

.must-swatch-row .must-swatch-button:hover {
	cursor: pointer;
}

.must-swatches-panel__resizer {
	position: relative;
	width: calc(100% + 1.5rem);
	height: 0.5rem;
	margin: 0.625rem -0.75rem -0.625rem;
	cursor: row-resize;
	background: var(--mq-white);
}

.must-swatches-panel__resizer::before {
	position: absolute;
	top: 0;
	left: calc(50% - 0.75rem);
	width: 1.5rem;
	height: 0.3125rem;
	content: '';
	border-bottom: 0.125rem solid var(--mq-grey-300);
}

.must-swatches-panel__resizer::after {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 0.125rem;
	content: '';
	border-top: 0.063rem solid var(--mq-grey-100);
}
