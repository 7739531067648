.fullscreen-notification {
	display: none;
	opacity: 1;
	transition: opacity 0.2s;
	position: fixed;
	top: 5em;
	left: 50%;
	transform: translate(-50%, 0);
	background: rgba(0, 0, 0, 0.6);
	padding: 1em;
	border-radius: 0.5em;
	color: var(--mq-white);
	font-size: 1.2em;
	z-index: 10000;
}

body.fullscreen .fullscreen-notification {
	display: block;
}

body.fullscreen .fullscreen-notification.dismiss {
	opacity: 0;
	pointer-events: none;
}

body:-full-screen .fullscreen-notification {
	display: none;
}

body:-moz-full-screen .fullscreen-notification {
	display: none;
}

body:-webkit-full-screen .fullscreen-notification {
	display: none;
}

body.fullscreen .fullscreen-nav-arrow {
	display: block;
}

.fullscreen-nav-arrow {
	display: none;
	position: fixed;
	height: 40%;
	width: 5%;
	font-size: 5rem;
	top: 30%;
	z-index: 25;
}

.fullscreen-nav-arrow a {
	pointer-events: none;
	opacity: 0;
	display: block;
	top: 50%;
	position: fixed;
	transform: translate(0, -50%);
	transition: opacity 0.15s;
	line-height: 1;
}

@media (hover: hover), (any-hover: hover), (-moz-touch-enabled: 0) {
	.fullscreen-nav-arrow:hover a {
		opacity: 1;
		pointer-events: visible;
	}
}

.fullscreen-nav-arrow a:active .mq-icon,
.fullscreen-nav-arrow a .mq-icon:active {
	fill: var(--editor-active-color);
}

.canvas-stage:fullscreen,
.canvas-stage:-webkit-full-screen {
	background: var(--stage-bg);
}

body.fullscreen #stage-wrapper {
	top: 0;
}

/*body.fullscreen #sidebar {
	display: none;
}*/

body.fullscreen #toolbar-wrapper {
	position: fixed;
	transition: top ease-in 0.15s 0.05s;
	top: -50px;
}

body.fullscreen #toolbar-wrapper:hover,
body.fullscreen #toolbar-wrapper.menu-shown {
	top: 0px;
}

body.fullscreen #toolbar-wrapper.hidden {
	display: none;
}

body.fullscreen #toolbar-wrapper:hover #toolbar-hit-detection {
	height: 10px;
	bottom: -10px;
}

#toolbar-hit-detection {
	display: none;
}

body.fullscreen #toolbar-hit-detection {
	position: absolute;
	bottom: -25px;
	width: 100%;
	height: 25px;
	display: block;
	left: 0;
}
/* For some reason Autoprefixer doesn't autoprefix this so we need to keep
the prefixed versions in style.css for now
*/
.canvas-stage:-webkit-full-screen {
	background: var(--stage-bg);
}
.canvas-stage:-moz-full-screen {
	background: var(--stage-bg);
}

.prev-arrow {
	left: 15px;
}

.prev-arrow a {
	left: 0;
}

.next-arrow {
	right: 15px;
}

.next-arrow a {
	right: 0;
}
