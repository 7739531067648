/* Sortable pages */

.drag-source {
	opacity: 0.25;
	pointer-events: none;
}

.drag-ghost {
	position: absolute;
	pointer-events: none;
	display: none;
	margin-left: 10px;
	margin-top: 10px;
}

.drag-ghost.dragging {
	display: block;
}

.droptarget {
	box-shadow: 0 0 0 2px var(--link-color) inset;
}

.droptarget-accept-link {
	box-shadow: 0 0 0 2px var(--success-text-color) inset;
}

.droptarget-flash-link {
	animation: droptarget-flash-animation 0.15s linear 3;
}

@keyframes droptarget-flash-animation {
	/*0% { box-shadow: 0 0 0 0 #00e59c inset; }*/
	50% {
		box-shadow: 0 0 0 2px var(--success-text-color) inset;
	}
}

#line-placeholder {
	height: 2px;
	background: var(--link-color);
	position: absolute;
	z-index: 10000;
	pointer-events: none;
}

#line-placeholder:before,
#line-placeholder:after {
	background: #fff;
	border-radius: 50%;
	border: 2px solid var(--link-color);
	position: absolute;
	transform: translate(-50%, -50%);
	width: 4px;
	height: 4px;
	content: ' ';
}

#line-placeholder:before {
	top: 50%;
	left: 0;
}

#line-placeholder:after {
	top: 50%;
	left: 100%;
}

#sortable-ghost {
	z-index: 999;
	background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIxMnB4IiBoZWlnaHQ9IjE0cHgiIHZpZXdCb3g9IjAgMCAxMiAxNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTIgMTQiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxnPjxwb2x5Z29uIGZpbGw9IiNGQ0ZDRkMiIHBvaW50cz0iMC41LDEzLjUgMC41LDAuNSA3LjQxOCwwLjUgMTEuNSw0LjU4MiAxMS41LDEzLjUiLz48cGF0aCBmaWxsPSIjNEM0QzREIiBkPSJNNy4yMTEsMUwxMSw0Ljc4OVYxM0gxVjFINy4yMTEgTTcuNjI1LDBIMHYxNGgxMlY0LjM3NUw3LjYyNSwwTDcuNjI1LDB6Ii8+PC9nPjxsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzRDNEM0RCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiB4MT0iNy42MjUiIHkxPSIxIiB4Mj0iNy42MjUiIHkyPSI0Ljg3NSIvPjxsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzRDNEM0RCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiB4MT0iMTEiIHkxPSI0LjM3NSIgeDI9IjcuNjI1IiB5Mj0iNC4zNzUiLz48L2c+PC9zdmc+DQo=')
		no-repeat center center;
	width: 12px;
	height: 14px;
}

/* /Sortable pages */
