.add-stencil-handle {
	cursor: copy;
}

.add-stencil-handle:hover path {
	fill: var(--normal-stroke);
}

.add-stencil-handle:active path {
	fill: var(--active-stroke);
}
