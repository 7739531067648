.selection-tooltip {
	position: absolute;
	top: 5px;
	left: 3px;
	z-index: 999;
	padding: 3px 4px;
	line-height: 1;
	box-sizing: border-box;
	border-radius: 3px;
	background: var(--mq-blue);
	color: var(--mq-white);
	border: 1px solid rgba(255, 255, 255, 0.1);
	white-space: pre;
	display: none;
	font-size: 10px;
	font-weight: 700;
	font-family: Consolas, Monaco, Courier, 'Courier New', monospace;
	contain: content;
	will-change: transform;
}

/*
	Don't show an empty tooltip.
 */
.selection-tooltip:empty {
	visibility: hidden;
}
