.must-pad {
	/* Positioning re: handle */
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 70.71%;
	border-radius: 0.1875rem;
	outline: none;
	background: var(--background);
	--background: var(--must-mq-grey-10);
	/*
		Make taps faster.
	 */
	touch-action: none;
	-webkit-touch-callout: none;
}

.must-pad:focus-within {
	z-index: 100;
}

.must-pad:focus {
	outline: none;
}

.must-pad:focus .must-handle::before {
	top: -0.125rem;
	right: -0.125rem;
	bottom: -0.125rem;
	left: -0.125rem;
	border: 0.125rem solid var(--must-mq-blue);
}

.must-pad:focus-visible .must-handle::before {
	top: -0.125rem;
	right: -0.125rem;
	bottom: -0.125rem;
	left: -0.125rem;
	border: 0.125rem solid var(--must-mq-blue);
}
