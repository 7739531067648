.snapping__wrapper {
	pointer-events: none;
}

.snapping__basepath {
	stroke: var(--guide-accent);
	stroke-width: 1;
	fill: none;
}

.snapping__textpath {
	stroke: var(--snap-accent);
	stroke-width: 1;
	fill: none;
}

.snapping__text {
	fill: var(--snap-accent);
	font-size: 10px;
	text-anchor: middle;
	/*alignment-baseline: central;*/
	dominant-baseline: central;
}

.snapping__pattern {
	overflow: visible;
}

.snapping__pattern path {
	stroke-width: 0.6;
	shape-rendering: auto;
	stroke: var(--snap-accent);
	stroke-linecap: square;
	overflow: visible;
	stroke-opacity: 0.75;
}
