.must-handle-tooltip {
	font-family: var(--mq-font-main);
	font-size: 0.75rem;
	position: absolute;
	z-index: 20;
	top: 0;
	left: 0;
	padding: 0.25rem 0.4375rem;
	user-select: none;
	transform: translate(-50%, calc((100% + var(--handle-size, 1em) * 0.5 + 0.5rem) * -1));
	white-space: nowrap;
	pointer-events: none;
	color: var(--mq-grey-100);
	border-radius: 0.25rem;
	background: var(--mq-grey-900);
}
