#help-tooltip {
	position: relative;
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.1s ease-in;
	z-index: 1000;
}

#help-tooltip.visible {
	opacity: 1;
	transition: opacity 0.05s ease-out;
}
