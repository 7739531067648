.simple-color-picker {
	display: flex;
	border: 1px solid var(--toolbar-border-color);
	background: var(--toolbar-bg);
	border-radius: calc(var(--inspector-border-radius) * 2);
	flex-wrap: wrap;
	width: calc(
		var(--swatch-size) * var(--swatch-count) + var(--swatch-spacing) * var(--swatch-count) * 2
	);
	padding: 0.5em;
	box-shadow: var(--mq-box-shadow);
	--swatch-count: 8;
	--swatch-size: 20px;
	--swatch-spacing: 3px;
	--swatch-radius: 9px;
}

.simple-color-picker .color {
	display: inline-block;
	box-sizing: border-box;
	width: var(--swatch-size);
	height: var(--swatch-size);
	margin: var(--swatch-spacing);
	border-radius: var(--swatch-radius);
	border: solid 1px rgba(125, 125, 125, 0.08);
	transition: border 0.1s var(--mq-transition);
}

.simple-color-picker .color--white-bk {
	border: solid 1px rgba(125, 125, 125, 0.08);
}

.simple-color-picker .color-picker-reset {
	background: var(--mq-white);
	display: inline-block;
	box-sizing: border-box;
	width: var(--swatch-size);
	height: var(--swatch-size);
	margin: var(--swatch-spacing);
	border-radius: var(--swatch-radius);
	position: relative;
	border: solid 1px var(--toolbar-border-color);
}

.simple-color-picker .color-picker-reset:after {
	content: ' ';
	position: absolute;
	top: 50%;
	left: 0%;
	transform: translate(0, -50%) rotate(45deg);
	width: 100%;
	height: 2px;
	background: red;
}

.simple-color-picker .color--selected {
	border: 1px solid var(--editor-text-color);
}

.simple-color-picker .color:hover,
.simple-color-picker .color-picker-reset:hover {
	border: 1px solid var(--editor-text-color);
	cursor: pointer;
}
