.open-crisp-chat {
	border: none;
	font: inherit;
	background: transparent;
	color: var(--mq-blue);
	cursor: pointer;
	display: block;
	width: 100%;
	text-align: left;
}

.open-crisp-chat:hover,
.open-crisp-chat:focus {
	text-decoration: underline;
	background: var(--bg-default);
	position: relative;
	z-index: 10;
}

.open-crisp-chat .mq-badge {
	font-weight: normal;
	margin-left: 0.75em;
	vertical-align: middle;
	background: var(--mq-blue);
	color: #fff;
	font-size: 0.8em;
}
