.must-kbd {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-family: var(--must-font-main);
	color: var(--editor-text-color);
}

.must-kbd span {
	margin: auto 0;
	font-size: 12px;
	max-width: 230px;
}

.must-kbd--keys {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 3px;
}

.must-kbd--key {
	background-color: var(--editor-highlight-bg);
	padding: 4px;
	font-size: 11px;
	border-radius: 4px;
	min-width: 13px;
	text-align: center;
	height: 15px;
}

.must-kbd--group {
	width: 280px;
	display: flex;
	flex-direction: column;
	row-gap: 10px;
}

.must-kbd--group > span {
	font-family: var(--must-font-main);
	color: var(--editor-text-color);
	font-size: 14px;
	font-weight: bold;
	padding-bottom: 2px;
}

.must-kbd--group > .must-kbd--group-separator {
	padding-top: 0;
	border-bottom: 1px solid var(--editor-border-color);
}

.must-kbd--group-content {
	display: flex;
	flex-direction: column;
	row-gap: 10px;
}
