/* =============================================================================
   Forms
   ========================================================================== */

.mq__signup input[type='text'],
.mq__signup input[type='password'],
.mq__signup input[type='number'],
.mq__signup input[type='email'],
select,
textarea {
	padding: 0.725em 0.75em;
	background-color: var(--input-bg);
}

.mq__signup .mq__input {
	margin-top: 1rem;
}

.mq-file-input {
	position: absolute;
	right: 41px;
	left: 0;
	top: -8px;
	height: 37px;
	font-size: 10px;
	cursor: pointer;
	direction: ltr;
	opacity: 0;
	width: 100px;
	z-index: 2;
}

/* Todo make me pretty */
.mq-input-error {
	color: var(--error-text-color);
	font-size: 0.75rem;
	font-weight: 500;
}

/* =============================================================================
   Alt Forms styles
   ========================================================================== */

.mq__form {
}

.mq__form button[type='submit'] {
	margin-top: 30px;
}
.mq__signup .mq__form button[type='submit'] {
	margin-top: 34px;
}

.mq__input {
	display: flex;
	flex-direction: column;
	margin-top: 0.7rem;
}

.mq__input--readonly input {
	background: #ebebeb;
}

.mq__input label {
	color: var(--editor-text-color);
	font-weight: bold;
	margin-bottom: 0.7rem;
}

.mq__input span {
	color: var(--error-text-color);
	font-size: 0.75rem;
	font-weight: 500;
}

.mq__input input {
	width: 100%;
	border: 1px solid #c0c0c0;
}

.mq__input input:focus,
.mq__input input:hover {
	border: 1px solid #006ce5;
}

.mq__name__input input {
	padding: 1em 0.5em;
}

.mq__terms__checkbox {
	margin-top: 0.4rem;
	font-size: 0.825em;
}

.mq__terms__checkbox label {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	letter-spacing: -0.2px;
}
.mq__terms__checkbox label input[type='checkbox'] {
	margin-right: 0.625em;
	transform: scale(1.125);
	cursor: pointer;
}

.mq__signup {
	color: #3b4753;
}

.mq__signup .mq__terms__checkbox label {
	padding-top: 4px;
	color: var(--editor-text-muted-color);
}

.mq__signup .mq__terms__checkbox a:focus {
	text-decoration: underline;
}

.mq__signup .mq__terms__checkbox label span {
	padding-left: 1px;
}
.mq__signup .mq__terms__checkbox label input[type='checkbox'] {
	padding-top: 4px;
	color: #4a5a6a !important;
}

.mq__terms__checkbox .mq-input-error {
	display: block;
}

.secondary__login__link {
	display: flex;
	justify-content: center;
	flex-grow: 1;
	color: rgb(61, 138, 247);
	font-weight: 600;
	line-height: 20px;
	text-align: center;
	align-items: center;
}

.input__instruction {
	color: var(--editor-text-muted-color);
	font-weight: normal;
	margin-left: 0.3em;
}
