.contextual-toolbar-border {
	width: 100%;
}

.contextual-toolbar-border__ui {
	position: absolute;
	left: -50%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
	transform: translate(-50%, 0);
	white-space: nowrap;
	color: var(--toolbar-text-color);
	background: var(--toolbar-bg);
	box-shadow: var(--mq-box-shadow-lg);
	border-radius: calc(var(--inspector-border-radius) * 1.5);
	border: 1px solid var(--toolbar-border-color);
	height: var(--toolbar-size);
	padding: 0 calc(var(--toolbar-gap) * 2);
	gap: calc(var(--toolbar-gap) * 1);
}

.contextual-toolbar-border__ui svg,
.contextual-toolbar-border__button svg {
	color: var(--toolbar-text-color);
	fill: var(--toolbar-text-color);
	width: var(--toolbar-icon-size);
	height: var(--toolbar-icon-size);
}

.contextual-toolbar-border__separator {
	height: 22px;
	border-left: 1px solid var(--toolbar-border-color);
	margin: 0 calc(var(--toolbar-gap) * 1.5) 0 calc((var(--toolbar-gap) * 1.5) + 1px);
}

.contextual-toolbar-border__button {
	height: calc(var(--toolbar-size) - calc(var(--toolbar-gap) * 2.5));
	min-width: calc(var(--toolbar-size) - calc(var(--toolbar-gap) * 2.5));
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	cursor: pointer;
	background: transparent;
	border: none;
	border-radius: 4px;
}

.contextual-toolbar-border__ui .contextual-toolbar-border__button:hover svg {
	fill: var(--editor-active-color);
	--editor-icon-color-tone: var(--editor-active-color);
}

.contextual-toolbar-border__button:focus {
	outline: none;
}

.contextual-toolbar-border__button--active {
	background: var(--toolbar-selection-bg);
	box-shadow: var(--mq-box-shadow-inset-sm);
}

.contextual-toolbar-border__button--active svg {
	fill: var(--editor-active-color);
}
