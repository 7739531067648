/* Image panel */

.mq-image-upload {
	display: inline;
	position: relative;
	cursor: pointer;
	overflow: hidden;
	font-size: 0.875rem;
}

.upload-assets-action {
	padding: 0.3em 0;
}

.upload-assets-action .mq-file-input {
	font-size: 0;
}

.mq-sidebar-upload-status {
	border-top: 1px solid var(--editor-menu-border-color);
	background-color: var(--editor-menu-bg);
}

.mq-sidebar-upload-status:empty {
	border: none;
}

.mq-image-upload:hover .mq-image-upload-action,
.mq-image-upload:hover .mq-image-upload-action > svg {
	color: var(--editor-active-color);
	fill: var(--editor-active-color);
}

.mq-image-upload .mq-image-upload-action {
	line-height: 36px;
	margin: 0;
	text-align: center;
	cursor: pointer;
}

.mq-image-upload p {
	line-height: 1.5;
	margin: 0;
	padding: 0.75em 1em;
	text-align: center;
	font-size: 12px;
	cursor: pointer;
}

.mq-image-upload p em {
	color: var(--editor-text-muted-color);
}

.mq-image-upload p strong {
	color: var(--editor-text-color);
	font-weight: 500;
	display: block;
}

.mq-image-upload:hover p strong {
	color: var(--editor-active-color);
}

.mq-image-upload .mq-icon {
	width: 16px;
	height: 16px;
}

.mq-image-upload-bar {
	position: absolute;
	top: -1px;
	left: 0;
	height: 3px;
	background: var(--editor-active-color);
	width: 1%;
	transition: all 0.3s ease;
}

.mq-image-upload .images-pending-status {
	color: red;
}
