.must-tab-button {
	font-size: 0.75rem;
	position: relative;
	overflow-y: hidden;
	margin-bottom: -0.0625rem;
	padding: 0.625rem 0 0.75rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	border: none;
	background: transparent;
}

.must-tab-button:active {
	color: inherit;
}

.must-tab-button .must-icon {
	vertical-align: top;
}

.must-tab-button::after {
	position: absolute;
	right: 0;
	bottom: 0rem;
	left: 0;
	height: 0.0625rem;
	content: '';
	transition: all 0.4s var(--mq-transition);
	background-color: transparent;
}

.must-tab-button:not(.must-tab-button--active):hover {
	cursor: pointer;
}

.must-tab-button:not(.must-tab-button--active):hover::after {
	background-color: var(--mq-grey-400);
}

/*
	Focused tab should always come on top
	so that its outline doesn't get truncated.
 */
.must-tab-button:focus {
	z-index: 1;
}

.must-tab-button:focus-visible {
	z-index: 1;
}

.must-tab-button--active {
	color: var(--mq-blue);
}

.must-tab-button--active::after {
	height: 0.125rem;
	background-color: var(--mq-blue);
}
