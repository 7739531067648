/*.welcome__project {
	position: relative;
	width: calc(33.3% - 20px);
	display: flex;
	flex-direction: column;
	margin: 10px;
	border-radius: var(--welcome-border-radius);
	box-sizing: border-box;
	box-shadow: 0 2px 3px 1px rgba(41, 50, 61, 0.08);
	text-decoration: none !important;
	min-width: 100px;
}

.welcome__project:after {
	content: '';
	display: block;
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
	border-radius: var(--welcome-border-radius);
	border: 2px solid transparent;
	pointer-events: none;
	transition: border-color 0.15s;
}

.welcome__project:hover:after,
.welcome__project:focus:after {
	border-color: #1c91f7;
}

.welcome__project__preview {
	height: 130px;
	background-size: cover;
	border-top-right-radius: var(--welcome-border-radius);
	border-top-left-radius: var(--welcome-border-radius);
}

.welcome__project__preview > div {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ffffff;
	border-top-right-radius: var(--welcome-border-radius);
	border-top-left-radius: var(--welcome-border-radius);
}

.welcome__project__preview svg {
	font-size: 3em;
}

.welcome__project__title {
	padding: 0.8em 1em;
	text-align: center;
	font-size: 12px;
	font-weight: bold;
	color: #4a5a6a;
	background-color: #f7f8fa;
	border-top: 1px solid #eeefef;
	border-bottom-right-radius: var(--welcome-border-radius);
	border-bottom-left-radius: var(--welcome-border-radius);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

@media (--tablet) {
	.welcome__project {
		width: calc(50% - 20px);
	}
}

@media (--phone) {
	.welcome__project {
		width: 100%;
	}
}
*/
