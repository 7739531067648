.force-bullets-because-i-dont-get-css {
	list-style: circle;
	padding-left: 2em;
}

.mq-basic-divider {
	margin: 0;
	padding: 10px 0;
	position: relative;
}

.mq-text-divider {
	text-align: center;
	color: var(--editor-text-muted-color);
	margin: 0;
	padding: 10px 0;
	position: relative;
}

.mq-text-divider em {
	background: var(--modal-bg);
	padding: 0.5em;
	position: relative;
	z-index: 2;
}

.mq-text-divider span {
	width: 100%;
	height: 1px;
	background: var(--editor-text-muted-color);
	position: absolute;
	left: 0;
	top: 50%;
	z-index: 1;
}
.mq-basic-divider2 {
	color: transparent;
	padding-top: 4px;
	position: relative;
}
.mq-auth-checkboxes {
	font-size: 12px;
}

.custom-auth-row {
	gap: 0.4rem;
}

.custom-auth-row a {
	min-width: 0;
	border: 1px solid var(--input-border-color);
	border-radius: 0.4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--button-login-bg);
	height: 40px;
	text-decoration: none;
	transition: border-color 0.1s ease-out, color 0.1s ease-out;
	box-sizing: border-box;
	color: var(--button-text-color);
	padding: 0px 12px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03);
}

.custom-auth-row a:hover {
	color: var(--editor-active-color);
	border-color: var(--editor-active-color);
	background: var(--button-login-bg);
}

.custom-auth-row a:focus {
	color: var(--editor-active-color);
	border-color: var(--editor-active-color);
	background: var(--editor-semiselection-bg);
}

.plain-container__rightdivider {
	margin-right: 7px;
}
