.mq-unsupported {
	position: absolute;
	z-index: 1001;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0.96;
	background: var(--editor-bg);
	text-align: center;
	color: var(--editor-text-color);
	font-size: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.mq-unsupported .mq-icon {
	fill: var(--editor-active-color);
	width: 10em;
	height: 4em;
	max-width: 60vw;
	display: inline-block;
}

.current-ua {
	max-width: 70vw;
	margin-bottom: 2em;
	color: var(--editor-text-muted-color);
}

.mq-unsupported footer {
	margin-top: 2em;
}
