.double-check-link .mq__modal__actions {
	padding: 2em 0 0;
	margin: 0;
	display: flex;
	justify-content: flex-end;
}

.double-check-link .mq__modal__actions > * {
	margin-left: 0.5em;
}

.double-check-link .mq__button {
	font-size: 1em;
	line-height: 1;
	font-weight: normal;
}

.double-check-link var {
	background: #f0f0f0;
	border: 1px solid #ddd;
	font-weight: bold;
	padding: 0.1em 0.25em;
}

.external-link {
	text-decoration: underline;
	text-decoration-color: #ccc;
}

.external-link:hover {
	text-decoration-color: currentColor;
}
