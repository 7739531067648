.individual-border {
	pointer-events: none;
	stroke: var(--normal-stroke);
	stroke-width: 1px;
	fill: none;
}

.individual-border--locked {
	stroke: var(--lock-stroke);
}
