.paste-options__modal p {
	color: var(--editor-text-muted-color);
	font-size: 14px;
	margin-bottom: 18px;
	max-width: 80%;
	margin-top: 0;
}

.paste-options__meta {
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	column-gap: 10px;
	flex-wrap: wrap;
	justify-content: space-between;
}

.paste-options__actions {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 10px;
	justify-content: space-between;
	margin: auto 0;
	flex-wrap: wrap;
}

.paste-options__actions button {
	min-width: 120px;
}
