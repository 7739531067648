.must-color-palette {
	margin: 0;
}

.must-color-palette__title {
	font-size: 0.6875rem;
	line-height: 1rem;
	display: flex;
	width: 100%;
	max-width: 11.25rem;
	margin: 0rem 0 0.5rem -0.25rem;
	padding: 0rem 0;
	cursor: pointer;
	transition: all 0.08s linear;
	text-align: left;
	text-indent: -0.25rem;
	color: var(--mq-grey-500);
	border: none;
	background: none;
	align-items: center;
}

.must-color-palette__title:hover {
	color: var(--mq-grey-600);
}

.must-color-palette__title .must-icon {
	font-size: 1rem;
	line-height: 0;
	display: inline-block;
	fill: currentColor;
}

.must-color-palette__title .must-icon.must-icon__chevron {
	transition: transform 0.08s linear;
	transform: rotate(-90deg);
}

.must-color-palette__title.open .must-icon.must-icon__chevron {
	transform: rotate(0);
}

.must-color-palette__title .must-icon.must-icon__folder {
	margin: 0 0.5625rem 0 0;
}

.must-color-palette__content {
	margin-bottom: 0.1875rem;
}
