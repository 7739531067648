.export__options {
	font-size: 13px;
}

.export__options__section {
	margin: 1.25em 0 0;
}
.export__options__section > div {
	margin-top: 0.4em;
	display: inline-flex;
	align-items: center;
	width: 100%;
}

.export__options__section > div.export-scale-menu {
	margin-top: 0;
}

.export__options__section label {
	display: inline-flex;
	color: var(--editor-text-muted-color);
	align-items: center;
	padding-right: 1.9em;
	gap: 7px;
}

.export__options__section input[type='radio']:checked + span,
.export__options__section input[type='checkbox']:checked + span {
	color: var(--editor-text-color);
}

.export-scale-menu span {
	line-height: 2em;
}

.export__format {
	border-bottom: 1px solid var(--editor-menu-border-color);
	margin: 0 -30px;
	padding: 0 30px;
	display: flex;
	align-items: flex-end;
	justify-content: stretch;
}

.export__format:focus,
.export__format__option:focus {
	outline: none;
}

.export__format__option {
	padding: 12px 10px 10px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: auto;
	border-bottom: 2px solid transparent;
}
.export__format__option span {
	font-size: 13px;
}

.export__option-info {
	font-size: 13px;
	color: var(--editor-text-muted-color);
	display: inline-block;
	margin-left: 5px;
}

.export__format__option:hover,
.export__format__option.uix-list__item--selected {
	color: var(--editor-active-color);
}
.export__format__option.uix-list__item--selected {
	font-weight: 600;
}

.export__format__option:hover .mq-icon,
.export__format__option.uix-list__item--selected .mq-icon {
	fill: var(--editor-active-color);
}
.export__format__option.uix-list__item--selected {
	border-bottom: 2px solid var(--editor-active-color);
}

.export__message {
	margin: 1em 0 0 0;
}

.export__progress .mq-progress-bar {
	height: 0.5em;
}

.export__progress + p {
	text-align: right;
}

.export__progress__label {
	font-size: 14px;
	text-align: center;
	color: var(--editor-text-color);
	margin: 20px 0 15px;
}

.export__password {
	margin: 0 0 10px 20px;
	height: 38px;
	width: 100%;
}

.mq-modal--export {
	padding: 15px 30px 30px;
}

/* revert compact buttons in modal version of export */
.mq-modal--export .mq__button--compact {
	padding: 8px 16px;
	min-width: 6em;
	height: 38px;
	font-size: 14px;
}

.export-popover .export__options {
	padding: 0 1.25em 1.25em;
}

.export-popover .mq-modal-footer {
	padding-top: 20px;
}

.export-popover .export__format {
	margin: 0 -1.25em;
	padding: 0 1.25em;
}

.export-popover .export__password {
	background: var(--modal-bg);
}
